import { TitleIcon } from 'components/blocks/EventsTimeline/components/icons/TitleIcon';
import { CommentIcon } from 'components/blocks/EventsTimeline/components/icons/CommentIcon';
import { CrawlerIcon } from 'components/blocks/EventsTimeline/components/icons/CrawlerIcon';
import { GuestIcon } from 'components/blocks/EventsTimeline/components/icons/GuestIcon';
import { LayoutIcon } from 'components/blocks/EventsTimeline/components/icons/LayoutIcon';
import { PictureIcon } from 'components/blocks/EventsTimeline/components/icons/PictureIcon';
import { ScreenIcon } from 'components/blocks/EventsTimeline/components/icons/ScreenIcon';
import { VideoIcon } from 'components/blocks/EventsTimeline/components/icons/VideoIcon';
import { BigCommentIcon } from 'components/blocks/EventsTimeline/components/icons/BigCommentIcon';
import { BigLikeIcon } from 'components/blocks/EventsTimeline/components/icons/BigLikeIcon';
import { PriceTagIcon } from 'components/blocks/EventsTimeline/components/icons/PriceTagIcon';
import { AmazonProductIcon } from 'components/blocks/EventsTimeline/components/icons/AmazonProductIcon';
import { FollowIcon } from 'components/blocks/EventsTimeline/components/icons/FollowIcon';

export const EVENTS_LOADED_ACTION = 'EVENTS_LOADED_ACTION';
export const RESET_TIMELINE_EVENTS = 'RESET_TIMELINE_EVENTS';

export const EVENT_TYPES = {
  TITLE: 'TITLE',
  COMMENT: 'COMMENT',
  CRAWLER: 'CRAWLER',
  GUEST: 'GUEST',
  LAYOUT: 'LAYOUT',
  PICTURE: 'PICTURE',
  SCREEN: 'SCREEN',
  VIDEO: 'VIDEO',
  LIVE_SHOPPING: 'LIVE_SHOPPING',
  FEATURED_PRODUCT: 'FEATURED_PRODUCT',
};

export const PEAK_EVENT_TYPES = {
  COMMENT: 'COMMENT',
  LIKE: 'LIKE',
  FOLLOW: 'FOLLOW',
};

export const EVENT_ICONS = {
  [EVENT_TYPES.TITLE]: TitleIcon,
  [EVENT_TYPES.COMMENT]: CommentIcon,
  [EVENT_TYPES.CRAWLER]: CrawlerIcon,
  [EVENT_TYPES.GUEST]: GuestIcon,
  [EVENT_TYPES.LAYOUT]: LayoutIcon,
  [EVENT_TYPES.PICTURE]: PictureIcon,
  [EVENT_TYPES.SCREEN]: ScreenIcon,
  [EVENT_TYPES.VIDEO]: VideoIcon,
  [EVENT_TYPES.LIVE_SHOPPING]: PriceTagIcon,
  [EVENT_TYPES.FEATURED_PRODUCT]: AmazonProductIcon,
};

export const EVENT_TITLES = {
  [EVENT_TYPES.TITLE]: 'Title',
  [EVENT_TYPES.COMMENT]: 'Comment on Screen',
  [EVENT_TYPES.CRAWLER]: 'Crawler',
  [EVENT_TYPES.GUEST]: 'Guest',
  [EVENT_TYPES.LAYOUT]: 'Change Layout',
  [EVENT_TYPES.PICTURE]: 'Picture',
  [EVENT_TYPES.SCREEN]: 'Screen',
  [EVENT_TYPES.VIDEO]: 'Video',
  [EVENT_TYPES.LIVE_SHOPPING]: 'Live Sales',
  [EVENT_TYPES.FEATURED_PRODUCT]: 'Amazon Product',
};

export const PEAK_EVENT_ICONS = {
  [PEAK_EVENT_TYPES.COMMENT]: BigCommentIcon,
  [PEAK_EVENT_TYPES.LIKE]: BigLikeIcon,
  [PEAK_EVENT_TYPES.FOLLOW]: FollowIcon,
};
