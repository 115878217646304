import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { sagaMiddleware } from 'common/sagas/sagaMiddleware';
import { ENTER_HOME_PAGE, LEAVE_HOME_PAGE } from './actions';
import {
  openEditorToolboxItem,
  openMediaToolboxItem,
} from 'common/Redux/Toolbox';
import { pausePreviewPlayer } from 'common/Redux/Preview/PreviewActions';
import { selectActiveProjectActiveMedia } from '../Project/selectors';
import { openProjectIfExist } from '../Project/sagas';
import { ActionWithPayload } from 'common/Redux/types';
import { RouteComponentProps } from 'react-router-dom';

function* handleHomePageEnter(
  props: ActionWithPayload<RouteComponentProps<{ id: string }>>
) {
  const projectId: string = props?.payload?.match?.params?.id;

  if (!projectId) throw new Error('Project id has not been found');

  yield call(openProjectIfExist, Number(projectId));

  const activeProjectMedia = yield select(selectActiveProjectActiveMedia);

  if (activeProjectMedia) {
    yield put(openEditorToolboxItem());
  } else {
    yield put(openMediaToolboxItem());
  }
}

function* handleHomePageLeave() {
  yield put(pausePreviewPlayer());
}

function* routerRootSaga() {
  yield all([
    takeEvery(ENTER_HOME_PAGE, handleHomePageEnter),
    takeEvery(LEAVE_HOME_PAGE, handleHomePageLeave),
  ]);
}

sagaMiddleware.run(routerRootSaga);
