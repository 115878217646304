import React, { FC } from 'react';

export const RefreshIcon: FC<{
  active?: boolean;
  onClick?: () => void;
  className?: string;
}> = (props) => (
  <svg {...props} viewBox="9 9 20 20">
    <circle
      cx="9"
      cy="9"
      r="9.5"
      stroke="none"
      fill="inherit"
      transform="translate(10 10)"
    />
    <path
      className="refresh-arrow"
      fill="none"
      fillRule="nonzero"
      stroke="inherit"
      strokeLinecap="round"
      strokeWidth="1.5"
      transform="translate(10 10)"
      strokeLinejoin="round"
      d="M3.105 6.946L4.472 9.33l2.1-1.774"
    />
    <path
      className="refresh-arrow"
      fill="none"
      fillRule="nonzero"
      stroke="inherit"
      strokeLinecap="round"
      strokeWidth="1.5"
      transform="translate(10 10)"
      d="M12.8 5.879C11.937 4.86 10.542 4.2 8.968 4.2c-2.62 0-4.313 2.204-4.313 4.463"
    />
    <path
      className="refresh-arrow"
      fill="none"
      fillRule="nonzero"
      stroke="inherit"
      strokeLinecap="round"
      strokeWidth="1.5"
      transform="translate(10 10)"
      strokeLinejoin="round"
      d="M14.895 11.054L13.528 8.67l-2.1 1.774"
    />
    <path
      className="refresh-arrow"
      fill="none"
      fillRule="nonzero"
      stroke="inherit"
      strokeLinecap="round"
      strokeWidth="1.5"
      transform="translate(10 10)"
      d="M5.2 12.121C6.063 13.14 7.458 13.8 9.032 13.8c2.62 0 4.313-2.204 4.313-4.463"
    />
  </svg>
);
