import React, { FC } from 'react';

export const AddIcon: FC<{ active?: boolean }> = React.memo((props) => (
  <svg viewBox="0 0 33 33" {...props}>
    <path
      fill="inherit"
      stroke="inherit"
      fillRule="nonzero"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M31.062960886496502,16.54836248621601 H2.089413929552889 M16.570951139725896,30.81652392194995 V1.980201050482207 "
    />
  </svg>
));
