import React from 'react';
import styled, { css } from 'styled-components';

export const PlayPauseIcon = ({ ...props }) => {
  return (
    <StyledPlayPause viewBox="0 0 24 24" {...props}>
      <rect
        x="6"
        y="4"
        width="5"
        height="16"
        rx="1.5"
        stroke="none"
        fill="inherit"
      />
      <rect
        x="13"
        y="4"
        width="5"
        height="16"
        rx="1.5"
        stroke="none"
        fill="inherit"
      />
      <path
        d="M7.01538 10.6667V13.3333C7.01538 16.3105 7.01538 17.799 7.98639 18.3817C8.9574 18.9643 10.2708 18.2638 12.8977 16.8627L15.3977 15.5294C18.3095 13.9765 19.7654 13.2 19.7654 12C19.7654 10.8 18.3095 10.0235 15.3977 8.47059L12.8977 7.13725C10.2708 5.73625 8.9574 5.03574 7.98639 5.61835C7.01538 6.20095 7.01538 7.68952 7.01538 10.6667Z"
        stroke="none"
        fill="inherit"
      />
    </StyledPlayPause>
  );
};

export const StyledPlayPause = styled.svg`
  * {
    transition: transform 0.2s;
    transform-origin: 50% 50%;
  }

  rect {
    transform: rotateZ(90deg) scale(0);
  }

  ${({ isPause }) =>
    isPause &&
    css`
      rect {
        transform: rotateZ(0) scale(1);
      }
      path {
        transform: rotateZ(-90deg) scale(0);
      }
    `};
`;
