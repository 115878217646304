import React from 'react';
import { connect } from 'react-redux';
import { TimelineZoomChange } from 'common/Redux';
import 'common/Utils.js';
import 'components/blocks/Timeline/TimelineZoom/TimelineZoom.css';
import { Button } from 'components/ui/Button/Button';
import {
  MAX_ZOOM,
  MIN_ZOOM,
} from 'components/blocks/Timeline/TimelineZoom/constant';
import { BUTTON_SIZES_ENUM } from 'components/ui/Button/types';

class TimeLineZoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = { zoom: 1 };
  }

  componentDidMount = async () => {
    this.timelineZoomButtonsVisibillityUpdate(this.props.TimeLine.currentZoom);
  };

  render = () => {
    const { currentZoom } = this.props.TimeLine;
    const isZoomInDisabled = currentZoom >= MAX_ZOOM;
    const isZoomOutDisabled = currentZoom <= MIN_ZOOM;

    return (
      <div className="TimeLineZoom">
        <Button
          className="TimeLineZoomFit"
          size={BUTTON_SIZES_ENUM.XS}
          onClick={this.timelineZoomFit}
        >
          Fit
        </Button>
        <Button
          className="TimeLineZoomOut"
          size={BUTTON_SIZES_ENUM.XS}
          disabled={isZoomOutDisabled}
          onClick={this.timelineZoomDownChange}
        >
          - Zoom Out
        </Button>
        <Button
          size={BUTTON_SIZES_ENUM.XS}
          disabled={isZoomInDisabled}
          className="TimeLineZoomIn"
          onClick={this.timelineZoomUpChange}
        >
          + Zoom In
        </Button>
      </div>
    );
  };

  timelineZoomUpChange = () => {
    console.log('TimelineZoomUpChange');
    let newZoomValue = Math.min(this.props.TimeLine.currentZoom * 2, MAX_ZOOM);
    this.timelineZoomButtonsVisibillityUpdate(newZoomValue);
  };

  timelineZoomDownChange = () => {
    console.log('TimelineZoomDownChange');
    let newZoomValue = Math.max(this.props.TimeLine.currentZoom / 2, MIN_ZOOM);
    this.timelineZoomButtonsVisibillityUpdate(newZoomValue);
  };

  timelineZoomFit = () => {
    console.log('TimelineZoomFit');
    let newZoomValue = 1;
    this.timelineZoomButtonsVisibillityUpdate(newZoomValue);
  };

  timelineZoomButtonsVisibillityUpdate = (zoomValue) => {
    this.props.TimelineZoomChange(zoomValue);
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    TimelineZoomChange: (zoom) => dispatch(TimelineZoomChange(zoom)),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(TimeLineZoom);
