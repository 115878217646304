import React, { FC } from 'react';

export const ShareIcon: FC<{ active?: boolean }> = React.memo((props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M15.5 11H16C17.1046 11 18 11.8954 18 13V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V13C6 11.8954 6.89543 11 8 11H8.5"
      fill="none"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3V15M12 3L8 7M12 3L16 7"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
