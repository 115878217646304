const noop = () => {};

if (process.env.NODE_ENV !== 'development') {
  Object.assign(window.console, {
    assert: noop,
    clear: noop,
    debug: noop,
    error: noop,
    info: noop,
    log: noop,
    table: noop,
    trace: noop,
    warn: noop,
    dir: noop,
    dirxml: noop,
  });
}
