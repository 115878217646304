import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { conditionalStyle, opacity } from 'utils/styles';
import { colors } from 'common/colors';
import { ActiveStyle, NewStyle } from 'common/styles';
import { PreviewColorTheme } from 'components/blocks/PreviewThemes/types';
import { StyledFC } from '../../../../types';

export type ThemeIndicatorProps = {
  theme: PreviewColorTheme;
  isActive: boolean;
  withImage?: boolean;
  onClick: () => void;
};

export const ThemeIndicator: StyledFC<PropsWithChildren<ThemeIndicatorProps>> = ({
  theme,
  isActive,
  withImage,
  onClick,
  children,
}) => (
  <ThemeIndicator.Styled theme={theme} isActive={isActive} withImage={withImage} onClick={onClick}>
    A
    {children}
  </ThemeIndicator.Styled>
);

ThemeIndicator.Styled = styled.div<ThemeIndicatorProps>`
  display: flex;
  position: relative;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  margin: 8px;
  cursor: pointer;
  transition: box-shadow 0.1s;
  font-family: Inter, sans-serif;

  border: 1px solid ${opacity(colors.fog, 0.5)};
  color: ${({ theme: { textColor } }) => textColor};
  background-color: ${({ theme: { backgroundColor } }) => backgroundColor};
  ${({ withImage, theme: { backgroundImage }}) => withImage && css`
    background-image: url("${backgroundImage}");
    background-size: 100% 100%;
  `};

  ${({ theme }) => theme.isNew && NewStyle};

  ${conditionalStyle({ isActive: ActiveStyle })}
`;
