import React, { FC, useCallback } from 'react';
import { StyledIndicators } from 'components/blocks/PreviewThemes/styles';
import { StyledBlockName } from 'common/styles';
import { ThemeIndicator } from 'components/blocks/PreviewThemes/components/ThemeIndicator';
import {
  previewColorThemes,
  previewColorThemesOrder,
} from 'components/blocks/PreviewThemes/constants';
import { PreviewColorThemeType } from 'components/blocks/PreviewThemes/types';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import {
  analyticsEvents,
  analyticsOverlayColorNames,
} from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { CustomTheme } from './components/CustomTheme';

export const PreviewThemes: FC<{
  themeType?: PreviewColorThemeType;
  setColorThemeType?: (theme: PreviewColorThemeType) => void;
}> = ({
  setColorThemeType = () => {},
  themeType = previewColorThemesOrder[0],
}) => {
  const onThemeChanged = (newColorThemeType: PreviewColorThemeType) => {
    setColorThemeType(newColorThemeType);
    analytics.trackEvent(analyticsEvents.TEXT_OVERLAY_COLORS_CLICKED, {
      Style: analyticsOverlayColorNames[newColorThemeType],
    });
  };

  const onCustomClick = useCallback(
    () => onThemeChanged(PreviewColorThemeType.Custom),
    [onThemeChanged]
  );
  const onCustomDeselect = useCallback(
    () => onThemeChanged(previewColorThemesOrder.slice(-1)[0]),
    [onThemeChanged, previewColorThemesOrder]
  );

  return (
    <>
      <StyledBlockName>Backgrounds</StyledBlockName>
      <StyledIndicators>
        {previewColorThemesOrder.map((currentThemeType) => (
          <ThemeIndicator
            theme={previewColorThemes[currentThemeType]}
            key={currentThemeType}
            isActive={themeType === currentThemeType}
            onClick={() => onThemeChanged(currentThemeType)}
          />
        ))}
        <CustomTheme
          isActive={themeType === PreviewColorThemeType.Custom}
          onClick={onCustomClick}
          onDeselect={onCustomDeselect}
        />
      </StyledIndicators>
    </>
  );
};
