import {
  FETCH_BROADCASTS_SUCCESS,
  SELECTED_MEDIA,
} from './BroadcastsListTypes';

import { SET_BROADCAST_ID, UPDATED_VIDEOURL } from '../Constants';

const initialState = {
  userId: 564711,
  broadcastId: null,
  isBroadcastsReceived: false,
  broadcastList: [],
};

const BroadcastsListReducer = (state = initialState, action) => {
  let result;
  switch (action.type) {
    case FETCH_BROADCASTS_SUCCESS:
      result = {
        ...state,
        userId: state.userId,
        isBroadcastsReceived: true,
        broadcastList: action.payload,
      };
      break;
    case SELECTED_MEDIA:
      result = {
        ...state,
        selectedMedia: action.payload,
      };
      break;
    case UPDATED_VIDEOURL:
      result = {
        ...state,
        videoUrl: action.payload,
      };
      break;
    case SET_BROADCAST_ID:
      result = {
        ...state,
        broadcastId: action.payload,
      };
      break;
    default:
      result = state;
  }
  return result;
};

export default BroadcastsListReducer;
