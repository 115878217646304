import { connect } from 'react-redux';
import { createSelector } from 'common/Redux/selectors';
import { PreviewThemes } from 'components/blocks/PreviewThemes/PreviewThemes';
import { setColorThemeType } from 'common/Redux/Preview/PreviewActions';
import { selectColorThemeType } from 'common/Redux/Preview/selectors';

const mapStateToProps = createSelector({
  themeType: selectColorThemeType,
});

const mapDispatchToProps = {
  setColorThemeType,
};

export const PreviewThemesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewThemes);
