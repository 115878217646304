import React, { FC } from 'react';
import { components } from 'react-select';
import { CROSS_ARROW_DIRECTION } from 'components/ui/Icons/CrossArrowIcon';
import { StyledCrossArrowIcon } from 'components/ui/Select/SelectComponentStyles';

export const DropdownIndicator: FC<any> = (props) => (
  <components.DropdownIndicator {...props}>
    <StyledCrossArrowIcon
      direction={
        props.selectProps.menuIsOpen
          ? CROSS_ARROW_DIRECTION.UP
          : CROSS_ARROW_DIRECTION.DOWN
      }
    />
  </components.DropdownIndicator>
);
