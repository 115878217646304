import styled from 'styled-components';

import { colors } from 'common/colors';
import { Button } from 'components/ui/Button';
import { AddIcon } from 'components/ui/Icons/AddIcon';
import { VideoIcon } from 'components/ui/Icons/VideoIcon';
import { Loader } from 'components/ui/Loader';
import { Support } from 'components/ui/Support/Support';

export const StyledVideoProjectTitle = styled.div`
  display: flex;
`;

export const StyledCreateProjectButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  margin-left: 32px;
`;

export const StyledPlusIcon = styled(AddIcon)`
  height: 14px;
  width: 14px;
  stroke: ${colors.black};
  margin-right: 8px;
`;

export const StyledMediaIcon = styled(VideoIcon)`
  width: 42px;
  height: 42px;
  fill: ${colors.yellow};
  margin-bottom: 5px;
`;

export const StyledBroadcastPlaceholderText = styled.div``;

export const StyledLoader = styled(Loader)`
  margin: 20px auto;
`;

export const StyledSidebar = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 12px 16px 16px;
  box-sizing: border-box;
  background: ${colors.white};
`;

export const StyledSupport = styled(Support)`
  position: absolute;
  left: 16px;
  bottom: 16px;
`;

export const StyledNewBroadcastButton = styled(Button)`
  margin: auto 0 0;
  flex-shrink: 0;
  flex-grow: 0;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
`;
