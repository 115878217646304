export type ChecklistItem = {
  title: string;
  description?: string;
  id: string;
  isChecked: boolean;
};

export type ChecklistItems = ChecklistItem[];

export enum LIST_IDS {
  shoppableVideoCreation = 'shoppableVideoCreation',
  amazonGuidelines = 'amazonGuidelines',
}
