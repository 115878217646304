import React, { FC } from 'react';
import styled from 'styled-components';
import { TrimmedData } from 'components/pages/SupervisorPage/types';
import { colors } from 'common/colors';

export const TrimmedDataRow: FC<{
  data: TrimmedData;
  className?: string;
  onClick: () => void;
}> = ({ data, className, onClick = () => {} }) => {
  const { timestamp, userId, projectId } = data;

  const date = new Date(timestamp);
  return (
    <StyledRow className={className} onClick={onClick}>
      <StyledProject>{projectId}</StyledProject>
      <StyledUser>{userId}</StyledUser>
      <StyledTime>{`${date.toTimeString()} ${date.toDateString()}`}</StyledTime>
    </StyledRow>
  );
};

const StyledRow = styled.div`
  display: flex;
  margin-bottom: 4px;
  cursor: pointer;
  background-color: ${colors.lightgray};

  &:hover {
    background-color: ${colors.yellow};
  }

  & > * {
    padding: 8px;
    border: 1px solid ${colors.darkGrey};
  }
`;

const StyledTime = styled.div``;

const StyledProject = styled.div``;

const StyledUser = styled.div``;
