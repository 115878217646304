import React from 'react';

export const CommentIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 7.99984C8.22876 7.99984 7.33333 8.89527 7.33333 9.99984V17.057L8.08088 16.3095C8.706 15.6844 9.55385 15.3332 10.4379 15.3332H14.6667C15.7712 15.3332 16.6667 14.4377 16.6667 13.3332V9.99984C16.6667 8.89527 15.7712 7.99984 14.6667 7.99984H9.33333ZM6 9.99984C6 8.15889 7.49238 6.6665 9.33333 6.6665H14.6667C16.5076 6.6665 18 8.15889 18 9.99984V13.3332C18 15.1741 16.5076 16.6665 14.6667 16.6665H10.4379C9.90747 16.6665 9.39876 16.8772 9.02369 17.2523L8.27614 17.9998C7.43619 18.8398 6 18.2449 6 17.057V9.99984ZM8.66667 10.6665C8.66667 10.2983 8.96514 9.99984 9.33333 9.99984H14.6667C15.0349 9.99984 15.3333 10.2983 15.3333 10.6665C15.3333 11.0347 15.0349 11.3332 14.6667 11.3332H9.33333C8.96514 11.3332 8.66667 11.0347 8.66667 10.6665ZM8.66667 13.3332C8.66667 12.965 8.96514 12.6665 9.33333 12.6665H12.6667C13.0349 12.6665 13.3333 12.965 13.3333 13.3332C13.3333 13.7014 13.0349 13.9998 12.6667 13.9998H9.33333C8.96514 13.9998 8.66667 13.7014 8.66667 13.3332Z"
        fill="inherit"
      />
    </svg>
  );
});
