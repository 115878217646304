import { sagaMiddleware } from 'common/sagas/sagaMiddleware';
import { all, call, put } from 'redux-saga/effects';
import { getFeatures } from '../../requests/others';
import { saveFeaturesAction } from './slice';


function* fetchUserFeatures() {
    const features = yield call(getFeatures);
    yield put(saveFeaturesAction(features));
  }

function* featuresRootSaga() {
  yield all([fetchUserFeatures()]);
}

sagaMiddleware.run(featuresRootSaga);
