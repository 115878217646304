import React, { FC, PropsWithChildren } from 'react';
import { StyledLink } from 'components/ui/Link/StyledLink';
import { LinkProps } from 'components/ui/Link/types';

export const Link: FC<PropsWithChildren<LinkProps>> = ({ blank, ...props }) => {
  const additionalProps: { target?: string } = {};
  if (blank) {
    additionalProps.target = '_blank';
  }

  return <StyledLink {...props} {...additionalProps} />;
};
