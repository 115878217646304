import React from 'react';

export const LogoutIcon = React.memo((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...props}>
      <path
        d="M20 12C19.45 12 19 11.55 19 11V6H8C7.45 6 7 5.55 7 5C7 4.45 7.45 4 8 4H20C20.55 4 21 4.45 21 5V11C21 11.55 20.55 12 20 12Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M20 24H15C14.45 24 14 23.55 14 23C14 22.45 14.45 22 15 22H19V17C19 16.45 19.45 16 20 16C20.55 16 21 16.45 21 17V23C21 23.55 20.55 24 20 24Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M28 15H18C17.45 15 17 14.55 17 14C17 13.45 17.45 13 18 13H28C28.55 13 29 13.45 29 14C29 14.55 28.55 15 28 15Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M24.0005 19.0001C23.7405 19.0001 23.4905 18.9001 23.2905 18.7101C22.9005 18.3201 22.9005 17.6901 23.2905 17.3001L26.5805 14.0101L23.2905 10.7201C22.9005 10.3301 22.9005 9.70008 23.2905 9.31008C23.6805 8.92008 24.3105 8.92008 24.7005 9.31008L28.7005 13.3101C29.0905 13.7001 29.0905 14.3301 28.7005 14.7201L24.7005 18.7201C24.5105 18.9001 24.2605 19.0001 24.0005 19.0001Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M15 28C14.8 28 14.6 27.94 14.42 27.82L7.42 22.89C7.16 22.7 7 22.4 7 22.07V4.99996C7 4.62996 7.21 4.27996 7.54 4.10996C7.87 3.93996 8.27 3.96996 8.58 4.17996L15.58 9.10996C15.84 9.29996 16 9.59996 16 9.92996V27C16 27.37 15.79 27.72 15.46 27.89C15.32 27.96 15.16 28 15 28ZM9 21.55L14 25.07V10.45L9 6.92996V21.55Z"
        fill="inherent"
        stroke="none"
      />
    </svg>
  );
});
