import React from 'react';

export const ProfileSettingsIcon = React.memo(({ isHover, ...otherProps }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...otherProps}>
      <path
        d="M7.40039 22.24C7.78039 22.95 8.92039 23.62 9.06039 23.96C9.38039 21.29 11.0104 19.82 14.1304 19.38C14.5504 19.32 14.8904 19 14.9704 18.58C15.0504 18.16 14.8604 17.74 14.4904 17.52C13.4104 16.9 12.7404 15.74 12.7404 14.49C12.7404 12.56 14.3104 10.99 16.2404 10.99C18.1704 10.99 19.7404 12.56 19.7404 14.49C19.7404 15.74 19.0704 16.9 17.9904 17.52C17.6204 17.73 17.4304 18.16 17.5104 18.58C17.5904 19 17.9304 19.32 18.3504 19.38C21.3604 19.81 22.9904 21.2 23.3904 23.71C23.4104 23.7 24.9304 22.33 25.0904 22.23C24.4204 20.1 22.9104 18.61 20.6004 17.85C21.3304 16.9 21.7504 15.72 21.7504 14.49C21.7504 11.46 19.2804 8.98999 16.2504 8.98999C13.2204 8.98999 10.7504 11.46 10.7504 14.49C10.7504 15.72 11.1704 16.89 11.8904 17.84C9.5804 18.61 8.07039 20.1 7.40039 22.24Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M16 28C9.38 28 4 22.62 4 16C4 9.38 9.38 4 16 4C22.62 4 28 9.38 28 16C28 22.62 22.62 28 16 28ZM16 6C10.49 6 6 10.49 6 16C6 21.51 10.49 26 16 26C21.51 26 26 21.51 26 16C26 10.49 21.51 6 16 6Z"
        fill="inherent"
        stroke="none"
      />
    </svg>
  );
});
