import { MediaType, Project, ProjectType } from 'common/types';

export type DeleteMediaRequest = (params: { id: number }) => Promise<void>;
type CreateMediaResponse = Promise<{
  mediaId: number;
  processKey?: string;
}>;

export type CreateBroadcastMediaRequestParams = {
  type: MediaType.Broadcast;
  broadcastId: number;
  name?: string;
  projectId?: number;
};

export type CreateRecordingMediaRequestParams = {
  type: MediaType.Recording;
  file: Blob;
  name?: string;
  projectId?: number;
};

export type CreateUploadMediaRequestParams = {
  type: MediaType.Upload;
  file: File;
  name?: string;
  projectId?: number;
};

export type CreateMediaRequest = {
  <T extends MediaType.Broadcast | MediaType.Recording | MediaType.Upload>(
    params: T extends MediaType.Broadcast
      ? CreateBroadcastMediaRequestParams
      : (CreateRecordingMediaRequestParams | CreateUploadMediaRequestParams),
  ): CreateMediaResponse;
};

export type CreateProject = (params?: {
  name?: string;
  title?: string;
  broadcastId?: number;
  type?: ProjectType;
}) => Promise<{
  projectId: number;
  updateVersion: number;
  processKey?: string;
}>;

export type DeleteProjectRequest = (params: { id: number }) => Promise<void>;

export type AttachMediaRequest = (params: {
  projectId: number;
  mediaId: number;
}) => Promise<void>;

export type DetachMediaRequest = (params: {
  projectId: number;
  mediaId: number;
}) => Promise<void>;

export type SelectMediaRequest = (params: {
  projectId: number;
  mediaId: number;
}) => Promise<void>;

export type UpdateProjectRequest = (
  projectId: number,
  params: Partial<Omit<Project, 'media'>>,
) => Promise<void>;

export const OKStatus = 200;
