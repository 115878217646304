import styled from 'styled-components';
import { lighten } from 'polished';

import { Button } from 'components/ui/Button';
import { colors } from 'common/colors';

export const StyledWrapper = styled.div`
  display: inline-flex;
`;

export const StyledTooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

export const StyledText = styled.div`
  display: inline-block;
  max-width: 155px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: center;
`;

export const StyledCancelButton = styled(Button)`
  width: 72px;
  color: ${colors.steel};
  background-color: transparent;
`;

export const StyledConfirmButton = styled(Button)`
  width: 72px;
  color: ${colors.winterRabbit};
  background-color: ${colors.watermelon};
  margin-left: 8px;

  :hover {
    background-color: ${lighten(0.05, colors.watermelon)};
  }
`;
