import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { PreviewSeekTo } from 'common/Redux';
import {
  StyledPreviewProgressBar,
  StyledPreviewProgressWrapper,
} from 'components/blocks/Preview/components/Progress/styles';

const PreviewProgress = (props) => {
  const { PreviewSeekTo, TimeLine } = props;
  const { currentPlayPosition, duration } = TimeLine;
  const progress = (currentPlayPosition / duration) * 100;
  const [mouseX, setMouseX] = useState(0);
  const ref = useRef();

  const handleClickOnProgress = useCallback(() => {
    try {
      let nodeOffsets = ref.current.getBoundingClientRect();
      let mouseClickX = mouseX - nodeOffsets.x;
      let seekToPosition = duration * (mouseClickX / nodeOffsets.width);
      PreviewSeekTo(seekToPosition, true);
    } catch (e) {
      console.error(e);
    }
  }, [PreviewSeekTo, mouseX, duration]);

  return (
    <StyledPreviewProgressWrapper
      ref={ref}
      onMouseMove={(e) => setMouseX(e.clientX)}
      onClick={handleClickOnProgress}
    >
      <StyledPreviewProgressBar
        style={{ width: `${progress}%` }}
        onClick={handleClickOnProgress}
      />
    </StyledPreviewProgressWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    PreviewSeekTo: (position) => dispatch(PreviewSeekTo(position)),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(PreviewProgress);
