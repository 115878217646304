import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import { VideoIcon } from 'components/ui/Icons/VideoIcon';
import { LiveIcon } from 'components/ui/Icons/LiveIcon';
import { ProcessingIcon } from 'components/ui/Icons/ProcessingIcon';
import { conditionalStyle } from 'utils/styles';
import { CheckIcon } from 'components/ui/Icons/CheckIcon';
import { Button } from 'components/ui/Button';

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-grow: 0;
  margin-top: 12px;
  font-size: 14px;
  min-height: 20px;
  overflow: hidden;
`;

export const StyledSubInfoWrapper = styled.div`
  display: flex;
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  padding-top: 4px;
  align-items: center;
  color: ${colors.grey};
`;

export const StyledEditVideoButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
  height: 32px;
  opacity: 0;
  transition: opacity 0.1s;
  box-shadow: 0 0 8px 16px ${colors.snow};
`;

export const StyledStartTime = styled.div``;

export const StyledDuration = styled.div`
  margin-left: 12px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -8px;
    top: 50%;
    width: 4px;
    height: 4px;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: ${colors.grey};
  }
`;

export const StyledStatus = styled.div<{ isDisabled: boolean }>`
  flex-shrink: 0;
  position: relative;
  width: 32px;
  height: 32px;
  background-color: ${colors.yellow};
  border-radius: 4px;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: rgba(187, 187, 187, 0.3);
    `}
`;

export const StyledVideoIcon = styled(VideoIcon)`
  fill: ${colors.white};
`;

export const StyledProcessingIcon = styled(ProcessingIcon)`
  fill: ${colors.grey};
`;

export const StyledLiveIcon = styled(LiveIcon)`
  fill: ${colors.grey};
  stroke: ${colors.grey};
`;

export const StyledCheckIcon = styled(CheckIcon)`
  fill: ${colors.yellow};
  width: 24px;
  height: 24px;
`;

export const StyledLine = styled.div<{
  isDisabled: boolean;
  selected: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px;
  background-color: ${colors.white};
  cursor: pointer;
  transition: background-color 0.1s;

  ${conditionalStyle({
    isDisabled: css`
      pointer-events: none;
      cursor: initial;
      background-color: ${colors.snow};

      ${StyledTitle} {
        color: ${colors.grey};
      }

      ${StyledCheckIcon} {
        fill: ${colors.disabledBackgroundColor};
      }

      ${StyledEditVideoButton} {
        display: none;
      }
    `,
    selected: css`
      background-color: ${colors.snow};
    `,
  })}

  &:hover {
    background-color: ${colors.snow};

    ${StyledEditVideoButton} {
      opacity: 1;
    }
  }
`;

export const StyledIconWrapper = styled.div`
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLabel = styled.div`
  border: 1px solid ${colors.lightgray3};
  color: ${colors.grey};
  height: 16px;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  margin: 8px;
  user-select: none;
`;
