import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import {
  StyledTextAreaWrapper,
  StyledButtonsContainer,
  StyledTextArea,
  StyledSendButton,
} from 'components/ui/TextArea/styles';
import { EmojiButtonComponent } from 'components/ui/EmojiButtonComponent';

export class TextAreaComponent extends PureComponent {
  ref = React.createRef();

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      emoji,
      active,
      buttons,
      className,
      withSendButton,
      onPress,
      ...props
    } = this.props;

    return (
      <StyledTextAreaWrapper className={className}>
        <StyledButtonsContainer disabled={props.disabled}>
          {buttons}
          {emoji && <EmojiButtonComponent onSelect={this.insertEmoji} />}
          {withSendButton && this.getSendButton()}
        </StyledButtonsContainer>

        <StyledTextArea
          active={active}
          setChange={this.setChange}
          {...props}
          forwardRef={this.ref}
        />
      </StyledTextAreaWrapper>
    );
  }

  setChange = (onChange) => {
    this.onChange = onChange;
  };

  getSendButton = () => {
    const defaultSendMessageButtonText = 'SEND';
    const { sendButtonText, onPress, value, disabled } = this.props;
    return (
      <StyledSendButton onClick={onPress} disabled={!value || disabled}>
        {sendButtonText || defaultSendMessageButtonText}
      </StyledSendButton>
    );
  };

  insertEmoji = ({ native }) => {
    // NOTE: refs used instead of state to increase performance
    const textarea = this.ref.current;
    const { value, selectionStart: start, selectionEnd: end } = textarea;
    const cursorPosition = start + native.length;
    const newValue = value.slice(0, start) + native + value.slice(end);
    Object.assign(textarea, {
      value: newValue,
      selectionStart: cursorPosition,
      selectionEnd: cursorPosition,
    });
    this.onChange({ target: { value: newValue } });
    textarea.focus();
  };
}

TextAreaComponent.propTypes = {
  emoji: PropTypes.bool,
  withSendButton: PropTypes.bool,
  active: PropTypes.bool,
  buttons: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  sendButtonText: PropTypes.string,
  onPress: PropTypes.func,
};

TextAreaComponent.defaultProps = {
  emoji: false,
  withSendButton: false,
  buttons: [],
  onChange: noop,
  onPress: noop,
};
