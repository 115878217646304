import styled, { css } from 'styled-components';

import { colors } from 'common/colors';
import { medium, small } from 'common/typography';

import { LINK_SIZES_ENUM, LinkProps } from 'components/ui/Link/types';
import { lighten } from 'polished';

const getSizeStyles = (size: number) => {
  switch (size) {
    case LINK_SIZES_ENUM.XS:
      return css`
        font-size: ${small};
        line-height: 16px;
      `;

    case LINK_SIZES_ENUM.S:
      return css`
        font-size: ${medium};
        line-height: 20px;
      `;

    default:
      return ``;
  }
};

export const StyledLink = styled.a<LinkProps>`
  color: ${colors.white};
  cursor: pointer;

  :hover {
    color: ${colors.grey};
  }

  ${({ primary }) =>
    primary &&
    css`
      color: ${colors.yellow};
      :hover {
        color: ${lighten(0.1, colors.yellow)};
      }
    `};

  ${({ size }) =>
    size &&
    css`
      ${getSizeStyles(size)};
      font-weight: bold;
    `};
`;
