import React from 'react';
import styled from 'styled-components';
import { StyledFC } from 'types';

interface SpacerProps { width?: number; height?: number; }

export const Spacer: StyledFC<SpacerProps> = ({ width = 0, height = 0  }) => {
  return <Spacer.Styled width={width} height={height} />;
};

Spacer.Styled = styled.div<SpacerProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
