import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { StyledHorizontalTrack, StyledVerticalTrack } from 'components/ui/CustomScroll/styles';

// used to customize scroll of any DOM element (div, .etc)
export const Scrollbar = ({
  noWrap = false,
  forwardRef,
  noScrollX,
  styleParams,
  ...props
}) => {
  const renderParams = {};

  if (noScrollX) {
    renderParams.overflowX = 'hidden';
  }

  return noWrap ? (
    <div {...props} />
  ) : (
    <Scrollbars
      ref={forwardRef}
      renderTrackHorizontal={(localProps) => (
        <StyledHorizontalTrack noScrollX={noScrollX} {...localProps} />
      )}
      renderTrackVertical={(localProps) => (
        <StyledVerticalTrack {...localProps} />
      )}
      renderView={(props) => (
        <div {...props} style={{ ...props.style, ...renderParams }} />
      )}
      {...props}
    />
  );
};
