import styled from 'styled-components';
import { InterBoldFont } from 'common/typography';
import { EventsFilter } from 'components/blocks/EventsTimeline/components/EventsFilter';
import { colors } from 'common/colors';

export const StyledEventsWrapper = styled.div`
  background-color: ${colors.white};
`;

export const StyledEventsInnerWrapper = styled.div`
  position: relative;
  margin: 0 24px 52px;
  ${InterBoldFont};
`;

export const StyledProgressLine = styled.div`
  position: absolute;
  top: 0;
  left: ${({ progressPosition }) => progressPosition}%;
  width: 4px;
  height: 100%;
  background-color: ${colors.yellow};
  transform: translateX(-50%);
`;

export const StyledEventsFilter = styled(EventsFilter).attrs(
  ({ scrollLeft }) => ({
    style: { left: `${scrollLeft}px` },
  })
)`
  position: absolute;
  margin-left: -12px;
  right: -4px;
  bottom: -36px;
  overflow: hidden;
`;

export const StyledStaticContainer = styled.div.attrs(
  ({ scrollLeft, containerWidth }) => ({
    style: { left: `${scrollLeft}px`, width: `${containerWidth}px` },
  })
)`
  position: absolute;
  top: 0;
  height: 100%;
  align-items: center;
  pointer-events: none;
`;
