import React from 'react';

export const PictureIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.33333C6 6.59695 6.59695 6 7.33333 6H16.6667C17.403 6 18 6.59695 18 7.33333V16.6667C18 17.403 17.403 18 16.6667 18H8.66699H8.66585H7.33333C6.59695 18 6 17.403 6 16.6667V7.33333ZM16.6667 16.6667H10.144L13.7463 12.5841L16.5835 14.8539C16.6102 14.8752 16.638 14.8942 16.6667 14.9108V16.6667ZM16.6667 7.33333V13.2129L14.0831 11.1461C13.8054 10.9239 13.4021 10.9589 13.1668 11.2256L8.36582 16.6667H7.33333V7.33333H16.6667ZM10 8.66667C9.26362 8.66667 8.66667 9.26362 8.66667 10C8.66667 10.7364 9.26362 11.3333 10 11.3333C10.7364 11.3333 11.3333 10.7364 11.3333 10C11.3333 9.26362 10.7364 8.66667 10 8.66667Z"
        fill="inherit"
      />
    </svg>
  );
});
