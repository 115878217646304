import React, { FC } from 'react';
import styled from 'styled-components';

export const ReactionsIcon: FC<{ className?: string }> = ({ className }) => (
  <StyledReactionsIcon className={className} viewBox="0 0 288 64">
    <g opacity="0.5">
      <circle
        cx="57.1202"
        cy="7.15185"
        r="4"
        transform="rotate(-15 57.1202 7.15185)"
        fill="#F9BB40"
      />
      <circle
        cx="82.2344"
        cy="0.422544"
        r="4"
        transform="rotate(-15 82.2344 0.422544)"
        fill="#F9BB40"
      />
      <circle
        cx="63.8492"
        cy="32.2659"
        r="4"
        transform="rotate(-15 63.8492 32.2659)"
        fill="#F9BB40"
      />
      <circle
        cx="88.9634"
        cy="25.5366"
        r="4"
        transform="rotate(-15 88.9634 25.5366)"
        fill="#F9BB40"
      />
      <circle
        cx="114.077"
        cy="18.8073"
        r="4"
        transform="rotate(-15 114.077 18.8073)"
        fill="#F9BB40"
      />
      <circle
        cx="139.191"
        cy="12.078"
        r="4"
        transform="rotate(-15 139.191 12.078)"
        fill="#F9BB40"
      />
      <circle
        cx="164.305"
        cy="5.34875"
        r="4"
        transform="rotate(-15 164.305 5.34875)"
        fill="#F9BB40"
      />
      <circle
        cx="189.419"
        cy="-1.38056"
        r="4"
        transform="rotate(-15 189.419 -1.38056)"
        fill="#F9BB40"
      />
      <circle
        cx="70.5787"
        cy="57.38"
        r="4"
        transform="rotate(-15 70.5787 57.38)"
        fill="#F9BB40"
      />
      <circle
        cx="95.6929"
        cy="50.6507"
        r="4"
        transform="rotate(-15 95.6929 50.6507)"
        fill="#F9BB40"
      />
      <circle
        cx="120.807"
        cy="43.9214"
        r="4"
        transform="rotate(-15 120.807 43.9214)"
        fill="#F9BB40"
      />
      <circle
        cx="145.921"
        cy="37.1921"
        r="4"
        transform="rotate(-15 145.921 37.1921)"
        fill="#F9BB40"
      />
      <circle
        cx="171.035"
        cy="30.4628"
        r="4"
        transform="rotate(-15 171.035 30.4628)"
        fill="#F9BB40"
      />
      <circle
        cx="196.149"
        cy="23.7335"
        r="4"
        transform="rotate(-15 196.149 23.7335)"
        fill="#F9BB40"
      />
      <circle
        cx="152.65"
        cy="62.3062"
        r="4"
        transform="rotate(-15 152.65 62.3062)"
        fill="#F9BB40"
      />
      <circle
        cx="177.764"
        cy="55.5769"
        r="4"
        transform="rotate(-15 177.764 55.5769)"
        fill="#F9BB40"
      />
      <circle
        cx="202.878"
        cy="48.8476"
        r="4"
        transform="rotate(-15 202.878 48.8476)"
        fill="#F9BB40"
      />
    </g>
    <g filter="url(#filter0_d)">
      <rect
        x="100.797"
        y="26.6906"
        width="73"
        height="73"
        rx="36.5"
        transform="rotate(-15 100.797 26.6906)"
        fill="url(#paint0_linear)"
      />
      <g clipPath="url(#reactionIconClip0)">
        <rect
          x="129.311"
          y="52.5194"
          width="8.34286"
          height="18.7714"
          rx="2"
          transform="rotate(-15 129.311 52.5194)"
          fill="white"
        />
        <path
          d="M141.554 38.8726C143.574 46.4091 141.774 49.5837 138.926 52.1418L142.292 64.7025C143.341 65.319 145.932 66.4196 147.91 65.8896L157.8 63.2396C161.44 62.2643 162.072 59.4024 160.799 57.9485C162.401 57.1485 162.095 53.9916 160.502 53.5408C162.463 53.353 162.895 48.9188 159.979 48.2957C162.085 45.8968 159.79 42.0638 155.936 43.0965L149.343 44.8632L147.548 38.1642C146.426 33.9773 140.657 35.5231 141.554 38.8726Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter1_d)">
      <rect
        x="193.808"
        y="-12.6274"
        width="64"
        height="64"
        rx="32"
        transform="rotate(-15 193.808 -12.6274)"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.965 -6.12857C244.4 -5.30014 244.888 -3.47877 245.864 0.163964L250.974 19.2342C251.506 21.2191 251.772 22.2116 251.242 22.6184C250.711 23.0251 249.822 22.5114 248.042 21.4839L246.695 20.7063C245.279 19.8888 244.571 19.48 243.782 19.3762C242.993 19.2723 242.204 19.4839 240.625 19.9071L228.37 23.1905C224.728 24.1666 222.906 24.6546 221.472 23.8262C220.037 22.9978 219.549 21.1764 218.573 17.5337L216.058 8.15038C215.082 4.50764 214.594 2.68628 215.423 1.2514C216.251 -0.18348 218.072 -0.671515 221.715 -1.64758L236.066 -5.49289C239.709 -6.46896 241.53 -6.957 242.965 -6.12857ZM240.335 9.33601C238.963 9.70358 237.553 8.88951 237.186 7.51774C236.818 6.14597 237.632 4.73596 239.004 4.36839C240.376 4.00083 241.786 4.8149 242.154 6.18667C242.521 7.55844 241.707 8.96845 240.335 9.33601ZM229.734 9.51434C230.102 10.8861 231.512 11.7002 232.884 11.3326C234.256 10.9651 235.07 9.55504 234.702 8.18327C234.335 6.8115 232.925 5.99743 231.553 6.365C230.181 6.73256 229.367 8.14257 229.734 9.51434ZM225.432 13.3292C224.061 13.6968 222.651 12.8827 222.283 11.5109C221.915 10.1392 222.73 8.72917 224.101 8.3616C225.473 7.99404 226.883 8.80811 227.251 10.1799C227.618 11.5516 226.804 12.9617 225.432 13.3292Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_d)">
      <g clipPath="url(#reactionsIconClip1)">
        <rect
          x="18"
          y="21.3174"
          width="36"
          height="36"
          rx="18"
          transform="rotate(-15 18 21.3174)"
          fill="url(#paint2_linear)"
        />
        <path
          d="M42.4892 43.1627C58.7112 27.5354 42.3199 19.6816 38.5434 28.437C30.8944 22.7431 20.6261 37.7403 42.4892 43.1627Z"
          fill="white"
          stroke="white"
          strokeWidth="1.8"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="78.7969"
        y="-10.2032"
        width="133.406"
        height="133.406"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.976471 0 0 0 0 0.733333 0 0 0 0 0.25098 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="171.808"
        y="-47.1918"
        width="122.384"
        height="122.384"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="-4"
        y="-6.00003"
        width="88.0908"
        height="88.0908"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.941176 0 0 0 0 0.356863 0 0 0 0 0.360784 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="137.297"
        y1="26.6906"
        x2="137.297"
        y2="99.6906"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBC33D" />
        <stop offset="1" stopColor="#E68009" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="225.808"
        y1="-12.6274"
        x2="225.808"
        y2="51.3726"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3DD9FB" />
        <stop offset="1" stopColor="#413DFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="36"
        y1="21.3174"
        x2="36"
        y2="57.3174"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.203125" stopColor="#FF6683" />
        <stop offset="1" stopColor="#EC1733" />
      </linearGradient>
      <clipPath id="reactionIconClip0">
        <rect
          width="41.7143"
          height="41.7143"
          fill="white"
          transform="translate(119.956 37.7518) rotate(-15)"
        />
      </clipPath>
      <clipPath id="reactionsIconClip1">
        <rect
          x="18"
          y="21.3174"
          width="36"
          height="36"
          rx="18"
          transform="rotate(-15 18 21.3174)"
          fill="white"
        />
      </clipPath>
    </defs>
  </StyledReactionsIcon>
);

const StyledReactionsIcon = styled.svg``;
