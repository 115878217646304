import React from 'react';
import 'common/Utils.js';
import {
  StyledBackground,
  StyledCloseButton,
  StyledContent,
  StyledLine,
  StyledLoader,
  StyledMessage,
  StyledProcessNotifier,
  StyledProcessNotifierTitle,
  StyledRightTitle,
} from 'components/ui/Popup/styles';

export const Popup = ({
  title,
  rightTitle = '',
  content,
  percentage = 0,
  onClose,
  ...restProps
}) => {
  return (
    <>
      <StyledBackground />
      <StyledProcessNotifier {...restProps}>
        <StyledProcessNotifierTitle>
          {title}
          <StyledRightTitle>
            {rightTitle}
            {onClose && <StyledCloseButton onClick={onClose} />}
          </StyledRightTitle>
        </StyledProcessNotifierTitle>
        <StyledLine percentage={percentage} />
        <StyledContent>
          <StyledLoader />
          <StyledMessage>{content}</StyledMessage>
        </StyledContent>
      </StyledProcessNotifier>
    </>
  );
};
