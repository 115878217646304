import { FETCH_BROADCASTS_SUCCESS } from './BroadcastsListTypes';
import { SET_BROADCAST_ID, UPDATED_VIDEOURL } from '../Constants';
import { every } from 'lodash/fp';
import { requests } from 'common/requests';

export const fetchBroadcastsListSuccessAction = (broadcasts) => {
  return {
    type: FETCH_BROADCASTS_SUCCESS,
    payload: broadcasts,
  };
};

export const videoUrlUpdated = (videoUrl) => {
  return {
    type: UPDATED_VIDEOURL,
    payload: videoUrl,
  };
};

export const setBroadcastId = (payload) => {
  return {
    type: SET_BROADCAST_ID,
    payload,
  };
};

export const fetchBroadcastList = () => (dispatch) => {
  requests.getUserBroadcasts().then((broadcasts) => {
    const areAllBroadcastsCompleted = every({
      isProcessing: false,
      isLive: false,
    })(broadcasts);

    if (!areAllBroadcastsCompleted) {
      setTimeout(() => dispatch(fetchBroadcastList()), 30000);
    }

    dispatch(fetchBroadcastsListSuccessAction(broadcasts));
  });
};
