import omitBy from 'lodash/omitBy';

import { GlobalState } from 'common/Redux/types';
import { DataFetchingStatuses, Media, MediaType } from 'common/types';
import { createSelector } from 'reselect';
import { UserState } from './types';
import { filter, flow, map } from 'lodash/fp';

const selectUserState = (state: GlobalState): UserState => state.user;

export const selectUserMediaCollection = createSelector(
  selectUserState,
  (user) => user.media
);

export const selectUserBroadcastMediaIds = createSelector(
  selectUserMediaCollection,
  (userMediaCollection) => {
    return flow(
      filter((media: Media) => media.type === MediaType.Broadcast),
      map((media: Media) => Number(media.source))
    )(userMediaCollection);
  }
);

export const selectUserMediaWithoutBroadcasts = createSelector(
  selectUserMediaCollection,
  (userMediaCollection) =>
    omitBy(userMediaCollection, { type: MediaType.Broadcast })
);

export const selectUserProjectCollection = (state: GlobalState) =>
  selectUserState(state).projects.collection;

export const selectUserProjectsFetchingStatus = (state: GlobalState) =>
    selectUserState(state).projects.fetchingStatus;

export const selectAreProjectsFetched = createSelector(
  selectUserProjectsFetchingStatus,
  (status) =>
    status !== DataFetchingStatuses.Idle &&
    status !== DataFetchingStatuses.Pending
);
