import React from 'react';

export const EmojiIcon = React.memo((props) => (
  <svg {...props} viewBox="10 10 16 16">
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M228 49.25a.25.25 0 0 1 .25-.25h7.5a.25.25 0 0 1 .25.25c0 2.204-1.794 3.75-4 3.75s-4-1.546-4-3.75zm1-5.25c.827 0 1.5.672 1.5 1.5 0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5c0-.828.673-1.5 1.5-1.5m6 0c.827 0 1.5.672 1.5 1.5 0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5c0-.828.673-1.5 1.5-1.5m-3-4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8"
      transform="translate(-214 -30)"
    />
  </svg>
));
