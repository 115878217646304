import styled from 'styled-components';
import { ProblemIcon } from 'components/ui/Icons/ProblemIcon';
import { colors } from 'common/colors';
import { Link } from 'components/ui/Link';
import { CrossArrowIcon } from 'components/ui/Icons/CrossArrowIcon';

export const StyledProblemIcon = styled(ProblemIcon)`
  width: 40px;
  margin: auto;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${colors.white};
  border-radius: 8px;
`;

export const StyledMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
  padding: 24px 22px 14px;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  font-weight: 700;
  text-decoration: none;
`;

export const StyledCloseIcon = styled(CrossArrowIcon)`
  width: 16px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 16px;
  stroke: ${colors.grey};
`;
