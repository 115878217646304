export class Defer<T = void> {
  reject: (e: Error) => void = () => {};

  resolve: (param: T) => void = () => {};

  promise: Promise<T>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      Object.assign(this, { resolve, reject });
    });
  }
}
