import { ProjectState } from './types';
import { CLOSE_PROJECT, OPEN_PROJECT } from 'common/Redux/Project/actions';
import { Action } from 'redux';

const initialState: ProjectState = {
  id: 0,
};

export const ProjectReducer = (
  state = initialState,
  action: Action & { payload?: any }
): ProjectState => {
  switch (action.type) {
    case OPEN_PROJECT:
      return {
        ...state,
        id: action.payload,
      };
    case CLOSE_PROJECT:
      return {
        ...state,
        id: 0,
      };
    default:
      return state;
  }
};
