export enum LINK_SIZES_ENUM {
  XS,
  S,
}

export type LinkProps = {
  blank?: boolean;
  primary?: boolean;
  size?: LINK_SIZES_ENUM;
  href?: string;
  id?: string;
  onClick?: () => void;
};
