import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { LogoutIcon } from 'components/ui/Icons/LogoutIcon';
import { MybroadcastsIcon } from 'components/ui/Icons/MybroadcastsIcon';
import { ProfileSettingsIcon } from 'components/ui/Icons/ProfileSettingsIcon';
import { HelpCenterIcon } from 'components/ui/Icons/HelpCenterIcon';
import { logout } from 'common/Redux';
import { getCookie } from 'utils/helpers';
import { conditionalStyle } from 'utils/styles';
import { gotToLoginPage } from 'components/pages/HomePage/services';

const AuthProxyServerAddress =
  process.env.REACT_APP_BELIVE_EDITOR_AUTH_PROXY_SERVER;
const BeliveSeverAddress = process.env.REACT_APP_BELIVE_BE_URL;
const profileURL = BeliveSeverAddress + '/profile';
const HelpCenterURL = process.env.REACT_APP_BELIVE_HELPCENTER_URL;

export const USER_PLANS_ENUM = {
  NO_SUBSCRIPTION: 0,
  FREE: 1,
  LITE: 2,
  STANDARD: 3,
  STANDARD_PLUS: 4,
  PRO: 5,
};

export const USER_PLANS = {
  NO_SUBSCRIPTION: 'noSubscription',
  FREE: 'Free',
  LITE: 'Lite',
  STANDARD: 'Standard',
  STANDARD_PLUS: 'Standard+',
  APP_SUMO: 'appSumo',
  PRO: 'Pro',
};

export const USER_PLANS_BY_ID = Object.keys(USER_PLANS).reduce((acc, key) => {
  return {
    ...acc,
    [USER_PLANS_ENUM[key]]: USER_PLANS[key],
  };
}, {});

const AvatarMenu = (props) => {
  const { isVisible = false } = props;

  const handleLogoutClick = () => {
    window.location.href = AuthProxyServerAddress + '/logout';
  };

  const handleMyBroadcastsClick = () => {
    window.open(profileURL + '/mystreams', '_blank');
  };

  const handleProfileSettings = () => {
    window.open(profileURL, '_blank');
  };

  const handleMHelpCenterClick = () => {
    window.open(HelpCenterURL, '_blank');
  };

  return (
    <StyledAvatarMenu isVisible={isVisible} ref={props.forwardRef}>
      <StyledPersonalDetailsWrapper>
        <StyledPersonalDetailsName>
          {props.Auth.me.name}
        </StyledPersonalDetailsName>
        <StyledPersonalDetailsPlan>
          {props.Auth.me.subscription.name}
        </StyledPersonalDetailsPlan>
      </StyledPersonalDetailsWrapper>
      <StyledOption onClick={handleProfileSettings}>
        <StyledOptionTitle>Profile Settings</StyledOptionTitle>
        <ProfileSettingsIcon />
      </StyledOption>
      <StyledOption onClick={handleMyBroadcastsClick}>
        <StyledOptionTitle>My Broadcasts</StyledOptionTitle>
        <MybroadcastsIcon />
      </StyledOption>
      <StyledOption onClick={handleMHelpCenterClick}>
        <StyledOptionTitle>Help center</StyledOptionTitle>
        <HelpCenterIcon />
      </StyledOption>
      <StyledOption onClick={handleLogoutClick}>
        <StyledOptionTitle>Log Out</StyledOptionTitle>
        <LogoutIcon />
      </StyledOption>
    </StyledAvatarMenu>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(AvatarMenu);

const StyledAvatarMenu = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: absolute;
  width: 264px;
  right: 10px;
  top: 72px;
  background: #363636;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25), 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 200;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform-origin: 100% 0;
  opacity: 0;
  transform: scale(0.7);
  pointer-events: none;

  ${conditionalStyle({
    isVisible: css`
      opacity: 1;
      transform: none;
      pointer-events: initial;
    `,
  })}
`;

const StyledPersonalDetailsWrapper = styled.div`
  user-select: none;
  position: relative;
  width: 264px;
  height: 58px;
  flex: none;
  order: 0;
  align-self: center;
  margin: 0 0 0 0;
  padding: 0 0 10px 0;
`;

const StyledPersonalDetailsName = styled.div`
  position: relative;
  width: 107px;
  height: 16px;
  left: 16px;
  top: 15px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #b1b1b1;
`;

const StyledPersonalDetailsPlan = styled.div`
  position: relative;
  width: 117px;
  height: 24px;
  left: 16px;
  top: 16px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f9bb40;
`;

const StyledOption = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  color: #ffffff;
  font-weight: normal;
  border-top: 1px solid #b1b1b1;
  fill: #b1b1b1;

  &:hover {
    color: #f05b5c;
    fill: #f05b5c;
    font-weight: bold;
  }

  svg {
    width: 32px;
    height: 32px;
    padding-right: 8px;
  }
`;

const StyledOptionTitle = styled.div`
  position: relative;
  font-family: Inter, serif;
  font-size: 14px;
  margin: 0 16px;
`;
