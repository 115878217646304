import {
  getVideoUrlSelector,
  isCursorOutOfTrimmingRangeSelector,
  isTimelineReadySelector,
} from 'common/Redux/Timeline/selectors';

import {
  togglePreviewPlayingState,
  TimelinePlayedPosition,
  TimelineTrackLoadedAndReady,
  TimeLineTrackUpdateTrimmEndPos,
  TimeLineTrackUpdateTrimmStartPos,
  TimelineUpdateDuration,
} from 'common/Redux';
import { connect } from 'react-redux';
import { Preview } from 'components/blocks/Preview/Preview';
import { selectCurrentOverlayType, selectIsPlaying } from 'common/Redux/Preview/selectors';
import {
  selectActiveProject,
  selectActiveProjectActiveMedia,
} from 'common/Redux/Project/selectors';
import { selectAreProjectsFetched } from 'common/Redux/User/selectors';
import { selectActiveProjectActiveMediaSource } from 'common/Redux/Project/selectors';

const mapStateToProps = (state) => {
  return {
    isCursorOutOfTrimmingRange: isCursorOutOfTrimmingRangeSelector(state),
    isReady: isTimelineReadySelector(state),
    videoUrl: getVideoUrlSelector(state),
    isPlaying: selectIsPlaying(state),
    activeMedia: selectActiveProjectActiveMedia(state),
    areProjectsFetched: selectAreProjectsFetched(state),
    activeMediaSource: selectActiveProjectActiveMediaSource(state),
    overlayType: selectCurrentOverlayType(state),
    activeProject: selectActiveProject(state),
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PreviewControlsPlayPause: (forcePause) =>
      dispatch(togglePreviewPlayingState(forcePause)),
    TimeLineTrackUpdateTrimmStartPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmStartPos(...params)),
    TimeLineTrackUpdateTrimmEndPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmEndPos(...params)),
    TimelineTrackLoadedAndReady: () => dispatch(TimelineTrackLoadedAndReady()),
    TimelinePlayedPosition: (ts) => dispatch(TimelinePlayedPosition(ts)),
    TimelineUpdateDuration: (duration) =>
      dispatch(TimelineUpdateDuration(duration)),
  };
};

export const PreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);
