import styled, { css } from 'styled-components';

export const StyledText = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 14px;
  line-height: 1;
  padding-right: 15px;
`;

export const StyledDisabled = css`
  filter: grayscale(1);
  opacity: 0.3;
`;

type StyledWrapperProps = {
  disabled: boolean;
};

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  width: 100%;
  height: 100%;
  ${(props) => (props.disabled ? StyledDisabled : '')};
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  margin-right: 8px;
`;
