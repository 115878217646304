import { connect } from 'react-redux';

import {
  getEndTrimSelector,
  getStartTrimSelector,
} from 'common/Redux/Timeline/selectors';
import { SharePlayer } from 'components/blocks/SharePopup/components/SharePlayer/SharePlayer';
import { createSelector } from 'common/Redux/selectors';
import { selectActiveProjectActiveMediaSource, selectActiveProject } from 'common/Redux/Project/selectors';

const mapStateToProps = createSelector({
  startTrim: getStartTrimSelector,
  endTrim: getEndTrimSelector,
  projectSource: selectActiveProjectActiveMediaSource,
  activeProject: selectActiveProject,
});

export const SharePlayerContainer = connect(mapStateToProps)(SharePlayer);
