import React from 'react';
import { connect } from 'react-redux';
import 'components/blocks/Timeline/TracksArea/TrackLine/TimelineTrackLine.css';
import { ReactComponent as VectorButtonIcon } from 'common/Images/VectorButton.svg';
import { ReactComponent as XButtonIcon } from 'common/Images/XButton.svg';
import { ReactComponent as TrimmStartButtonIcon } from 'common/Images/TrimmL.svg';
import { ReactComponent as TrimmEndButtonIcon } from 'common/Images/TrimmR.svg';
import {
  TimeLineTrackUpdateTrimmStartPos,
  TimeLineTrackUpdateTrimmEndPos,
} from 'common/Redux';
import { StyledTrackLineWrapper } from 'components/blocks/Timeline/TracksArea/TrackLine/styles';
import { isDevFeature } from 'common/Utils';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';

let PositionFromTimelinePlayer = 0;
class TimelineTrackLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: props.openMenu,
    };
  }

  componentDidUpdate = () => {
    PositionFromTimelinePlayer = this.props.TimeLine.currentPlayPosition;
  };

  render = () => {
    const { isFlagReversed } = this.props;
    const progress =
      (this.props.TimeLine.currentPlayPosition / this.props.TimeLine.duration) *
      100;
    const timePosition = isFlagReversed
      ? `calc(${progress}% - ${99}px)`
      : `${progress}%`;

    return (
      <StyledTrackLineWrapper onMouseDown={(e) => e.stopPropagation()}>
        <div
          className="TimeLineTrackTimeStamp"
          style={{
            left: timePosition,
          }}
        >
          {isDevFeature() &&
            (!this.state.openMenu ? (
              <VectorButtonIcon
                className="VectorButton"
                onClick={this.handleVButtonClick}
              />
            ) : (
              <XButtonIcon
                className="VectorButton"
                onClick={this.handleVButtonClick}
              />
            ))}
          <span>{this.toHHMMSS(PositionFromTimelinePlayer * 10)}</span>
        </div>

        {this.state.openMenu ? (
          <div
            className="TimeLineTrackTimeMenu"
            style={{
              left: timePosition,
            }}
          >
            <div
              className="TimeLineTrackTimeMenu_StartTrim"
              onClick={() => this.handleTrimmStartClick()}
            >
              <TrimmStartButtonIcon className="TrimmStartButton" />
              <span>Trim Start</span>
            </div>
            <div
              className="TimeLineTrackTimeMenu_EndTrim"
              onClick={() => this.handleTrimmEndClick()}
            >
              <TrimmEndButtonIcon className="TrimmEndButton" />
              <span>Trim End</span>
            </div>
          </div>
        ) : (
          <div />
        )}

        <div
          className="TimelineTrackLine"
          style={{
            left: `${progress}%`,
          }}
        />
      </StyledTrackLineWrapper>
    );
  };

  toHHMMSS = (_string) => {
    var sec_num = parseInt(_string, 10) / 10; // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds =
      Math.floor(sec_num) - Math.floor(hours * 3600) - Math.floor(minutes * 60);
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  handleVButtonClick = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  handleTrimmStartClick = () => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.TRIMMING_THRU_GREENLINE_USED);
    if (!this.props.TimeLine) return;
    if (!this.props.TimeLine.duration) return;
    if (!this.props.TimeLine.ready) return;
    this.props.TimeLineTrackUpdateTrimmStartPos(
      this.props.TimeLine.currentPlayPosition,
      true
    );
  };

  handleTrimmEndClick = () => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.TRIMMING_THRU_GREENLINE_USED);
    if (!this.props.TimeLine) return;
    if (!this.props.TimeLine.duration) return;
    if (!this.props.TimeLine.ready) return;
    this.props.TimeLineTrackUpdateTrimmEndPos(
      this.props.TimeLine.currentPlayPosition,
      true
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    TimeLineTrackUpdateTrimmStartPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmStartPos(...params)),
    TimeLineTrackUpdateTrimmEndPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmEndPos(...params)),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(TimelineTrackLine);
