import React, { FC } from 'react';
import { noop } from 'lodash';

import {
  StyledCheckIcon,
  StyledDuration,
  StyledInfoWrapper,
  StyledLine,
  StyledLiveIcon,
  StyledProcessingIcon,
  StyledStartTime,
  StyledStatus,
  StyledSubInfoWrapper,
  StyledTitle,
  StyledVideoIcon,
  StyledLabel,
  StyledEditVideoButton,
} from './styles';
import { MONTHS } from 'components/blocks/BroadcastList/components/BroadcastListItem/constants';
import { StyledIconWrapper } from './styles';
import { BroadcastListItemProps, GetStatusIcon } from './types';
import { ButtonType } from 'components/ui/Button/types';

export const BroadcastListItem: FC<BroadcastListItemProps> = ({
  title,
  startTime,
  endTime,
  onClick,
  className,
  selected = false,
  isScheduled = false,
  isLive = false,
  isProcessing = false,
  isDisabled = false,
  attached = false,
}) => {
  const start = startTime ? getDateTimeString(startTime) : '-';
  const isBroadcastEnded = startTime && endTime;

  const duration = isBroadcastEnded && getDurationString(
    new Date(
      new Date(endTime!).getTime() - new Date(startTime!).getTime()
    ).toJSON()
  );

  const StatusIcon = getStatusIcon({ isLive, isProcessing });

  return (
    <StyledLine
      isDisabled={isLive || isProcessing || isDisabled}
      onClick={isDisabled ? noop : onClick}
      className={className}
      selected={selected}
    >
      <StyledIconWrapper>
        {selected ? (
          <StyledCheckIcon />
        ) : (
          <StyledStatus isDisabled={isLive || isProcessing || isDisabled}>
            <StatusIcon withAnimation />
          </StyledStatus>
        )}
      </StyledIconWrapper>
      <StyledInfoWrapper>
        <StyledTitle>{title ? title : 'Empty title'}</StyledTitle>
        <StyledSubInfoWrapper>
          <StyledStartTime>{start}</StyledStartTime>
          {isBroadcastEnded && <StyledDuration>{duration}</StyledDuration>}
          {isScheduled && <StyledDuration>Schedule</StyledDuration>}
          {isLive && <StyledDuration>Live now</StyledDuration>}
        </StyledSubInfoWrapper>
        <StyledEditVideoButton type={ButtonType.Primary}>
          Edit video
        </StyledEditVideoButton>
      </StyledInfoWrapper>
      {attached && <StyledLabel>Already added</StyledLabel>}
    </StyledLine>
  );
};

const getStatusIcon: GetStatusIcon = ({ isLive, isProcessing }) => {
  let result = StyledVideoIcon;

  if (isLive) result = StyledLiveIcon;
  if (isProcessing) result = StyledProcessingIcon;

  return result;
};

function getDateTimeString(timestamp: string, withDate = true) {
  const d = new Date(timestamp);
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  const hours = d.getHours();
  const minutes = d.getMinutes();

  const date = `${MONTHS[month]} ${day}, ${year} `;
  const ampm = +hours >= 12 ? 'PM' : 'AM';
  const h = +hours % 12 || 12;
  const leadingZeroMinutes = `${minutes < 10 ? '0' : ''}${minutes}`;
  const time = `${h}:${leadingZeroMinutes} ${ampm}`;

  return `${withDate ? date : ''}${time}`;
}

function getDurationString(timestamp: string) {
  const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
  const { 4: hours, 5: minutes } = regex.exec(timestamp) as any;

  const m = `${+minutes} min`;
  const h = `${+hours} h `;

  return `${+hours ? h : ''}${m}`;
}
