import React from 'react';
import { colors } from 'common/colors';

export const YTIcon = React.memo(({ ...props }) => {
  return (
    <svg viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M36.6295 11.6095C36.2295 10.1151 35.0551 8.94073 33.5607 8.5407C30.8549 7.81494 19.9999 7.81494 19.9999 7.81494C19.9999 7.81494 9.14494 7.81494 6.43905 8.5407C4.94467 8.94073 3.77032 10.1151 3.37029 11.6095C2.64453 14.3153 2.64453 19.9643 2.64453 19.9643C2.64453 19.9643 2.64453 25.6132 3.37029 28.3191C3.77032 29.8135 4.94467 30.9878 6.43905 31.3878C9.14494 32.1136 19.9999 32.1136 19.9999 32.1136C19.9999 32.1136 30.8549 32.1136 33.5607 31.3878C35.0551 30.9878 36.2295 29.8135 36.6295 28.3191C37.3553 25.6132 37.3553 19.9643 37.3553 19.9643C37.3553 19.9643 37.3524 14.3153 36.6295 11.6095Z"
        fill={props.isDisabled ? colors.grey : '#FF0000'}
      />
      <path
        d="M16.5273 25.1704L25.545 19.9643L16.5273 14.7583V25.1704Z"
        fill={colors.white}
      />
    </svg>
  );
});
