/* eslint-disable @typescript-eslint/camelcase */
import { REACT_APP_HELP_CRUNCH_ID as APP_ID } from '../config';

let isInited = false;

export const HelpCrunch = {
  ID: APP_ID,
  init() {
    // eslint-disable-next-line
    (function (w, d) {
      // eslint-disable-next-line no-param-reassign,prefer-rest-params
      w.HelpCrunch = function () {
        // eslint-disable-next-line prefer-rest-params
        w.HelpCrunch.q.push(arguments);
      };
      // eslint-disable-next-line no-param-reassign
      w.HelpCrunch.q = [];
      function r() {
        const s = document.createElement('script');
        s.async = 1;
        s.type = 'text/javascript';
        s.src = 'https://widget.helpcrunch.com/';
        (d.body || d.head).appendChild(s);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', r);
      } else {
        w.addEventListener('load', r, false);
      }
    })(window, document);
  },
  setUser(user) {
    window.HelpCrunch('updateUser', user);
  },
  track(name, json) {
    HelpCrunch.lazyInit();
    window.HelpCrunch('trackEvent', name, json);
  },
  async lazyInit() {
    if (!isInited) {
      isInited = true;
      await window.HelpCrunch('init', 'belive', {
        applicationId: APP_ID,
        applicationSecret:
          'YZdhDWeoiAZIJbCgGKnpeuDP9W23UVC2SW/UmlGZl9sShq517i2H/tnhkAC4EIWBCBUHiHziaQM0/Sk4nG8PBw==',
      });
    }
  },
  getInstance: async () => {
    await HelpCrunch.lazyInit();
    return window.HelpCrunch ? window.HelpCrunch : null;
  },
};
