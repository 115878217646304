import React, { FC, useEffect, useState } from 'react';
import date from 'date-and-time';

import { useInterval } from 'components/hooks/useInterval';

export const Stopwatch: FC<{ isStarted: boolean; intervalDelay?: number }> = ({
  isStarted,
  intervalDelay = 500,
}) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (isStarted) setTime(0)
  }, [isStarted])

  useInterval(
    () => setTime(time + intervalDelay),
    isStarted ? intervalDelay : null
  );

  return <React.Fragment>{date.format(new Date(time), 'm:ss')}</React.Fragment>;
};
