import styled from 'styled-components';
import { Button } from 'components/ui/Button';
import { colors } from 'common/colors';
import { CrossArrowIcon } from 'components/ui/Icons/CrossArrowIcon';
import { Reactions2Icon } from 'components/ui/Icons/Reactions2Icon';

export const StyledWrapper = styled.div`
  padding: 94px 8px 0;
`;

export const StyledTitleIcon = styled(Reactions2Icon)`
  width: 170px;
  height: 170px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: -85px auto;
`;

export const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
  color: ${colors.almostBack};
`;

export const StyledText = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
  color: ${colors.summerRabbit};
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  margin-left: 16px;
  min-width: 128px;
`;

export const StyledCloseIcon = styled(CrossArrowIcon)`
  width: 12px;
  position: absolute;
  right: 38px;
  top: 38px;
  stroke: ${colors.grey};

  &:hover {
    stroke: ${colors.lightgray};
  }
`;
