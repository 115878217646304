import React from 'react';
import { connect } from 'react-redux';

import {
  StyledContentWrapper,
  StyledLeftPanel,
  StyledProjectName,
  StyledTab,
  StyledTabSection,
} from 'components/blocks/LeftPanel/styles';
import { createSelector } from 'common/Redux/selectors';
import {
  selectActiveToolboxItem,
  TOOLBOX_ITEM_TYPES,
} from 'common/Redux/Toolbox';
import { Tabs } from 'components/ui/Tabs';
import { TemplatesTab } from 'components/blocks/LeftPanel/components/TemplatesTab';
import { selectActiveProject } from 'common/Redux/Project/selectors';
import { updateProject } from 'common/Redux/Project/actions';
import { Scrollbar } from 'components/ui/CustomScroll';

export const LeftPanel = ({
  activeToolboxItem,
  activeProject,
  updateProject,
}) => {
  const updateProjectName = (name) =>
    updateProject({ projectId: activeProject.id, name });

  const updateProjectTitle = (title) =>
    updateProject({ projectId: activeProject.id, title });

  return (
    <StyledLeftPanel>
      <StyledContentWrapper>
        <Tabs activeTabId={activeToolboxItem}>
          <Tabs.TabContent tabId={TOOLBOX_ITEM_TYPES.EDITOR}>
            <Scrollbar>
              <StyledTab>
                <StyledTabSection>
                  {activeProject?.name && (
                    <StyledProjectName
                      name={activeProject.name}
                      onChange={updateProjectName}
                    />
                  )}
                  {activeProject?.title && (
                    <StyledProjectName
                      name={activeProject.title}
                      onChange={updateProjectTitle}
                    />
                  )}
                  <TemplatesTab />
                </StyledTabSection>
              </StyledTab>
            </Scrollbar>
          </Tabs.TabContent>
        </Tabs>
      </StyledContentWrapper>
    </StyledLeftPanel>
  );
};

const mapState = createSelector({
  activeToolboxItem: selectActiveToolboxItem,
  activeProject: selectActiveProject,
});

const mapDispatch = {
  updateProject,
};

export default connect(mapState, mapDispatch)(LeftPanel);
