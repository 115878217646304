import { useCallback, useEffect, useRef } from 'react';

export function useBlur(onBlur: () => void) {
  const ref = useRef<Node>();

  const handler: EventListener = useCallback(
    ({ target }) => {
      if (ref.current && !exactOrHasParent(target as Node, ref.current)) {
        onBlur();
      }
    },
    [onBlur]
  );

  useEffect(() => {
    window.addEventListener('click', handler, true);
    return () => {
      window.removeEventListener('click', handler, true);
    };
  }, [handler]);

  return ref;
}

function exactOrHasParent(target: Node, parent: Node) {
  let context = target;
  let isFound = target === parent;

  while (!isFound) {
    if (context.parentNode === parent) {
      isFound = true;
    }

    if (!context.parentNode) {
      break;
    }

    context = context.parentNode;
  }

  return isFound;
}
