import styled from 'styled-components';

import {colors} from 'common/colors';
import {UploadIcon} from 'components/ui/Icons/UploadIcon';
import {VideoIcon} from 'components/ui/Icons/VideoIcon';
import {CameraIcon} from 'components/ui/Icons/CameraIcon';
import { Button } from '../../../../ui/Button';

export const StyledMediaTabWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledVideoIcon = styled(VideoIcon)`
  width: 30px;
  height: 30px;
`;

export const StyledUploadIcon = styled(UploadIcon)`
  width: 27px;
  height: 27px;
`;

export const StyledCameraIcon = styled(CameraIcon)`
  width: 21px;
  height: 15px;
`;

export const StyledMediaButton = styled(Button)`
  display: inline-flex;
  flex-direction: column;
  border-radius: 8px;
  height: 72px;
  width: 72px;
  box-sizing: border-box;
  padding: 8px 0;
  cursor: pointer;
  transition-property: background-color, box-shadow, fill;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  
  & > * {
    margin: auto;
  }

  :hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
`;

export const StyledTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const StyledBroadcastSelectPopup = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 430px;
  box-sizing: border-box;
  background: ${colors.white};
  border-radius: 8px;
`;

export const StyledBroadcastSelectPopupTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  padding: 32px 32px 0;
`;

export const BroadcastSelectPopupBody = styled.div`
  flex-grow: 1;
  padding: 0 32px 32px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
