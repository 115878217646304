import { EVENTS_LOADED_ACTION, RESET_TIMELINE_EVENTS } from 'components/blocks/EventsTimeline/constants';

export const timelineEventsLoaded = (payload: any) => {
  return {
    type: EVENTS_LOADED_ACTION,
    payload,
  };
};

export const resetTimelineEvents = () => {
  return {
    type: RESET_TIMELINE_EVENTS
  };
};
