import React from 'react';
import PropTypes from 'prop-types';

import { EmojiComponent } from 'components/ui/EmojiComponent';
import { StyledEmojiIcon } from 'components/ui/EmojiButtonComponent/styles';
import { PLACEMENT_ENUM, THEME_ENUM, Tooltip, TRIGGER_ENUM } from 'components/ui/Tooltip';

export const EmojiButtonComponent = React.memo(({ onSelect }) => (
  <Tooltip
    theme={THEME_ENUM.LIGHT}
    trigger={TRIGGER_ENUM.CLICK}
    overlay={<EmojiComponent onSelect={onSelect} />}
    placement={PLACEMENT_ENUM.TOP}
    hover
  >
    <span>
      <StyledEmojiIcon />
    </span>
  </Tooltip>
));

EmojiButtonComponent.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
EmojiButtonComponent.defaultProps = {};
