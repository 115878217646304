import React from 'react';

export const DownloadIcon = React.memo(({ ...props }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15.5 8H17C18.1046 8 19 8.89543 19 10V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V10C5 8.89543 5.89543 8 7 8H8.5"
        stroke="inherit"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17V3M12 17L8 13M12 17L16 13"
        stroke="inherit"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
