import styled, { createGlobalStyle, css } from 'styled-components';

import { Scrollbar } from 'components/ui/CustomScroll';
import { colors } from 'common/colors';
import { CrossArrowIcon } from 'components/ui/Icons/CrossArrowIcon';

export const CustomScrollbar = styled(Scrollbar)`
  height: ${({ height }) => height}px !important;
  overflow-y: hidden !important;
`;

export const StyledOptionWrapper = styled.div`
  ${(props) => {
    return (
      props.withSeparators &&
      css`
        cursor: pointer;
        &:not(:last-child) {
          .react-select__option {
            box-sizing: border-box;
            border-bottom: 1px solid ${colors.lightgray};
          }
        },
      `
    );
  }};
`;

// We need this empty wrapper to avoid affect "Select component" to "Scroll component"
export const StyledMenuListWrapper = styled.span``;

export const StyledCrossArrowIcon = styled(CrossArrowIcon)`
  width: 13px;
  height: 13px;
  stroke: ${colors.lightgray};
`;
