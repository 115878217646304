import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const useRedirectBroadcastId = (
  cb: (id: number) => void
): number | null => {
  const location = useLocation();
  const rawBroadcastId = new URLSearchParams(location.search).get(
    'broadcastId'
  );
  const broadcastId = rawBroadcastId ? Number(rawBroadcastId) : null;

  useEffect(() => {
    if (broadcastId) cb(broadcastId);
  }, [broadcastId]);

  return broadcastId;
};
