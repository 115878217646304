import styled, { css, keyframes } from 'styled-components';
import { EventCircle } from 'components/blocks/EventsTimeline/components/EventCircle';
import { colors } from 'common/colors';
import { PeakEvent } from 'components/blocks/EventsTimeline/components/PeakEvent';
import { PEAK_EVENT_TYPES } from 'components/blocks/EventsTimeline/constants';

export const StyledEventsTimelineArea = styled.div`
  height: 200px;
  position: relative;
`;

const StyledCircle = css`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${colors.summerRabbit};
  bottom: 8px;
`;

export const StyledLine = styled.div`
  background-color: ${colors.summerRabbit};
  bottom: 11px;
  height: 2px;
  width: 100%;
  left: 0;
  position: absolute;
`;

export const StyledLeftCircle = styled.div`
  ${StyledCircle};
  left: -4px;
`;

export const StyledRightCircle = styled.div`
  ${StyledCircle};
  right: -4px;
`;

const PeakEventAppearingKeyframes = keyframes`
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

const EventAppearingKeyframes = keyframes`
  0% {
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }
  50% {
    transform: translateX(-50%) scale(1.2);  
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
`;

const EventAppearingAnimation = (keyframes, delay = 0) => css`
  animation: ${keyframes};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-delay: ${({ position }) => (position / 100) * 0.5 + delay}s;
`;

export const StyledEvent = styled(EventCircle)`
  position: absolute;
  width: 24px;
  height: 24px;
  left: ${({ position }) => position}%;
  bottom: 0;
  transition: transform 0.1s;
  transform: translateX(-50%) scale(0);
  z-index: 1;

  ${EventAppearingAnimation(EventAppearingKeyframes)};

  &:hover {
    z-index: 2;
    transform: translateX(-50%) scale(1.2);
  }
`;

export const StyledPeakEvent = styled(PeakEvent)`
  position: absolute;
  left: ${({ position }) => position}%;
  bottom: 13px;
  opacity: 0;
  transform: translateX(-50%);

  ${({ eventType }) => eventType === PEAK_EVENT_TYPES.COMMENT && css`
    bottom: 24px;
  `};
    
  ${EventAppearingAnimation(PeakEventAppearingKeyframes, 0.5)};

  &:hover {
    z-index: 1;
  }
`;
