import { createTooltipTheme } from 'components/ui/Tooltip/themes/createTooltipTheme';

export const TooltipLightGlobalTheme = createTooltipTheme({
  name: 'light-theme',
  fontColor: '#000',
  backgroundColor: '#fff',
  borderColor: '#cccccc',
  boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  borderWidth: 1,
  opacity: 1,
});
