import { isEqual } from 'lodash';
import { select, take } from 'redux-saga/effects';

export function* selectorChangeSaga<Saga extends (...args: any[]) => any>(
  selector: (...args: any[]) => any,
  saga: Saga,
) {
  let previous = yield select(selector);
  while (true) {
    yield take();
    const next = yield select(selector);
    if (!isEqual(next, previous)) {
      yield* saga(next, previous);
      previous = next;
    }
  }
}
