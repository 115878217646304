import React, { FC, useCallback, useState } from 'react';
import {
  StyledCameraIcon,
  StyledIconWrapper,
  StyledMediaButton,
  StyledMediaTabWrapper,
  StyledTitle,
  StyledUploadIcon,
  StyledVideoIcon,
} from 'components/blocks/LeftPanel/components/MediaButtonGroup/styles';
import { connect, useSelector } from 'react-redux';
import { createSelector } from 'common/Redux/selectors';
import {
  selectActiveProject,
  selectProjectId,
} from 'common/Redux/Project/selectors';
import { requests } from 'common/requests';
import { MediaType, UserFeatures } from 'common/types';
import { ChooseBroadcastPopup } from '../../../AddBroadcastPopup';
import { VideoRecordingPopup } from 'components/blocks/VideoRecordingPopup';
import { ButtonType } from 'components/ui/Button/types';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import {
  analyticsEvents,
  analyticsMediaType,
} from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { FeatureWrapperComponent } from 'components/blocks/FeatureWrapper';
import { WithBetaLabel } from '../../../../ui/Badge/WithBetaLabel';

const ALLOWED_VIDEO_EXTENSION = ['mp4'];

const MediaButtonGroup: FC<{
  projectId: number;
  className?: string;
}> = ({ projectId, className }) => {
  const [
    isBroadcastPopupVisible,
    toggleBroadcastPopupVisibility,
  ] = useState<boolean>(false);

  const [
    isVideoRecordingPopupVisible,
    setVideoRecordingPopupVisibleState,
  ] = useState(false);

  const activeProject = useSelector(selectActiveProject);

  async function getOnBroadcastSelect({ id }: { id: number }) {
    const { mediaId } =
      (await requests.createMedia({
        type: MediaType.Broadcast,
        broadcastId: id,
        projectId,
      })) || {};

    if (mediaId) {
      analytics.trackEvent(analyticsEvents.ADD_MEDIA_SUCCESS, {
        Source: analyticsMediaType[MediaType.Broadcast],
      });
    }
  }

  const trackEventAddMedia = (type: MediaType) => {
    analytics.trackEvent(analyticsEvents.ADD_MEDIA_CLICKED, {
      Source: analyticsMediaType[type],
    });
  };

  const onFileUpload = useCallback(async (event: Event) => {
    const { target } = event;
    const { files } = target as HTMLInputElement;
    if (files?.[0]) {
      const video = files?.[0];
      await requests.createMedia({
        type: MediaType.Upload,
        projectId: activeProject?.id,
        name: video.name,
        file: video,
      });
    }
  }, []);

  const onUploadFileClick = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = ALLOWED_VIDEO_EXTENSION.map((ext) => `.${ext}`).join(', ');
    input.onchange = onFileUpload;
    input.click();
  }, [onFileUpload]);

  // @ts-ignore
  return (
    <StyledMediaTabWrapper className={className}>
      <StyledMediaButton
        type={ButtonType.Primary}
        onClick={() => {
          toggleBroadcastPopupVisibility(!isBroadcastPopupVisible);
          trackEventAddMedia(MediaType.Broadcast);
        }}
      >
        <StyledIconWrapper>
          <StyledVideoIcon />
        </StyledIconWrapper>
        <StyledTitle>Broadcasts</StyledTitle>
      </StyledMediaButton>
      <FeatureWrapperComponent feature={UserFeatures.RecordVideo}>
        <StyledMediaButton
          onClick={() => {
            setVideoRecordingPopupVisibleState(!isVideoRecordingPopupVisible);
            trackEventAddMedia(MediaType.Recording);
          }}
          type={ButtonType.Secondary}
        >
          <StyledIconWrapper>
            <StyledCameraIcon />
          </StyledIconWrapper>
          <StyledTitle>Record</StyledTitle>
        </StyledMediaButton>
      </FeatureWrapperComponent>
      <WithBetaLabel>
        <StyledMediaButton
          type={ButtonType.Secondary}
          onClick={onUploadFileClick}
        >
          <StyledIconWrapper>
            <StyledUploadIcon />
          </StyledIconWrapper>
          <StyledTitle>Upload</StyledTitle>
        </StyledMediaButton>
      </WithBetaLabel>

      <ChooseBroadcastPopup
        isVisible={isBroadcastPopupVisible}
        onClose={() => toggleBroadcastPopupVisibility(false)}
        onSelect={(payload) => {
          getOnBroadcastSelect(payload);
          toggleBroadcastPopupVisibility(false);
        }}
      />

      <VideoRecordingPopup
        isVisible={isVideoRecordingPopupVisible}
        onClose={() => setVideoRecordingPopupVisibleState(false)}
        onVideoRecorded={(success: boolean) => {
          setVideoRecordingPopupVisibleState(false);
          if (success) {
            analytics.trackEvent(analyticsEvents.ADD_MEDIA_SUCCESS, {
              Source: analyticsMediaType[MediaType.Recording],
            });
          }
        }}
      />
    </StyledMediaTabWrapper>
  );
};

const mapState = createSelector({
  projectId: selectProjectId,
});

export default connect(mapState)(MediaButtonGroup);
