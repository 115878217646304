import React from 'react';
import { connect } from 'react-redux';
import { Header } from 'components/blocks/Header';
import {
  StyledButtonsGroup,
  StyledRightPanel,
} from 'components/blocks/RightPanel/styles';
import {
  StyledDownloadButton,
  StyledOpenBroadcastButton,
  StyledShareButton,
} from 'components/blocks/Header/styles';
import { EditableText } from 'components/ui/EditableText';
import { createStructuredSelector } from 'reselect';
import { selectActiveProject } from 'common/Redux/Project/selectors';
import { updateProject } from 'common/Redux/Project/actions';
import { UserFeatures } from 'common/types';
import { FeatureWrapperComponent } from '../FeatureWrapper';
import { ChecklistContainer } from './ChecklistContainer';
import { BUTTON_SIZES_ENUM, ButtonType } from '../../ui/Button/types';
import { analytics } from '../../../common/3rdUtils/analytics/analytics';
import { useHistory } from 'react-router-dom';
import { analyticsEvents } from '../../../common/3rdUtils/analytics/analyticsEventsEnums';

export const RightPanel = ({
  projectSource = null,
  activeProject,
  updateProject,
}) => {
  const updateProjectName = (name) =>
    updateProject({ projectId: activeProject.id, name });

  const history = useHistory();

  const onOpenBroadcast = () => {
    analytics.trackEvent(analyticsEvents.BACK_TO_PROJECTS);
    history.push('/');
  };

  const onShoppableVideoGuidelinesComplete = () => {
    analytics.trackEvent(analyticsEvents.SHOPPABLE_GUIDELINES_COMPLETE);
  };

  const onAmazonGuidelinesComplete = () => {
    analytics.trackEvent(analyticsEvents.AMAZON_GUIDELINES_COMPLETE);
  };

  return (
    <StyledRightPanel>
      <Header />
      {activeProject && (
        <EditableText text={activeProject.name} onChange={updateProjectName} />
      )}
      <ChecklistContainer
        onShoppableVideoGuidelinesComplete={onShoppableVideoGuidelinesComplete}
        onAmazonGuidelinesComplete={onAmazonGuidelinesComplete}
      />
      <StyledButtonsGroup>
        <FeatureWrapperComponent feature={UserFeatures.Share}>
          <StyledShareButton broadcastId={projectSource} />
        </FeatureWrapperComponent>
        <FeatureWrapperComponent feature={UserFeatures.Download}>
          <StyledDownloadButton broadcastId={projectSource} />
        </FeatureWrapperComponent>
      </StyledButtonsGroup>
      <StyledOpenBroadcastButton
        size={BUTTON_SIZES_ENUM.S}
        type={ButtonType.Secondary}
        onClick={onOpenBroadcast}
      >
        Back to Projects
      </StyledOpenBroadcastButton>
    </StyledRightPanel>
  );
};

const mapState = createStructuredSelector({
  activeProject: selectActiveProject,
});

const mapDispatch = {
  updateProject,
};

export const RightPanelContainer = connect(mapState, mapDispatch)(RightPanel);
