import { Checkbox } from '../../Checkbox';
import styled, { css } from 'styled-components';
import React from 'react';
import { useChecklistContext } from '../context';
import { ChecklistItem } from '../types';
import { conditionalStyle } from '../../../../utils/styles';
import { colors } from '../../../../common/colors';

export const Item = ({ title, description, id, isChecked }: ChecklistItem) => {
  const { toggleItem } = useChecklistContext();

  const onToggle = () => {
    toggleItem(id, !isChecked);
  };

  return (
    <Item.Styled onClick={onToggle}>
      <StyledContainer>
        <Checkbox checked={isChecked} onChange={() => {}} />
      </StyledContainer>
      <div>
        <StyledTitle isCrossed={isChecked}>{title}</StyledTitle>
        <StyledDescription isCrossed={isChecked}>
          {description}
        </StyledDescription>
      </div>
    </Item.Styled>
  );
};

const StyledContainer = styled.div`
  pointer-events: none;
`;
Item.Styled = styled.div<{ onClick: () => void }>`
  min-height: 50px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  color: ${colors.almostBack};
  font-size: 12px;
  cursor: pointer;

  & > div {
    padding: 10px;
  }

  &:hover {
    background-color: rgba(187, 187, 187, 0.3);
  }
`;

const StyledDescription = styled.div<{ isCrossed: boolean }>`
  font-size: 12px;
  ${conditionalStyle({
    isCrossed: css`
      color: ${colors.lightgray};
    `,
  })};
`;

const StyledTitle = styled.div<{ isCrossed: boolean }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 0;
  border-radius: 4px;

  ${conditionalStyle({
    isCrossed: css`
      color: ${colors.lightgray};
    `,
  })};
`;
