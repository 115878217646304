import styled from 'styled-components';
import { YTIcon } from 'components/ui/Icons/YTIcon';
import { FBIcon } from 'components/ui/Icons/FBIcon';
import { LIIcon } from 'components/ui/Icons/LIIcon';
import { SoonIcon } from 'components/ui/Icons/SoonIcon';

export const StyleOption = styled.div`
  display: -webkit-flex;
  display: flex;
  height: 30px;
  align-items: center;
  vertical-align: middle;
  line-height: 20px;
`;

export const StyleFacebookIcon = styled(FBIcon)`
  display: -webkit-flex;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  vertical-align: middle;
`;

export const StyleYoutubeIcon = styled(YTIcon)`
  width: 40px;
  height: 40px;
`;

export const StyleLinkedinIcon = styled(LIIcon)`
  position: absolute;
  width: 30px;
  height: 30px;
  left: 16px;
`;

export const StyleSoonIcon = styled(SoonIcon)`
  position: relative;
  width: 38px;
  height: 16px;
  left: 4px;
`;

export const StyleOptionText = styled.div`
  position: absolute;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 30px;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  left: 66px;
`;
