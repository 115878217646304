import { request } from './request';
import { MediaType } from 'common/types';
import {
  CreateBroadcastMediaRequestParams,
  CreateMediaRequest,
  CreateRecordingMediaRequestParams,
  DeleteMediaRequest,
} from './types';

export const createMedia: CreateMediaRequest = async (params) => {
  const form = new FormData();
  let formFields: [string, any][] = [
    ['type', params.type],
    ['name', params.name],
    ['projectId', params.projectId],
  ];

  if (params.type === MediaType.Broadcast) {
    const { broadcastId } = params as CreateBroadcastMediaRequestParams;
    formFields = formFields.concat([['broadcastId', broadcastId]]);
  } else if ([MediaType.Recording, MediaType.Upload].includes(params.type)) {
    const { file } = params as CreateRecordingMediaRequestParams;
    formFields = formFields.concat([['webm', file]]);
  }

  formFields.forEach(([key, value]) => value && form.append(key, value));

  const { data } = await request.post(`/media/create`, form);

  return data;
};

export const deleteMedia: DeleteMediaRequest = (params) =>
  request.delete(`/media/${params.id}`);
