import React, { FC } from 'react';

export const Reactions2Icon: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox="0 0 184 185" fill="none" stroke="none">
    <g opacity="0.5">
      <circle
        cx="14.2789"
        cy="42.6458"
        r="4"
        transform="rotate(-15 14.2789 42.6458)"
        fill="#F9BB40"
      />
      <circle
        cx="39.3931"
        cy="35.9163"
        r="4"
        transform="rotate(-15 39.3931 35.9163)"
        fill="#F9BB40"
      />
      <circle
        cx="64.5074"
        cy="29.1868"
        r="4"
        transform="rotate(-15 64.5074 29.1868)"
        fill="#F9BB40"
      />
      <circle
        cx="89.6207"
        cy="22.4583"
        r="4"
        transform="rotate(-15 89.6207 22.4583)"
        fill="#F9BB40"
      />
      <circle
        cx="114.735"
        cy="15.7288"
        r="4"
        transform="rotate(-15 114.735 15.7288)"
        fill="#F9BB40"
      />
      <circle
        cx="139.849"
        cy="8.99933"
        r="4"
        transform="rotate(-15 139.849 8.99933)"
        fill="#F9BB40"
      />
      <circle
        cx="21.0152"
        cy="67.7747"
        r="4"
        transform="rotate(-15 21.0152 67.7747)"
        fill="#F9BB40"
      />
      <circle
        cx="46.1294"
        cy="61.0452"
        r="4"
        transform="rotate(-15 46.1294 61.0452)"
        fill="#F9BB40"
      />
      <circle
        cx="71.2437"
        cy="54.3157"
        r="4"
        transform="rotate(-15 71.2437 54.3157)"
        fill="#F9BB40"
      />
      <circle
        cx="96.357"
        cy="47.5872"
        r="4"
        transform="rotate(-15 96.357 47.5872)"
        fill="#F9BB40"
      />
      <circle
        cx="121.471"
        cy="40.8577"
        r="4"
        transform="rotate(-15 121.471 40.8577)"
        fill="#F9BB40"
      />
      <circle
        cx="146.586"
        cy="34.1282"
        r="4"
        transform="rotate(-15 146.586 34.1282)"
        fill="#F9BB40"
      />
      <circle
        cx="27.7515"
        cy="92.9046"
        r="4"
        transform="rotate(-15 27.7515 92.9046)"
        fill="#F9BB40"
      />
      <circle
        cx="52.8658"
        cy="86.1751"
        r="4"
        transform="rotate(-15 52.8658 86.1751)"
        fill="#F9BB40"
      />
      <circle
        cx="77.98"
        cy="79.4456"
        r="4"
        transform="rotate(-15 77.98 79.4456)"
        fill="#F9BB40"
      />
      <circle
        cx="103.093"
        cy="72.7171"
        r="4"
        transform="rotate(-15 103.093 72.7171)"
        fill="#F9BB40"
      />
      <circle
        cx="128.208"
        cy="65.9876"
        r="4"
        transform="rotate(-15 128.208 65.9876)"
        fill="#F9BB40"
      />
      <circle
        cx="153.322"
        cy="59.2581"
        r="4"
        transform="rotate(-15 153.322 59.2581)"
        fill="#F9BB40"
      />
      <circle
        cx="34.4878"
        cy="118.034"
        r="4"
        transform="rotate(-15 34.4878 118.034)"
        fill="#F9BB40"
      />
      <circle
        cx="59.6021"
        cy="111.304"
        r="4"
        transform="rotate(-15 59.6021 111.304)"
        fill="#F9BB40"
      />
      <circle
        cx="84.7164"
        cy="104.575"
        r="4"
        transform="rotate(-15 84.7164 104.575)"
        fill="#F9BB40"
      />
      <circle
        cx="109.83"
        cy="97.846"
        r="4"
        transform="rotate(-15 109.83 97.846)"
        fill="#F9BB40"
      />
      <circle
        cx="134.944"
        cy="91.1165"
        r="4"
        transform="rotate(-15 134.944 91.1165)"
        fill="#F9BB40"
      />
      <circle
        cx="160.058"
        cy="84.387"
        r="4"
        transform="rotate(-15 160.058 84.387)"
        fill="#F9BB40"
      />
      <circle
        cx="41.2242"
        cy="143.162"
        r="4"
        transform="rotate(-15 41.2242 143.162)"
        fill="#F9BB40"
      />
      <circle
        cx="66.3384"
        cy="136.433"
        r="4"
        transform="rotate(-15 66.3384 136.433)"
        fill="#F9BB40"
      />
      <circle
        cx="91.4527"
        cy="129.703"
        r="4"
        transform="rotate(-15 91.4527 129.703)"
        fill="#F9BB40"
      />
      <circle
        cx="116.566"
        cy="122.975"
        r="4"
        transform="rotate(-15 116.566 122.975)"
        fill="#F9BB40"
      />
      <circle
        cx="141.68"
        cy="116.245"
        r="4"
        transform="rotate(-15 141.68 116.245)"
        fill="#F9BB40"
      />
      <circle
        cx="166.794"
        cy="109.516"
        r="4"
        transform="rotate(-15 166.794 109.516)"
        fill="#F9BB40"
      />
      <circle
        cx="47.9605"
        cy="168.291"
        r="4"
        transform="rotate(-15 47.9605 168.291)"
        fill="#F9BB40"
      />
      <circle
        cx="73.0748"
        cy="161.562"
        r="4"
        transform="rotate(-15 73.0748 161.562)"
        fill="#F9BB40"
      />
      <circle
        cx="98.189"
        cy="154.832"
        r="4"
        transform="rotate(-15 98.189 154.832)"
        fill="#F9BB40"
      />
      <circle
        cx="123.302"
        cy="148.104"
        r="4"
        transform="rotate(-15 123.302 148.104)"
        fill="#F9BB40"
      />
      <circle
        cx="148.417"
        cy="141.374"
        r="4"
        transform="rotate(-15 148.417 141.374)"
        fill="#F9BB40"
      />
      <circle
        cx="173.531"
        cy="134.645"
        r="4"
        transform="rotate(-15 173.531 134.645)"
        fill="#F9BB40"
      />
    </g>
    <g filter="url(#filter0_dc)">
      <rect
        width="73.4104"
        height="73.3538"
        rx="36.6769"
        transform="matrix(0.965983 -0.258604 0.259034 0.965868 51.5361 95.7354)"
        fill="url(#paint0_linear)"
      />
      <g clipPath="url(#clip0)">
        <rect
          width="8.38976"
          height="18.8624"
          rx="2"
          transform="matrix(0.965983 -0.258604 0.259034 0.965868 80.2119 121.688)"
          fill="white"
        />
        <path
          d="M92.5254 107.976C94.5562 115.549 92.7466 118.738 89.8825 121.309L93.2672 133.93C94.3217 134.549 96.9281 135.655 98.9173 135.122L108.864 132.46C112.524 131.48 113.16 128.604 111.88 127.143C113.49 126.339 113.182 123.167 111.58 122.714C113.553 122.526 113.987 118.07 111.055 117.444C113.173 115.034 110.865 111.183 106.989 112.22L100.358 113.995L98.553 107.264C97.4248 103.057 91.6228 104.611 92.5254 107.976Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter1_dc)">
      <g clipPath="url(#clip1dc)">
        <rect
          width="64.3598"
          height="64.3102"
          rx="32.1551"
          transform="matrix(0.965966 0.258668 -0.258842 0.96592 106.374 11.4209)"
          fill="url(#paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.93 41.772C146.759 43.2066 146.271 45.028 145.295 48.6707L140.143 67.8959C139.611 69.8798 139.345 70.8718 138.683 70.9592C138.02 71.0467 137.507 70.1576 136.479 68.3794L135.673 66.9851C134.855 65.5704 134.447 64.8631 133.816 64.3792C133.185 63.8953 132.395 63.684 130.817 63.2613L118.43 59.9444C114.787 58.9689 112.966 58.4811 112.137 57.0464C111.309 55.6117 111.797 53.7904 112.773 50.1477L115.32 40.6441C116.296 37.0014 116.784 35.18 118.219 34.3514C119.654 33.5228 121.476 34.0106 125.118 34.9861L139.638 38.874C143.28 39.8495 145.102 40.3373 145.93 41.772ZM135.859 53.9117C134.479 53.5423 133.66 52.1254 134.03 50.747C134.399 49.3686 135.817 48.5506 137.196 48.92C138.576 49.2894 139.395 50.7063 139.025 52.0847C138.656 53.4632 137.238 54.2811 135.859 53.9117ZM126.536 48.7403C126.167 50.1187 126.986 51.5356 128.365 51.905C129.745 52.2744 131.162 51.4565 131.532 50.0781C131.901 48.6997 131.082 47.2828 129.703 46.9134C128.323 46.5439 126.905 47.3619 126.536 48.7403ZM120.871 49.8984C119.492 49.529 118.673 48.1121 119.042 46.7337C119.412 45.3552 120.83 44.5373 122.209 44.9067C123.589 45.2761 124.407 46.693 124.038 48.0714C123.669 49.4498 122.251 50.2678 120.871 49.8984Z"
          fill="white"
        />
      </g>
    </g>
    <g filter="url(#filter2_dc)">
      <g clipPath="url(#clip2dc)">
        <rect
          width="36.1983"
          height="36.1786"
          rx="18.0893"
          transform="matrix(0.866161 -0.499765 0.500676 0.865635 16.0322 76.3994)"
          fill="url(#paint2_linear)"
        />
        <path
          d="M45.5156 91.2241C57.2025 71.8386 39.232 68.4833 37.8449 77.9619C28.9301 74.4274 22.863 91.6521 45.5156 91.2241Z"
          fill="white"
          stroke="white"
          strokeWidth="1.8"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dc"
        x="29.5361"
        y="58.751"
        width="133.914"
        height="133.834"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.976471 0 0 0 0 0.733333 0 0 0 0 0.25098 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dc"
        x="67.7275"
        y="-6.5791"
        width="122.816"
        height="122.766"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.345098 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dc"
        x="-5.96777"
        y="40.3086"
        width="93.4673"
        height="93.4081"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="8"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.941176 0 0 0 0 0.356863 0 0 0 0 0.360784 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="36.7052"
        y1="0"
        x2="36.7052"
        y2="73.3538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBC33D" />
        <stop offset="1" stopColor="#E68009" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="32.1799"
        y1="0"
        x2="32.1799"
        y2="64.3102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3DD9FB" />
        <stop offset="1" stopColor="#413DFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="18.0991"
        y1="0"
        x2="18.0991"
        y2="36.1786"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.203125" stopColor="#FF6683" />
        <stop offset="1" stopColor="#EC1733" />
      </linearGradient>
      <clipPath id="clip0">
        <rect
          width="41.9488"
          height="41.9165"
          fill="white"
          transform="matrix(0.965983 -0.258604 0.259034 0.965868 70.8037 106.85)"
        />
      </clipPath>
      <clipPath id="clip1dc">
        <rect
          width="64.3598"
          height="64.3102"
          rx="32.1551"
          transform="matrix(0.965966 0.258668 -0.258842 0.96592 106.374 11.4209)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2dc">
        <rect
          width="36.1983"
          height="36.1786"
          rx="18.0893"
          transform="matrix(0.866161 -0.499765 0.500676 0.865635 16.0322 76.3994)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
