/* eslint-disable */
import { REACT_APP_GOOGLE_ID } from '../config';

export const Google = {
  ID: REACT_APP_GOOGLE_ID,
  init() {
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      'https://www.google-analytics.com/analytics.js',
      'ga'
    );

    window.ga('create', REACT_APP_GOOGLE_ID, 'auto');
    Google.pageView();
  },
  setUserId(userId) {
    window.ga('set', 'userId', userId);
  },
  pageView(path) {
    window.ga('send', 'pageview', path ? path : location.pathname);
  },
  track(name) {
    window.ga('send', 'event', 'Click', 'User Action', name);
  },
};
