import React from 'react';
import 'components/blocks/Preview/Preview.css';
import {
  StyledControlsWrapper,
  StyledPreviewWrapper,
  StyledVideoPlayer,
  StyledWrapper
} from 'components/blocks/Preview/styles';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { TIME_LINE_INTERVAL } from 'components/blocks/Preview/constants';
import { videoPlayerService } from 'components/blocks/Preview/videoPlayerService';
import { buildVideoUrl } from 'utils/helpers';
import { PlayerPlaceholder } from './components/PlayerPlaceholder';
import PreviewProgress from './components/Progress/PreviewProgress';
import { ProjectAspectRatio, ProjectAspectRatioValue } from '../../../common/types';

const PLAYER_CONFIG = {
  file: {
    attributes: {
      crossOrigin: 'true',
    },
    hlsOptions: {
      xhrSetup: function (xhr) {
        xhr.withCredentials = true;
      },
    },
  },
};

const onPlayerError = (e) => console.log('onError', e);

export class Preview extends React.Component {
  state = {
    playbackRate: 1,
  };

  render() {
    const {
      isReady,
      isPlaying,
      activeMedia,
      activeMediaSource,
      areProjectsFetched,
      isVisible,
      overlayType,
      activeProject,
    } = this.props;
    const { playbackRate } = this.state;

    const videoUrl = activeMediaSource
      ? buildVideoUrl(activeMediaSource)
      : undefined;

    const projectAspectRatioType = activeProject?.aspectRatio || ProjectAspectRatio.Wide16x9;
    const projectAspectRatioValue = ProjectAspectRatioValue[projectAspectRatioType];

    return (
      <StyledPreviewWrapper isVisible={isVisible}>
        {areProjectsFetched && (
          <StyledWrapper
            interactive
            disabled={!isReady}
            aspectRatio={projectAspectRatioValue}
            contentAspectRatio={16/9}
            content={
              activeMedia ? (
                <StyledVideoPlayer
                  onClick={this.handleVideoClick}
                  ref={this.ref}
                  url={videoUrl}
                  volume={1}
                  muted={false}
                  progressInterval={TIME_LINE_INTERVAL}
                  playbackRate={playbackRate}
                  playing={isPlaying}
                  onReady={this.handleTrackPlayerOnReady}
                  onSeek={this.handleTrackPlayerOnSeek}
                  onEnded={this.handleTrackPlayerEnded}
                  onError={onPlayerError}
                  onProgress={this.handleTrackPlayerOnProgress}
                  onDuration={this.handleTrackPlayerOnDuration}
                  config={PLAYER_CONFIG}
                />
              ) : (
                <PlayerPlaceholder />
              )
            }
          >
            <StyledControlsWrapper>
              <PreviewProgress />
            </StyledControlsWrapper>
          </StyledWrapper>
        )}
      </StyledPreviewWrapper>
    );
  }

  ref = (dom) => {
    videoPlayerService.setPlayer(dom);
  };

  handleTrackPlayerOnReady = () => {
    this.props.TimelineTrackLoadedAndReady();
  };

  handleTrackPlayerOnSeek = (seekPosition) => {
    const { isCursorOutOfTrimmingRange } = this.props;
    if (isCursorOutOfTrimmingRange) {
      this.props.PreviewControlsPlayPause(true);
    }
    this.props.TimelinePlayedPosition(seekPosition);
  };

  handleTrackPlayerEnded = () => {
    console.log('Ended');
    this.props.PreviewControlsPlayPause();
  };

  handleTrackPlayerOnProgress = (progressPosition) => {
    const { isPlaying } = this.props;
    if (isPlaying) {
      this.props.TimelinePlayedPosition(progressPosition.playedSeconds);
      if (this.props.isCursorOutOfTrimmingRange) {
        this.props.PreviewControlsPlayPause();
      }
    }
  };

  handleTrackPlayerOnDuration = (duration) => {
    console.log('Player duration:', duration);
    const { endTrimmPos } = this.props.TimeLine;
    // TODO HACK!!! will be removed after timeline reimplementation
    const trimEnd = endTrimmPos === 2 ? duration : endTrimmPos;

    this.props.TimelineUpdateDuration(duration);
    this.props.TimeLineTrackUpdateTrimmEndPos(trimEnd, false);
  };

  handleVideoClick = () => {
    if (!this.props.TimeLine) return;
    if (!this.props.TimeLine.duration) return;
    if (!this.props.TimeLine.ready) return;
    this.props.PreviewControlsPlayPause();
  };

  handleTrimmStartClick = () => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.TRIMMING_THRU_PLAYER_USED);
    if (!this.props.TimeLine) return;
    if (!this.props.TimeLine.duration) return;
    if (!this.props.TimeLine.ready) return;
    this.props.TimeLineTrackUpdateTrimmStartPos(
      this.props.TimeLine.currentPlayPosition,
      true,
    );
  };

  handleTrimmEndClick = () => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.TRIMMING_THRU_PLAYER_USED);
    if (!this.props.TimeLine) return;
    if (!this.props.TimeLine.duration) return;
    if (!this.props.TimeLine.ready) return;
    this.props.TimeLineTrackUpdateTrimmEndPos(
      this.props.TimeLine.currentPlayPosition,
      true,
    );
  };

  onSpeedChange = (playbackRate) => {
    this.setState({ playbackRate });
  };
}

Preview.defaultProps = {
  TimelineUpdateDuration: () => {},
  TimeLineTrackUpdateTrimmEndPos: () => {},
  TimeLineTrackUpdateTrimmStartPos: () => {},
  TimelinePlayedPosition: () => {},
  PreviewControlsPlayPause: () => {},
  isVisible: true,
};
