import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import { conditionalStyle } from 'utils/styles';
import { DeleteButton } from 'components/ui/DeleteButton';
import { lighten } from 'polished';
import { Button } from '../Button';

export const StyledMediaRemoveButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 4px;
`;

export const StyledMediaRemoveButton = styled(DeleteButton)`
  visibility: hidden;

  ${conditionalStyle({
    active: css`
      visibility: visible;
    `,
  })};
`;
export const StyledMediaButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  background-color: ${colors.yellow};
  opacity: 1 !important;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  :hover {
    background-color: ${lighten(0.05, colors.yellow)};
  }
`;

export const StyledUseMediaButton = styled(StyledMediaButton)`
  visibility: hidden;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0 12px;
`;

export const StyledMediaInnerText = styled.div`
  position: absolute;
  bottom: 5px;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 92%;
  margin: 0 4%;
  white-space: nowrap;
`;

export const StyledMediaWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 117px;
  margin: 4px;

  :hover {
    ${StyledMediaRemoveButton} {
      visibility: visible;
    }

    ${StyledMediaInnerText} {
      visibility: hidden;
    }

    ${StyledUseMediaButton} {
      visibility: visible;
    }
  }
`;

export const StyledTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.summerRabbit};
`;

export const StyledMediaThumbnail = styled.div<{
  img?: string;
  active: boolean;
  isProcessing: boolean;
  isGrayed: boolean;
}>`
  box-sizing: border-box;
  display: inline-flex;
  position: relative;
  height: 72px;
  border-radius: 8px;
  overflow: hidden;
  background: ${colors.darkGrey};
  box-shadow: 0 0 0 0 #f28a21;
  transition: box-shadow 0.1s;
  ${(props) =>
    conditionalStyle({
      img: css`
        background-image: url(${props.img});
        background-size: cover;
      `,
      active: css`
        box-shadow: 0 0 0 4px #f28a21;
      `,
      isProcessing: css`
        :before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.3);
        }

        ${StyledUseMediaButton} {
          display: none;
        }
      `,
      isGrayed: css`
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
        }
      `,
    })(props)
  };
`;
