import styled, { css } from 'styled-components';
import { colors } from 'common/colors';

export const StyledOverlayTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 16px;
  font-size: 12px;
  color: ${colors.summerRabbit};
`;

export const BasicOverlayLayoutStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${colors.white};
`;
