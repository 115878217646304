import { request } from 'common/requests';
import { LIST_IDS } from '../../components/ui/Checklist/types';
import { AxiosResponse } from 'axios';

type SaveChecklist = (params: {
  checklistId: LIST_IDS;
  itemId: string;
  checked: boolean;
}) => Promise<AxiosResponse<any>>;

export const saveChecklist: SaveChecklist = async ({
  checklistId,
  itemId,
  checked,
}) => {
  const res = await request.post(`checklist/${checklistId}`, {
    itemId,
    checked,
  });
  return res;
};
