import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { colors } from 'common/colors';

export const StyledToolbox = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 72px;
  background: ${colors.almostBack};
`;

export const StyledToolboxItem = styled.div<{
  active: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 72px;
  cursor: pointer;
  color: ${colors.steel};
  fill: ${colors.steel};
  stroke: ${colors.steel};
  transition: all 0.2s ease;

  :hover {
    color: ${lighten(0.1, colors.steel)};
    fill: ${lighten(0.1, colors.steel)};
    stroke: ${lighten(0.1, colors.steel)};
  }

  ${({ active }) =>
    active &&
    css`
      ::before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: 0;
        width: 6px;
        background: ${colors.yellow};
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      transition: all 0s ease;

      color: ${colors.yellow} !important;
      fill: ${colors.yellow} !important;
      stroke: ${colors.yellow} !important;
    `}
`;

export const StyledToolboxTitle = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
`;

export const StyledToolboxFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;
