import axios from 'axios';
import { gotToLoginPage } from 'components/pages/HomePage/services';
import { firebaseService } from 'services/firebase';
import { Dispatch } from 'react';
import { Me } from 'common/Redux/Auth/types';
import { requests } from 'common/requests';

const PROXY_SERVER = process.env.REACT_APP_BELIVE_EDITOR_AUTH_PROXY_SERVER;

export const CHECK_FOR_AUTH = 'CHECK_FOR_AUTH';
export const LOGOUT = 'LOGOUT';

export const checkForAuth = () => {
  return (dispatch: Dispatch<any>) => {
    requests.getMe().then(
      async (me: Me) => {
        await firebaseService.signInWithCustomToken(me.firebaseToken);
        dispatch(updateAuth(me));
      },
      (error: Error) => {
        console.log(error);
        if (error) {
          console.log('No AUTH');
          gotToLoginPage();
        }
      }
    );
  };
};

export const logout = () => {
  return (dispatch: Dispatch<any>) => {
    let postAddress = `${PROXY_SERVER}/logout`;
    axios.post(postAddress).then(
      (response) => {
        if (response) {
          console.log(response);
          dispatch(logoutAction);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const updateAuth = (authData: any) => {
  return {
    type: CHECK_FOR_AUTH,
    payload: authData,
  };
};

export const logoutAction = {
  type: LOGOUT,
};
