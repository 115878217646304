export function startStreamRecording(stream: MediaStream, limit?: number) {
  const recorder = new MediaRecorder(stream);
  const data: Blob[] = [];
  let limitTimeout = 0;

  recorder.ondataavailable = (event) => data.push(event.data);
  recorder.start();

  let stopped = new Promise((resolve, reject) => {
    recorder.onstop = resolve;
    recorder.onerror = (event) => reject(event.error);
  });

  if (limit) {
    limitTimeout = setTimeout(() => {
      if (recorder.state === 'recording') {
        recorder.stop();
      }
    }, limit) as any;
  }

  return stopped.then(() => {
    clearTimeout(limitTimeout);
    return data;
  });
}

export function stopStream(stream: MediaStream) {
  stream.getTracks().forEach((track) => track.stop());
}

export function createCameraStream(
  cameraId?: string,
  microphoneId?: string
): Promise<MediaStream> {
  return navigator.mediaDevices.getUserMedia({
    video: {
      deviceId: cameraId,
      aspectRatio: 16 / 9,
      width: { ideal: 1920 },
      height: { ideal: 1080 },
    },
    audio: {
      deviceId: microphoneId,
    },
  });
}

export type Devices = Record<MediaDeviceKind, MediaDeviceInfo[]>;

export async function getDevices() {
  return await navigator.mediaDevices.enumerateDevices().then((devices) =>
    devices.reduce(
      (acc, device) => {
        acc[device.kind].push(device);
        return acc;
      },
      { audioinput: [], videoinput: [], audiooutput: [] } as Devices
    )
  );
}
