import { createSelector } from 'reselect';
import { GlobalState } from '../types';
import { selectUserProjectCollection } from 'common/Redux/User/selectors';
import { ProjectState } from './types';
import { toArray } from 'lodash/fp';
import { ProjectType } from '../../types';

const getProjectState = (state: GlobalState) => state.project;

export const selectProjectId = createSelector(getProjectState, ({ id }) => id);

export const selectActiveProject = createSelector(
  selectProjectId,
  selectUserProjectCollection,
  (projectId, projects) => (projectId ? projects[projectId] : null)
);

export const isShoppableProjectSelector = createSelector(
  selectActiveProject,
  (activeProject) => activeProject?.type === ProjectType.SHOPPABLE
);

export const selectActiveProjectMediaCollection = createSelector(
  selectActiveProject,
  (activeProject) => (activeProject ? activeProject.media : {})
);

export const selectActiveProjectActiveMedia = createSelector(
  selectActiveProject,
  (activeProject) => {
    let activeMedia = null;

    if (activeProject) {
      activeMedia = toArray(activeProject.media)[0] || null;
    }

    return activeMedia;
  }
);

export const selectActiveProjectActiveMediaId = createSelector(
  selectActiveProjectActiveMedia,
  (activeMedia) => {
    return activeMedia?.id || null;
  }
);

export const selectActiveProjectActiveMediaSource = createSelector(
  selectActiveProjectActiveMedia,
  (activeProjectActiveMedia) => {
    if (!activeProjectActiveMedia || activeProjectActiveMedia.processing)
      return null;
    return activeProjectActiveMedia.source;
  }
);
