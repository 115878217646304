import { useState } from 'react';
import { createCounter } from '../../utils/helpers';

export function useTimer(time: number) {
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [stopTimer, setStopTimer] = useState<() => void>(() => () => {});
  const [timeLeft, setTimeLeft] = useState(time);

  function startTimer() {
    return new Promise<void>((resolve) => {
      if (isTimerStarted) {
        stopTimer();
      }
      setTimeLeft(time);
      setIsTimerStarted(true);

      const interruptTimer = createCounter(
        time,
        (value) => {
          setTimeLeft(value);
          if (value === 0) {
            resolve();
            setIsTimerStarted(false);
          }
        },
        1000
      );
      setStopTimer(() => () => {
        interruptTimer();
        setIsTimerStarted(false);
      });
    });
  }

  return { startTimer, stopTimer, isTimerStarted, timeLeft };
}
