import React, { FC, useRef } from 'react';
import { noop } from 'lodash';

import {
  StyledActionButtons,
  StyledDeleteButton,
  StyledDisabledCurtain,
  StyledEditButton,
  StyledImagePlaceholder,
  StyledImagePlaceholderIcon,
  StyledTitle,
  StyledWrapper
} from './styles';
import { ConfirmationTooltip } from 'components/ui/ConfirmationTooltip';
import { ButtonType } from '../../../ui/Button/types';

export const ProjectItem: FC<{
  title: string;
  isDisabled?: boolean;
  disableReason?: string;
  onClick?: () => void;
  onDelete?: () => void;
}> = ({ title, isDisabled = false, onClick = noop, onDelete = noop, disableReason = '' }) => {
  const tooltipContainerRef = useRef<any>(null);
  const getTooltipContainer = () => tooltipContainerRef.current as HTMLElement;

  return (
      <StyledWrapper onClick={isDisabled ? noop : onClick}
                     ref={tooltipContainerRef}
                     isDisabled={isDisabled}
      >
        <StyledImagePlaceholder>
          <StyledImagePlaceholderIcon />
          <StyledEditButton type={ButtonType.Primary}>Edit</StyledEditButton>
        </StyledImagePlaceholder>
        <StyledActionButtons>
          <ConfirmationTooltip
            confirmText="Are you sure you want to delete this asset?"
            onConfirm={() => onDelete()}
            getTooltipContainer={getTooltipContainer}
          >
            {(internalStata: { isTooltipVisible: boolean }) => (
              <StyledDeleteButton active={internalStata.isTooltipVisible} />
            )}
          </ConfirmationTooltip>
        </StyledActionButtons>
        <StyledTitle title={title}>{title}</StyledTitle>
        {isDisabled && disableReason &&
          <StyledDisabledCurtain>{disableReason}</StyledDisabledCurtain>}
      </StyledWrapper>
  );
};
