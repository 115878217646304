import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import {
  MAX_RIGHT_PANEL_WIDTH,
  RESPONSIVE_BREAKPOINT,
} from 'components/pages/HomePage/constants';

export const StyledListItem = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.lightgray3};
  border-right: 1px solid ${colors.lightgray3};
  box-sizing: border-box;
`;

export const StyledList = styled.div<{ itemsCount: number; asColumn: boolean }>`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.lightgray3};
  border-left: 1px solid ${colors.lightgray3};
  width: 100%;

  ${({ asColumn, itemsCount }) =>
    !asColumn &&
    css`
      @media (min-width: ${RESPONSIVE_BREAKPOINT + MAX_RIGHT_PANEL_WIDTH}px) {
        width: ${itemsCount === 1 ? 50 : 100}%;

        ${StyledListItem} {
          width: ${itemsCount === 1 ? 100 : 50}%;
        }
      }
    `}
`;
