/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import * as CONSTANTS from './constants';
import { FacebookPermission } from './constants';
import { Defer } from 'utils/models/Defer';
import { FacebookDestination } from 'components/blocks/SharePopup/types';

export const facebookLogin = async (scope: FacebookPermission[]) => {
  return new Promise((resolve) => {
    window.FB.login(resolve, { scope: scope as any, auth_type: 'rerequest' });
  });
};

export const arePermissionsGranted = async (scope: FacebookPermission[]) => {
  if (!window.FB) return false;

  const response: any = await new Promise((resolve) => {
    window.FB.api('/me/permissions', resolve);
  });

  if (response.error) {
    return false;
  }

  const permissions = response.data;

  let result = true;

  for (let i = 0; i < scope.length; i++) {
    const scopeItem = scope[i];
    const notGranted = !permissions.some(
      ({
        permission,
        status,
      }: {
        permission: FacebookPermission;
        status: string;
      }) => permission === scopeItem && status === 'granted'
    );

    if (notGranted) {
      result = false;
      break;
    }
  }

  return result;
};

/**
 * Optimized way to request permissions avoiding opening of iframe when possible.
 * 1) Get user permissions and check if user already has them
 * 2) If no permissions - ask them with opening iframe modal
 */
export const requestPermissions = async (scope: FacebookPermission[]) => {
  const alreadyHasPermissions = await arePermissionsGranted(scope);

  if (alreadyHasPermissions) {
    return { authResponse: true };
  }

  return facebookLogin(scope);
};

export const getLoginStatus = () => {
  return new Promise((resolve) => window.FB.getLoginStatus(resolve));
};

export const subscribeToEvent = (event: any, cb: () => void) =>
  window.FB.Event.subscribe(event, cb);

export const unsubscribeFromEvent = (event: any, cb: () => void) =>
  window.FB.Event.unsubscribe(event, cb);

export const getProfile = () => {
  return new Promise((resolve) => {
    const fields = ['name', 'picture'];
    window.FB.api('/me', { fields }, resolve);
  });
};

export const getGroups = (
  adminOnly: boolean,
  groupsCountToLoad = CONSTANTS.GROUPS_COUNT
) => {
  return new Promise<{ data: FacebookDestination[] }>((resolve) => {
    const params = {
      limit: groupsCountToLoad,
      fields: `cover, name, administrator, permissions, member_count, picture, privacy`,
      admin_only: adminOnly,
      locale: 'en',
    };
    window.FB.api('/me/groups', params, resolve);
  });
};

export const getPages = (pagesCountToLoad = CONSTANTS.PAGES_COUNT) => {
  return new Promise<{ data: FacebookDestination[] }>((resolve) => {
    const params = {
      fields: 'name, picture, access_token',
      limit: pagesCountToLoad,
    };
    window.FB.api('/me/accounts', params, resolve);
  });
};

export const getAlbums = async () => {
  return new Promise((resolve) => {
    const fields = [
      'albums.limit(25){name, type, cover_photo{picture}, count, updated_time}',
    ];
    window.FB.api('/me', { fields }, resolve);
  });
};

export const getAlbumPhotos = async (albumId: string) => {
  return new Promise((resolve) => {
    const fields = ['photos.limit(25){source, images}'];
    window.FB.api(`/${albumId}`, { fields }, resolve);
  });
};

export const getGalleryAlbumPhotos = async (albumId: string) => {
  return new Promise((resolve) => {
    const fields = ['photos.limit(100){source}'];
    window.FB.api(`/${albumId}`, { fields }, resolve);
  });
};

export const loadMore = (url: string) => axios.get(url);

export function share() {
  new Promise((response) => {
    window.FB.ui(
      {
        method: 'share',
        quote: 'test',
        href: 'https://app.sta',
      },
      response
    );
  });
}

let initDefer: Defer;

export function initFacebookSdk() {
  if (!initDefer) {
    initDefer = new Defer();

    // @ts-ignore
    window.fbAsyncInit = () => {
      console.log('init');
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: `v${process.env.REACT_APP_FACEBOOK_API_VERSION || '3.3'}`, // The Graph API version to use for the call
      });
      initDefer.resolve();
    };

    // Load the SDK asynchronously
    ((d, s, id) => {
      const fjs: any = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) return;

      const js: any = d.createElement(s);
      js.async = true;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
  return initDefer.promise;
}
