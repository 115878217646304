import styled, { css } from 'styled-components';
import { conditionalStyle, opacity } from 'utils/styles';
import { colors } from 'common/colors';
import { RecordIcon } from 'components/ui/Icons/RecordIcon';
import { SettingsIcon } from 'components/ui/Icons/SettingsIcon';
import { Button } from 'components/ui/Button';
import { StyledButton } from 'components/ui/Button/styles';
import { RefreshIcon } from 'components/ui/Icons/RefreshIcon';
import { lighten } from 'polished';
import { CrossIcon } from 'components/ui/Icons/CrossIcon';

export const StyledVideoRecord = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyleClosePanel = styled.div<{ isLoading: boolean }>`
  color: ${colors.steel};
  stroke: ${colors.steel};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: fill 0.1s, color 0.1s;
  line-height: 12px;

  &:hover {
    stroke: ${lighten(0.1, colors.steel)};
    color: ${lighten(0.1, colors.steel)};
  }

  ${conditionalStyle({
    isLoading: css`
      visibility: hidden;
    `,
  })};
`;

export const StyledPopup = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledCrossIcon = styled(CrossIcon)`
  height: 15px;
  margin: 8px;
`;

export const StyledVideoWrapper = styled.div`
  position: relative;
`;

export const StyledVideo = styled.video<{ isVisible: boolean }>`
  background-color: black;
  display: none;
  outline: none;

  ${conditionalStyle({
    isVisible: css`
      display: block;
    `,
  })}
`;

export const StyledCounter = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: ${colors.white};
  font-size: 120px;
  font-weight: 700;
  background-color: ${opacity(colors.black, 0.5)};
`;

export const StyledPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: ${colors.white};
`;

export const StyledSide = styled.div`
  width: 100px;
  display: flex;
`;

export const StyledSettings = styled(SettingsIcon)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  fill: ${colors.steel};
`;

export const StyledRecordIcon = styled(RecordIcon)<{
  isRecording: boolean;
  isDisabled: boolean;
}>`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  ${conditionalStyle({
    isRecording: css`
      stroke: ${colors.almostBack};
      fill: ${colors.almostBack};
    `,
    isDisabled: css`
      pointer-events: none;
      opacity: 0.5;
      filter: grayscale(1);
      cursor: initial;
    `,
  })};
`;

export const StyledTime = styled.div<{ isActive: boolean; isVisible: boolean }>`
  color: ${colors.black};
  margin-left: auto;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  position: relative;
  display: none;

  ${conditionalStyle({
    isActive: css`
      color: ${colors.watermelon};

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translate(-100%, -50%);
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${colors.watermelon};
      }
    `,
    isVisible: css`
      display: block;
    `,
  })};
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${StyledButton} {
    margin: 0 4px;
    padding: 1px 8px 0;
    height: 32px !important;
    display: flex;
    align-items: center;
  }
`;

export const StyledTakeAnotherVideoButton = styled(Button)``;

export const StyledRefreshIcon = styled(RefreshIcon)`
  width: 24px;
  fill: none;
  stroke: ${colors.yellow};
`;
