import styled from 'styled-components';
import { colors } from 'common/colors';

export const StyledTimeline = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  border-top: 1px solid ${colors.lightgray};
`;
