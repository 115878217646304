import styled, { css } from 'styled-components';

import { medium } from 'common/typography';
import { colors } from 'common/colors';
import { conditionalStyle } from '../../../utils/styles';

const DisabledStyles = css`
  cursor: default;
  pointer-events: none;
  color: ${colors.lightgray} !important;
`;

export const StyledCheckboxLabel = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${medium};

  ${(props) => props.disabled && DisabledStyles};
`;

export const StyledCheckmarkWrapper = styled.div`
  position: relative;
  display: flex;

  input {
    min-height: 14px;
    min-width: 14px;
    cursor: pointer;
    visibility: hidden;
  }
`;

export const StyledCheckmark = styled.div<{
  checked?: boolean;
  disabled?: boolean;
}>`
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 2px solid ${colors.lightgray};

  & > * {
    position: relative;
    stroke: ${colors.white};
    fill: ${colors.white};
    width: 100%;
    height: 100%;
    visibility: hidden;
  }

  ${conditionalStyle({
    checked: css`
      background-color: ${({ theme }) => colors.lightGreen};
      border-color: transparent;

      & > * {
        visibility: visible;
      }
    `,
    disabled: css`
      opacity: 0.5;
    `,
  })};
`;
