import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'common/colors';
import { conditionalStyle } from 'utils/styles';

export const RecordIcon: FC<{
  isRecording?: boolean;
  onClick?: () => void;
}> = React.memo((props) => (
  <StyledRecordIcon {...props} viewBox="0 0 40 40">
    <circle
      cx="20"
      cy="20"
      r="16.5"
      stroke="inherit"
      strokeWidth="2"
      fill="transparent"
    />
    <rect fill="inherit" />
  </StyledRecordIcon>
));

const StyledRecordIcon = styled.svg<{ isRecording?: boolean }>`
  stroke: ${colors.watermelon};
  fill: ${colors.watermelon};
  rect {
    x: 10;
    y: 10;
    width: 20px;
    height: 20px;
    rx: 10;
  }

  ${conditionalStyle({
    isRecording: css`
      rect {
        transition: all 0.1s;
        x: 12;
        y: 12;
        width: 16px;
        height: 16px;
        rx: 1;
      }
    `,
  })};
`;
