import styled from 'styled-components';
import { colors } from 'common/colors';
import { InterFont } from 'common/typography';

export const StyledBadge = styled.span`
  display: inline-flex;
  align-items: center;
  height: 20px;
  border-radius: 16px;
  background-color: ${colors.watermelon};
  font-size: 10px;
  color: white;
  padding: 0 6px;
  ${InterFont};
  font-weight: bold;
`;
