import { createScope } from 'utils/helpers';
import { action } from 'common/Redux/actions';

const createActionName = createScope('ROUTER');

export const ENTER_HOME_PAGE = createActionName('ENTER_HOME_PAGE');
export const LEAVE_HOME_PAGE = createActionName('LEAVE_HOME_PAGE');

export const enterHomePage = action(ENTER_HOME_PAGE);
export const leaveHomePage = action(LEAVE_HOME_PAGE);
