import React, { FC, useEffect, useState } from 'react';
import { AddCustomTheme } from './AddCustomTheme';
import { CustomThemeIndicator } from './CustomThemeIndicator';
import { requests } from 'common/requests';
import { PreviewColorTheme, PreviewColorThemeType } from '../types';
import { firebaseService } from 'services/firebase';
import { useSelector } from 'react-redux';
import { selectUserId } from 'common/Redux/Auth/selectors';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { previewColorThemes } from '../constants';
import { processManager } from 'services/processes';

const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
const ALLOWED_EXTENSION = ['png', 'jpg', 'jpeg', 'gif'];

export const CustomTheme: FC<{ isActive: boolean, onClick: () => void, onDeselect: () => unknown }> = ({
  isActive,
  onClick,
  onDeselect,
}) => {
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector(selectUserId);

  const isCustomThemeAvailable = Boolean(backgroundUrl);

  const theme: PreviewColorTheme = Object.assign(
    previewColorThemes[PreviewColorThemeType.Custom],
    {
      backgroundImage: backgroundUrl,
    },
  );

  useEffect(() => {
    const ref = getFeatureRef(userId);

    ref.on('value', async (snapshot) => {
      if (snapshot.val()) {
        const newBackgroundUrl = await requests.getCustomBackgroundUrl();
        setBackgroundUrl(newBackgroundUrl);
      }
    });

    return () => ref.off();
  }, [])


  useEffect(() => {
    const ref = getFeatureRef(userId);

    ref.on('value', async (snapshot) => {
      if (!snapshot.val()) {
        if (isActive) {
          onDeselect();
        }
        setBackgroundUrl('');
      }
    });

    return () => ref.off();
  }, [isActive])

  const uploadBackground = () => {
    const input = document.createElement('input');
    input.accept = ALLOWED_EXTENSION.map(ext => `.${ext}`).join(', ');
    input.type = 'file';
    input.click();

    input.onchange = async () => {
      const { files } = input;
      if (files?.length) {
        const file = files[0];
        if (ALLOWED_MIME_TYPES.includes(file.type)) {
          setIsLoading(true);
          const { processKey } = await requests.uploadCustomBackground({ file: file });
          analytics.trackEvent(analyticsEvents.UPLOAD_BACKGROUND);
          if (processKey) {
            const process = await processManager.createProcess(userId, processKey);
            await process.end;
            setBackgroundUrl(await requests.getCustomBackgroundUrl());
          }
          setIsLoading(false);
          onClick();
        } else {
          window.alert('Make sure to upload an image in the supported format - .png, .jpg, .jpeg, .gif');
        }
      }
    }
  }

  return isCustomThemeAvailable ?
    <CustomThemeIndicator isActive={isActive} theme={theme} onClick={onClick} /> :
    <AddCustomTheme isLoading={isLoading} onClick={uploadBackground} />;
}

function getFeatureRef(userId: number) {
  return firebaseService.getFeatureRef('isCustomBackgroundExist', userId);
}
