import React from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { BasicOverlayLayoutStyle } from 'components/blocks/Overlays/components/layouts/styles';

export const OverlayIcon = () => {
  return <StyledNone>None</StyledNone>;
};

const StyledNone = styled.div`
  ${BasicOverlayLayoutStyle};
  font-size: 14px;
  color: ${colors.summerRabbit};
  background: ${colors.white};
`;
