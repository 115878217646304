import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  PreviewControlsNotEnded,
  PreviewSeekTo,
  TimelineExtractFramesEnded,
  TimelinePlayedPosition,
  TimelineTrackLoadedAndReady,
  TimeLineTrackUpdateTrimmEndPos,
  TimeLineTrackUpdateTrimmStartPos,
  TimelineUpdateDuration,
  TimelineZoomChange,
  togglePreviewPlayingState,
} from 'common/Redux';
import {
  NONE,
  ON_CONTROL_PAUSE,
  ON_CONTROL_PLAY,
  ON_PROGRESS_SEEKTO,
  ON_SELECTED_MEDIA,
} from 'common/Redux/Constants';
import TrackLine from 'components/blocks/Timeline/TracksArea/TrackLine/TimelineTrackLine';
import { TrackTrimmer } from 'components/blocks/Timeline/TracksArea/TrackTrimmer';
import 'components/blocks/Timeline/TracksArea/Track/TimelineTrack.css';
import {
  getPercentProgressPositionSelector,
  getTimelineDurationSelector,
  getTimelineKeyframesSelector,
  getTimelineZoomSelector,
  isCursorOutOfTrimmingRangeSelector,
  selectIsTimelineDisabled,
} from 'common/Redux/Timeline/selectors';
import {
  StyledEventsTimeline,
  StyledScroll,
  StyledScrollFiller,
  StyledTrack,
} from 'components/blocks/Timeline/TracksArea/Track/styles';
import {
  selectIsEventsTimelineDisabled,
  selectIsEventsTimelineVisible,
} from 'components/blocks/EventsTimeline/selectors';
import { getClickedPosition } from 'utils/helpers';
import { getBroadcastIdSelector } from 'common/Redux/BroadcastsList/selectors';
import {
  getAccessTokenSelector,
  selectUserId,
} from 'common/Redux/Auth/selectors';
import { videoPlayerService } from 'components/blocks/Preview/videoPlayerService';
import {
  MAX_ZOOM,
  MIN_ZOOM,
} from 'components/blocks/Timeline/TimelineZoom/constant';
import { Keyframes } from 'components/blocks/Timeline/TracksArea/Track/components/Keyframes';
import { selectIsPlaying } from 'common/Redux/Preview/selectors';
import { selectActiveProjectActiveMediaSource } from 'common/Redux/Project/selectors';
import { getKeyframes } from 'common/requests/others';
import { resetKeyframes } from 'common/Redux/Timeline/actions';
import { MAX_TIMELINE_HEIGHT } from 'components/blocks/Timeline/TracksArea/Track/constants';
import { ActivityTimelineBanner } from 'components/blocks/Timeline/TracksArea/Track/components/ActivityTimelineBanner';

const TrackFrameWidth = 92;
const InternalTrackWidthPrec = 0.98; //The internal Track

let currentTime;
let lastProgressSeekTo = 0;
const timeToWaitForNextClick = 500;

const HORIZONTAL_OFFSET = 24;

class TimelineTrack extends React.Component {
  scrollerRef = React.createRef();
  fillerRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      scrollLeft: 0,
      containerWidth: 0,
      currentZoom: 1,
      previousZoom: 1,
      pageX: 0,
    };
    this.wrapperRef = React.createRef();
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.currentZoom !== prevState.previousZoom) {
      return {
        currentZoom: props.currentZoom,
        previousZoom: props.currentZoom,
      };
    }
    return null;
  }

  componentDidUpdate = async (prevProps) => {
    let node = this.wrapperRef.current;
    const nodeOffsets = node.getBoundingClientRect();

    if (
      this.props.projectSource &&
      this.props.projectSource !== prevProps.projectSource
    ) {
      this.getKeyframes();
    }

    //Calc position of Line when finish to load the Track Frames
    if (!(this.props.keyframes === undefined)) {
      this.props.TimeLine.leftOffsetCalc =
        (nodeOffsets.width - nodeOffsets.width * InternalTrackWidthPrec) / 2;
      if (prevProps.TimeLine.leftOffsetCalc) {
        if (
          prevProps.TimeLine.leftOffsetCalc !==
          this.props.TimeLine.leftOffsetCalc
        )
          this.forceUpdate();
      } else {
        this.forceUpdate();
      }
    }

    //Update Track if found and changed
    if (prevProps.TimeLine) {
      if (prevProps.TimeLine.Tracks) {
        if (prevProps.TimeLine.Tracks.length > 0)
          prevProps.TimeLine.Tracks.forEach(() => {});
      }
    }

    //Check for change with controls command
    switch (this.props.Preview.controlEvent) {
      case ON_SELECTED_MEDIA:
        break;
      case ON_CONTROL_PLAY: {
        const {
          isCursorOutOfTrimmingRange,
          TimeLine: { startTrimmPos },
        } = this.props;

        if (isCursorOutOfTrimmingRange || this.props.Preview.ended === true) {
          this.props.PreviewControlsNotEnded();
          console.log('startTrimmPos', startTrimmPos);
          this.props.PreviewSeekTo(startTrimmPos, true);
        }
        this.props.Preview.controlEvent = NONE;
        break;
      }
      case ON_CONTROL_PAUSE:
        console.log('playing:', this.props.Preview.playing);
        this.props.Preview.controlEvent = NONE;
        break;
      case ON_PROGRESS_SEEKTO:
        currentTime = new Date();
        if (
          currentTime.getTime() - lastProgressSeekTo >
          timeToWaitForNextClick
        ) {
          console.log('Seek To');
          lastProgressSeekTo = currentTime.getTime();
          console.log('SeekTo from Progress ');
          this.handleTrackPlayerSeek(this.props.Preview.seekToPosition, true);
          this.props.Preview.controlEvent = NONE;
        }
        break;
      default:
        break;
    }
  };

  alignScrollLeft() {
    const { isPlaying, playPosition } = this.props;
    const { scrollLeft, currentZoom } = this.state;
    let maxScrollLeft = scrollLeft;
    let currentScrollLeft = scrollLeft;

    if (
      this.scrollerRef.current &&
      this.wrapperRef.current &&
      this.fillerRef.current
    ) {
      const {
        totalPositionPercent: rightPositionPercent,
      } = this.getMousePosition(currentZoom, 1);
      const fillerWidth = this.fillerRef.current.getBoundingClientRect().width;
      const isCursorOutOfVisibleTimeline =
        playPosition / 100 > rightPositionPercent ||
        playPosition / 100 < scrollLeft / fillerWidth;

      if (isPlaying && isCursorOutOfVisibleTimeline) {
        currentScrollLeft = (playPosition / 100) * fillerWidth;
      }

      maxScrollLeft =
        fillerWidth -
        this.scrollerRef.current.container.getBoundingClientRect().width;
      currentScrollLeft = Math.min(currentScrollLeft, maxScrollLeft);
      if (scrollLeft !== currentScrollLeft) {
        this.scrollerRef.current.scrollLeft(currentScrollLeft);
      }
    }

    return currentScrollLeft;
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.onResize);
    const { container } = this.scrollerRef.current;
    container.addEventListener('wheel', this.onWheel, { passive: false });
    this.onResize();

    if (this.props.projectSource) {
      this.getKeyframes();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    const { container } = this.scrollerRef.current;
    container.removeEventListener('wheel', this.onWheel, { passive: false });
  }

  getKeyframes = () => {
    const { projectSource, TimelineExtractFramesEnded } = this.props;
    const { currentZoom } = this.state;
    const { current } = this.wrapperRef;

    const parentBox = current.getBoundingClientRect();
    const percentPerKeyframe =
      (TrackFrameWidth / parentBox.width) * currentZoom;

    const positions = [];
    for (let position = 0; position < 1; position += percentPerKeyframe) {
      positions.push(position);
    }

    const keyframesBody = {
      broadcastId: projectSource,
      positions,
    };

    this.props.resetKeyframes();

    getKeyframes(keyframesBody)
      .then((result) => {
        const keyframes = result.map((image, index) => {
          return {
            position: positions[index],
            image,
          };
        });
        TimelineExtractFramesEnded(keyframes);
      })
      .catch((error) => {
        TimelineExtractFramesEnded([{ position: 0, image: null }]);
        console.error(error.response || error);
      });
  };

  updateZoom = debounce((zoom) => this.props.TimelineZoomChange(zoom), 200);

  onResize = () => {
    const { container } = this.scrollerRef.current;
    this.setState({
      containerWidth: container.getBoundingClientRect().width - 32,
    });
  };

  onScroll = (e) => {
    const { currentZoom } = this.state;
    const { getScrollLeft } = this.scrollerRef.current;

    const scrollLeft = currentZoom > 1 ? getScrollLeft() : 0;
    this.setState({ scrollLeft });
    e && e.preventDefault();
  };

  onWheel = (event = {}) => {
    const scroller = this.scrollerRef.current;
    let offset = event.deltaY || 0;

    if (scroller) {
      // use vertical scroll as horizontal using shift key
      if (event.shiftKey && offset) {
        scroller.scrollLeft(scroller.getScrollLeft() + offset);
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.onZoom(offset);
        event.deltaY && event.preventDefault();
      }
    }
  };

  onZoom(offset) {
    const scroller = this.scrollerRef.current;
    const { currentZoom } = this.state;
    const previousScrollLeft = scroller.getScrollLeft();

    const calculatedZoom = currentZoom * (1 + offset / 1000);
    const zoom = Math.min(Math.max(calculatedZoom, MIN_ZOOM), MAX_ZOOM);
    if (zoom !== currentZoom) {
      this.setState({ currentZoom: zoom });
      this.updateZoom(zoom);
    }

    const { mousePercent, totalPositionPercent } = this.getMousePosition(
      currentZoom
    );
    const { timelinePercent, timelineWidth } = this.getMousePosition(zoom);

    const scrollPercent = totalPositionPercent - mousePercent * timelinePercent;

    const scrollLeft = scrollPercent * timelineWidth * zoom;
    if (scrollLeft !== previousScrollLeft) {
      this.setState({ scrollLeft });
      scroller.scrollLeft(scrollLeft);
    }

    if (scroller.getScrollLeft() === previousScrollLeft) {
      this.onScroll();
    }
  }

  onMouseMove = (event) => {
    const { pageX } = event;
    this.setState({ pageX });
  };

  getMousePosition(zoom, cursorPosition = null) {
    const { scrollLeft, pageX } = this.state;
    const { container } = this.scrollerRef.current;

    const rect = container.getBoundingClientRect();
    const timelineWidth = rect.width - HORIZONTAL_OFFSET * 2;
    const totalTimelineWidth = timelineWidth * zoom;
    const x = pageX - rect.left - HORIZONTAL_OFFSET;

    const scrollPercent = scrollLeft / totalTimelineWidth;
    const timelinePercent = 1 / zoom;
    const mousePercent = cursorPosition || x / timelineWidth;

    const totalPositionPercent = scrollPercent + mousePercent * timelinePercent;

    return {
      scrollPercent,
      timelinePercent,
      mousePercent,
      totalPositionPercent,
      timelineWidth,
      totalTimelineWidth,
    };
  }

  render = () => {
    const { containerWidth, currentZoom } = this.state;
    const {
      keyframes,
      isEventsTimelineDisabled,
      isTimelineDisabled,
      playPosition,
      isEventsTimelineVisible,
    } = this.props;
    const isZoomed = currentZoom > 1;
    let cursorPercent = 0;

    const scrollLeft = this.alignScrollLeft();

    if (containerWidth) {
      const { scrollPercent, timelinePercent } = this.getMousePosition(
        currentZoom
      );
      cursorPercent = (playPosition / 100 - scrollPercent) / timelinePercent;
    }

    return (
      <>
        <StyledScroll
          autoHeight
          autoHeightMax={MAX_TIMELINE_HEIGHT}
          styleParams={{ showOnHover: !isZoomed }}
          onScroll={this.onScroll}
          forwardRef={this.scrollerRef}
          onMouseMove={this.onMouseMove}
        >
          <StyledScrollFiller zoom={currentZoom} ref={this.fillerRef}>
            <div className="TrackLine">
              <StyledTrack
                ref={this.wrapperRef}
                className="Track"
                id="Track"
                disabled={isTimelineDisabled || !keyframes.length}
                onMouseDown={this.handleOnClickProgress}
              >
                <Keyframes frames={keyframes} />
                <TrackLine isFlagReversed={cursorPercent > 0.5} />
                <TrackTrimmer
                  zoom={currentZoom}
                  timeline={this.props.timeline}
                  changeTimeline={this.props.changeTimeline}
                />
              </StyledTrack>
            </div>
            {isEventsTimelineVisible && (
              <StyledEventsTimeline
                scrollLeft={scrollLeft}
                containerWidth={containerWidth}
                disabled={isEventsTimelineDisabled}
              />
            )}
          </StyledScrollFiller>
        </StyledScroll>
        {!isEventsTimelineVisible && <ActivityTimelineBanner />}
      </>
    );
  };

  handleTrackPlayerSeek = (seconds, fixed) => {
    const { duration } = this.props;
    if (isNaN(seconds)) return;
    console.log('Seek for :', seconds);
    let SeekTo = fixed
      ? seconds
      : videoPlayerService.player.getCurrentTime() + seconds;
    if (SeekTo < 0) SeekTo = 0;
    if (SeekTo > duration) SeekTo = duration;
    // 0.001 to make rounded greater
    videoPlayerService.player.seekTo(SeekTo + 0.001, 'seconds');
    this.props.TimelineTrackLoadedAndReady();
  };

  handleOnClickProgress = (event) => {
    try {
      if (this.props.keyframes === undefined) return;
      const { xPercentage } = getClickedPosition(
        event,
        this.wrapperRef.current
      );
      const seekPosition = (xPercentage / 100) * this.props.TimeLine.duration;
      this.props.TimelinePlayedPosition(seekPosition);
      this.props.PreviewSeekTo(seekPosition);
    } catch (e) {
      console.error(e);
    }
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isPlaying: selectIsPlaying(state),
    playPosition: getPercentProgressPositionSelector(state),
    currentZoom: getTimelineZoomSelector(state),
    keyframes: getTimelineKeyframesSelector(state),
    duration: getTimelineDurationSelector(state),
    broadcastId: getBroadcastIdSelector(state),
    accessToken: getAccessTokenSelector(state),
    userId: selectUserId(state),
    isCursorOutOfTrimmingRange: isCursorOutOfTrimmingRangeSelector(state),
    isEventsTimelineDisabled: selectIsEventsTimelineDisabled(state),
    isTimelineDisabled: selectIsTimelineDisabled(state),
    projectSource: selectActiveProjectActiveMediaSource(state),
    isEventsTimelineVisible: selectIsEventsTimelineVisible(state),
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    TimelineExtractFramesEnded: (frames) =>
      dispatch(TimelineExtractFramesEnded(frames)),
    TimelineUpdateDuration: (duration) =>
      dispatch(TimelineUpdateDuration(duration)),
    TimelineTrackLoadedAndReady: () => dispatch(TimelineTrackLoadedAndReady()),
    TimelinePlayedPosition: (ts) => dispatch(TimelinePlayedPosition(ts)),
    PreviewSeekTo: (position) => dispatch(PreviewSeekTo(position)),

    PreviewControlsPlayPause: () => dispatch(togglePreviewPlayingState()),
    TimeLineTrackUpdateTrimmEndPos: (ts) =>
      dispatch(TimeLineTrackUpdateTrimmEndPos(ts)),
    TimeLineTrackUpdateTrimmStartPos: (ts) =>
      dispatch(TimeLineTrackUpdateTrimmStartPos(ts)),
    PreviewControlsNotEnded: () => dispatch(PreviewControlsNotEnded()),
    TimelineZoomChange: (zoom) => dispatch(TimelineZoomChange(zoom)),
    resetKeyframes: () => dispatch(resetKeyframes()),
  };
};

export default {
  TimelineTrack: connect(mapStateToProps, mapDispachToProps)(TimelineTrack),
};
