import { connect } from 'react-redux';
import { Overlays } from 'components/blocks/Overlays/Overlays';
import { selectCurrentOverlayType } from 'common/Redux/Preview/selectors';
import { setOverlayType } from 'common/Redux/Preview/PreviewActions';
import { createSelector } from 'common/Redux/selectors';

const mapStateToProps = createSelector({
  overlayType: selectCurrentOverlayType,
});

const mapDispatchToProps = {
  setOverlayType,
};

export const OverlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overlays);
