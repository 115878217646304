import React, { FC, useEffect, useState } from 'react';
import noop from 'lodash/noop';

import {
  StyledCheckboxLabel,
  StyledCheckmark,
  StyledCheckmarkWrapper,
} from './CheckboxStyles';
import { TickIcon } from '../Icons/TickIcon';

type CheckboxProps = {
  text?: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
  checked?: boolean;
  id?: string;
};

export const Checkbox: FC<CheckboxProps> = React.memo(
  ({
    onChange = noop,
    text = '',
    className,
    disabled = false,
    checked,
    ...rest
  }) => {
    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    const onCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.checked);
      setIsChecked(event.currentTarget.checked);
    };

    return (
      <StyledCheckboxLabel className={className} disabled={disabled}>
        <StyledCheckmarkWrapper>
          <input
            type="checkbox"
            onChange={onCheckedChange}
            disabled={disabled}
            checked={isChecked}
            {...rest}
          />
          <StyledCheckmark checked={isChecked} disabled={disabled}>
            <TickIcon />
          </StyledCheckmark>
        </StyledCheckmarkWrapper>
        {text && <span>{text}</span>}
      </StyledCheckboxLabel>
    );
  }
);
