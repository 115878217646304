import { PLACEMENT_ENUM, Tooltip, TRIGGER_ENUM } from 'components/ui/Tooltip';
import { noop } from 'lodash';
import React, { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import {
  StyledCancelButton,
  StyledConfirmButton,
  StyledText,
  StyledTooltipBody,
  StyledWrapper,
} from 'components/ui/ConfirmationTooltip/styles';
import { ButtonType } from '../Button/types';

type ConfirmationTooltipProps = PropsWithChildren<{
  confirmText?: string;
  placement?: PLACEMENT_ENUM;
  onConfirm?: (...args: unknown[]) => unknown;
  onCancel?: (...args: unknown[]) => unknown;
  onVisibleChange?: (value: boolean) => void;
  stopClickPropagation?: boolean;
  getTooltipContainer?: () => HTMLElement;
  offset?: number[];
}>;

export const ConfirmationTooltip: FC<ConfirmationTooltipProps> = ({
  children,
  confirmText = 'Are you sure?',
  placement = PLACEMENT_ENUM.TOP,
  onConfirm = noop,
  onCancel = noop,
  onVisibleChange = noop,
  stopClickPropagation = true,
  getTooltipContainer,
  offset,
}) => {
  const [visible, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    onVisibleChange(visible);
  }, [visible]);

  return (
    <Tooltip
      hover
      placement={placement}
      visible={visible}
      offset={offset}
      trigger={TRIGGER_ENUM.CLICK}
      onVisibleChange={setVisibility}
      getTooltipContainer={getTooltipContainer}
      overlay={
        <StyledTooltipBody onClick={(e) => e.stopPropagation()}>
          <StyledText>{confirmText}</StyledText>
          <div>
            <StyledCancelButton
              onClick={() => {
                setVisibility(false);
                onCancel();
              }}
            >
              Cancel
            </StyledCancelButton>
            <StyledConfirmButton
              type={ButtonType.Primary}
              onClick={() => {
                setVisibility(false);
                onConfirm();
              }}
            >
              Yes
            </StyledConfirmButton>
          </div>
        </StyledTooltipBody>
      }
    >
      <StyledWrapper
        onClick={(e) => {
          if (stopClickPropagation) e.stopPropagation();
          setVisibility(true);
        }}
      >
        {typeof children === 'function'
          ? children({ isTooltipVisible: visible })
          : children}
      </StyledWrapper>
    </Tooltip>
  );
};
