import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import TracksArea from 'components/blocks/Timeline/TracksArea/TimelineTracksArea';
import TimelineHeaderArea from 'components/blocks/Timeline/TimelineHeaderArea/TimelineHeader';
import 'common/Utils.js';
import 'components/blocks/Timeline/Timeline.css';
import { StyledCommonTrack } from 'components/ui/CustomScroll/styles';
import { StyledTimeline } from 'components/blocks/Timeline/styles';
import 'common/Redux/Timeline/sagas';

export const Timeline = (props) => {
  const {
    TimeLine: { startTrimmPos, endTrimmPos, duration },
  } = props;
  const [timeline, setTimeline] = useState({
    startTrimmPos,
    endTrimmPos,
    duration,
  });

  useEffect(() => {
    setTimeline({ startTrimmPos, endTrimmPos, duration });
  }, [startTrimmPos, endTrimmPos, duration, setTimeline]);

  const changeTimeline = useCallback(
    (data) => {
      setTimeline({ ...timeline, ...data });
    },
    [timeline, setTimeline]
  );

  return (
    <StyledTimeline>
      <StyledTabScroll>
        <TimelineHeaderArea timeline={timeline} />
        <div className="TimeLineTracksArea">
          <TracksArea timeline={timeline} changeTimeline={changeTimeline} />
        </div>
      </StyledTabScroll>
    </StyledTimeline>
  );
};

const StyledTabScroll = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  ${StyledCommonTrack} {
    z-index: 1000;
  }
`;
