import { connect } from 'react-redux';
import {
  getTitlesSelector,
  selectColorThemeType,
  selectCurrentOverlayType
} from 'common/Redux/Preview/selectors';
import { PreviewOverlay } from 'components/blocks/PreviewOverlay/PreviewOverlay';
import { createSelector } from 'common/Redux/selectors';
import { setPreviewTitles, setVideoRect } from 'common/Redux/Preview/PreviewActions';

const mapStateToProps = createSelector({
  overlayType: selectCurrentOverlayType,
  themeType: selectColorThemeType,
  titles: getTitlesSelector,
});

const mapDispatchToProps = {
  setTitles: (titles: string[]) => setPreviewTitles(titles),
  setVideoRect,
};

export const PreviewOverlayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewOverlay);
