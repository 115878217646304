import React from 'react';
import styled from 'styled-components';

import { useChecklistContext } from '../context';
import { AngleIcon } from '../../Icons/AngleIcon';
import { colors } from 'common/colors';

export const ArrowIcon = () => {
  const { isOpen, toggleOpen } = useChecklistContext();
  return (
    <StyledContainer onClick={toggleOpen}>
      <StyledChecklistAngleIcon down={!isOpen} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: inline-block;
  z-index: 1;
  cursor: pointer;
`;

const StyledChecklistAngleIcon = styled(AngleIcon)<{ down: boolean }>`
  width: 15px;
  height: 15px;
  stroke: ${colors.grey};

  ${(props) =>
    props.down ? `transform: rotate(180deg)` : `transform: rotate(360deg)`}
`;
