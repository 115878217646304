import { GlobalState } from '../types';
import { createSelector, Selector } from 'reselect';
import { selectActiveProjectActiveMedia } from '../Project/selectors';

const getTimelineState: Selector<GlobalState, GlobalState['TimeLine']> = (
  state
) => state.TimeLine;

export const isCursorOutOfTrimmingRangeSelector = createSelector(
  getTimelineState,
  (state) => {
    const { startTrimmPos, endTrimmPos, currentPlayPosition } = state;
    return (
      currentPlayPosition < startTrimmPos || currentPlayPosition >= endTrimmPos
    );
  }
);

export const getPercentProgressPositionSelector = createSelector(
  getTimelineState,
  (state) => {
    const { duration, currentPlayPosition } = state;
    return (currentPlayPosition / duration) * 100;
  }
);

export const selectIsTimelineDisabled = createSelector(
  [getTimelineState, selectActiveProjectActiveMedia],
  (state, activeMedia) => {
    const { durationReady, ready } = state;
    return !activeMedia || !durationReady || !ready;
  }
);

export const getTimelineDurationSelector = createSelector(
  getTimelineState,
  (state) => state.duration
);

export const getVideoUrlSelector = createSelector(
  getTimelineState,
  (state) => state.videoUrl
);

export const isTimelineReadySelector = createSelector(
  getTimelineState,
  (state) => state.ready
);

export const getTimelineKeyframesSelector = createSelector(
  getTimelineState,
  (state) => state.trackFrames
);

export const getTimelineZoomSelector = createSelector(
  getTimelineState,
  (state) => state.currentZoom
);

export const getPlayPositionSelector = createSelector(
  getTimelineState,
  (state) => state.currentPlayPosition
);

export const getStartTrimSelector = createSelector(
  getTimelineState,
  (state) => state.startTrimmPos
);

export const getEndTrimSelector = createSelector(
  getTimelineState,
  (state) => state.endTrimmPos
);

export const getTrimDurationSelector = createSelector(
  getTimelineState,
  (state) => state.endTrimmPos - state.startTrimmPos
);
