import React from 'react';

export const HelpCenterIcon = React.memo(({ isHover, ...otherProps }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...otherProps}>
      <path
        d="M16 28C9.38 28 4 22.62 4 16C4 9.38 9.38 4 16 4C22.62 4 28 9.38 28 16C28 22.62 22.62 28 16 28ZM16 6C10.49 6 6 10.49 6 16C6 21.51 10.49 26 16 26C21.51 26 26 21.51 26 16C26 10.49 21.51 6 16 6Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M16.11 18C15.56 18 15.11 17.55 15.11 17C15.11 15.35 16.18 14.52 16.96 13.91C17.72 13.32 18 13.05 18 12.49C18 11.56 17.04 11 16.12 11C15.6 11 14.34 11.17 13.98 12.72C13.85 13.26 13.31 13.59 12.78 13.46C12.24 13.33 11.91 12.79 12.04 12.26C12.6 9.86 14.51 9 16.12 9C18.03 9 20 10.3 20 12.49C20 14.07 18.95 14.89 18.19 15.49C17.48 16.04 17.11 16.36 17.11 17.01C17.11 17.55 16.66 18 16.11 18Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M16.26 19.5C15.24 19.5 14.5 20.23 14.5 21.26C14.5 22.28 15.23 23 16.26 23C17.28 23 18 22.27 18 21.26C18 20.23 17.28 19.5 16.26 19.5Z"
        fill="inherent"
        stroke="none"
      />
    </svg>
  );
});
