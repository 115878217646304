import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { sagaMiddleware } from '../../sagas/sagaMiddleware';
import { closeProject, OPEN_PROJECT, openProject, REQUEST_OPEN_PROJECT } from './actions';
import { requests } from '../../requests';
import {
  selectActiveProjectActiveMedia,
  selectActiveProjectActiveMediaId,
  selectProjectId
} from './selectors';
import { ActionWithPayload } from '../types';
import {
  SET_ASPECT_RATIO,
  SET_COLOR_THEME,
  SET_PROJECT_OVERLAY,
  SET_TITLES_ACTION
} from '../Preview/PreviewTypes';
import { OverlayType } from 'components/blocks/Overlays/types';
import { selectUserId } from '../Auth/selectors';
import { FIREBASE_PROJECTS_PATH, firebaseService } from 'services/firebase';
import firebase from 'firebase';
import { OpenProjectProps, OpenProjectSaga } from './types';
import { setColorThemeType, setOverlayType, setPreviewTitles } from '../Preview/PreviewActions';
import { MediaType, Project } from '../../types';
import { PreviewColorThemeType } from 'components/blocks/PreviewThemes/types';
import { selectorChangeSaga } from '../../sagas/utils/selectorChageSaga';
import { getBroadcastStatisticData } from '../../requests/others';
import {
  resetTimelineEvents,
  timelineEventsLoaded
} from 'components/blocks/EventsTimeline/actions';
import { selectAreProjectsFetched, selectUserProjectCollection } from 'common/Redux/User/selectors';
import { PROJECTS_UPDATE } from 'common/Redux/User/actions';
import { LEAVE_HOME_PAGE } from '../Router/actions';
import { hideNotifier } from '../Notifier/NotifierActions';
import { defaultTheme, defaultTitles } from 'common/Redux/Preview/constants';

const overlayTypeProperty: keyof Project = 'overlay';
const colorThemeTypeProperty: keyof Project = 'theme';
const overlayTitlesProperty: keyof Project = 'overlayTitles';
const aspectRatioProperty: keyof Project = 'aspectRatio';

function* setInitialOverlay(rootRef: firebase.database.Reference) {
  const overlayType = yield call(
    firebaseService.once,
    rootRef.child(overlayTypeProperty)
  );

  yield put(yield call(setOverlayType, overlayType || OverlayType.Default));
}

function* setInitialColorTheme(rootRef: firebase.database.Reference) {
  const colorTheme = yield call(
    firebaseService.once,
    rootRef.child(colorThemeTypeProperty)
  );

  yield put(yield call(setColorThemeType, colorTheme || defaultTheme));
}

function* setInitialOverlayTitles(rootRef: firebase.database.Reference) {
  const overlayTitles = yield call(
    firebaseService.once,
    rootRef.child(overlayTitlesProperty)
  );

  yield put(yield call(setPreviewTitles, overlayTitles || defaultTitles));
}

export function* onEveryProjectOpenSaga(saga: OpenProjectSaga) {
  while (true) {
    const previousProjectId: number = yield select(selectProjectId);
    const { payload: projectId } = yield take(OPEN_PROJECT);
    const userId = yield select(selectUserId);

    const previousRef = previousProjectId
      ? firebaseService
          .getUserRef(userId)
          .child(FIREBASE_PROJECTS_PATH)
          .child(previousProjectId.toString())
      : null;
    const nextRef = projectId
      ? firebaseService
          .getUserRef(userId)
          .child(FIREBASE_PROJECTS_PATH)
          .child(projectId.toString())
      : null;

    yield saga(
      {
        ref: previousRef,
        projectId: previousProjectId,
      },
      {
        ref: nextRef,
        projectId,
      }
    );
  }
}

function* onProjectOpenSaga(
  previous: OpenProjectProps,
  next: OpenProjectProps
) {
  [overlayTypeProperty, colorThemeTypeProperty, overlayTitlesProperty].forEach(
    (property) => {
      previous.ref?.child(property).off('value');
    }
  );

  if (next.ref) {
    yield fork(setInitialOverlay, next.ref);
    yield fork(setInitialColorTheme, next.ref);
    yield fork(setInitialOverlayTitles, next.ref);
  }
}

function* onOverlayChangedSaga({
  payload: overlayType,
}: ActionWithPayload<OverlayType>) {
  const projectId = yield select(selectProjectId);
  yield call(requests.updateProject, projectId, {
    [overlayTypeProperty]: overlayType,
  });
}

function* onColorThemeChangedSaga({
  payload: colorThemeType,
}: ActionWithPayload<PreviewColorThemeType>) {
  const projectId = yield select(selectProjectId);
  yield call(requests.updateProject, projectId, {
    [colorThemeTypeProperty]: colorThemeType,
  });
}

function* onOverlayTitlesChangedSaga({
  payload: overlayTitles,
}: ActionWithPayload<string[]>) {
  const projectId = yield select(selectProjectId);
  yield call(requests.updateProject, projectId, {
    [overlayTitlesProperty]: overlayTitles,
  });
}

function* onAspectRatioChangedSaga({
  payload: aspectRatio,
}: ActionWithPayload<string>) {
  const projectId = yield select(selectProjectId);
  yield call(requests.updateProject, projectId, {
    [aspectRatioProperty]: aspectRatio,
  });
}

function* onActiveProjectActiveMediaChangeSaga(nextMediaId: number | null): any {
  yield put(resetTimelineEvents());
  if (!nextMediaId) return;

  const { type, source } = yield select(selectActiveProjectActiveMedia);
  if (type === MediaType.Broadcast) {
    const eventsData = yield getBroadcastStatisticData({
      broadcastId: Number(source),
    });
    yield put(timelineEventsLoaded(eventsData));
  }
}

export function* waitForProjectsFetch() {
  const areProjectsFetched = yield select(selectAreProjectsFetched);

  if (!areProjectsFetched) yield take(PROJECTS_UPDATE);
}

function* handleOpenProjectRequest(action: ActionWithPayload<number>) {
  const { payload: id } = action;
  yield call(openProjectIfExist, id);
}

export function* openProjectIfExist(id: number) {
  const areProjectsFetched = yield select(selectAreProjectsFetched);

  if (!areProjectsFetched) yield take(PROJECTS_UPDATE);

  const projects = yield select(selectUserProjectCollection);

  if (id in projects) {
    yield put(openProject(id));
  } else {
    yield call(() => (window.location.href = '/#/'));
  }
}

function* closeProjectSaga() {
  yield put(yield call(hideNotifier));
  yield put(yield call(closeProject));
}

function* projectSagas() {
  yield all([
    takeEvery(SET_PROJECT_OVERLAY, onOverlayChangedSaga),
    takeEvery(SET_ASPECT_RATIO, onAspectRatioChangedSaga),
    takeEvery(SET_COLOR_THEME, onColorThemeChangedSaga),
    takeEvery(SET_TITLES_ACTION, onOverlayTitlesChangedSaga),
    takeEvery(REQUEST_OPEN_PROJECT, handleOpenProjectRequest),
    onEveryProjectOpenSaga(onProjectOpenSaga),
    selectorChangeSaga(
      selectActiveProjectActiveMediaId,
      onActiveProjectActiveMediaChangeSaga
    ),
    takeEvery(LEAVE_HOME_PAGE, closeProjectSaga),
  ]);
}

sagaMiddleware.run(projectSagas);
