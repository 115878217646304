import { combineReducers } from 'redux';
import TimeLineReducer from 'common/Redux/Timeline/reducer';
import PreviewReducer from './Preview/PreviewReducer';
import BroadcastsListReducer from './BroadcastsList/BroadcastsListReducer';
import NotifierReducer from './Notifier/NotifierReducer';
import { AuthReducer } from 'common/Redux/Auth/reducer';
import { EventsTimelineReducer } from 'components/blocks/EventsTimeline/reducer';
import { DownloadReducer } from 'components/blocks/Download/reducer';
import { reducer as ToolboxReducer } from 'common/Redux/Toolbox';
import { ProjectReducer } from 'common/Redux/Project/reducer';
import { UserReducer } from 'common/Redux/User/reducer';
import { FEATURES_REDUCER_NAME, featuresReducer } from './Features';

const Reducer = combineReducers({
  TimeLine: TimeLineReducer,
  Preview: PreviewReducer,
  BroadcastList: BroadcastsListReducer,
  Notifier: NotifierReducer,
  Auth: AuthReducer,
  eventsTimeline: EventsTimelineReducer,
  download: DownloadReducer,
  toolbox: ToolboxReducer,
  project: ProjectReducer,
  user: UserReducer,
  [FEATURES_REDUCER_NAME]: featuresReducer,
});

export default Reducer;
