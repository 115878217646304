import firebase from 'firebase';
import { Defer } from 'utils/models/Defer';
import { FirebaseProcessBody, OnUpdateCallback, ProcessStatus } from './types';

const FINALLY_STATUSES: ProcessStatus[] = [ProcessStatus.FAILED, ProcessStatus.DONE, ProcessStatus.CANCELED];

export class FirebaseProcess {

  private readonly _catch = new Defer();
  private readonly _end = new Defer();
  private _subscribers: OnUpdateCallback<FirebaseProcess>[] = [];

  private _status: ProcessStatus = ProcessStatus.INIT;
  private _progress: number = 0;
  private _payload: Record<string, any> | null = null;

  constructor(
    private readonly _ref: firebase.database.Reference,
  ) {
    this._ref.on('value', (ss) => this.onValue(ss.val()));
  }

  get status(): ProcessStatus {
    return this._status;
  }

  get progress(): number {
    return this._progress;
  }

  get payload(): Record<string, any> | null {
    return this._payload;
  }

  get end() {
    return this._end.promise;
  }

  get catch() {
    return this._catch.promise;
  }

  onUpdate(callback: OnUpdateCallback<FirebaseProcess>) {
    this._subscribers.push(callback);
  }

  offUpdate(callback: OnUpdateCallback<FirebaseProcess>): boolean {
    const index = this._subscribers.indexOf(callback);
    if (index < 0) return false;
    this._subscribers.splice(index, 1);
    return true;
  }

  private onValue(value: FirebaseProcessBody) {
    if (value) {
      const { status, progress, payload} = value;
      this._status = status;
      this._progress = progress;
      this._payload = payload || null;

      if (this._status === ProcessStatus.FAILED) {
        this._catch.resolve();
      }

      if (FINALLY_STATUSES.includes(this._status)) {
        this.onEnd();
      }
    } else {
      this.onEnd();
    }

    this._subscribers.forEach(callback => callback(this));
  }

  private onEnd() {
    this._ref.off('value');
    this._end.resolve();
  }
}
