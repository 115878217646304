import React from 'react';
import styled from 'styled-components';
import defaultAvatarUrl from 'components/blocks/Header/components/Avatar/defaultAvatar.svg';

export const Avatar = (props) => (
  <StyledAvatarWrapper>
    <StyledAvatarIcon {...props} />
  </StyledAvatarWrapper>
);

const StyledAvatarWrapper = styled.div`
  cursor: pointer;
  display: block;
`;

const StyledAvatarIcon = styled.div.attrs(({ image }) => ({
  style: { backgroundImage: `url('${image || defaultAvatarUrl}')` },
}))`
  height: 38px;
  width: 38px;
  background-size: 38px;
  border-radius: 60px;
  border: 2px solid #f9bb40;
`;
