import React, { FC, PropsWithChildren, ReactElement } from 'react';

import {
  StyledContent,
  StyledLayout,
  StyledPlaceholder,
  StyledSection,
  StyledSectionTitle,
  StyledSidebar,
} from './styles';

interface StartupPageComposition {
  Content: FC<PropsWithChildren<{}>> & {
    Section: FC<
      PropsWithChildren<{
        title: string | ReactElement;
        placeholder: string | ReactElement;
      }>
    >;
  };
  Sidebar: FC<PropsWithChildren<{}>>;
}

export const Layout: FC<PropsWithChildren<{}>> & StartupPageComposition = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>;
};

const Content: StartupPageComposition['Content'] = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

Content.Section = ({ title, placeholder, children }) => {
  return (
    <StyledSection>
      <StyledSectionTitle>{title}</StyledSectionTitle>
      {children || <StyledPlaceholder>{placeholder}</StyledPlaceholder>}
    </StyledSection>
  );
};

Layout.Content = Content;

Layout.Sidebar = ({ children }) => {
  return <StyledSidebar>{children}</StyledSidebar>;
};
