import styled, { css } from 'styled-components';
import { Scrollbar } from 'components/ui/CustomScroll';
import { colors } from 'common/colors';
import { EventsTimelineContainer } from 'components/blocks/EventsTimeline/container';
import { StyledCommonTrack } from 'components/ui/CustomScroll/styles';
import timelineBackground from 'components/blocks/Timeline/TracksArea/Track/timelineBackground.png';

export const StyledScroll = styled(Scrollbar)`
  width: 100%;
  overflow: hidden !important;
  overflow-x: scroll;

  ${({ styleParams: { showOnHover } }) =>
    showOnHover &&
    css`
      ${StyledCommonTrack} {
        transition: opacity 200ms;
      }

      &:not(:hover) {
        ${StyledCommonTrack} {
          opacity: 0;
          pointer-events: none;
        }
      }
    `};
`;

export const StyledScrollFiller = styled.div.attrs(({ zoom }) => ({
  style: { width: `calc(${zoom * 100}% - ${48 * (zoom - 1)}px)` },
}))``;

const StyledDisabledState = css`
  transition: transform 0.3s, opacity 0.3s;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      filter: grayscale(1);
      opacity: 0.2;
    `};
`;

export const StyledTrack = styled.div`
  ${StyledDisabledState};
`;

export const StyledFramesWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 4px);
  top: 2px;
  border-radius: 4px;
  background-image: url('${timelineBackground}');
  background-size: 92px 54px;
`;

export const StyledFrame = styled.div`
  position: absolute;
  height: 100%;
  left: ${({ position }) => position}%;
  width: calc(${({ width }) => width}% - 1px);
  border-right: 1px solid ${colors.yellow};
  background: url('${({ src }) => src}') left;
  background-size: auto 100%;
`;

export const StyledEventsTimeline = styled(EventsTimelineContainer)`
  ${StyledDisabledState};
`;
