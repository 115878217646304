export const UPDATED_VIDEOURL = 'UPDATED_VIDEOURL';
export const SET_BROADCAST_ID = 'SET_BROADCAST_ID';
export const ON_SELECTED_MEDIA = 'ON_SELECTED_MEDIA';
export const ON_CONTROL_PLAY = 'ON_PREVIEW_CONTROL_PLAY';
export const ON_CONTROL_PAUSE = 'ON_PREVIEW_CONTROL_PAUSE';
export const ON_CONTROL_NOT_ENDED = 'ON_PREVIEW_CONTROL_NOT_ENDED';
export const ON_PROGRESS_SEEKTO = 'ON_PROGRESS_SEEKTO';
export const ON_CONTROL_SPEED_NORMAL = 'ON_CONTROL_SPEED_NORMAL';
export const ON_CONTROL_SPEED_X2 = 'ON_CONTROL_SPEED_X2';
export const ON_CONTROL_SPEED_X5 = 'ON_CONTROL_SPEED_X5';
export const ON_CONTROL_SPEED_X15 = 'ON_CONTROL_SPEED_X15';
export const ON_TIMELINE_ZOOM_CHANGE = 'ON_TIMELINE_ZOOM_CHANGE';
export const ON_TIMELINE_CHANGE_TIMESTAMP = 'ON_TIMELINE_CHANGE_TIMESTAMP';
export const ON_TIMELINE_EXTRACT_FRAMES_ENDED = 'TIMELINE_EXTRACT_FRAMES_ENDED';
export const ON_TIMELINE_UPDATE_DURATION = 'ON_TIMELINE_UPDATE_DURATION';
export const ON_TIMELINE_TRACK_LOADED_AND_READY =
  'ON_TIMELINE_TRACK_LOADED_AND_READY';
export const ON_TIMELINE_TRACK_PLAYED_POSITION =
  'ON_TIMELINE_TRACK_PLAYED_POSITION';
export const NONE = 'NONE';
