import React, { FC } from 'react';
import { EditableText } from 'components/ui/EditableText';
import { useDependentState } from 'components/hooks/useDependentState';

const PROJECT_NAME_MAX_LENGTH = 160;

export const ProjectName: FC<{
  name: string;
  onChange: (name: string) => void;
  className?: string;
}> = ({ name, onChange, className }) => {
  const [projectName, setProjectName] = useDependentState(name);

  return (
    <EditableText
      text={projectName}
      onChange={(name) => {
        setProjectName(name);
        onChange(name);
      }}
      maxLength={PROJECT_NAME_MAX_LENGTH}
      className={className}
    />
  );
};
