import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { saveChecklist } from '../../../common/Redux/User/actions';
import { LIST_IDS } from '../../ui/Checklist/types';
import { firebaseService } from '../../../services/firebase';
import { getAuthState } from '../../../common/Redux/Auth/selectors';
import {
  amazonGuidelinesChecklist,
  shoppableVideoCreationChecklist,
} from './checklists';

type ChecklistProps = {
  checklistId: LIST_IDS;
};

type Checklist = Record<
  LIST_IDS,
  Record<string, { id: string; isChecked: boolean }>
>;

export const useChecklists = ({ checklistId }: ChecklistProps) => {
  const authState = useSelector(getAuthState);
  const { me } = authState;

  const [checkedChecklists, setChecklist] = useState<Checklist>({
    [LIST_IDS.amazonGuidelines]: {},
    [LIST_IDS.shoppableVideoCreation]: {},
  });

  const onChecklistItemChecked = useCallback(
    (itemId: string, checked: boolean) => {
      saveChecklist({ checklistId, itemId, checked });
    },
    [checklistId]
  );

  useEffect(() => {
    firebaseService.subscribeOnUserUpdate(me.id, (rawUser) => {
      setChecklist(rawUser.features || {
          [LIST_IDS.amazonGuidelines]: {},
          [LIST_IDS.shoppableVideoCreation]: {},
      });
    });
  }, []);

  const amazon = checkedChecklists[LIST_IDS.amazonGuidelines]
    ? amazonGuidelinesChecklist.items.map((item) => ({
        ...item,
        isChecked:
          checkedChecklists[LIST_IDS.amazonGuidelines][item.id]?.isChecked ||
          false,
      }))
    : amazonGuidelinesChecklist.items;

  const shoppable = checkedChecklists[LIST_IDS.shoppableVideoCreation]
    ? shoppableVideoCreationChecklist.items.map((item) => ({
        ...item,
        isChecked:
          checkedChecklists[LIST_IDS.shoppableVideoCreation][item.id]
            ?.isChecked || false,
      }))
    : shoppableVideoCreationChecklist.items;

  return {
    checklists: {
      amazon,
      shoppable,
    },
    onChecklistItemChecked,
  };
};
