import { request } from 'common/requests';

export function getUserBroadcasts() {
  return request.post(`/broadcastList`).then((response) => response.data);
}

export function isBroadcastExists(body: any) {
  return request
    .post(`/checkForExistingBroadcastingVideo`, body)
    .then((response) => response.data);
}

export function startBroadcastConversion(body: any) {
  return request
    .post(`/broadcastConvertToHLSByBroadcastId`, body)
    .then((response) => response.data);
}

export function trimVideo(body: any) {
  return request.post(`/trimmVideo`, body).then((response) => response.data);
}

export function getFeatures() {
  return request
    .get('/featuresByUser')
    .then((response) => response.data);
}

export function getKeyframes(body: any) {
  return request
    .post(`/getKeyframes`, body)
    .then((response) => response.data);
}

export function shareFacebookVideo(body: any) {
  return request
    .post(`/facebook/shareVideo`, body)
    .then((response) => response.data);
}

export function getMe() {
  return request.get(`/me`).then((response) => response.data);
}

export function geTrimmedVideoList() {
  return request.get('/project/history').then((response) => response.data);
}

export async function getBroadcastStatisticData(body: { broadcastId: number }) {
  const response = await request.post('/broadcastStatisticsData', body);
  return response.data;
}
