import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import { colors } from 'common/colors';
import {
  PlayPauseIcon,
  StyledPlayPause,
} from 'components/ui/Icons/PlayPauseIcon';
import { conditionalStyle } from 'utils/styles';
import { TOOLBOX_ITEM_TYPES } from 'common/Redux/Toolbox';

type PlayButtonProps = {
  isPlaying: boolean;
  isDisabled?: boolean;
  playPause?: () => void;
};

export const PlayButton: FC<PlayButtonProps> = ({
  isPlaying,
  isDisabled = false,
  playPause = noop,
}) => {
  const handleClick = () => {
    playPause();
  };

  return (
    <StyledPlayButton isDisabled={isDisabled} onClick={handleClick}>
      <PlayPauseIcon isPause={isPlaying} />
    </StyledPlayButton>
  );
};

const StyledPlayButton = styled.div<{ isDisabled: boolean }>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;

  ${conditionalStyle({
    isDisabled: css`
      pointer-events: none;
      opacity: 0.3;
      filter: grayscale(1);
    `,
  })}
  ${StyledPlayPause} {
    width: 24px;
    height: 24px;
  }
`;
