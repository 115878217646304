import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducer from 'common/Redux/Reducer.ts';
import "regenerator-runtime/runtime";
import { sagaMiddleware } from 'common/sagas/sagaMiddleware';

const enhancers = [applyMiddleware(thunk), applyMiddleware(sagaMiddleware)];

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle, indent */
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

const store = createStore(Reducer, composeEnhancers(...enhancers));

export default store;
