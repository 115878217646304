import styled from 'styled-components';

import { Button } from 'components/ui/Button';
import { DownloadIcon } from 'components/ui/Icons/DownloadIcon';
import { colors } from 'common/colors';
import { Popup } from 'components/ui/Popup/Popup';

export const StyledDownloadButtonWrapper = styled.div`
  position: relative;
`;

export const StyledDownloadButton = styled(Button)`
  display: flex;
  height: 32px;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

export const StyledDownloadIcon = styled(DownloadIcon)`
  height: 22px;
  margin-right: 4px;
`;

export const StyledDownloadPopup = styled(Popup)`
  margin-top: 16px;
  right: 0;
`;

export const StyledDownloadPopupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledCancelButton = styled.div`
  cursor: pointer;
  color: ${colors.grey};
  transition: color 0.2s;

  &:hover {
    color: ${colors.lightgray};
  }
`;
