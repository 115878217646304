import React from 'react';
import styled from 'styled-components';
import { VideoPlayerIcon } from 'components/ui/Icons/VideoPlayerIcon';
import { BasicOverlayLayoutStyle, StyledOverlayTitle } from 'components/blocks/Overlays/components/layouts/styles';

export const OverlayIcon = () => {
  return (
    <StyledOverlay>
      <StyledVideoPlayerIcon />
      <StyledOverlayTitle>Text</StyledOverlayTitle>
    </StyledOverlay>
  );
};

const StyledOverlay = styled.div`
  ${BasicOverlayLayoutStyle};

  ${StyledOverlayTitle} {
    margin-bottom: auto;
  }
`;

export const StyledVideoPlayerIcon = styled(VideoPlayerIcon)`
  margin: 8px 8px 7px;
`;
