import {
  EVENTS_LOADED_ACTION,
  RESET_TIMELINE_EVENTS,
} from 'components/blocks/EventsTimeline/constants';
import { TIMELINE_UPDATE_DURATION } from 'common/Redux/Timeline/types';

const initialState = {
  events: [],
  peakEvents: [],
  duration: 0,
  rawState: null,
};

export const EventsTimelineReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EVENTS_LOADED_ACTION:
      return {
        ...state,
        rawState: action.payload,
        ...mapEvents(action.payload, state.duration),
      };

    case TIMELINE_UPDATE_DURATION:
      const duration = action.duration * 1000;
      return { ...state, duration, ...mapEvents(state.rawState, duration) };

    case RESET_TIMELINE_EVENTS:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

function mapEvents(rawState: any, duration: any) {
  if (rawState === null || duration === 0) return {};

  const {
    events: rawEvents = [],
    peakEvents: peakEventsRaw = [],
    attributes: { end_time = 0 } = {},
  } = rawState;
  const endTime = new Date(end_time).getTime();
  const startTime = endTime - duration;

  const events = rawEvents.map(({ type, timestamp }: any) => {
    const position = ((timestamp - startTime) / duration) * 100;
    return {
      eventType: type,
      // todo: hack to keep peaks in duration range
      // the peak events calculated from server start time, but it's not equal with actual start recording time
      // so we need to feet peaks for that period
      position: Math.max(Math.min(position, 100), 0),
    };
  });

  const peakEvents = peakEventsRaw.map(({ type, timestamp, size }: any) => {
    const position = (timestamp / duration) * 100;
    return {
      eventType: type,
      timestamp,
      size,
      // todo: hack to keep peaks in duration range
      // the peak events calculated from server start time, but it's not equal with actual start recording time
      // so we need to feet peaks for that period
      position: Math.max(Math.min(position, 100), 0),
    };
  });

  return { events, peakEvents };
}
