import { createGlobalStyle } from 'styled-components';

const defaultTheme = {
  name: 'default',
  fontColor: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.85)',
  borderColor: 'rgba(0, 0, 0, 0)',
  boxShadow: 'none',
  borderRadius: '4px',
  borderWidth: 0,
  opacity: 1,
};

export const createTooltipTheme = (props) => {
  const {
    name,
    fontColor,
    backgroundColor,
    borderColor,
    boxShadow,
    borderRadius,
    borderWidth,
    opacity,
  } = Object.assign({}, defaultTheme, props);
  const borderOffset = `${borderWidth}px`;
  const triangleBorder = '2px';

  return createGlobalStyle`
    .${name} {
      .bl-tooltip-inner {
        color: ${fontColor};
        background-color: ${backgroundColor};
        border: ${borderWidth}px solid ${borderColor};
        box-shadow: ${boxShadow};
        border-radius: ${borderRadius};
      }

      .bl-tooltip-arrow, .bl-tooltip-inner {
        opacity: ${opacity};
      }

      &.bl-tooltip-placement-top .bl-tooltip-arrow,
      &.bl-tooltip-placement-topLeft .bl-tooltip-arrow,
      &.bl-tooltip-placement-topRight .bl-tooltip-arrow {
        border-top-color: ${borderColor};
      }
      &.bl-tooltip-placement-top .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-topLeft .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-topRight .bl-tooltip-arrow-inner {
        border-top-color: ${backgroundColor};
        bottom: ${borderOffset};
      }
      &.bl-tooltip-placement-right .bl-tooltip-arrow,
      &.bl-tooltip-placement-rightTop .bl-tooltip-arrow,
      &.bl-tooltip-placement-rightBottom .bl-tooltip-arrow {
        border-right-color: ${borderColor};
      }
      &.bl-tooltip-placement-right .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-rightTop .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-rightBottom .bl-tooltip-arrow-inner {
        border-right-color: ${backgroundColor};
        left: ${borderOffset};
      }
      &.bl-tooltip-placement-left .bl-tooltip-arrow,
      &.bl-tooltip-placement-leftTop .bl-tooltip-arrow,
      &.bl-tooltip-placement-leftBottom .bl-tooltip-arrow {
        border-left-color: ${borderColor};
      }
      &.bl-tooltip-placement-left .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-leftTop .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-leftBottom .bl-tooltip-arrow-inner {
        border-left-color: ${backgroundColor};
        right: ${borderOffset};
      }
      &.bl-tooltip-placement-bottom .bl-tooltip-arrow,
      &.bl-tooltip-placement-bottomLeft .bl-tooltip-arrow,
      &.bl-tooltip-placement-bottomRight .bl-tooltip-arrow {
        border-bottom-color: ${borderColor};
      }
      &.bl-tooltip-placement-bottom .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-bottomLeft .bl-tooltip-arrow-inner,
      &.bl-tooltip-placement-bottomRight .bl-tooltip-arrow-inner {
        border-bottom-color: ${backgroundColor};
        top: ${triangleBorder};
      }
      
      &.bl-tooltip-placement-topRight .bl-tooltip-arrow {
        right: 8px;
      }
      
      &.bl-tooltip-placement-topLeft .bl-tooltip-arrow {
        left: 8px;
      }
      
      &.hide-arrow .bl-tooltip-arrow {
        display: none;
      }
    }
  `;
};
