import { connect } from 'react-redux';
import { ShareButton } from 'components/ui/ShareButton/ShareButton';
import {
  getVideoRectSelector,
  selectColorThemeType,
  selectCurrentOverlayType
} from 'common/Redux/Preview/selectors';
import { pausePreviewPlayer } from 'common/Redux';
import { selectProjectId } from 'common/Redux/Project/selectors';

const mapStateToProps = (state: any) => {
  return {
    isDownloadButtonDisabled: !Boolean(state.TimeLine.framesExtracted),
    userId: state.Auth.me.id,
    accessToken: state.Auth.accessToken,
    overlayType: selectCurrentOverlayType(state),
    themeType: selectColorThemeType(state),
    projectId: selectProjectId(state),
    videoRect: getVideoRectSelector(state),
  };
};

const mapDispatchToProps = {
  pausePreview: pausePreviewPlayer,
};

export const ShareContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareButton);
