import styled from 'styled-components';
import { colors } from 'common/colors';

export const StyledSpeedButtonsWrapper = styled.div`
  display: flex;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-left: 16px;
`;

export const StyledSpeedButton = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  user-select: none;
  margin: 0 6px;
  cursor: pointer;
  white-space: nowrap;
`;

export const StyledPreviewControls = styled.div`
  display: flex;

  & > svg {
    width: 24px;
    height: 24px;
    margin: 0 6px;
    fill: ${colors.darkGrey};
    stroke: ${colors.darkGrey};
    cursor: pointer;
    transition: transform 0.1s;

    &:active {
      transform: scale(0.9);
    }
  }
  position: absolute;
`;
