import React from 'react';

export const FollowIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 456 456" {...props} >
      <circle cx="228" cy="228" r="228" stroke="none" fill="url(#follow_linear)" />
      <g style={{ 'transform-origin': '50% 50%', 'transform': 'scale(0.6)' }} fill="white">
        <path d="M199.671,242.677c66.985,0,121.319-54.353,121.319-121.271C320.99,54.363,266.666,0,199.671,0 C132.638,0,78.38,54.373,78.38,121.406C78.38,188.324,132.628,242.677,199.671,242.677z" />
        <path d="M353.006,275.974c-6.407-13.722-14.478-27.521-24.786-40.688c-5.546-7.057-15.663-14.803-25.445-20.109 c-25.36,28.419-62.118,46.416-103.104,46.416c-40.956,0-77.705-17.997-103.036-46.35c-9.562,5.432-19.412,13.178-24.776,20.215
        c-17.404,22.711-44.265,66.728-48.549,126.044c-0.899,12.518,5.059,31.633,15.52,38.929
        c19.852,13.895,62.672,31.671,151.833,31.671c30.868,0,56.878-1.903,78.862-4.867c-8.874-14.965-14.066-32.369-14.066-50.997
        C255.449,321.836,298.968,277.504,353.006,275.974z" />
        <path d="M353.006,294.946c-44.887,0-81.281,36.395-81.281,81.281c0,44.886,36.395,81.28,81.281,81.28s81.281-36.395,81.281-81.28
        C434.287,331.341,397.901,294.946,353.006,294.946z M393.13,391.183h-31.355v31.365c0,5.192-4.217,9.399-9.409,9.399
        s-9.41-4.207-9.41-9.399v-31.355l-31.354-0.01c-5.202,0.01-9.41-4.197-9.4-9.391c0-5.201,4.207-9.409,9.4-9.409h31.364v-31.355
        c0-5.192,4.208-9.399,9.4-9.391c5.183,0,9.399,4.198,9.409,9.4v31.346h31.346c5.192,0,9.4,4.208,9.41,9.41
        C402.53,386.976,398.322,391.183,393.13,391.183z" />
      </g>
      <defs>
        <linearGradient
          id="follow_linear"
          x1="35"
          y1="0"
          x2="35"
          y2="456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20d263" />
          <stop offset="1" stopColor="#008733" />
        </linearGradient>
      </defs>
    </svg>
  );
});
