import React, { FC, useEffect } from 'react';
import { noop } from 'lodash';
import {
  BroadcastSelectPopupBody,
  StyledBroadcastSelectPopup,
  StyledBroadcastSelectPopupTitle,
} from 'components/blocks/LeftPanel/components/MediaButtonGroup/styles';
import { FullscreenPopup } from 'components/ui/FullscreenPopup';
import { BroadcastList } from 'components/blocks/BroadcastList';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectExpandedBroadcastsWithAttachedField } from 'common/Redux/BroadcastsList/selectors';
import { Broadcast } from 'common/types';
import { GlobalState } from 'common/Redux/types';
import { fetchBroadcastList } from 'common/Redux/BroadcastsList/BroadcastsListActions';

export const AddBroadcastPopup: FC<Props> = ({
  expandedBroadcasts,
  isVisible = true,
  onSelect = noop,
  onClose = noop,
  fetchBroadcastList,
}) => {
  useEffect(() => {
    fetchBroadcastList();
  }, []);

  return (
    <FullscreenPopup isVisible={isVisible} onClose={onClose} customPopup>
      <StyledBroadcastSelectPopup>
        <StyledBroadcastSelectPopupTitle>
          Add Broadcast
        </StyledBroadcastSelectPopupTitle>
        <BroadcastSelectPopupBody>
          <BroadcastList
            broadcasts={expandedBroadcasts}
            onBroadcastSelect={onSelect}
            asColumn
          />
        </BroadcastSelectPopupBody>
      </StyledBroadcastSelectPopup>
    </FullscreenPopup>
  );
};

const mapState = createStructuredSelector<
  GlobalState,
  { expandedBroadcasts: Array<Broadcast & { attached: boolean }> }
>({
  expandedBroadcasts: selectExpandedBroadcastsWithAttachedField,
});

const mapDispatch = {
  fetchBroadcastList,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  isVisible?: boolean;
  onSelect?: (payload: { id: number }) => void;
  onClose?: () => void;
};

export const ChooseBroadcastPopupContainer = connector(AddBroadcastPopup);
