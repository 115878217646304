import React, { FC } from 'react';
import styled from 'styled-components';

export enum CROSS_ARROW_DIRECTION {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CROSS = 'CROSS',
}

const directionPaths = {
  [CROSS_ARROW_DIRECTION.UP]: 'M8 5 2 11M8 5 14 11',
  [CROSS_ARROW_DIRECTION.DOWN]: 'M14 5 8 11M2 5 8 11',
  [CROSS_ARROW_DIRECTION.LEFT]: 'M11 2 5 8M5 8 11 14',
  [CROSS_ARROW_DIRECTION.RIGHT]: 'M11 8 5 14M5 2 11 8',
  [CROSS_ARROW_DIRECTION.CROSS]: 'M14 2 2 14M2 2 14 14',
};

export const CrossArrowIcon: FC<{
  direction?: CROSS_ARROW_DIRECTION;
  height?: number;
  width?: number;
  onClick?: () => void;
}> = React.memo(({ direction = CROSS_ARROW_DIRECTION.CROSS, ...props }) => (
  <StyledCrossArrowIcon
    className="dark-stroke"
    viewBox="0 0 16 16"
    direction={direction}
    {...props}
  >
    <path
      d={directionPaths[direction]}
      fill="none"
      fillRule="nonzero"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.7"
    />
  </StyledCrossArrowIcon>
));

const StyledCrossArrowIcon = styled.svg<{ direction: CROSS_ARROW_DIRECTION }>`
  cursor: pointer;
`;
