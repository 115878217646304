import styled from 'styled-components';
import { colors } from 'common/colors';

export const StyledLeftPanel = styled.div`
  display: flex;

  span {
    height: 100%;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    user-select: none;
    text-align: center;
    margin-right: 8px;
  }
`;

export const StyledLeftBlock = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  color: ${colors.summerRabbit};
`;

export const StyledRightPanel = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;

  & > :nth-child(n + 2) {
    margin-left: 8px;
  }
`;
