import React, {createContext, useContext, useState, useEffect} from 'react';
import { ChecklistItems, LIST_IDS } from './types';

interface ChecklistContext {
  onComplete: () => void;
  onChange: (id: string, checked: boolean) => void;
  isOpen: boolean;
  toggleOpen: () => void;
  toggleItem: (id: string, checked: boolean) => void;
  items: ChecklistItems;
  progress: number;
  id: LIST_IDS;
}

type ChecklistProviderProps = {
  onComplete: () => void;
  children: React.ReactNode;
  items: ChecklistItems;
  isOpen: boolean;
  onChange: (id: string, checked: boolean) => void;
  onOpenCallback?: (id: LIST_IDS) => void;
  id: LIST_IDS;
};

// @ts-ignore
const ChecklistContext = createContext<ChecklistContext>(null);

export const useChecklistContext = () => useContext(ChecklistContext);

export const CheckListProvider = ({
  onComplete,
  children,
  items,
  onChange,
  isOpen,
  onOpenCallback,
  id,
}: ChecklistProviderProps) => {
  const [_isOpen, setIsOpen] = useState<boolean>(isOpen);
  const checkedItemsLength = items.filter((item) => item.isChecked).length;
  const progress = (checkedItemsLength / items.length) * 100;

  const toggleOpen = () => {
    setIsOpen(!_isOpen);
  };

  useEffect(() => {
    if (_isOpen && onOpenCallback) {
      onOpenCallback(id);
    }
  }, [_isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  const toggleItem = (id: string, checked: boolean) => {
    onChange(id, checked);
  };

  useEffect(() => {
    if (checkedItemsLength === items.length) {
      onComplete();
    }
  }, [items]);

  const contextValue = {
    onComplete,
    isOpen: _isOpen,
    toggleOpen,
    toggleItem,
    items,
    progress,
    onChange,
    id,
  };
  return (
    <ChecklistContext.Provider value={contextValue}>
      {children}
    </ChecklistContext.Provider>
  );
};
