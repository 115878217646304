import { connect } from 'react-redux';
import {
  selectTimelineEvents,
  selectTimelinePeakEvents,
} from 'components/blocks/EventsTimeline/selectors';
import { EventsTimeline } from 'components/blocks/EventsTimeline/EventsTimeline';
import {
  getPercentProgressPositionSelector,
  getTimelineDurationSelector,
} from 'common/Redux/Timeline/selectors';
import { PreviewSeekTo, TimelinePlayedPosition } from 'common/Redux';
import { createSelector } from 'common/Redux/selectors';
import { timelineEventsLoaded } from './actions';

const mapStateToProps = createSelector({
  events: selectTimelineEvents,
  peakEvents: selectTimelinePeakEvents,
  progressPosition: getPercentProgressPositionSelector,
  duration: getTimelineDurationSelector,
});

const mapDispatchToProps = {
  TimelinePlayedPosition,
  PreviewSeekTo,
  eventsLoaded: timelineEventsLoaded,
};

export const EventsTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsTimeline);
