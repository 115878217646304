import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import { MoreIcon } from 'components/ui/Icons/MoreIcon';
import {
  Tooltip,
  THEME_ENUM,
  PLACEMENT_ENUM,
  TRIGGER_ENUM,
} from 'components/ui/Tooltip';
import { colors } from 'common/colors';
const tooltipOffset = [0, -5];

export class SelectRowWithMenu extends React.PureComponent {
  onIconClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      children,
      overlay,
      overlayClass,
      tooltipRef,
      hideTooltip,
      ...restProps
    } = this.props;

    return (
      <Wrapper {...restProps}>
        {children}

        <Tooltip
          hover
          destroyTooltipOnHide
          hide={hideTooltip}
          tooltipRef={tooltipRef}
          overlay={overlay}
          trigger={TRIGGER_ENUM.CLICK}
          placement={PLACEMENT_ENUM.BOTTOM}
          theme={THEME_ENUM.LIGHT}
          overlayClass={overlayClass}
          withPaddings={false}
          offset={tooltipOffset}
        >
          <IconWrapper
            className="select-row-with-menu__icon-wrapper"
            onClick={this.onIconClick}
          />
        </Tooltip>
      </Wrapper>
    );
  }
}

SelectRowWithMenu.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.element.isRequired,
  overlayClass: PropTypes.string,
  tooltipRef: PropTypes.object,
  hideTooltip: PropTypes.bool,
};
SelectRowWithMenu.defaultProps = {
  overlayClass: 'select-row-with-menu__overlay',
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled(MoreIcon)`
  height: 22px;
  width: 22px;
  position: absolute;
  right: -1px;
  top: 0;
  fill: ${colors.inactiveIconColor};
`;

export const SelectRowWithMenuGlobalStyle = createGlobalStyle`
  // used to hide MoreIcon in select's input when row is selected
  .react-select__control .select-row-with-menu__icon-wrapper {
    display: none;
  }
`;
