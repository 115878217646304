import React from 'react';
import 'typeface-inter';

import { isDevFeature } from 'common/Utils';
import {
  StyledPreviewControls,
  StyledSpeedButton,
  StyledSpeedButtonsWrapper,
} from 'components/blocks/Preview/components/Controls/styles';
import { Back15Icon } from 'components/ui/Icons/Back15Icon';
import { PlayPauseIcon } from 'components/ui/Icons/PlayPauseIcon';
import { Forward15Icon } from 'components/ui/Icons/Froward15Icon';

const TackNormalSpeed = 1;
const TackSpeedX2 = 2;
const TackSpeedX5 = 5;
const TackSpeedX15 = 15;

export class Controls extends React.Component {
  seekToSeconds = (seconds) => {
    const {
      duration,
      currentPlayPosition,
      TimelinePlayedPosition,
      PreviewSeekTo,
    } = this.props;
    const time = Math.max(Math.min(currentPlayPosition + seconds, duration), 0);
    TimelinePlayedPosition(time);
    PreviewSeekTo(time);
  };

  render = () => {
    const {
      speed,
      onSpeedChange,
      isSpeedFeatureEnabled,
      className,
      isPlaying,
    } = this.props;
    return (
      <StyledPreviewControls className={className}>
        <Back15Icon
          id="JumpPrev"
          className="JumpPrev"
          onClick={() => {
            this.seekToSeconds(-15);
          }}
        />
        <PlayPauseIcon
          isPause={isPlaying}
          className="PlayButton"
          onClick={this.props.PreviewControlsPlayPause}
        />
        <Forward15Icon
          id="JumpForward"
          className="JumpForward"
          onClick={() => {
            this.seekToSeconds(15);
          }}
        />
        {isSpeedFeatureEnabled && (
          <StyledSpeedButtonsWrapper>
            <StyledSpeedButton
              active={speed === TackNormalSpeed}
              onClick={() => onSpeedChange(TackNormalSpeed)}
            >
              Normal Speed
            </StyledSpeedButton>
            <StyledSpeedButton
              active={speed === TackSpeedX2}
              onClick={() => onSpeedChange(TackSpeedX2)}
            >
              x2
            </StyledSpeedButton>
            <StyledSpeedButton
              active={speed === TackSpeedX5}
              onClick={() => onSpeedChange(TackSpeedX5)}
            >
              x5
            </StyledSpeedButton>
            {isDevFeature() && (
              <StyledSpeedButton
                active={speed === TackSpeedX15}
                onClick={() => onSpeedChange(TackSpeedX15)}
              >
                x15
              </StyledSpeedButton>
            )}
          </StyledSpeedButtonsWrapper>
        )}
      </StyledPreviewControls>
    );
  };
}
