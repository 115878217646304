export enum MediaType {
  Broadcast = 'broadcast',
  Recording = 'recording',
  Upload = 'upload',
}

export enum ProjectType {
  STANDARD = 'standard',
  SHOPPABLE = 'shoppable',
}

export enum ProjectAspectRatio {
  Wide16x9 = '16x9',
  Square1x1 = '1x1',
  Vertical = '9x16',
}

export const ProjectAspectRatioValue: Record<ProjectAspectRatio, number> = {
  [ProjectAspectRatio.Wide16x9]: 16 / 9,
  [ProjectAspectRatio.Square1x1]: 1,
  [ProjectAspectRatio.Vertical]: 9 / 16,
}

export type Media = {
  id: number;
  type: MediaType;
  name: string;
  source: string;
  processing: boolean;
  isStreamReady: boolean;
};

export type MediaCollection = Collection<Media>;

export type Project = {
  id: number;
  name: string;
  media: Record<number, Media>;
  theme?: string;
  overlay?: string;
  overlayTitles?: string[];
  trimStart?: number;
  trimEnd?: number;
  title: string;
  type: ProjectType;
  aspectRatio: ProjectAspectRatio;
  checkboxes?: string[];
  createdAt: Date,
  updatedAt: Date,
};

export type ProjectCollection = Collection<Project>;

export type Collection<T> = Record<number, T>;

export type Broadcast = {
  id: number;
  startTime: string;
  endTime: string;
  wasLive: boolean;
  isLive: boolean;
  title: string;
  isDisabled: boolean;
  isProcessing: boolean;
};

export enum DataFetchingStatuses {
  Idle = 'idle',
  Pending = 'pending',
  Resolved = 'resolved',
  Rejected = 'rejected',
}

export enum UserFeatures {
  RecordVideo = 'recordVideo',
  UploadOwnBackground = 'uploadOwnBackground',
  Download = 'download',
  Share = 'share',
}
