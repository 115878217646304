import React from 'react';
import { connect } from 'react-redux';
import TimelineZoom from 'components/blocks/Timeline/TimelineZoom/TimelineZoom';
import TimelineUndoRedo from 'components/blocks/Timeline/TimelineUndoRedo/TimelineUndoRedo';
import InputMask from 'react-input-mask';
import { TimeLineTrackUpdateTrimmEndPos, TimeLineTrackUpdateTrimmStartPos } from 'common/Redux';
import 'components/blocks/Timeline/TimelineHeaderArea/TimelineHeader.css';
import { Button } from 'components/ui/Button';
import {
  StyledLeftBlock,
  StyledLeftPanel,
  StyledRightPanel,
} from 'components/blocks/Timeline/TimelineHeaderArea/styles';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { PlayButton } from 'components/blocks/Timeline/TimelineHeaderArea/components/PlayButton';

const defaultTimeValue = '00:00:00';

class TimelineHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { startValue: defaultTimeValue, endValue: defaultTimeValue };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.timeline.duration !== this.props.timeline.duration) {
      this.setState({
        endValue: this.toHHMMSS(this.props.timeline.duration * 10),
      });
    }

    if (
      prevProps.timeline.startTrimmPos !== this.props.timeline.startTrimmPos
    ) {
      if (isNaN(this.props.timeline.startTrimmPos)) return;
      this.setState({
        startValue: this.toHHMMSS(this.props.timeline.startTrimmPos * 10),
      });
    }

    if (prevProps.timeline.endTrimmPos !== this.props.timeline.endTrimmPos) {
      if (isNaN(this.props.timeline.endTrimmPos)) return;
      this.setState({
        endValue: this.toHHMMSS(this.props.timeline.endTrimmPos * 10),
      });
    }
  };

  render = () => {
    const isTimestampTrimmingEnabled = Boolean(
      process.env.REACT_APP_ENABLE_TIMESTAMP_TRIMMING
    );

    return (
      <div className="TimelineHeader">
        <StyledLeftPanel>
          <PlayButton />
          <StyledLeftBlock>
            <span>Start</span>
            <div className="StartTrimInputCont">
              <InputMask
                className="StartTrimInput"
                disabled={!isTimestampTrimmingEnabled}
                mask="99:99:99"
                maskPlaceholder={defaultTimeValue}
                value={this.state.startValue}
                onChange={this.handleStartTrimInputOnChange}
              />
            </div>
          </StyledLeftBlock>
          <StyledLeftBlock>
            <span>End</span>
            <div className="EndTrimInputCont">
              <InputMask
                className="EndTrimInput"
                disabled={!isTimestampTrimmingEnabled}
                mask="99:99:99"
                maskPlaceholder={defaultTimeValue}
                value={this.state.endValue}
                onChange={this.handleEndTrimInputOnChange}
              />
            </div>
          </StyledLeftBlock>
          <StyledLeftBlock>
            {isTimestampTrimmingEnabled && (
              <Button primary onClick={this.handleManualTrimmUpdate}>
                Finish
              </Button>
            )}
          </StyledLeftBlock>
        </StyledLeftPanel>
        <StyledRightPanel>
          <TimelineUndoRedo />
          <TimelineZoom />
        </StyledRightPanel>
      </div>
    );
  };

  handleStartTrimInputOnChange = (event) => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.TRIMMING_THRU_NUMBERS_USED);
    this.setState({
      startValue: this.validateTSValue(event.target.value, true),
    });
  };

  handleEndTrimInputOnChange = (event) => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.TRIMMING_THRU_NUMBERS_USED);
    this.setState({
      endValue: this.validateTSValue(event.target.value, false),
    });
  };

  handleManualTrimmUpdate = () => {
    //Write event to analytics
    analytics.trackEvent(analyticsEvents.VIDEO_TRIMMED);

    const { startTrimmPos, endTrimmPos } = this.props.timeline;
    const { startValue, endValue } = this.state;

    const previousStartValue = this.toHHMMSS(startTrimmPos * 10);
    const previousEndValue = this.toHHMMSS(endTrimmPos * 10);

    // todo: need to stick handlers to avoid situation when start bigger then end

    if (startValue !== previousStartValue) {
      this.props.TimeLineTrackUpdateTrimmStartPos(
        this.fromHHMMSS(startValue),
        true
      );
    }
    if (endValue !== previousEndValue) {
      this.props.TimeLineTrackUpdateTrimmEndPos(
        this.fromHHMMSS(endValue),
        true
      );
    }
  };

  toHHMMSS = (_string) => {
    var sec_num = parseInt(_string, 10) / 10; // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;
    seconds = seconds.toFixed(3);
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  fromHHMMSS = (_string) => {
    var hours = parseInt(_string.substring(0, 2));
    var minutes = parseInt(_string.substring(3, 5));
    var seconds = parseInt(_string.substring(6, 8));
    return hours * 3600 + minutes * 60 + seconds;
  };

  validateTSValue = (_value, bIsStartValue) => {
    let value = this.fromHHMMSS(_value);
    if (value < 0) value = 0;
    if (value > this.props.timeline.duration) {
      //In case of bigger than MAX duration
      //and it is a start Trimm
      //then reduse 2s
      if (bIsStartValue) {
        value = this.props.timeline.duration - 2;
        //In That case we should also set end trim value
        //to duration (because end value can't be lawer than start value)
        this.setState({
          endValue: this.toHHMMSS(this.props.timeline.duration * 10),
        });
      } else {
        value = this.props.timeline.duration;
      }
    }
    value = this.toHHMMSS(value * 10);
    return value;
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    TimeLineTrackUpdateTrimmStartPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmStartPos(...params)),
    TimeLineTrackUpdateTrimmEndPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmEndPos(...params)),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(TimelineHeader);
