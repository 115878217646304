import React, { FC } from 'react';

export const PreviewPlaceholderIcon: FC<any> = (props) => {
  return (
    <svg viewBox="2 0 99 40" {...props}>
      <path d="M52.4976 16.5094L52.4942 8.99573C52.4942 8.16841 51.8259 7.5002 50.9986 7.5002C50.1713 7.50019 49.5031 8.16841 49.5031 8.99573L49.5065 16.5094L41.9942 16.4988C41.1669 16.4988 40.4987 17.167 40.4987 17.9944C40.4987 18.8217 41.1669 19.4899 41.9942 19.4899L49.5065 19.5005L49.497 27.0108C49.497 27.8381 50.1652 28.5063 50.9925 28.5063C51.4168 28.5063 51.7774 28.3366 52.0532 28.0609C52.329 27.7851 52.4881 27.4138 52.4987 27.0002L52.4976 19.5005L59.9986 19.5005C60.4229 19.5005 60.7835 19.3308 61.0593 19.055C61.3351 18.7792 61.4942 18.408 61.5048 17.9943C61.5048 17.167 60.8366 16.4988 60.0092 16.4988L52.4976 16.5094Z" />
      <g clipPath="url(#previewPlaceholderClip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.5686 10.4947C78.5016 10.2088 77.405 10.842 77.1191 11.9089L73.4956 25.4319C73.2097 26.4988 73.8429 27.5955 74.9098 27.8814L88.4328 31.5048C89.4997 31.7907 90.5964 31.1575 90.8823 30.0906L94.5057 16.5676C94.7916 15.5007 94.1585 14.404 93.0915 14.1182L79.5686 10.4947ZM79.0509 12.4265L92.5739 16.05L88.9504 29.573L75.4275 25.9495L79.0509 12.4265ZM79.9822 24.4059L88.3473 22.1644L82.2236 16.0407L79.9822 24.4059Z"
        />
      </g>
      <g clipPath="url(#previewPlaceholderClip1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6817 18.8165L24.4047 14.0735C24.4633 13.9721 24.5351 13.9201 24.6343 13.8483C24.6956 13.8039 24.7674 13.7519 24.853 13.6759L24.8531 13.676C25.0172 13.7707 25.1813 13.8655 25.2506 14.1243L28.4412 26.0316C28.5105 26.2905 28.4158 26.4546 28.3211 26.6186L28.321 26.6187L28.321 26.6187C28.2263 26.7828 28.2263 26.7828 28.0969 26.8175C28.0322 26.8348 27.9911 26.8111 27.9501 26.7874C27.9091 26.7638 27.8681 26.7401 27.8033 26.7574L22.0342 23.8642L22.485 25.5468C22.8665 26.9705 22.0138 28.4474 20.5901 28.8289L11.6596 31.2218C10.2359 31.6033 8.75892 30.7506 8.37744 29.3269L6.26196 21.4318C5.88048 20.0081 6.73321 18.5311 8.15691 18.1497L17.0874 15.7567C18.5111 15.3753 19.9881 16.228 20.3695 17.6517L20.6817 18.8165ZM14.891 25.5933C16.0824 25.274 16.7894 24.0495 16.4701 22.8581C16.1509 21.6668 14.9264 20.9598 13.735 21.279C12.5437 21.5982 11.8367 22.8228 12.1559 24.0141C12.4751 25.2055 13.6997 25.9125 14.891 25.5933Z"
        />
      </g>
      <defs>
        <clipPath id="previewPlaceholderClip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(75.5156 6.30273) rotate(15)"
          />
        </clipPath>
        <clipPath id="previewPlaceholderClip1">
          <rect
            width="26.7986"
            height="26.7986"
            fill="white"
            transform="translate(0.598633 13.2388) rotate(-15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
