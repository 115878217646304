import { ALIGNMENT, DrawLinesParams, VERTICAL_ALIGNMENT } from 'components/blocks/PreviewOverlay/components/canvasText/types';
import { drawRoundRect } from 'components/blocks/PreviewOverlay/components/roundedRect';
import { RectPosition } from '../../../Overlays/types';

export function drawText(
  text: string,
  context: CanvasRenderingContext2D,
  position: RectPosition,
  params?: {
    maxFontSize?: number;
    align?: ALIGNMENT;
    verticalAlign?: VERTICAL_ALIGNMENT;
    /** run call calculations without draw **/
    dry?: boolean;
    foregroundColor?: string;
  }
) {
  const {
    align = ALIGNMENT.LEFT,
    verticalAlign = VERTICAL_ALIGNMENT.TOP,
    maxFontSize = 96,
    dry = false,
    foregroundColor,
  } = params || {};
  const fontFamily = 'Arial';

  const { firstLineOffset, size, height, lines } = applyFitSize(
    context,
    text,
    position,
    fontFamily,
    maxFontSize
  );

  let offsetY = getVerticalOffset({
    position,
    height,
    firstLineOffset,
    verticalAlign,
  });

  const isFirstDrawDry = Boolean(dry || foregroundColor);

  const { maxWidth, bottomLine } = drawLines({
    lines,
    context,
    align,
    position,
    dry: isFirstDrawDry,
    offsetY,
    size,
  });

  if (foregroundColor) {
    // draw rect
    context.save();
    context.fillStyle = foregroundColor;
    const foregroundSize = size / 4;
    const xOffset = getXOffset(maxWidth, position, align);
    drawRoundRect({
      fill: true,
      context,
      radius: size / 4,
      y: bottomLine - height - foregroundSize,
      height: height + foregroundSize * 2,
      x: xOffset - foregroundSize,
      width: maxWidth + foregroundSize * 2,
    });
    context.restore();
    drawLines({
      lines,
      context,
      align,
      position,
      dry: false,
      offsetY,
      size,
    });
  }

  return {
    width: maxWidth,
    height,
    bottomLine,
  };
}

function drawLines(params: DrawLinesParams) {
  const { lines, context, align, position, dry, offsetY, size } = params;
  let y = offsetY;
  let maxWidth = 0;

  lines.forEach((line) => {
    const { width } = context.measureText(line);
    const horizontalOffset =
      align === ALIGNMENT.CENTER ? (position.width - width) / 2 : 0;
    if (!dry) {
      context.fillText(line, position.left + horizontalOffset, y);
    }
    y += size;
    maxWidth = Math.max(maxWidth, width);
  });

  return {
    bottomLine: y - size,
    maxWidth,
  };
}

function getXOffset(
  maxWidth: number,
  position: RectPosition,
  align: ALIGNMENT
) {
  switch (align) {
    case ALIGNMENT.LEFT:
      return 0;
    case ALIGNMENT.CENTER:
      return position.left + (position.width - maxWidth) / 2;
    case ALIGNMENT.RIGHT:
      return position.left - position.width;
  }
}

function getVerticalOffset(params: {
  position: RectPosition;
  height: number;
  firstLineOffset: number;
  verticalAlign: VERTICAL_ALIGNMENT;
}) {
  const { position, height, firstLineOffset, verticalAlign } = params;

  switch (verticalAlign) {
    default:
    case VERTICAL_ALIGNMENT.TOP:
      return position.top + firstLineOffset;

    case VERTICAL_ALIGNMENT.BOTTOM:
      return position.top + position.height - height + firstLineOffset;

    case VERTICAL_ALIGNMENT.CENTER:
      return position.top + (position.height - height) / 2 + firstLineOffset;
  }
}

function applyFitSize(
  context: CanvasRenderingContext2D,
  text: string,
  frame: RectPosition,
  fontFamily = 'Sans-Serif',
  maxFontSize = 96,
  minFontSize = 1
): {
  firstLineOffset: number;
  size: number;
  width: number;
  height: number;
  lines: string[];
} {
  let min = minFontSize;
  let max = maxFontSize;
  let fontSize = max;
  let isFontFitArea = true;

  let lines: string[][] = [];
  const words = text.split(/[\s\n]/);

  while (max - min > 1 || !isFontFitArea) {
    isFontFitArea = true;
    let lineCount = 0;
    lines = [[]];

    context.font = `bold ${fontSize}px ${fontFamily}`;
    for (let wordCount = 0; wordCount < words.length; wordCount++) {
      let line = lines[lineCount];
      line.push(words[wordCount]);

      const textRect = context.measureText(line.join(' '));

      if (textRect.width > frame.width) {
        if (line.length === 1) {
          isFontFitArea = false;
          break;
        } else {
          lines[lineCount].pop();
          wordCount--;
          lines.push([]);
          lineCount++;
          const textHeight = lines.length * fontSize;
          if (textHeight > frame.height) {
            isFontFitArea = false;
            break;
          }
        }
      }
    }

    if (isFontFitArea) {
      min = fontSize;
      fontSize += (max - fontSize) / 2;
    } else {
      max = fontSize;
      fontSize -= (fontSize - min) / 2;
    }
  }

  context.font = `bold ${min}px ${fontFamily}`;

  const textLines = lines.map((line) => line.join(' '));
  const size = context.measureText(textLines[0]);
  const maxWidth = textLines.reduce(
    (acc, line) => Math.max(context.measureText(line).width, acc),
    0
  );

  return {
    size: min,
    width: maxWidth,
    height: min * (lines.length - 1) + size.actualBoundingBoxAscent,
    lines: textLines,
    firstLineOffset: size.actualBoundingBoxAscent,
  };
}
