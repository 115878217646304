export const PREVIEW_CONTROL_PLAY = 'PREVIEW_CONTROL_PLAY';
export const PREVIEW_CONTROL_PAUSE = 'PREVIEW_CONTROL_PAUSE';
export const PREVIEW_CONTROL_NOT_ENDED = 'PREVIEW_CONTROL_NOT_ENDED';
export const PREVIEW_SEEK_TO = 'PREVIEW_SEEK_TO';
export const SELECTED_MEDIA = 'SELECTED_MEDIA';
export const SET_PROJECT_OVERLAY = 'SET_OVERLAY';
export const SET_COLOR_THEME = 'SET_THEME_ACTION';
export const SET_TITLES_ACTION = 'SET_TITLES_ACTION';
export const SET_ASPECT_RATIO = 'SET_ASPECT_RATIO';
export const SET_VIDEO_RECT = 'SET_VIDEO_RECT';
