import React from 'react';
import ReactSelect, { components } from 'react-select';

import PropTypes from 'prop-types';
import {
  CustomScrollbar,
  StyledMenuListWrapper,
  StyledOptionWrapper,
} from 'components/ui/Select/SelectComponentStyles';
// react select does `event.stopPropagation()` on menu onmousedown which close modal if select is rendered inside of it
// we changed it manually, do a search by `MANUAL_EDIT` to find it.
import { DropdownIndicator } from 'components/ui/Select/DropdownIndicator';
import { DEFAULT_OPTION_HEIGHT } from 'components/ui/Select/SelectConstants';
import overrides from 'components/ui/Select/reactSelectOverrides';

export class SelectComponent extends React.PureComponent {
  render() {
    const {
      components: componentsProp,
      scrollMaxHeight,
      innerRef,
      withSeparators,
      ...otherProps
    } = this.props;

    return (
      <ReactSelect
        classNamePrefix="react-select"
        styles={overrides}
        isOptionDisabled={this.isOptionDisabled}
        ref={innerRef}
        components={{
          MenuList: (props) => {
            if (props.options.length > 0) {
              return (
                <StyledMenuListWrapper>
                  <CustomScrollbar
                    height={this.getScrollbarFullHeight()}
                    autoHeight
                    autoHeightMax={scrollMaxHeight}
                  >
                    <components.MenuList {...props} />
                  </CustomScrollbar>
                </StyledMenuListWrapper>
              );
            }
            return <components.NoOptionsMessage {...props} />;
          },
          SingleValue: (props) => {
            const label = props.data.selectedLabel || props.data.label;
            return (
              <components.SingleValue {...props}>
                {label}
              </components.SingleValue>
            );
          },
          Option: (props) => {
            return (
              <StyledOptionWrapper withSeparators={withSeparators}>
                <components.Option {...props} />
              </StyledOptionWrapper>
            );
          },
          Control: (props) => <components.Control {...props} />,
          DropdownIndicator,
          ...componentsProp,
        }}
        {...otherProps}
      />
    );
  }

  getScrollbarFullHeight = () => {
    const { options } = this.props;

    return options.reduce(
      (sum, current) => sum + (current.optionHeight || DEFAULT_OPTION_HEIGHT),
      0
    );
  };

  isOptionDisabled = (option) => !!option.disabled;
}

SelectComponent.propTypes = {
  scrollMaxHeight: PropTypes.number,
  withSeparators: PropTypes.bool,
};

SelectComponent.defaultProps = {
  scrollMaxHeight: 145,
  withSeparators: false,
};
