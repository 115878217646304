import VideoPlayer from 'react-player';
import styled, { css } from 'styled-components';
import { ControlsContainer } from './components/Controls';
import { PreviewOverlayContainer } from 'components/blocks/PreviewOverlay';
import { conditionalStyle } from 'utils/styles';
import { colors } from 'common/colors';

export const StyledPreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  ${conditionalStyle({
    isVisible: css`
      position: relative;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      visibility: visible;
      margin: 8px;
    `,
  })}
`;

export const StyledTrimmingButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 16px;
  transform: translateY(-100%);
  transition: transform 0.1s;
`;

export const StyledWrapper = styled(PreviewOverlayContainer)`
  transition: filter 0.3s;

  ${conditionalStyle({
    disabled: css`
      pointer-events: none;
      video {
        background: ${colors.disabledBackgroundColor};
      }
    `,
  })}
  &:hover {
    ${StyledTrimmingButtonsWrapper} {
      transform: none;
    }
  }
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100% !important;
  height: 100% !important;
`;

export const StyledControlsWrapper = styled.div`
  left: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;

export const StyledPreviewControls = styled(ControlsContainer)`
  position: absolute;
  top: calc(100%);
  margin: 8px auto;
  background-color: white;
  padding: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`;
