import styled from 'styled-components';
import React from 'react';

import { useChecklistContext } from '../context';
import { colors } from '../../../../common/colors';

export const Progress = ({ title }: { title: string }) => {
  const { progress } = useChecklistContext();

  return (
    <>
      <StyledNote>{title}</StyledNote>
      <Progress.Styled>
        <StyledProgressFiller
          width={Math.round(progress)}
        ></StyledProgressFiller>
      </Progress.Styled>
    </>
  );
};

const StyledNote = styled.div`
  font-size: 12px;
  padding-bottom: 3px;
`;

Progress.Styled = styled.div`
  background-color: #eaebec;
  height: 5px;
  overflow: hidden;
  border-radius: 8px;
`;

const StyledProgressFiller = styled.span<{ width: number }>`
  background-color: ${colors.lightGreen};
  height: 100%;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-100%);
  ${(props) =>
    props.width > 0 && `transform: translateX(calc(${props.width}% - 100%))}`};
  display: block;
`;
