import { request } from './request';
import {
  AttachMediaRequest,
  CreateProject,
  DeleteProjectRequest,
  DetachMediaRequest,
  OKStatus,
  SelectMediaRequest,
  UpdateProjectRequest,
} from './types';
import { analytics } from '../3rdUtils/analytics/analytics';
import { analyticsEvents } from '../3rdUtils/analytics/analyticsEventsEnums';

export const createProject: CreateProject = async (params) => {
  const { data } = await request.post(`/project/create`, params);
  return data;
};

export const deleteProject: DeleteProjectRequest = async ({ id }) => {
  const response = await request.delete(`/project/${id}`);
  if (response.status === OKStatus) {
    analytics.trackEvent(analyticsEvents.PROJECT_DELETED);
  }
}

export const attachMedia: AttachMediaRequest = (params) => {
  analytics.trackEvent(analyticsEvents.MEDIA_APPLIED);
  return request.post(`/project/addMedia`, params);
}

export const detachMedia: DetachMediaRequest = ({ projectId, mediaId }) =>
  request.delete(`/project/${projectId}/media/${mediaId}`);

export const selectMedia: SelectMediaRequest = (params) =>
  request.post('/project/selectMedia', params);

export const updateProject: UpdateProjectRequest = (projectId, params) =>
  request.put(`/project/${projectId}`, params);
