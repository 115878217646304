import React from 'react';

export const CrawlerIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3327 6.6665H8.66602C7.53268 6.6665 6.66602 7.53317 6.66602 8.6665V15.3332C6.66602 16.4665 7.53268 17.3332 8.66602 17.3332H15.3327C16.466 17.3332 17.3327 16.4665 17.3327 15.3332V8.6665C17.3327 7.53317 16.466 6.6665 15.3327 6.6665ZM15.9993 15.3332C15.9993 15.7332 15.7327 15.9998 15.3327 15.9998H8.66602C8.26602 15.9998 7.99935 15.7332 7.99935 15.3332V8.6665C7.99935 8.2665 8.26602 7.99984 8.66602 7.99984H15.3327C15.7327 7.99984 15.9993 8.2665 15.9993 8.6665V15.3332Z"
        fill="inherit"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3932 14.2196C9.24133 14.0677 9.24133 13.8215 9.3932 13.6696L11.0627 12.0002L9.3932 10.3307C9.24133 10.1788 9.24133 9.9326 9.3932 9.78073C9.54507 9.62886 9.7913 9.62886 9.94317 9.78073L11.8876 11.7252C12.0395 11.877 12.0395 12.1233 11.8876 12.2751L9.94317 14.2196C9.7913 14.3715 9.54507 14.3715 9.3932 14.2196Z"
        fill="inherit"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3092 14.2196C12.1573 14.0677 12.1573 13.8215 12.3092 13.6696L13.9787 12.0002L12.3092 10.3307C12.1573 10.1788 12.1573 9.9326 12.3092 9.78073C12.4611 9.62886 12.7073 9.62886 12.8592 9.78073L14.8036 11.7252C14.9555 11.877 14.9555 12.1233 14.8036 12.2751L12.8592 14.2196C12.7073 14.3715 12.4611 14.3715 12.3092 14.2196Z"
        fill="inherit"
      />
    </svg>
  );
});
