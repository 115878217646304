import React, { FC } from 'react';
import { FullscreenPopup } from 'components/ui/FullscreenPopup';
import {
  StyledCloseIcon,
  StyledLink,
  StyledMessage,
  StyledProblemIcon,
  StyledWrapper,
} from 'components/blocks/UnsupportedBrowserPopup/styles';
import { CROSS_ARROW_DIRECTION } from 'components/ui/Icons/CrossArrowIcon';

const GOOGLE_CHROME_URL = 'https://www.google.com/chrome';

export const UnsupportedBrowserPopup: FC<{ onClose?: () => void }> = ({
  onClose = () => {},
}) => {
  return (
    <FullscreenPopup onClose={onClose} customPopup>
      <StyledWrapper>
        <StyledProblemIcon />
        <StyledMessage>
          Oh, no... We don’t support this browser yet.
          <br />
          Please, use{' '}
          <StyledLink primary blank href={GOOGLE_CHROME_URL}>
            Chrome browser
          </StyledLink>
        </StyledMessage>
        <StyledCloseIcon
          direction={CROSS_ARROW_DIRECTION.CROSS}
          onClick={onClose}
        />
      </StyledWrapper>
    </FullscreenPopup>
  );
};
