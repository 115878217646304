import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'components/ui/Button';
import { colors } from 'common/colors';
import { ButtonType } from '../../../../ui/Button/types';

export const InstallFacebookApp: FC<{ destinationId?: string }> = ({ destinationId }) => {
  const onClick = () => {
    if (destinationId) {
      window.open(`https://www.facebook.com/groups/${destinationId}/edit`);
    }
  };

  return <StyledInstallFacebookApp>
    <StyledTitle>Add app to Facebook Group</StyledTitle>
    <StyledList>
      <li>In the group's Advanced Settings, go to Apps</li>
      <li>Search fo Be.Live and add</li>
      <li>Return here to continue</li>
    </StyledList>
    <StyledButton type={ButtonType.Primary} onClick={onClick} disabled={!destinationId}>Add app to Facebook</StyledButton>
  </StyledInstallFacebookApp>
}

const StyledInstallFacebookApp = styled.div`
  padding: 16px;
  border: 1px solid ${colors.lightgray};
  border-radius: 4px;
  font-size: 14px;
`;

const StyledTitle = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;

const StyledList = styled.ul`
  list-style-type: decimal;
  padding-left: 20px;

  li {
    font-size: 14px;
    margin: 4px 0;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
