import styled from 'styled-components';
import { colors } from 'common/colors';
import { TrimmerIcon } from 'components/ui/Icons/TrimmerIcon';
import { opacity } from 'utils/styles';

const StyledUnselectedZone = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${colors.white};
  opacity: 0.7;
  pointer-events: none;
`;

export const StyledLeftUnselectedZone = styled(StyledUnselectedZone).attrs(
  ({ position }) => ({
    style: { width: `${position}px` },
  })
)`
  left: 0;
`;

export const StyledRightUnselectedZone = styled(StyledUnselectedZone).attrs(
  ({ position }) => ({
    style: { left: `${position}px` },
  })
)`
  right: 0;
`;

export const StyledTrimmer = styled.div`
  background: ${opacity(colors.yellow, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 52px;
  cursor: col-resize;
`;

export const StyledTrimmerIcon = styled(TrimmerIcon)`
  fill: ${colors.white};
  width: 3px;
  height: 11px;
`;

export const StyledTimelineTrimmer = styled.div`
  display: block;
  position: absolute;
  height: 52px;
  border-right-width: 4px;
  border-left-width: 4px;
  top: 2px;
  width: 212px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px ${colors.yellow};
`;
