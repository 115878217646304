import React from 'react';

export const LogoIcon = React.memo(({ ...props }) => {
  const { monochrome, ...otherProps } = props;

  let evenColor = '#F05B5C';
  let oddColor = '#F9BB40';

  if (monochrome) {
    evenColor = '#585858';
    oddColor = '#808080';
  }

  return (
    <svg viewBox="0 0 25 28" {...otherProps}>
      <g transform="translate(-2 0)" stroke="none">
        <path
          d="m13.036 0.66667h0.011656c0.22571 0.0010046 0.47848 0.060284 0.74864 0.18448l0.35946 0.16381c1.0846 0.49957 2.8604 1.3154 3.9471 1.8128l0.35897 0.16697c1.0855 0.49872 1.1684 1.4536 0.18755 2.1267l-0.32831 0.22254c-0.98418 0.67164-2.5916 1.7709-3.5758 2.4421l-0.32769 0.22352c-0.98083 0.67189-1.8691 0.26365-1.9733-0.90781l-0.031652-0.38854c-0.10513-1.1689-0.26674-3.0856-0.37113-4.2553l-0.031776-0.38671c-0.078824-0.87329 0.35074-1.4016 1.0262-1.4046h-2e-7z"
          fill={evenColor}
        />
        <path
          d="m2.7074 16.459c0.10387 1.1711 0.27052 3.0869 0.37426 4.2569l0.031768 0.38823c0.10064 1.1697 0.99225 1.5772 1.9731 0.90549l0.32413-0.22184c0.98418-0.67185 2.5945-1.7722 3.5797-2.4421l0.32339-0.22537c0.98406-0.66991 0.89856-1.6287-0.18428-2.1272l-0.3621-0.16517c-1.0822-0.49757-2.8606-1.3145-3.9431-1.8124l-0.35937-0.16395c-1.0882-0.49951-1.8935 0.049258-1.7892 1.2199l0.031644 0.38762"
          fill={evenColor}
        />
        <path
          d="m22.414 22.453c0.98097-0.67281 2.5907-1.7712 3.5751-2.441l0.32372-0.22374c0.98395-0.67208 0.90231-1.6297-0.18314-2.128l-0.35971-0.16465c-1.0847-0.49819-2.8599-1.3155-3.9454-1.813l-0.36318-0.16452c-1.0851-0.49856-1.8859 0.047667-1.7859 1.2193l0.035611 0.38973c0.10063 1.1676 0.2664 3.0836 0.37075 4.255l0.031888 0.38766c0.10038 1.1704 0.99214 1.5781 1.9731 0.90725l0.32707-0.22399"
          fill={evenColor}
        />
        <path
          d="m2 9.3776v-0.227c0.042502-0.32704 0.26793-0.65854 0.6809-0.9398l0.32666-0.22243c0.98259-0.67021 2.5948-1.7695 3.5799-2.442l0.32318-0.22279c0.98619-0.67142 1.8743-0.26414 1.975 0.90759l0.032542 0.38624c0.10433 1.1698 0.2699 3.0857 0.37026 4.2562l0.036516 0.38952c0.10023 1.1695-0.70549 1.7173-1.7879 1.2197l-0.36268-0.16503c-1.0834-0.49885-2.8642-1.3151-3.9504-1.8123l-0.35597-0.16515c-0.52725-0.24174-0.81895-0.59077-0.86795-0.96276v2.6e-7z"
          fill={oddColor}
        />
        <path
          d="m10.897 25.17l-0.35919-0.16477c-1.085-0.49929-1.1671-1.4554-0.18673-2.1271l0.32681-0.22204c0.98476-0.67233 2.5912-1.7715 3.5753-2.4432l0.32792-0.22326c0.98004-0.67111 1.8683-0.26168 1.9721 0.90726l0.032135 0.38863c0.1041 1.1699 0.26998 3.0844 0.37097 4.2548l0.035236 0.38827c0.10025 1.1693-0.70473 1.7198-1.789 1.2203l-0.35919-0.16525c-1.0864-0.49832-2.8613-1.3157-3.9464-1.8135z"
          fill={oddColor}
        />
        <path
          d="m26.292 11.539c-0.10463-1.1702-0.2692-3.0849-0.37036-4.2539l-0.03649-0.38852c-0.10016-1.1712-0.98721-1.5785-1.9727-0.90569l-0.32381 0.22022c-0.98423 0.67234-2.5949 1.7715-3.5764 2.4425l-0.32692 0.22278c-0.9815 0.67356-0.89908 1.63 0.18282 2.1295l0.36403 0.1638c1.0846 0.49881 2.8602 1.3144 3.9467 1.8138l0.35919 0.16514c1.0854 0.49942 1.8905-0.051803 1.7859-1.222l-0.032022-0.38767"
          fill={oddColor}
        />
      </g>
    </svg>
  );
});
