export enum FacebookPermission {
  PUBLISH_TO_GROUPS = 'publish_to_groups',
  GROUP_ACCESS_MEMBER_INFO = 'groups_access_member_info',
  PAGES_READ_USER_CONTENT = 'pages_read_user_content',
  PUBLISH_VIDEO = 'publish_video',
  PAGES_MANAGE_POSTS = 'pages_manage_posts',
  PAGES_MANAGE_ENGAGEMENT = 'pages_manage_engagement',
  PUBLIC_PROFILE = 'public_profile',
  USER_ALBUMS = 'user_photos',
}

export const PERMISSIONS_FOR_GROUPS = [
  FacebookPermission.PUBLISH_TO_GROUPS,
  FacebookPermission.GROUP_ACCESS_MEMBER_INFO,
];
export const PERMISSIONS_FOR_PAGES = [
  FacebookPermission.PAGES_READ_USER_CONTENT,
  FacebookPermission.PAGES_MANAGE_POSTS,
  FacebookPermission.PAGES_MANAGE_ENGAGEMENT,
];

export const GROUPS_COUNT = 50;

export const PAGES_COUNT = 50;
