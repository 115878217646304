import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import { SocialPreviewDropDown } from 'components/blocks/SocialPopupDropDown';
import { Button } from 'components/ui/Button';
import { TextAreaComponent } from 'components/ui/TextArea';
import {
  StyledPreviewWrapper,
  StyledWrapper,
} from 'components/blocks/Preview/styles';
import { conditionalStyle, opacity } from 'utils/styles';
import { SharePlayerContainer } from 'components/blocks/SharePopup/components/SharePlayer';
import { VideoStatsContainer } from 'components/blocks/SharePopup/components/VideoStats';
import { StyledLoader } from 'components/ui/Loader';

export const StyledSharePreviewPopup = styled.div`
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25), 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

export const StyledPopupTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  padding: 14px 16px;
  color: ${colors.steel};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLine = styled.div`
  height: 1px;
  background: rgba(187, 187, 187, 0.3);
`;

export const StyledContent = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
`;

export const StyledContentRightColumn = styled.div`
  width: 248px;
  margin: 8px;
  display: flex;
  flex-direction: column;
`;

export const StyledSocialMediaDropDownArea = styled.div`
  height: 56px;
  margin: 8px 0;
`;

export const StyledSocialMediaDropDown = styled(SocialPreviewDropDown)``;

export const StyledSocialTitle = styled.div`
  width: 248px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colors.steel};
`;

export const StyledContentLeftColumn = styled.div`
  display: flex;
  width: 365px;
  flex-direction: column;
  margin: 8px;
`;

export const StyledVideoPreviewArea = styled.div`
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: flex-start;

  ${StyledPreviewWrapper} {
    margin: 0;
    height: 100%;
  }

  ${StyledWrapper} {
    top: 0;
    transform: translate(-50%, 0);
  }
`;

export const StyledVideoPreview = styled(SharePlayerContainer)`
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

export const StyledVideoStats = styled(VideoStatsContainer)`
  margin: 8px 0;
`;

export const StyledMessageArea = styled.div`
  display: flex;
  width: 365px;
  height: 48px;
  line-height: 24px;
  align-items: center;
`;

export const StyledMessage = styled.div`
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212529;
`;

export const StyledButton = styled(Button)`
  margin: auto 0 0 auto;
  width: 96px;
`;

const textAreaStyle = css`
  width: 248px;
  border-radius: 4px;
  margin: 8px 0;

  textarea {
    padding: 8px;
  }

  ${conditionalStyle({
    disabled: css`
      background-color: ${opacity(colors.fog, 0.3)};
    `,
  })}
`;

export const StyledTitleTextArea = styled(TextAreaComponent)`
  ${textAreaStyle};
  height: 64px;
`;

export const StyledDescriptionTextArea = styled(TextAreaComponent)`
  ${textAreaStyle};
  height: 120px;
`;

export const StyledDestinationOption = styled.div`
  display: flex;
  align-items: flex-start;
  height: 32px;
`;

export const StyledDestinationImage = styled.div<{ image?: string }>`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  background-image: url('${({ image }) => image}');
  background-size: cover;
`;

export const StyledDestinationText = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledLoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${opacity(colors.white, 0.8)};
  display: flex;
  align-items: center;
  border-radius: 8px;

  ${StyledLoader} {
    background-color: ${colors.white};
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 0 0 16px ${colors.white};
  }
`;
