import React, { FC } from 'react';
import { colors } from 'common/colors';

export const ProblemIcon: FC = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6555 7.40229C19.253 6.42189 20.747 6.42189 21.3445 7.40229L34.7897 29.4611C35.3872 30.4415 34.6403 31.667 33.4452 31.667H6.55483C5.3597 31.667 4.61275 30.4415 5.21031 29.4611L18.6555 7.40229ZM18.3333 14.0337C18.3333 13.6471 18.6467 13.3337 19.0333 13.3337H20.9667C21.3533 13.3337 21.6667 13.6471 21.6667 14.0337V22.6337C21.6667 23.0203 21.3533 23.3337 20.9667 23.3337H19.0333C18.6467 23.3337 18.3333 23.0203 18.3333 22.6337V14.0337ZM19.0333 25.0003C18.6467 25.0003 18.3333 25.3137 18.3333 25.7003V27.6337C18.3333 28.0203 18.6467 28.3337 19.0333 28.3337H20.9667C21.3533 28.3337 21.6667 28.0203 21.6667 27.6337V25.7003C21.6667 25.3137 21.3533 25.0003 20.9667 25.0003H19.0333Z"
        fill={colors.watermelon}
      />
    </svg>
  );
};
