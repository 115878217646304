import React, { useMemo } from 'react';
import { StyledFrame, StyledFramesWrapper } from 'components/blocks/Timeline/TracksArea/Track/styles';

export const Keyframes = ({ frames }) => {
  const renderedFrames = useMemo(() => {
    return frames.map(({ position, image }, index) => {
      const nextFrame = frames[index + 1];
      const { position: nextPosition } = nextFrame || { position: 1 };
      const width = (nextPosition - position) * 100;
      return (
        <StyledFrame
          key={index}
          src={image}
          position={position * 100}
          width={width}
        />
      );
    });
  }, [frames]);

  return <StyledFramesWrapper>{renderedFrames}</StyledFramesWrapper>;
};
