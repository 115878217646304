import { TOOLBOX_ITEM_TYPES } from 'common/Redux/Toolbox/types';

export const TOOLBOX_UPDATE_ACTIVE_ITEM = 'TOOLBOX_UPDATE_ACTIVE_ITEM';

export const updateActiveToolboxItem = (
  payload: TOOLBOX_ITEM_TYPES | null
) => ({
  type: TOOLBOX_UPDATE_ACTIVE_ITEM,
  payload,
});

export const openMediaToolboxItem = () => ({
  type: TOOLBOX_UPDATE_ACTIVE_ITEM,
  payload: TOOLBOX_ITEM_TYPES.MEDIA,
});

export const openEditorToolboxItem = () => ({
  type: TOOLBOX_UPDATE_ACTIVE_ITEM,
  payload: TOOLBOX_ITEM_TYPES.EDITOR,
});
