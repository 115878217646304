import React from 'react';

import { StyledIconWrapper, StyledLine, StyledPeakEvent } from 'components/blocks/EventsTimeline/components/PeakEvent/styles';
import { PEAK_EVENT_ICONS } from 'components/blocks/EventsTimeline/constants';

export const PeakEvent = ({ eventType, size, ...restProps }) => {
  const Icon = PEAK_EVENT_ICONS[eventType];
  return (
    <StyledPeakEvent size={size} {...restProps}>
      <StyledIconWrapper>
        <Icon />
      </StyledIconWrapper>
      <StyledLine size={size} />
    </StyledPeakEvent>
  );
};
