import { css } from 'styled-components';

export const scrollMixin = (color = '#ddd') => css`
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 16px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${color};
    border: 6px solid transparent;
    background-clip: padding-box;
    min-height: 30px;
  }
`;
