import styled, { css } from 'styled-components';
import { Loader } from 'components/ui/Loader';
import { colors } from 'common/colors';
import { conditionalStyle, opacity } from 'utils/styles';

export const StyledLoadingWrapper = styled.div`
  position: relative;
`;

export const StyledDimmedCover = styled.div<{ isLoading: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${opacity(colors.black, 0.6)};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.1s;
  pointer-events: none;

  ${conditionalStyle({
    isLoading: css`
      opacity: 1;
      pointer-events: initial;
    `,
  })}
`;

export const StyledLoader = styled(Loader)`
  width: 48px;
  height: 48px;
`;
