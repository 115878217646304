import React from 'react';
import Select from 'react-select';

import {
  StyleFacebookIcon,
  StyleLinkedinIcon,
  StyleOption,
  StyleOptionText,
  StyleSoonIcon,
  StyleYoutubeIcon,
} from 'components/blocks/SocialPopupDropDown/styles';
import { SocialNetworks } from 'components/blocks/SocialPopupDropDown/constants';

export const socialNetworkOptions = [
  {
    value: SocialNetworks.Facebook,
    label: (
      <StyleOption>
        <StyleFacebookIcon isDisabled={false} />
        <StyleOptionText>Facebook</StyleOptionText>
      </StyleOption>
    ),
  },
  {
    value: SocialNetworks.Youtube,
    label: (
      <StyleOption>
        <StyleYoutubeIcon isDisabled={true} />
        <StyleOptionText>
          Youtube <StyleSoonIcon />
        </StyleOptionText>
      </StyleOption>
    ),
    isDisabled: true,
  },
  {
    value: SocialNetworks.LinkedIn,
    label: (
      <StyleOption>
        <StyleLinkedinIcon isDisabled={true} />
        <StyleOptionText>
          Linkedin <StyleSoonIcon />
        </StyleOptionText>
      </StyleOption>
    ),
    isDisabled: true,
  },
];

const customStyles = {
  container: (styles) => ({
    ...styles,
    width: '238px',
    height: '56px',
  }),
  control: (styles) => ({
    ...styles,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    height: '56px',
    width: '248px',
    backgroundColor: 'white',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    width: '238px',
    background: 'white',
    color: '#B1B1B1',
  }),
  menu: (styles) => ({
    ...styles,
    top: '45px',
    background: 'white',
    width: '248px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? 'grey' : 'black',
      verticalAlign: 'middle',
    };
  },
  input: (styles) => ({
    ...styles,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#B1B1B1',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    width: '200px',
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (styles) => ({
    ...styles,
    display: 'flex',
    width: '200px',
    lineHeight: '50px',
    height: '50px',
    alignItems: 'center',
  }),
};

export const SocialPreviewDropDown = (props) => {
  return (
    <Select
      placeholder="Select Destination..."
      styles={customStyles}
      options={socialNetworkOptions}
      closeMenuOnSelect={true}
      isMulti={false}
      isSearchable={false}
      {...props}
    />
  );
};
