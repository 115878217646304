class VideoPlayerService {
  _player = null;

  get player() {
    return this._player;
  }

  setPlayer(player) {
    this._player = player;
  }
}

export const videoPlayerService = new VideoPlayerService();
