import styled from 'styled-components';

import { colors } from 'common/colors';

export const StyledBaseTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${colors.lightgray};
  color: ${colors.textAreaTextColor};
  outline: none;
  resize: none;
  font-family: inherit;

  ::placeholder {
    color: ${colors.textAreaPlaceholderTextColor};
  }
`;
