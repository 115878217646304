import React from 'react';

export const BinIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 33 34" stroke="none" {...props}>
      <g>
        <path d="M10 24.2558C10 25.2209 10.8089 26 11.8109 26H20.1891C21.1911 26 22 25.2209 22 24.2558V14H10V24.2558ZM19.2354 16.1628C19.2354 15.9186 19.4406 15.7209 19.6942 15.7209C19.9477 15.7209 20.1529 15.9186 20.1529 16.1628V23.8372C20.1529 24.0814 19.9477 24.2791 19.6942 24.2791C19.4406 24.2791 19.2354 24.0814 19.2354 23.8372V16.1628ZM15.5412 16.1628C15.5412 15.9186 15.7465 15.7209 16 15.7209C16.2535 15.7209 16.4588 15.9186 16.4588 16.1628V23.8372C16.4588 24.0814 16.2535 24.2791 16 24.2791C15.7465 24.2791 15.5412 24.0814 15.5412 23.8372V16.1628ZM11.8471 16.1628C11.8471 15.9186 12.0523 15.7209 12.3058 15.7209C12.5594 15.7209 12.7646 15.9186 12.7646 16.1628V23.8372C12.7646 24.0814 12.5594 24.2791 12.3058 24.2791C12.0523 24.2791 11.8471 24.0814 11.8471 23.8372V16.1628Z" />
        <path d="M22.1929 11.4949H18.814C18.8463 11.4136 18.8786 11.322 18.8786 11.2305V11.0169C18.8786 10.4576 18.3943 10 17.8025 10H14.1868C13.5949 10 13.1107 10.4576 13.1107 11.0169V11.2305C13.1107 11.322 13.143 11.4136 13.1645 11.4949H9.79631C9.35511 11.4949 9 11.8305 9 12.2475C9 12.6644 9.35511 13 9.79631 13H22.2037C22.6449 13 23 12.6644 23 12.2475C22.9892 11.8305 22.6341 11.4949 22.1929 11.4949Z" />
      </g>
    </svg>
  );
};
