export type PreviewColorTheme = {
  isNew?: boolean;
  textColor: string;
  foregroundColor?: string;
  backgroundColor: string;
  backgroundImage: string;
  shadowColor: string;
};

export enum PreviewColorThemeType {
  White = 'white',
  Black = 'black',
  Orange = 'orange',
  NewYear2 = 'newYear2',
  NewYear5 = 'newYear5',
  NewYear6 = 'newYear6',
  Simple1 = 'simple1',
  Simple3 = 'simple3',
  Simple4 = 'simple4',
  Summer1 = 'summer1',
  Summer2 = 'summer2',
  More1 = 'more1',
  Custom = 'custom',
}
