import * as MediaRequests from './media';
import * as ProjectRequests from './project';
import * as CustomBackground from './customBackground';
import * as OtherRequests from './others';
import * as FeatureRequests from './features';

export * from './request';

export const requests = {
  ...MediaRequests,
  ...ProjectRequests,
  ...CustomBackground,
  ...OtherRequests,
  ...FeatureRequests,
};
