import { GlobalState } from 'common/Redux/types';
import { createSelector, Selector } from 'reselect';

const getPreviewState: Selector<GlobalState, GlobalState['Preview']> = (
  state
) => state.Preview;

export const selectIsPlaying = createSelector(
  getPreviewState,
  (state) => state.playing
);

export const selectCurrentOverlayType = createSelector(
  getPreviewState,
  (state) => state.overlayType
);

export const selectColorThemeType = createSelector(
  getPreviewState,
  (state) => state.themeType
);

export const getTitlesSelector = createSelector(
  getPreviewState,
  (state) => state.titles
);

export const getVideoRectSelector = createSelector(
  getPreviewState,
  (state) => state.videoRect
);
