import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { ErrorIcon } from 'components/ui/Icons/ErrorIcon';

export const ShareFailed: FC = () => {
  return (
    <StyledShareComplete>
      <StyledErrorIcon />
      <StyledText>
        Something went wrong.
        <br />
        Try again later or contact support
      </StyledText>
    </StyledShareComplete>
  );
};

const StyledShareComplete = styled.div`
  width: 560px;
  height: 200px;
  padding: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  fill: ${colors.watermelon};
  width: 40px;
  height: 40px;
  margin: 0 auto 16px;
`;

const StyledText = styled.div`
  line-height: 24px;
  size: 16px;
  text-align: center;
`;
