import React from 'react';
import styled from 'styled-components';

import { LogoIcon } from 'components/ui/Icons/LogoIcon';
import { colors } from 'common/colors';

export const Logo = (props) => {
  return (
    <StyledLogoWrapper {...props}>
      <StyledLogoIcon />
      <StyledTitleWrapper>
        <StyledLine>
          <StyledTitle>Video Editor</StyledTitle>
        </StyledLine>
        <StyledDescription>from Be.Live</StyledDescription>
      </StyledTitleWrapper>
    </StyledLogoWrapper>
  );
};

const StyledLogoWrapper = styled.div`
  display: flex;
`;

const StyledLogoIcon = styled(LogoIcon)`
  height: 38px;
  margin: 1px;
`;

const StyledLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const StyledTitle = styled.div`
  margin-top: 4px;
  color: ${colors.tangerine};
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
`;

const StyledDescription = styled.div`
  color: ${colors.grey};
  font-size: 14px;
`;
