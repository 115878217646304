import React from 'react';

export const FBIcon = React.memo(({ ...props }) => {
  return (
    <svg viewBox="0 0 40 40" fill="none" {...props}>
      <path
        d="M36.3346 20C36.3346 10.9793 29.022 3.66663 20.0013 3.66663C10.9806 3.66663 3.66797 10.9793 3.66797 20C3.66797 28.1523 9.6408 34.9096 17.4492 36.1349V24.7213H13.3021V20H17.4492V16.4015C17.4492 12.308 19.8877 10.0468 23.6186 10.0468C25.4057 10.0468 27.2747 10.3658 27.2747 10.3658V14.3854H25.2152C23.1863 14.3854 22.5534 15.6445 22.5534 16.9362V20H27.0833L26.3592 24.7213H22.5534V36.1349C30.3618 34.9096 36.3346 28.1523 36.3346 20Z"
        fill="#1877F2"
      />
      <path
        d="M26.3579 24.7214L27.082 20H22.5521V16.9362C22.5521 15.6446 23.185 14.3854 25.2139 14.3854H27.2734V10.3659C27.2734 10.3659 25.4044 10.0469 23.6173 10.0469C19.8864 10.0469 17.4479 12.308 17.4479 16.4016V20H13.3008V24.7214H17.4479V36.1349C18.2796 36.2654 19.1317 36.3333 20 36.3333C20.8683 36.3333 21.7204 36.2654 22.5521 36.1349V24.7214H26.3579Z"
        fill="white"
      />
    </svg>
  );
});
