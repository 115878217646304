import * as defaultLayout from 'components/blocks/Overlays/components/layouts/Default';
import * as upperLayout from 'components/blocks/Overlays/components/layouts/UpperTitle';
import * as bottomLayout from 'components/blocks/Overlays/components/layouts/BottomTitle';
import * as twoTitleLayout from 'components/blocks/Overlays/components/layouts/TwoTitles';
import { OverlayLayout, OverlayType } from './types';

export const OverlayLayouts: Record<OverlayType, OverlayLayout> = {
  [OverlayType.Default]: defaultLayout,
  [OverlayType.UpperTitle]: upperLayout,
  [OverlayType.BottomTitle]: bottomLayout,
  [OverlayType.TwoTitles]: twoTitleLayout,
};

export const overlayOrder: OverlayType[] = [
  OverlayType.Default,
  OverlayType.UpperTitle,
  OverlayType.BottomTitle,
  OverlayType.TwoTitles,
]
