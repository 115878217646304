import React, { FC } from 'react';
import styled from 'styled-components';
import { Devices } from 'services/video';
import { SelectComponent } from 'components/ui/Select';
import { colors } from 'common/colors';

export const DeviceSettings: FC<{
  devices: Devices;
  onMicrophoneChange: (id: string) => void;
  onCameraChange: (id: string) => void;
}> = ({
  devices,
  onCameraChange = () => {},
  onMicrophoneChange = () => {},
}) => {
  return (
    <StyledDeviceSetting>
      <StyledTitle>Camera</StyledTitle>
      <SelectComponent
        defaultValue={makeOption(devices.videoinput[0])}
        options={devices.videoinput.map(makeOption)}
        onChange={({ value }: { value: string }) => onCameraChange(value)}
      />
      <StyledTitle>Microphone</StyledTitle>
      <SelectComponent
        defaultValue={makeOption(devices.audioinput[0])}
        options={devices.audioinput.map(makeOption)}
        onChange={({ value }: { value: string }) => onMicrophoneChange(value)}
      />
    </StyledDeviceSetting>
  );
};

function makeOption(device: MediaDeviceInfo) {
  return { label: device.label, value: device.deviceId };
}

const StyledDeviceSetting = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px 8px;
  width: 246px;
`;

const StyledTitle = styled.div`
  padding: 8px 0;
  font-size: 14px;
  color: ${colors.steel};
`;
