import { useEffect } from 'react';

export const useResize = (callback, deps = []) => {
  useEffect(() => {
    callback();
    window.addEventListener('resize', callback, false);
    return () => window.removeEventListener('resize', callback, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps]);
};
