import React from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';

export const EmojiComponent = React.memo((props) => {
  return <Picker {...props} />;
});

EmojiComponent.propTypes = {
  native: PropTypes.bool,
  title: PropTypes.string,
};

EmojiComponent.defaultProps = {
  native: true,
  showPreview: false,
  showSkinTones: true,
  title: 'Pick your emoji…',
};
