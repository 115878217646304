import { useEffect, useState } from 'react';

export function useDependentState<T>(dependency: T): [T, (data: T) => void] {
  const [state, setState] = useState<T>(dependency);

  useEffect(() => {
    setState(dependency);
  }, [dependency]);

  return [state, setState];
}
