type createUrlMap = <D, T extends Record<string, string>>(
  domainName: string,
  pathMap: T
) => Record<Extract<keyof T, string>, string>;

const createUrlMap: createUrlMap = (domainName, pathMap) => {
  const result: Record<string, string> = {};

  for (const key in pathMap) {
    result[key] = `${domainName}${pathMap[key]}`;
  }

  return result;
};

export const knowledgeCenter = createUrlMap(
  process.env.REACT_APP_BELIVE_HELPCENTER_URL!,
  {
    audienceActivity: 'articles/4996737-belive-editor-your-audience-activity',
  }
);

export default {
  knowledgeCenter,
};
