import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { StyledFC } from 'types';
import { UserFeatures } from 'common/types';
import { getFeaturesSelector } from 'common/Redux/Features';
import { openPricingPage } from 'utils/naviagation';
import { CrownIcon } from 'components/ui/Icons/CrownIcon';

export const FeatureWrapperComponent: StyledFC<PropsWithChildren<{ feature: UserFeatures }>> =
  ({ feature, children }) => {
    const features = useSelector(getFeaturesSelector);
    const ref = useRef<HTMLDivElement>();

    const isFeaturePermitted = !features[feature];

    useEffect(() => {
      const { current } = ref;
      if (current) {
        const onClick = (e: MouseEvent) => {
          if (!features[feature]) {
            e.preventDefault();
            e.stopPropagation();
            openPricingPage();
          }
        };

        current.addEventListener('click', onClick, false);

        return () => current.removeEventListener('click', onClick, false);

      }
    }, [ref.current, features, feature])

    return (
      <FeatureWrapperComponent.Styled ref={ref}>
        {children}
        {isFeaturePermitted && <StyledCrownIcon />}
      </FeatureWrapperComponent.Styled>
    )
  };

FeatureWrapperComponent.Styled = styled.div`
  position: relative;
`;

const StyledCrownIcon = styled(CrownIcon)`
  position: absolute;
  right: -4px;
  top: -4px;
  width: 20px;
  height: 20px;
`;