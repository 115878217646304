export const CHANGE_LOADING_MESSAGE_INTERVAL = 5000;
export const AUTO_CLOSE_POPUP_TIMEOUT = 3000;

export const TOGGLE_DOWNLOAD_STATUS_ACTION = 'TOGGLE_DOWNLOAD_STATUS_ACTION';

export const DOWNLOAD_STATUSES = {
  IDLE: 'IDLE',
  PREPARATION: 'PREPARATION',
  DOWNLOADING: 'DOWNLOADING',
  CANCELED: 'CANCELED',
};
