export enum ProcessNotifierTypes {
  WAIT_FOR_CONVERT = 'WAIT_FOR_CONVERT',
  BROADCAST_NOT_FOUND = 'BROADCAST_NOT_FOUND',
  BROADCAST_IS_TOO_LONG = 'BROADCAST_IS_TOO_LONG',
}

export const ProcessNotifierTitles = {
  [ProcessNotifierTypes.WAIT_FOR_CONVERT]: 'Notice',
  [ProcessNotifierTypes.BROADCAST_NOT_FOUND]: 'Notice',
  [ProcessNotifierTypes.BROADCAST_IS_TOO_LONG]:
    'Sorry, we can’t proceed with your broadcast',
};

export const ProcessNotifierMessages = {
  [ProcessNotifierTypes.WAIT_FOR_CONVERT]:
    'Please wait till the video is processed. [It can take few minutes]',
  [ProcessNotifierTypes.BROADCAST_NOT_FOUND]:
    "We couldn't find that broadcast in our systems please check again.",
  [ProcessNotifierTypes.BROADCAST_IS_TOO_LONG]:
    'For now we don’t allow broadcasts longer then 2.5 hours for editing. Please let us know via support chat if you want to have such an ability.',
};
