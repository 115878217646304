import { useDispatch, useSelector } from 'react-redux';
import { setAspectRatio as setRatio } from './PreviewActions';
import { ProjectAspectRatio } from 'common/types';
import {isShoppableProjectSelector, selectActiveProject} from "../Project/selectors";

export const usePreviewActions = () => {
  const dispatch = useDispatch();
  const activeProject = useSelector(selectActiveProject);
  const isShoppable = useSelector(isShoppableProjectSelector);
  const setAspectRatio = (payload: ProjectAspectRatio) =>
    dispatch(setRatio(payload));
  return {
    aspectRatio: activeProject?.aspectRatio,
    setAspectRatio,
    isShoppable,
  };
};
