import React, { FC, useCallback, useRef, useState } from 'react';
import {
  StyledInteractiveProgressBar,
  StyledInteractiveProgressBarContainer,
} from 'components/ui/InteractiveProgressBar/styles';

type InteractiveProgressBarProps = {
  progress: number;
  color: string;
  animated?: boolean;
  onProgressChange?: (progress: number) => void;
  className?: string;
};

export const InteractiveProgressBar: FC<InteractiveProgressBarProps> = (
  props
) => {
  const {
    progress,
    color,
    onProgressChange = () => {},
    animated = true,
    className,
  } = props;
  const [mouseX, setMouseX] = useState(0);
  const ref = useRef<HTMLElement>();

  const handleClickOnProgress = useCallback(() => {
    if (ref.current) {
      try {
        const nodeOffsets = ref.current.getBoundingClientRect();
        const mouseClickX = mouseX - nodeOffsets.x;
        const newProgress = mouseClickX / nodeOffsets.width;
        onProgressChange(newProgress);
      } catch (e) {
        console.error(e);
      }
    }
  }, [mouseX, onProgressChange]);

  return (
    <StyledInteractiveProgressBarContainer
      ref={ref}
      className={className}
      onMouseMove={(e: MouseEvent) => setMouseX(e.clientX)}
      onClick={handleClickOnProgress}
    >
      <StyledInteractiveProgressBar
        color={color}
        progress={progress}
        animated={animated}
        onClick={handleClickOnProgress}
      />
    </StyledInteractiveProgressBarContainer>
  );
};
