import { OverlayType } from 'components/blocks/Overlays/types';
import { PreviewColorThemeType } from '../../../components/blocks/PreviewThemes/types';
import { FacebookDestinationType } from '../../../components/blocks/SharePopup/types';
import { MediaType } from '../../types';

export const analyticsEvents = {
  BACK_TO_PROJECTS: '[Repurposing] Back to Projects',
  OPEN_EDITOR: '[Repurposing] Open editor',
  VIDEO_TRIMMED: '[Repurposing] Video trimmed (finish button clicked)',
  TIMELINE_FILTER_CLICKED: '[Repurposing] Events timeline filter clicked',
  DOWNLOAD_PREP_CANCELED: '[Repurposing] Downloading preparation canceled',
  EVENTS_CLICKED: '[Repurposing] Events clicked',
  TRIMMING_THRU_HANDLERS_USED: '[Repurposing] trimming used: handlers',
  TRIMMING_THRU_PLAYER_USED: '[Repurposing] trimming used: player',
  TRIMMING_THRU_GREENLINE_USED: '[Repurposing] trimming used: green line',
  TRIMMING_THRU_NUMBERS_USED: '[Repurposing] trimming used: numbers',
  HELP: '[Repurposing] Help',
  GO_TO_STUDIO: '[Repurposing] Go to Studio',
  TEXT_OVERLAYS_CLICKED: '[Repurposing] [Text Overlays] Clicked',
  TEXT_OVERLAY_COLORS_CLICKED: '[Repurposing] [Text Overlays] Color Clicked',
  CREATE_PROJECT: '[Repurposing] Create Project',
  OPEN_PROJECT: '[Editor] Open Project',
  PROJECT_DELETED: '[Editor] Project Deleted',
  EXPORT_CLICKED: '[Editor] Export Clicked',
  EXPORT_SUCCESS: '[Editor] Export Success',
  START_RECORDING: '[Repurposing] [Media Source] Start Recording',
  RECORDING_STARTED: '[Repurposing] [Media Source] Recording Started',
  INSERT_RECORDING: '[Repurposing] [Media Source] Insert Recording',
  SHARE_FAILED: '[Repurposing] [Share] Share Failed',
  MEDIA_TAB_CLICKED: '[Editor] Media Tab',
  EDITOR_TAB_CLICKED: '[Editor] Editor Tab',
  ADD_MEDIA_CLICKED: '[Editor] Add Media Click',
  ADD_MEDIA_SUCCESS: '[Editor] Add Media Success',
  MEDIA_APPLIED: '[Editor] Media Applied',
  MEDIA_DELETED: '[Editor] Media Deleted',
  UPLOAD_BACKGROUND: '[Editor] Upload background',
  EDIT_VIDEO: '[Editor] Edit Video',
  CREATE_SHOPPABLE_VIDEO: '[Editor] Create shoppable video',
  SHOPPABLE_GUIDELINES_COMPLETE: '[Editor] Shoppable video guidelines complete',
  AMAZON_GUIDELINES_COMPLETE: '[Editor] Amazon guidelines complete',
};

export const analyticsOverlayNames = {
  [OverlayType.Default]: 'None',
  [OverlayType.UpperTitle]: 'Top',
  [OverlayType.BottomTitle]: 'Bottom',
  [OverlayType.TwoTitles]: 'Both',
};

export const analyticsOverlayColorNames: Record<PreviewColorThemeType, string> = {
  [PreviewColorThemeType.Black]: 'Dark',
  [PreviewColorThemeType.White]: 'Light',
  [PreviewColorThemeType.Orange]: 'BeLive',
  [PreviewColorThemeType.NewYear2]: 'Christmas 1',
  [PreviewColorThemeType.NewYear5]: 'Christmas 4',
  [PreviewColorThemeType.NewYear6]: 'Christmas 5',
  [PreviewColorThemeType.Simple1]: 'Simple1',
  [PreviewColorThemeType.Simple3]: 'Simple3',
  [PreviewColorThemeType.Simple4]: 'Simple4',
  [PreviewColorThemeType.Summer1]: 'Summer1',
  [PreviewColorThemeType.Summer2]: 'Summer2',
  [PreviewColorThemeType.More1]: 'More1',
  [PreviewColorThemeType.Custom]: 'Custom',
};

export const analyticsDestinations: Record<string, string> = {
  DownloadFolder: 'Downloads Folder',
  [FacebookDestinationType.GROUP]: 'Facebook Group',
  [FacebookDestinationType.PAGE]: 'Facebook Page',
  [FacebookDestinationType.TIMELINE]: 'Timeline',
}

export const analyticsMediaType: Record<MediaType, string> = {
  [MediaType.Upload]: 'Upload',
  [MediaType.Recording]: 'Record',
  [MediaType.Broadcast]: 'Broadcast',
}
