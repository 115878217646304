import { DOWNLOAD_STATUSES } from 'components/blocks/Download/constants';

const getDownloadState = (state) => state.download;

export const getDownloadStatusSelector = (state) =>
  getDownloadState(state).status;
export const isDownloadCanceledSelector = (state) =>
  getDownloadState(state).status === DOWNLOAD_STATUSES.CANCELED;
export const isDownloadPreparationSelector = (state) =>
  getDownloadState(state).status === DOWNLOAD_STATUSES.PREPARATION;
