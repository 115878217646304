import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import 'components/blocks/Timeline/TracksArea/TrackTrimmer/styles.css';
import { useResize } from 'components/hooks/useResize';
import {
  calculatePositions,
  getWidth,
  handleLeftTrimmerOnDrag,
  handleRightTrimmerOnDrag,
} from 'components/blocks/Timeline/TracksArea/TrackTrimmer/utils';
import {
  StyledLeftUnselectedZone,
  StyledRightUnselectedZone,
  StyledTimelineTrimmer,
  StyledTrimmer,
  StyledTrimmerIcon,
} from 'components/blocks/Timeline/TracksArea/TrackTrimmer/styles';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';

const trimmerSize = 14;

export const TimelineTrimmer = (props) => {
  const { TimeLine, timeline, zoom } = props;
  const { trackFrames } = TimeLine;
  const { startTrimmPos, endTrimmPos, duration } = timeline;

  const ref = useRef();
  const [positions, setPositions] = useState({
    leftTrimmerPosition: { x: 0, y: 0 },
    rightTrimmerPosition: { x: 0, y: 0 },
  });

  const updatePositions = useCallback(() => {
    setPositions(calculatePositions(timeline, getWidth(ref)));
  }, [timeline, ref, setPositions]);

  useResize(updatePositions, [startTrimmPos, endTrimmPos, duration, zoom]);

  useEffect(() => {
    if (!trackFrames) return;

    if (isNaN(endTrimmPos) || isNaN(startTrimmPos)) {
      console.log(
        'Error Trim Start/End Values:',
        startTrimmPos,
        ' , ',
        endTrimmPos
      );
      return;
    }
    updatePositions();
  }, [updatePositions, trackFrames, startTrimmPos, endTrimmPos, duration]);

  const leftTrimmerHandler = useCallback(
    (event, data, saveHistory = false) => {
      handleLeftTrimmerOnDrag({ event, data, props, ref, saveHistory });
    },
    [props, ref]
  );

  const onLeftTrimmerStopDrag = useCallback(
    (...params) => {
      //Write event to analytics
      analytics.trackEvent(analyticsEvents.TRIMMING_THRU_HANDLERS_USED);
      leftTrimmerHandler(...params, true);
    },
    [leftTrimmerHandler]
  );

  const rightTrimmerHandler = useCallback(
    (event, data, saveHistory = false) => {
      handleRightTrimmerOnDrag({ event, data, props, ref, saveHistory });
    },
    [props, ref]
  );

  const onRightTrimmerStopDrag = useCallback(
    (...params) => {
      //Write event to analytics
      analytics.trackEvent(analyticsEvents.TRIMMING_THRU_HANDLERS_USED);
      rightTrimmerHandler(...params, true);
    },
    [rightTrimmerHandler]
  );

  let rightBound;
  if (ref.current) {
    const { width } = ref.current.parentNode.getBoundingClientRect();
    rightBound = width;
  }

  return (
    <div ref={ref} className="Trimmer">
      <StyledLeftUnselectedZone
        position={Math.max(0, positions.leftTrimmerPosition.x)}
      />
      <StyledRightUnselectedZone
        position={positions.rightTrimmerPosition.x}
      />
      <StyledTimelineTrimmer
        style={{
          left: `${positions.trimmerRangeLeft}px`,
          width: `${positions.trimmerRangePositionWidth}px`,
        }}
      />

      <Draggable
        axis="x"
        onStart={leftTrimmerHandler}
        onDrag={leftTrimmerHandler}
        onStop={onLeftTrimmerStopDrag}
        bounds={{
          left: 0,
          right: positions.rightTrimmerPosition.x - trimmerSize,
        }}
        position={positions.leftTrimmerPosition}
      >
        <StyledTrimmer className="TrimmerStart">
          <StyledTrimmerIcon />
        </StyledTrimmer>
      </Draggable>

      <Draggable
        axis="x"
        onStart={rightTrimmerHandler}
        onDrag={rightTrimmerHandler}
        onStop={onRightTrimmerStopDrag}
        bounds={{
          left: positions.leftTrimmerPosition.x + trimmerSize,
          right: rightBound,
        }}
        position={positions.rightTrimmerPosition}
      >
        <StyledTrimmer className="TrimmerEnd">
          <StyledTrimmerIcon />
        </StyledTrimmer>
      </Draggable>
    </div>
  );
};
