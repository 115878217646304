import React, { FC, PropsWithChildren, ReactElement, useContext } from 'react';
import { noop } from 'lodash';

import {
  StyledToolbox,
  StyledToolboxFooter,
  StyledToolboxItem,
  StyledToolboxTitle,
} from 'components/ui/Toolbox/styles';

const ToolboxContext = React.createContext<{ activeItemId: null | string }>({
  activeItemId: null,
});

type ItemProps = PropsWithChildren<{
  itemId?: any;
  name?: string;
  icon?: ReactElement;
  onClick?: () => void;
}>;

interface ToolboxComposition {
  Item: FC<ItemProps>;
  Footer: FC<PropsWithChildren<{}>>;
}

export const Toolbox: FC<PropsWithChildren<{ activeItemId: any }>> & ToolboxComposition = ({
  activeItemId = null,
  children,
}) => {
  return (
    <ToolboxContext.Provider value={{ activeItemId }}>
      <StyledToolbox>{children}</StyledToolbox>
    </ToolboxContext.Provider>
  );
};

const Item: FC<PropsWithChildren<ItemProps>> = ({
  itemId,
  name,
  icon = null,
  onClick = noop,
  children,
}) => {
  const { activeItemId } = useContext(ToolboxContext);
  const isActive = itemId === activeItemId;

  return (
    <StyledToolboxItem active={isActive} onClick={onClick}>
      {children ? (
        children
      ) : (
        <>
          {icon}
          <StyledToolboxTitle>{name}</StyledToolboxTitle>
        </>
      )}
    </StyledToolboxItem>
  );
};

const Footer: FC<PropsWithChildren<{}>> = ({ children }) => (
  <StyledToolboxFooter>{children}</StyledToolboxFooter>
);

Toolbox.Item = Item;
Toolbox.Footer = Footer;
