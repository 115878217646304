import { FC } from 'react';

export type RectPosition = {
  width: number;
  height: number;
  left: number;
  top: number;
};

export type OverlayVideoConfig = {
  width: number;
  height: number;
  left: number;
  top: number;
  shadowOffset: {
    x: number;
    y: number;
  };
};

export type OverlayConfig = {
  video: OverlayVideoConfig;
  titles: RectPosition[];
};

export type OverlayLayout = {
  OverlayIcon: FC;
  name: string;
};

export enum OverlayType {
  Default = 'default',
  UpperTitle = 'upperTitle',
  BottomTitle = 'bottomTitle',
  TwoTitles = 'twoTitles',
}
