import React, { FC, useRef, useState } from 'react';
import { noop } from 'lodash';

import { ConfirmationTooltip } from 'components/ui/ConfirmationTooltip';
import {
  StyledMediaInnerText,
  StyledMediaRemoveButton,
  StyledMediaRemoveButtonWrapper,
  StyledMediaThumbnail,
  StyledMediaWrapper,
  StyledTitle,
  StyledUseMediaButton,
} from 'components/ui/Media/styles';
import { PLACEMENT_ENUM } from 'components/ui/Tooltip';

export const Media: FC<{
  name: string;
  img?: string;
  active?: boolean;
  innerText?: string;
  processing?: boolean;
  isStreamReady?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
  onUse?: () => void;
  className?: string;
  deleteConfirmationPlacement?: PLACEMENT_ENUM;
}> = ({
  name,
  img,
  innerText,
  active = false,
  processing = false,
  isStreamReady = true,
  onDelete = noop,
  onClick = noop,
  onUse = noop,
  className,
  deleteConfirmationPlacement,
}) => {
  const [
    isDeleteConfirmationVisible,
    setDeleteConfirmationVisibility,
  ] = useState<boolean>(false);

  const tooltipContainerRef = useRef<any>(null);
  const getTooltipContainer = () => tooltipContainerRef.current as HTMLElement;

  return (
    <StyledMediaWrapper
      onClick={onClick}
      className={className}
      ref={tooltipContainerRef}
    >
      <StyledMediaThumbnail img={img} active={active} isProcessing={processing} isGrayed={!isStreamReady}>
        {!isDeleteConfirmationVisible && innerText && (
          <StyledMediaInnerText>{innerText}</StyledMediaInnerText>
        )}
        {isStreamReady && (
          <StyledUseMediaButton onClick={onUse}>USE</StyledUseMediaButton>
        )}
        <StyledMediaRemoveButtonWrapper>
          <ConfirmationTooltip
            onConfirm={onDelete}
            onVisibleChange={setDeleteConfirmationVisibility}
            placement={deleteConfirmationPlacement}
            getTooltipContainer={getTooltipContainer}
          >
            {(state: { isTooltipVisible: boolean }) => (
              <StyledMediaRemoveButton active={state.isTooltipVisible} />
            )}
          </ConfirmationTooltip>
        </StyledMediaRemoveButtonWrapper>
      </StyledMediaThumbnail>
      <StyledTitle title={name}>{name}</StyledTitle>
    </StyledMediaWrapper>
  );
};
