import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledBlockName } from 'common/styles';
import { ProjectAspectRatio } from '../../../common/types';
import { conditionalStyle } from 'utils/styles';
import { usePreviewActions } from '../../../common/Redux/Preview/usePreviewActions';
import { colors } from '../../../common/colors';

export const VideoFormatSelector = () => {
  const { aspectRatio, setAspectRatio } = usePreviewActions();

  const [selected, setSelected] = useState<ProjectAspectRatio | undefined>(aspectRatio);

  const onRatioSelect = (selected: ProjectAspectRatio) => {
    setSelected(selected);
    setAspectRatio(selected);
  };
  return (
    <>
      <StyledBlockName>Video format:</StyledBlockName>
      <StyledRatioContainer>
        <StyledAspectRatioButton
          active={selected === ProjectAspectRatio.Square1x1}
          onClick={() => onRatioSelect(ProjectAspectRatio.Square1x1)}
        >
          1x1
        </StyledAspectRatioButton>
        <StyledAspectRatioButton
          active={selected === ProjectAspectRatio.Wide16x9}
          onClick={() => onRatioSelect(ProjectAspectRatio.Wide16x9)}
          wide
        >
          16x9
        </StyledAspectRatioButton>
        <StyledAspectRatioButton
          active={selected === ProjectAspectRatio.Vertical}
          onClick={() => onRatioSelect(ProjectAspectRatio.Vertical)}
          vertical
        >
          9x16
        </StyledAspectRatioButton>
      </StyledRatioContainer>
    </>
  );
};

const wideStyles = css`
  height: 45px;
`;

const verticalStyles = css`
  width: 45px;
`;

const hoverStyles = css`
  box-shadow: 0 0 0 4px ${colors.lightgray};
`;

const activeStyles = css`
  box-shadow: 0 0 0 4px ${colors.yellow};
  &:hover {
    box-shadow: 0 0 0 4px ${colors.yellow};
  }
`;

const StyledAspectRatioButton = styled.button<{
  active: boolean;
  wide?: boolean;
  vertical?: boolean;
}>`
  width: 72px;
  height: 72px;
  cursor: pointer;
  padding: 0;
  border: none;
  border-radius: 8px;
  background-color: white;

  &:hover {
    ${hoverStyles};
  }

  ${conditionalStyle({
    active: activeStyles,
  })}

  ${conditionalStyle({
    wide: wideStyles,
  })}

  ${conditionalStyle({
    vertical: verticalStyles,
  })}

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledRatioContainer = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  font-size: 12px;
`;
