import '../Redux/Project/sagas';
import '../Redux/Preview/sagas';
import '../Redux/Router/sagas';
import '../Redux/User/sagas';
import '../Redux/Features/sagas';
import { sagaMiddleware } from './sagaMiddleware';

export function* rootSaga() {
  console.log('root saga running');
}

sagaMiddleware.run(rootSaga);
