import React, { FC } from 'react';

export const MediaCollectionIcon: FC = (props) => (
  <svg viewBox="0 0 22 20" stroke="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.085 0.25H19.415C20.7725 0.25 21.875 1.3375 21.875 2.6725V9.8275C21.875 11.1625 20.7725 12.25 19.415 12.25H18.125V13.5775C18.125 14.9125 17.0225 16 15.665 16H14.375V17.125C14.375 18.5725 13.1975 19.75 11.75 19.75H2.75C1.3025 19.75 0.125 18.5725 0.125 17.125V10.375C0.125 8.9275 1.3025 7.75 2.75 7.75H3.875V6.4225C3.875 5.0875 4.9775 4 6.335 4H7.625V2.6725C7.625 1.3375 8.7275 0.25 10.085 0.25ZM11.75 17.5C11.96 17.5 12.125 17.335 12.125 17.125V10.375C12.125 10.165 11.96 10 11.75 10H2.75C2.54 10 2.375 10.165 2.375 10.375V17.125C2.375 17.335 2.54 17.5 2.75 17.5H11.75ZM15.665 13.75C15.785 13.75 15.875 13.675 15.875 13.5775V6.4225C15.875 6.3325 15.7775 6.25 15.665 6.25H6.335C6.2225 6.25 6.125 6.325 6.125 6.4225V7.75H11.75C13.1975 7.75 14.375 8.9275 14.375 10.375V13.75H15.665ZM19.415 10C19.535 10 19.625 9.925 19.625 9.8275V2.6725C19.625 2.5825 19.5275 2.5 19.415 2.5H10.085C9.9725 2.5 9.875 2.575 9.875 2.6725V4H15.665C17.0225 4 18.125 5.0875 18.125 6.4225V10H19.415ZM6.425 11.4025L9.25251 13.0675C9.61251 13.2775 9.73249 13.735 9.51499 14.0875C9.45499 14.1925 9.36501 14.2825 9.25251 14.35L6.425 16.015C6.065 16.225 5.6075 16.1125 5.3975 15.7525C5.33 15.6325 5.29251 15.505 5.29251 15.37V12.0475C5.29251 11.635 5.63001 11.2975 6.04251 11.2975C6.17751 11.2975 6.3125 11.335 6.425 11.4025Z"
    />
  </svg>
);
