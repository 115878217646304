import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';

import {
  ThirdPartiesServiceIdentify,
  ThirdPartiesServiceInit,
} from 'common/Service3rdUtils';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { checkForAuth, showNotifier } from 'common/Redux';
import { getCookie } from 'utils/helpers';
import { gotToLoginPage } from 'components/pages/HomePage/services';
import { getAuthState } from 'common/Redux/Auth/selectors';
import { AuthState } from 'common/Redux/Auth/types';
import { updateUserMedia, updateUserProjects } from 'common/Redux/User/actions';
import { MediaCollection, ProjectCollection } from 'common/types';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { firebaseService } from 'services/firebase';
import { GlobalState } from 'common/Redux/types';
import { StyledApp } from './styles';
import { ProcessNotifier } from 'components/ui/ProcessNotifier/ProcessNotifier';
import { selectNotifier } from 'common/Redux/Notifier/selectors';
import {
  hideNotifier,
  updateNotifier,
} from 'common/Redux/Notifier/NotifierActions';
import { UnsupportedBrowserPopup } from 'components/blocks/UnsupportedBrowserPopup';

export const App: FC<
  PropsWithChildren<{
    checkForAuth: () => void;
    authState: AuthState;
    updateUserProjects: (projects: ProjectCollection) => void;
    updateUserMedia: (media: MediaCollection) => void;
    notifier: {
      visible: boolean;
      notifierData?: any;
    };
  }>
> = ({
  children,
  authState,
  checkForAuth,
  updateUserProjects,
  updateUserMedia,
  notifier,
}) => {
  const {
    visible: isProcessNotifierVisible,
    notifierData: { Type, percentage } = {},
  } = notifier;
  const [
    isUnsupportedBrowserVisible,
    setIsUnsupportedBrowserVisible,
  ] = useState(isSafari);

  useEffect(() => {
    //Initialize 3rd parties
    ThirdPartiesServiceInit();

    checkForAuth();
  }, []);

  useEffect(() => {
    const { me } = authState;

    //Write event to analytics
    ThirdPartiesServiceIdentify(me);
    analytics.setUserId(me.id);

    firebaseService.subscribeOnUserUpdate(me.id, (rawUser) => {
      // convert array to object in case firebase returns array
      if (!rawUser) return;

      const projects = { ...rawUser.projects };
      const media = { ...rawUser.media };

      // fix possible arrays from firebase
      Object.values(projects).forEach((project: any) => {
        project.media = { ...project.media };
      });

      updateUserProjects(projects);
      updateUserMedia(media);
    });
  }, [authState.isAuth]);

  return authState.isAuth ? (
    <StyledApp>
      {isUnsupportedBrowserVisible && (
        <UnsupportedBrowserPopup
          onClose={() => setIsUnsupportedBrowserVisible(false)}
        />
      )}
      {isProcessNotifierVisible && (
        <ProcessNotifier type={Type} percentage={percentage} />
      )}
      {children}
    </StyledApp>
  ) : null;
};

const mapProps = createStructuredSelector<
  GlobalState,
  { authState: AuthState; notifier: any }
>({
  authState: getAuthState,
  notifier: selectNotifier,
});

const mapDispatch = {
  checkForAuth,
  updateUserProjects,
  updateUserMedia,
  showNotifier,
  updateNotifier,
  hideNotifier,
};

export const AppContainer = connect(mapProps, mapDispatch)(App);
