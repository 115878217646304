export enum BUTTON_SIZES_ENUM {
  XS = 'xs',
  S = 's',
  M = 'm',
}

export type ButtonProps = {
  size?: BUTTON_SIZES_ENUM;
  disabled?: boolean;
  onClick?: () => void;
  primary?: boolean;
  type?: ButtonType;
  title?: string;
};

export enum ButtonType {
  Regular,
  Primary,
  Secondary,
}
