import { requests } from 'common/requests';

export const OPEN_PROJECT = 'OPEN_PROJECT';
export const CLOSE_PROJECT = 'CLOSE_PROJECT';
export const REQUEST_OPEN_PROJECT = 'REQUEST_OPEN_PROJECT';

export const requestOpenProject = (id: number) => {
  return {
    type: REQUEST_OPEN_PROJECT,
    payload: id,
  };
};

export const openProject = (id: number) => {
  return {
    type: OPEN_PROJECT,
    payload: id,
  };
};

export const closeProject = () => {
  return {
    type: CLOSE_PROJECT,
  };
};

export const updateProject = (payload: {
  projectId: number;
  name?: string;
  title?: string;
}) => () => {
  const { projectId, ...props } = payload;
  requests.updateProject(payload.projectId, props);
};

export const attachMediaToProject = (payload: {
  projectId: number;
  mediaId: number;
}) => () => requests.attachMedia(payload);

export const detachMediaToProject = (payload: {
  projectId: number;
  mediaId: number;
}) => () => requests.detachMedia(payload);
