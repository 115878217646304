export enum ProcessStatus {
  INIT = 'init',
  PROCESSING = 'processing',

  DONE = 'done',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export type FirebaseProcessBody = {
  status: ProcessStatus;
  progress: number;
  payload?: Record<string, any>;
} | null;

export type OnUpdateCallback<T> = (process: T) => any;
