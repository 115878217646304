export const TIMELINE_CHANGE_TIMESTAMP = 'TIMELINE_CHANGE_TIMESTAMP';
export const TIMELINE_EXTRACT_FRAMES_ENDED = 'TIMELINE_EXTRACT_TRACK_ENDED';
export const TIMELINE_UPDATE_DURATION = 'TIMELINE_UPDATE_DURATION';
export const TIMELINE_UPDATE_START_TRIMM_POS =
  'TIMELINE_UPDATE_START_TRIMM_POS';
export const TIMELINE_UPDATE_END_TRIMM_POS = 'TIMELINE_UPDATE_END_TRIMM_POS';
export const TIMELINE_TRACK_LOADED_AND_READY =
  'TIMELINE_TRACK_LOADED_AND_READY';
export const TIMELINE_TRACK_PLAYED_POSITION = 'TIMELINE_TRACK_PLAYED_POSITION';
export const TIMELINE_TRIMM_POS_UNDO = 'TIMELINE_TRIMM_POS_UNDO';
export const TIMELINE_TRIMM_POS_REDO = 'TIMELINE_TRIMM_POS_REDO';
export const TIMELINE_UPDATE_EVENTS_DATA = 'TIMELINE_UPDATE_EVENTS_DATA';
export const TIMELINE_UPDATE_PEEKEVENTS_DATA =
  'TIMELINE_UPDATE_PEEKEVENTS_DATA';
export const TIMELINE_TRIM_START_UPDATED = 'TIMELINE_TRIM_START_UPDATED';
export const TIMELINE_TRIM_END_UPDATED = 'TIMELINE_TRIM_END_UPDATED';
export const TIMELINE_ZOOM_CHANGE = 'TIMELINE_ZOOM_CHANGE';
export const TIMELINE_RESET = 'TIMELINE_RESET';
export const TIMELINE_RESET_KEYFRAMES = 'TIMELINE_RESET_KEYFRAMES';

export type TimelineState = {
  currentZoom: number;
  duration: number;
  durationReady: boolean;
  trackEnded: boolean;
  ready: boolean;
  currentPlayPosition: number;
  startTrimmPos: number;
  endTrimmPos: number;
  framesExtracted: boolean;
  actionHistory: string[];
  actionHistoryIndex: number;
  trackFrames: any[];
  videoUrl?: string;
};
