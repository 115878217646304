import React from 'react';

export const BigCommentIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 70 70" fill="none" {...props}>
      <path
        d="M0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35Z"
        fill="url(#comment_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2034 20.6716C51.375 21.8431 51.375 23.7288 51.375 27.5V50.2966C51.375 52.3515 51.375 53.379 50.7577 53.6347C50.1404 53.8904 49.4138 53.1638 47.9608 51.7108L46.2181 49.9681C45.062 48.812 44.484 48.234 43.7489 47.9295C43.0138 47.625 42.1963 47.625 40.5613 47.625H25.625C21.8538 47.625 19.9681 47.625 18.7966 46.4534C17.625 45.2819 17.625 43.3962 17.625 39.625V27.5C17.625 23.7288 17.625 21.8431 18.7966 20.6716C19.9681 19.5 21.8538 19.5 25.625 19.5H43.375C47.1462 19.5 49.0319 19.5 50.2034 20.6716ZM42.9375 36.375C41.3842 36.375 40.125 35.1158 40.125 33.5625C40.125 32.0092 41.3842 30.75 42.9375 30.75C44.4908 30.75 45.75 32.0092 45.75 33.5625C45.75 35.1158 44.4908 36.375 42.9375 36.375ZM31.6875 33.5625C31.6875 35.1158 32.9467 36.375 34.5 36.375C36.0533 36.375 37.3125 35.1158 37.3125 33.5625C37.3125 32.0092 36.0533 30.75 34.5 30.75C32.9467 30.75 31.6875 32.0092 31.6875 33.5625ZM26.0625 36.375C24.5092 36.375 23.25 35.1158 23.25 33.5625C23.25 32.0092 24.5092 30.75 26.0625 30.75C27.6158 30.75 28.875 32.0092 28.875 33.5625C28.875 35.1158 27.6158 36.375 26.0625 36.375Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="comment_linear"
          x1="35"
          y1="0"
          x2="35"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3DD9FB" />
          <stop offset="1" stopColor="#413DFB" />
        </linearGradient>
      </defs>
    </svg>
  );
});
