import React, { FC } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { StartupPage } from 'components/pages/StartupPage';
import { HomePage } from 'components/pages/HomePage';
import { SupervisorPage } from 'components/pages/SupervisorPage';
import { connect } from 'react-redux';
import { enterHomePage, leaveHomePage } from 'common/Redux/Router/actions';
import { HookedRoute } from './components/ActionDispatchingRoute';

export const Router: FC<{
  enterHomePage: () => void;
  leaveHomePage: () => void;
}> = ({ enterHomePage, leaveHomePage }) => {
  return (
    <HashRouter>
      <Route exact path="/" component={StartupPage} />

      <HookedRoute
        exact
        path="/project/:id"
        component={HomePage}
        onEnter={enterHomePage}
        onLeave={leaveHomePage}
      />

      <Route path="/supervisor" component={SupervisorPage} />
    </HashRouter>
  );
};

const mapDispatch = {
  enterHomePage,
  leaveHomePage,
};

export const RouterContainer = connect(null, mapDispatch)(Router);
