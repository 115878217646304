import React from 'react';

export const SoonIcon = React.memo(({ ...props }) => {
  return (
    <svg width="44" height="20" viewBox="0 0 44 20" fill="none" {...props}>
      <rect width="44" height="20" rx="10" fill="#B1B1B1" />
      <path
        d="M11.802 8.81889H13.2757C13.2544 7.52273 12.1891 6.62784 10.5698 6.62784C8.97532 6.62784 7.807 7.50852 7.8141 8.82955C7.81055 9.90199 8.56694 10.5163 9.79563 10.8111L10.5875 11.0099C11.3794 11.2017 11.8198 11.429 11.8233 11.919C11.8198 12.4517 11.3155 12.8139 10.5343 12.8139C9.73526 12.8139 9.15998 12.4446 9.11026 11.7166H7.62234C7.6614 13.2898 8.78711 14.103 10.552 14.103C12.3276 14.103 13.3716 13.2543 13.3752 11.9226C13.3716 10.7116 12.459 10.0689 11.1948 9.7848L10.5414 9.62855C9.90927 9.48295 9.38015 9.24858 9.3908 8.72656C9.3908 8.25781 9.80629 7.91335 10.5591 7.91335C11.2942 7.91335 11.7452 8.24716 11.802 8.81889ZM21.0927 10.3636C21.0927 7.98438 19.6154 6.62784 17.6871 6.62784C15.7482 6.62784 14.2816 7.98438 14.2816 10.3636C14.2816 12.7322 15.7482 14.0994 17.6871 14.0994C19.6154 14.0994 21.0927 12.7429 21.0927 10.3636ZM19.5337 10.3636C19.5337 11.9048 18.8022 12.7393 17.6871 12.7393C16.5685 12.7393 15.8406 11.9048 15.8406 10.3636C15.8406 8.82244 16.5685 7.98793 17.6871 7.98793C18.8022 7.98793 19.5337 8.82244 19.5337 10.3636ZM28.915 10.3636C28.915 7.98438 27.4377 6.62784 25.5094 6.62784C23.5705 6.62784 22.1039 7.98438 22.1039 10.3636C22.1039 12.7322 23.5705 14.0994 25.5094 14.0994C27.4377 14.0994 28.915 12.7429 28.915 10.3636ZM27.356 10.3636C27.356 11.9048 26.6245 12.7393 25.5094 12.7393C24.3908 12.7393 23.6628 11.9048 23.6628 10.3636C23.6628 8.82244 24.3908 7.98793 25.5094 7.98793C26.6245 7.98793 27.356 8.82244 27.356 10.3636ZM36.1371 6.72727H34.6065V11.3011H34.5426L31.4034 6.72727H30.054V14H31.5916V9.42259H31.6449L34.8089 14H36.1371V6.72727Z"
        fill="white"
      />
    </svg>
  );
});
