import React, { FC } from 'react';

export const TemplateIcon: FC = (props) => {
  return (
    <svg viewBox="0 0 18 18" stroke="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.895431 0 2 0H16C17.1046 0 18 0.895431 18 2V16C18 17.1046 17.1046 18 16 18H2C0.895431 18 0 17.1046 0 16V2ZM16 2H10V8L16 8V2ZM16 10H10V16H16V10ZM2 2H8V16H2V2Z"
      />
    </svg>
  );
};
