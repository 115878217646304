import { ChecklistItem } from '../../ui/Checklist/types';

type RawChecklist = {
  title: string;
  description: string;
  items: ChecklistItem[];
};
export const shoppableVideoCreationChecklist: RawChecklist = {
  title: `Let's create a shoppable video!`,
  description:
    'Complete the checklist to make sure your video is eligible for Amazon:',
  items: [
    {
      title: 'MP4 format',
      isChecked: false,
      id: '1',
    },
    {
      title: 'Up to 5 GB',
      isChecked: false,
      id: '2',
    },
    {
      title: 'Video is 30+ seconds',
      id: '3',
      isChecked: false,
    },
  ],
};

export const amazonGuidelinesChecklist: RawChecklist = {
  title: 'Check out Amazon Guidelines',
  description:
    'This is a set of rules & recommendations to get the most value out of the shoppable video:',
  items: [
    {
      title: 'Demonstrate the product',
      description:
        'Show the product in the environment where it is used to help customers get its value.',
      isChecked: false,
      id: '1',
    },
    {
      title: 'Describe the product',
      description:
        'What is this product, what are its features, who is it for, how is it different, and/or why you like it better.',
      isChecked: false,
      id: '2',
    },
    {
      title: 'Make sure the product is sold on Amazon',
      description:
        'Videos must not include references to products not sold on Amazon.',
      isChecked: false,
      id: '3',
    },
    {
      title: 'Mention fewer products',
      description:
        'For best results, mention fewer than 3 products. Also, single-product videos are highly valued on Amazon.',
      isChecked: false,
      id: '4',
    },
    {
      title: 'Make a short video',
      description:
        'Amazon prefers shorter-form videos that range between 1 to 12 minutes in length.',
      isChecked: false,
      id: '5',
    },
    {
      title: 'Be careful of mistakes',
      description:
        'Make sure to avoid spelling/grammar mistakes in the video title, video description, or text displayed in the video.',
      isChecked: false,
      id: '6',
    },
    {
      title: `Don't mention prices`,
      description:
        'Videos must not contain prices, promotion information, discount claims, or other time-sensitive information. ',
      isChecked: false,
      id: '7',
    },
    {
      title: `Don't direct viewers to other websites`,
      description:
        'Do not include any web links, URLs, or calls to action that asks viewers to take actions on other sites.',
      isChecked: false,
      id: '8',
    },
    {
      title: `Don't disclose private info`,
      description:
        'Do not disclose private information such as phone numbers or email addresses, or ask viewers to do so.',
      isChecked: false,
      id: '9',
    },
  ],
};

