import React, { useEffect, useState } from 'react';

import { messages } from 'components/blocks/Download/messages';
import {
  AUTO_CLOSE_POPUP_TIMEOUT,
  CHANGE_LOADING_MESSAGE_INTERVAL,
  DOWNLOAD_STATUSES,
} from 'components/blocks/Download/constants';
import {
  StyledCancelButton,
  StyledDownloadButton,
  StyledDownloadButtonWrapper,
  StyledDownloadIcon,
  StyledDownloadPopup,
  StyledDownloadPopupTitleWrapper,
} from 'components/blocks/Download/styles';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import {
  analyticsDestinations,
  analyticsEvents,
  analyticsOverlayColorNames,
  analyticsOverlayNames,
} from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { getBase64Overlay } from 'components/blocks/PreviewOverlay/context';
import { OverlayLayouts } from 'components/blocks/Overlays/constants';
import { requests } from 'common/requests';
import { OverlayType } from 'components/blocks/Overlays/types';
import { BUTTON_SIZES_ENUM, ButtonType } from 'components/ui/Button/types';
import { processManager } from 'services/processes';

export const Download = ({
  isDownloadButtonDisabled,
  status,
  toggleStatus,
  overlayType,
  themeType,
  className,
  userId,
  projectId,
  videoRect,
}) => {
  const [rotateMessagesInterval, setRotateMessagesInterval] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const isDownloadPopupVisible =
    status === DOWNLOAD_STATUSES.PREPARATION ||
    status === DOWNLOAD_STATUSES.DOWNLOADING;
  const isDownloadingInProgress = status === DOWNLOAD_STATUSES.PREPARATION;
  const isOverlayEnabled = overlayType !== OverlayType.Default;

  const startLoadingMessageRotating = () => {
    const { processMessages } = messages;
    setRotateMessagesInterval(
      setInterval(() => {
        setLoadingMessage(
          processMessages[(processMessages.length * Math.random()) | 0],
        );
      }, CHANGE_LOADING_MESSAGE_INTERVAL),
    );
  };

  const stopLoadingMessageRotating = () => {
    clearInterval(rotateMessagesInterval);
  };

  const onDownload = async () => {
    try {
      //Write event to analytics
      setProgress(0);
      toggleStatus(DOWNLOAD_STATUSES.PREPARATION);
      analytics.trackEvent(analyticsEvents.EXPORT_CLICKED, { 'Export Type': 'Download' });

      let videoData = {
        projectId,
        ...(isOverlayEnabled && {
          overlay: getBase64Overlay(),
          overlayConfig: {
            videoRect,
          }
        }),
      };

      // Start Download
      const downloadData = await requests.trimVideo(videoData);
      const { processKey = null } = downloadData;
      if (processKey) {
        (await processManager.createProcess(userId, processKey))
          .onUpdate((process) => {
            setProgress(Math.round(process.progress));
          });
      }
    } catch (err) {
      console.error(err);
      toggleStatus(DOWNLOAD_STATUSES.IDLE);
    }
  };

  const onCancel = () => {
    toggleStatus(DOWNLOAD_STATUSES.CANCELED);
  };

  useEffect(() => {
    stopLoadingMessageRotating();

    switch (status) {
      case DOWNLOAD_STATUSES.PREPARATION:
        setLoadingMessage(messages.downloadPopupContent);
        startLoadingMessageRotating();
        break;
      case DOWNLOAD_STATUSES.DOWNLOADING:
        setLoadingMessage(messages.done);
        setTimeout(() => {
          analytics.trackEvent(analyticsEvents.EXPORT_SUCCESS, {
            'Type': 'Download',
            'Destination': analyticsDestinations.DownloadFolder,
            'Text Overlays': isOverlayEnabled,
            'Text Overlays Type': analyticsOverlayNames[overlayType],
            'Text Overlays Style':
              isOverlayEnabled
                ? analyticsOverlayColorNames[themeType]
                : 'None',
          });
          toggleStatus(DOWNLOAD_STATUSES.IDLE);
        }, AUTO_CLOSE_POPUP_TIMEOUT);
        break;

      default:
        break;
    }
  }, [status, toggleStatus]);

  return (
    <StyledDownloadButtonWrapper className={className}>
      <StyledDownloadButton
        type={ButtonType.Primary}
        size={BUTTON_SIZES_ENUM.S}
        disabled={isDownloadButtonDisabled}
        onClick={onDownload}
      >
        <StyledDownloadIcon />
        Download
      </StyledDownloadButton>
      {isDownloadPopupVisible && (
        <StyledDownloadPopup
          title={
            <StyledDownloadPopupTitleWrapper>
              {messages.downloadPopupTitle}
              {` - ${Math.floor(progress)}%`}
              {isDownloadingInProgress && (
                <StyledCancelButton onClick={onCancel}>
                  {messages.cancel}
                </StyledCancelButton>
              )}
            </StyledDownloadPopupTitleWrapper>
          }
          percentage={progress}
          content={loadingMessage}
          onClose={isDownloadingInProgress ? null : onCancel}
        />
      )}
    </StyledDownloadButtonWrapper>
  );
};
