import {
  HIDE_NOTIFIER,
  SHOW_NOTIFIER,
  UPDATE_NOTIFIER,
  CANCELED_DOWNLOAD_NOTIFIER,
  START_DOWNLOAD_NOTIFIER,
} from './NotifierTypes';

// let serverAddress = process.env.REACT_APP_BELIVE_EDITOR_SERVER;

// export const fetchBroadcastsList = (userId) => {
//   return (dispatch) => {
//     let postAddress = serverAddress + '/BroadcastsByUser/' + userId;
//     return axios.post(postAddress).then((response) => {
//         if (response)
//           if (response.data)
//             dispatch(fetchBroadcastsListSuccess(response.data))
//       })
//       .catch(error => {
//         console.log(error);
//       })
//   }
// }

export const showNotifier = (NotifierData) => {
  return {
    type: SHOW_NOTIFIER,
    payload: NotifierData,
  };
};

export const updateNotifier = (payload) => {
  return {
    type: UPDATE_NOTIFIER,
    payload,
  };
};

export const hideNotifier = () => {
  return {
    type: HIDE_NOTIFIER,
  };
};

export const cancelDownload = () => {
  return {
    type: CANCELED_DOWNLOAD_NOTIFIER,
  };
};

export const startDownload = () => {
  return {
    type: START_DOWNLOAD_NOTIFIER,
  };
};
