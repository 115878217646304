import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './wdyr';
import './utils/noConsole';
import './index.css';
import store from './common/Redux/Store';
import 'common/sagas';
import * as serviceWorker from './serviceWorker';
import { TooltipGlobalStyle } from 'components/ui/Tooltip/GlobalTooltipStyles';
import { TooltipLightGlobalTheme } from 'components/ui/Tooltip/themes/tooltipLightGlobalTheme';
import { EmojiGlobalStyle } from 'components/ui/EmojiComponent/styles';
import { App } from 'components/blocks/App';
import { Router } from 'components/blocks/Router';

ReactDOM.render(
  <React.Fragment>
    <TooltipGlobalStyle />
    <TooltipLightGlobalTheme />
    <EmojiGlobalStyle />

    <Provider store={store}>
      <App>
        <Router />
      </App>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
