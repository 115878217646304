import { transparentize } from 'polished';

export function opacity(color, amount = 1) {
  return transparentize(1 - amount, color);
}

export function conditionalStyle(propStyles) {
  return (props) =>
    Object.keys(propStyles).map((key) => {
      return props[key] && propStyles[key];
    });
}
