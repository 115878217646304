import React, {
  cloneElement,
  createContext,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useContext
} from 'react';
import noop from 'lodash/noop';

import { ITabsContext, TabsComposition, TabsProps, TabState } from './types';

const TabsContext = createContext<ITabsContext>({
  activeTabId: null,
  onChange: noop,
});

export const Tabs: FC<PropsWithChildren<TabsProps>> & TabsComposition = ({
  activeTabId = null,
  onChange = noop,
  children,
}) => {
  return (
    <TabsContext.Provider value={{ activeTabId: activeTabId, onChange }}>
      {children}
    </TabsContext.Provider>
  );
};

const Tab: TabsComposition['Tab'] = ({ tabId, children }) => {
  const { activeTabId, onChange } = useContext<ITabsContext>(TabsContext);

  const tabState: TabState = {
    isActive: tabId === activeTabId,
    makeTabActive: () => onChange(tabId),
  };

  if (typeof children === 'function') {
    return children(tabState);
  }

  return isValidElement(children) ? cloneElement(children, tabState) : children;
};

const TabContent: TabsComposition['TabContent'] = ({ tabId, children }) => {
  const { activeTabId } = useContext<ITabsContext>(TabsContext);

  return tabId === activeTabId ? (children as ReactElement) : null;
};

Tabs.Tab = Tab;
Tabs.TabContent = TabContent;
