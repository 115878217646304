import React, { FC } from 'react';
import { colors } from 'common/colors';

export const ErrorIcon: FC = React.memo((props) => (
  <svg viewBox="0 0 41 40" {...props}>
    <g clipPath="url(#errorIconClip0)">
      <path d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z" fill="inherit"/>
      <path
        d="M12 12L29 28Z M12 28L29 12Z"
        strokeWidth="4"
        strokeLinejoin="round"
        stroke={colors.white}
      />
    </g>
    <defs>
      <clipPath id="errorIconClip0">
        <rect width="40" height="40" fill={colors.white} transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
));
