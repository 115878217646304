import styled from 'styled-components';
import { StyledPanel } from 'components/pages/HomePage/styles';
import { ProjectName } from 'components/blocks/LeftPanel/components/ProjectName';

export const StyledLeftPanel = styled(StyledPanel)`
  overflow-y: auto;
  background-color: transparent;

  /* for Firefox */
  min-height: 0;
`;

export const StyledContentWrapper = styled.div`
  direction: ltr;
  width: 280px;
  height: 100%;
`;

export const StyledTab = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
`;

export const StyledTabSection = styled.div`
  margin-bottom: 24px;
`;

export const StyledTabSectionTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 22px;
`;

export const StyledProjectName = styled(ProjectName)`
  margin-left: -10px;
`;
