import { TOOLBOX_UPDATE_ACTIVE_ITEM } from 'common/Redux/Toolbox/actions';
import { TOOLBOX_ITEM_TYPES } from 'common/Redux/Toolbox/types';

const initialState = {
  activeItem: TOOLBOX_ITEM_TYPES.MEDIA,
};

export const reducer = (
  state = initialState,
  action: { type: string; payload: any }
): typeof initialState => {
  switch (action.type) {
    case TOOLBOX_UPDATE_ACTIVE_ITEM:
      return { ...state, activeItem: action.payload };
    default:
      return state;
  }
};
