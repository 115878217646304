import {
  togglePreviewPlayingState,
  PreviewSeekTo,
  TimelinePlayedPosition,
} from 'common/Redux';
import { connect } from 'react-redux';
import { Controls } from 'components/blocks/Preview/components/Controls/Controls';
import { FEATURES } from 'components/pages/HomePage/features';
import { selectIsPlaying } from 'common/Redux/Preview/selectors';

const mapStateToProps = (state) => {
  return {
    currentPlayPosition: state.TimeLine.currentPlayPosition,
    duration: state.TimeLine.duration,
    isSpeedFeatureEnabled: FEATURES.IS_PREVIEW_SPEED_BUTTONS_ENABLED,
    isPlaying: selectIsPlaying(state),
    ...state,
  };
};

const mapDispatchToProps = {
  PreviewControlsPlayPause: togglePreviewPlayingState,
  TimelinePlayedPosition: TimelinePlayedPosition,
  PreviewSeekTo: PreviewSeekTo,
};

export const ControlsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
