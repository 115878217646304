import React from 'react';
import styled from 'styled-components';

export const Header = ({ children }: { children: React.ReactNode }) => {
    return <Header.Styled>{children}</Header.Styled>;
};

const StyledArrowContainer = styled.span``;

Header.Styled = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  ${StyledArrowContainer} {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 30px;
  }
`;
