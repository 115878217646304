import React from 'react';
import styled from 'styled-components';
import { Badge } from './Badge';

type WithBetaLabelProps = {
  children: React.ReactNode;
};

export const WithBetaLabel = ({ children }: WithBetaLabelProps) => {
  return (
    <StyledWrapper>
      <Badge>BETA</Badge>
      {children}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  
  ${Badge.Styled} {
    position: absolute;
    top: -10px;
    right: -10px;
    pointer-events: none;
    z-index: 1;
  }
`;
