import { CHECK_FOR_AUTH, LOGOUT } from 'common/Redux/Auth/actions';
import { AuthState } from 'common/Redux/Auth/types';

const initialState: AuthState = {
  isAuth: false,
  accessToken: '',
  me: {
    id: 0,
    image: '',
    name: 'Username',
    firebaseToken: '',
    subscription: {
      name: 'Free',
      plan: 1,
    },
  },
};

export const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHECK_FOR_AUTH:
      return {
        ...state,
        controlEvent: CHECK_FOR_AUTH,
        me: action.payload,
        isAuth: true,
      };

    case LOGOUT:
      return {
        ...state,
        controlEvent: LOGOUT,
        isAuth: false,
      };

    default:
      return state;
  }
};
