import React from 'react';

export const SettingsIcon = React.memo((props) => (
  <svg width="13" height="13" viewBox="0 0 13 13" {...props}>
    <path
      fillRule="evenodd"
      d="M6.5 8.667A2.17 2.17 0 0 1 4.333 6.5 2.17 2.17 0 0 1 6.5 4.333 2.17 2.17 0 0 1 8.667 6.5 2.17 2.17 0 0 1 6.5 8.667m6.23-3.25h-1.547c-.109-.428-.246-.903-.422-1.263l1.101-1.101a.27.27 0 0 0 0-.383L10.33 1.139a.27.27 0 0 0-.383 0l-1.101 1.1c-.36-.176-.835-.313-1.263-.421V.27A.27.27 0 0 0 7.313 0H5.688a.27.27 0 0 0-.271.27v1.548c-.428.108-.903.245-1.263.42l-1.101-1.1a.27.27 0 0 0-.383 0L1.137 2.67a.271.271 0 0 0 0 .383L2.24 4.154c-.175.36-.313.834-.422 1.263H.271a.27.27 0 0 0-.271.27v1.625c0 .15.121.271.27.271h1.547c.109.429.247.904.422 1.264l-1.102 1.1a.269.269 0 0 0 0 .383l1.533 1.532a.278.278 0 0 0 .383 0l1.101-1.1c.359.174.834.312 1.263.421v1.546c0 .15.121.271.27.271h1.625a.27.27 0 0 0 .271-.27v-1.547c.428-.11.904-.247 1.263-.422l1.101 1.101a.27.27 0 0 0 .383 0l1.532-1.532a.27.27 0 0 0 0-.382l-1.1-1.101c.174-.36.312-.835.42-1.264h1.547a.27.27 0 0 0 .271-.27V5.688a.27.27 0 0 0-.27-.271"
    />
  </svg>
));
