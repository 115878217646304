import { request } from './request';

export const uploadCustomBackground = async ({ file }: { file: File }): Promise<{ processKey?: string; }> => {
  const form = new FormData();

  form.append('file', file);
  const { data } = await request.post(`/features/customBackground`, form);

  return data;
};

export const getCustomBackgroundUrl = async (): Promise<string> => {
  const { data } = await request.get(`/features/customBackground`);
  return data;
}

export const removeCustomBackground = async () => {
  const { data } = await request.delete(`/features/customBackground`);
  return data;
};