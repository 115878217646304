import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import 'components/blocks/Header/styles.css';
import { Logo } from 'components/blocks/Header/components/Logo';
import { StyledAvatarWrapper, StyledHeader } from 'components/blocks/Header/styles';
import { Avatar } from 'components/blocks/Header/components/Avatar';
import AvatarMenu from 'components/blocks/Header/components/AvatarMenu';
import { DevModeIndicator } from 'components/blocks/Header/components/DevModeIndicator';
import { useBlur } from 'components/hooks/useBlur';
import { getFeaturesSelector } from 'common/Redux/Features';

export const Header = (props) => {
  const { image } = props;
  const features = useSelector(getFeaturesSelector);
  const [showMenu, setShowMenu] = useState(false);
  const isDevMode = Boolean(features.devMode);

  const handleShowHideMenu = () => {
    setShowMenu(!showMenu);
  };

  const avatarRef = useBlur(() => {
    setShowMenu(false);
  });

  return (
    <StyledHeader>
      <Logo />
      <StyledAvatarWrapper ref={avatarRef}>
        <Avatar image={image} onClick={handleShowHideMenu} />
        <AvatarMenu isVisible={showMenu} brodcastId={props.broadcastId} />
      </StyledAvatarWrapper>
      {isDevMode && <DevModeIndicator />}
    </StyledHeader>
  );
};
