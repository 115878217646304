import React, { FC, useEffect, useRef, useState } from 'react';
import {
  StyleClosePanel,
  StyledButtonsWrapper,
  StyledCounter,
  StyledCrossIcon,
  StyledPanel,
  StyledPopup,
  StyledRecordIcon,
  StyledRefreshIcon,
  StyledSettings,
  StyledSide,
  StyledTakeAnotherVideoButton,
  StyledTime,
  StyledVideo,
  StyledVideoRecord,
  StyledVideoWrapper,
} from 'components/blocks/VideoRecord/styles';
import { StyledButton } from 'components/blocks/Timeline/TimelineUndoRedo/styles';
import { Stopwatch } from 'components/ui/Stopwatch';
import { LoadingWrapper } from 'components/blocks/LoadingWrapper';
import { createCameraStream, Devices, getDevices, startStreamRecording, stopStream } from 'services/video';
import { useTimer } from 'components/hooks/useTimer';
import { CAMERA_RECORDING_LIMIT } from 'components/blocks/VideoRecord/contants';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { PLACEMENT_ENUM, Tooltip, TRIGGER_ENUM } from 'components/ui/Tooltip';
import { DeviceSettings } from 'components/blocks/DeviceSettings';
import { ButtonType } from '../../ui/Button/types';

export const VideoRecord: FC<{
  isVisible: boolean;
  isLoading: boolean;
  onClose?: () => void;
  onVideoRecorded?: (videoData: Blob) => void;
}> = ({
  onVideoRecorded = () => {},
  onClose = () => {},
  isVisible,
  isLoading,
}) => {
  const videoPreviewRef = useRef<HTMLVideoElement>(null);
  const videoRecordingRef = useRef<HTMLVideoElement>(null);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [recordedData, setRecordedData] = useState<Blob | null>(null);
  const [stream, setStream] = useState<MediaStream>();
  const { startTimer, stopTimer, timeLeft, isTimerStarted } = useTimer(3000);
  const [devices, setDevices] = useState<Devices>();
  const [cameraId, setCameraId] = useState<string>();
  const [microphoneId, setMicrophoneId] = useState<string>();

  const timerTime = Math.round(timeLeft / 1000);

  const stopRecording = (stream?: MediaStream) => {
    stopTimer();
    if (stream) {
      stopStream(stream);
      setStream(undefined);
    }
    setIsVideoRecording(false);
  };

  const stopRecordedVideo = () => {
    const recording = videoRecordingRef.current;
    if (recording) recording.pause();
  };

  const applyStream = (stream: MediaStream) => {
    const preview = videoPreviewRef.current;
    if (preview) {
      preview.srcObject = stream;
    }
    setStream(stream);
    return stream;
  };

  const updateDeviceList = (stream: MediaStream) => {
    getDevices().then((devices) => {
      setDevices(devices);
      const micDeviceId = stream.getAudioTracks()[0]?.getSettings().deviceId;
      const cameraDeviceId = stream.getVideoTracks()[0]?.getSettings().deviceId;
      if (micDeviceId) {
        setMicrophoneId(micDeviceId);
      }
      if (cameraDeviceId) {
        setCameraId(cameraDeviceId);
      }
    });
    return stream;
  };

  useEffect(() => {
    const preview = videoPreviewRef.current;
    if (preview && isVisible && !recordedData) {
      const promise = createCameraStream(cameraId, microphoneId)
        .then(applyStream)
        .then(updateDeviceList);
      return () => {
        promise.then((stream: MediaStream) => {
          stopRecording(stream);
          stopRecordedVideo();
        });
      };
    }
  }, [isVisible, microphoneId, cameraId]);

  const onStartRecording = () => {
    const preview = videoPreviewRef.current;
    const recording = videoRecordingRef.current;
    if (preview && recording) {
      analytics.trackEvent(analyticsEvents.RECORDING_STARTED);
      startCameraRecording(stream as MediaStream, preview, recording)
        .then(setRecordedData)
        .then(() => stopRecording(stream));
    }
  };

  const onStartTimer = async () => {
    analytics.trackEvent(analyticsEvents.START_RECORDING);
    setIsVideoRecording(true);
    await startTimer();
    onStartRecording();
  };

  const handleClose = () => {
    if (!isLoading) {
      if (isVideoRecording) {
        stopRecording(stream);
      } else {
        stopRecordedVideo();
      }
      onClose();
    }
  };

  const onRecordIconClick = () => {
    if (stream) {
      isVideoRecording
        ? stopRecording(isTimerStarted ? undefined : stream)
        : onStartTimer();
    }
  };

  const onNextStep = () => {
    const preview = videoRecordingRef.current;
    if (preview) preview.pause();
    if (recordedData) {
      onVideoRecorded(recordedData);
      setRecordedData(null);
    }
  };

  const isPreviewVideo = isVideoRecording || !recordedData;

  return (
    <StyledVideoRecord>
      <StyleClosePanel isLoading={isLoading} onClick={handleClose}>
        <StyledCrossIcon />
      </StyleClosePanel>
      <StyledPopup>
        <LoadingWrapper isLoading={isLoading}>
          <StyledVideoWrapper>
            <StyledVideo
              isVisible={isPreviewVideo}
              ref={videoPreviewRef}
              width="800"
              height="450"
              autoPlay
              muted
            />
            <StyledVideo
              ref={videoRecordingRef}
              isVisible={!isPreviewVideo}
              controls
              width="800"
              height="450"
              autoPlay
            />
            {isTimerStarted && <StyledCounter>{timerTime}</StyledCounter>}
          </StyledVideoWrapper>
          <StyledPanel>
            <StyledSide>
              {devices && (
                <Tooltip
                  hover
                  hideArrow
                  placement={PLACEMENT_ENUM.TOP_LEFT}
                  trigger={TRIGGER_ENUM.CLICK}
                  overlay={
                    <DeviceSettings
                      devices={devices}
                      onCameraChange={setCameraId}
                      onMicrophoneChange={setMicrophoneId}
                    />
                  }
                >
                  <StyledSettings />
                </Tooltip>
              )}
            </StyledSide>
            {!recordedData && (
              <StyledRecordIcon
                isRecording={isVideoRecording}
                isDisabled={!stream}
                onClick={onRecordIconClick}
              />
            )}
            {recordedData && (
              <StyledButtonsWrapper>
                <StyledTakeAnotherVideoButton
                  type={ButtonType.Secondary}
                  onClick={() => {
                    const preview = videoPreviewRef.current;
                    stopRecordedVideo();
                    setRecordedData(null);
                    if (preview) {
                      createCameraStream().then(applyStream);
                    }
                  }}
                >
                  <StyledRefreshIcon />
                  Retake
                </StyledTakeAnotherVideoButton>
                <StyledButton type={ButtonType.Primary} onClick={onNextStep}>
                  Save Recording
                </StyledButton>
              </StyledButtonsWrapper>
            )}
            <StyledSide>
              <StyledTime
                isActive={!recordedData}
                isVisible={
                  (!isTimerStarted && isVideoRecording) || Boolean(recordedData)
                }
              >
                <Stopwatch isStarted={isVideoRecording && !isTimerStarted} />
              </StyledTime>
            </StyledSide>
          </StyledPanel>
        </LoadingWrapper>
      </StyledPopup>
    </StyledVideoRecord>
  );
};

function startCameraRecording(
  stream: MediaStream,
  preview: HTMLVideoElement,
  recording: HTMLVideoElement
): Promise<Blob> {
  return startStreamRecording(stream, CAMERA_RECORDING_LIMIT).then(
    (recordedChunks) => {
      const recordedBlob: Blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });

      recording.src = URL.createObjectURL(recordedBlob);
      recording.currentTime = 9999999999;

      return recordedBlob;
    }
  );
}
