import React, { FC, PropsWithChildren } from 'react';
import {
  StyledDimmedCover,
  StyledLoadingWrapper,
  StyledLoader,
} from 'components/blocks/LoadingWrapper/styles';

export const LoadingWrapper: FC<PropsWithChildren<{ isLoading: boolean }>> = ({
  isLoading,
  children,
}) => {
  return (
    <StyledLoadingWrapper>
      {children}
      <StyledDimmedCover isLoading={isLoading}>
        <StyledLoader />
      </StyledDimmedCover>
    </StyledLoadingWrapper>
  );
};
