import React from 'react';
import styled, { css } from 'styled-components';

import {
  EVENT_ICONS,
  EVENT_TYPES,
} from 'components/blocks/EventsTimeline/constants';
import { colors } from 'common/colors';

export const EventCircle = ({ eventType, text, ...restProps }) => {
  const Icon = EVENT_ICONS[eventType];
  return (
    <StyledCircle eventType={eventType} {...restProps}>
      {Icon && <Icon />}
      {text && <StyledText isIconPresented={Boolean(Icon)}>{text}</StyledText>}
    </StyledCircle>
  );
};

const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.white};
  font-size: 12px;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 12px;
  user-select: none;
  cursor: pointer;

  ${getColorsByEventType(
    ({ color, background }) => css`
      background-color: ${background};
      color: ${color};
      fill: ${color};
      stroke: ${color};
    `
  )};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.disabledBackgroundColor};
      color: ${colors.disabledColor};
      fill: ${colors.disabledColor};
      stroke: ${colors.disabledColor};
    `};

  svg {
    width: 24px;
    height: 24px;
    padding: 2px;
  }
`;

const StyledText = styled.div`
  margin-right: 8px;
  white-space: nowrap;

  ${({ isIconPresented }) =>
    !isIconPresented &&
    css`
      margin-left: 8px;
    `}
`;

function getColorsByEventType(callback) {
  return ({ eventType }) => {
    let theme;

    switch (eventType) {
      case EVENT_TYPES.TITLE:
        theme = { color: '#363636', background: '#FBC33D' };
        break;

      case EVENT_TYPES.CRAWLER:
        theme = { color: '#363636', background: '#FF824B' };
        break;

      case EVENT_TYPES.COMMENT:
        theme = { color: 'white', background: '#754F9D' };
        break;

      case EVENT_TYPES.LAYOUT:
      case EVENT_TYPES.GUEST:
      case EVENT_TYPES.SCREEN:
      case EVENT_TYPES.PICTURE:
      default:
        theme = { color: 'white', background: colors.watermelon };
        break;

      case EVENT_TYPES.VIDEO:
        theme = { color: 'white', background: colors.darkCyan };
        break;
      case EVENT_TYPES.FEATURED_PRODUCT:
        theme = { color: 'white', background: '#ff9900' };
        break;
    }

    return callback(theme);
  };
}
