import React, { FC } from 'react';

export const SupportIcon: FC = (props) => {
  return (
    <svg viewBox="0 0 24 24" stroke="none" {...props}>
      <path d="M12 21C7.035 21 3 16.965 3 12C3 7.035 7.035 3 12 3C16.965 3 21 7.035 21 12C21 16.965 16.965 21 12 21ZM12 4.5C7.8675 4.5 4.5 7.8675 4.5 12C4.5 16.1325 7.8675 19.5 12 19.5C16.1325 19.5 19.5 16.1325 19.5 12C19.5 7.8675 16.1325 4.5 12 4.5Z" />
      <path d="M12.0825 13.5C11.67 13.5 11.3325 13.1625 11.3325 12.75C11.3325 11.5125 12.135 10.89 12.72 10.4325C13.29 9.99 13.5 9.7875 13.5 9.3675C13.5 8.67 12.78 8.25 12.09 8.25C11.7 8.25 10.755 8.3775 10.485 9.54C10.3875 9.945 9.98252 10.1925 9.58502 10.095C9.18002 9.9975 8.93252 9.5925 9.03002 9.195C9.45002 7.395 10.8825 6.75 12.09 6.75C13.5225 6.75 15 7.725 15 9.3675C15 10.5525 14.2125 11.1675 13.6425 11.6175C13.11 12.03 12.8325 12.27 12.8325 12.7575C12.8325 13.1625 12.495 13.5 12.0825 13.5Z" />
      <path d="M12.195 14.625C11.43 14.625 10.875 15.1725 10.875 15.945C10.875 16.71 11.4225 17.25 12.195 17.25C12.96 17.25 13.5 16.7025 13.5 15.945C13.5 15.1725 12.96 14.625 12.195 14.625Z" />
    </svg>
  );
};
