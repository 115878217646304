import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const ProcessingIcon = React.memo(({ ...props }) => {
  return (
    <StyledProcessingIcon viewBox="0 0 32 32" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1608 13.2135C23.4549 13.2619 23.7121 13.4389 23.8622 13.6963L25.9622 17.2963C26.2405 17.7734 26.0794 18.3857 25.6023 18.664C25.1253 18.9423 24.513 18.7811 24.2347 18.3041L22.7437 15.7482L20.5552 17.6543C20.1388 18.017 19.5071 17.9735 19.1444 17.557C18.7816 17.1405 18.8252 16.5089 19.2417 16.1461L22.3417 13.4461C22.5664 13.2504 22.8667 13.1651 23.1608 13.2135Z"
        fill="inherit"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5015 13.5002C23.0499 13.435 23.5463 13.3394 23.6114 13.8879C23.8768 16.1213 23.4362 18.4338 22.3371 20.2798C21.2288 22.1413 19.4311 23.5486 17.039 23.8329C14.2366 24.1659 11.593 23.1154 9.873 21.2458C9.49907 20.8394 9.52543 20.2068 9.93187 19.8329C10.3383 19.4589 10.9709 19.4853 11.3449 19.8917C12.6315 21.2902 14.6404 22.1038 16.803 21.8468C18.4822 21.6473 19.7752 20.6733 20.6187 19.2566C21.4714 17.8244 21.8438 15.9618 21.6254 14.1239C21.928 13.8879 22.023 13.8879 22.5015 13.5002Z"
        fill="inherit"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51454 13.2259C6.99732 12.9577 7.60613 13.1316 7.87434 13.6144L9.27462 16.1349L11.4434 14.246C11.8599 13.8832 12.4915 13.9268 12.8543 14.3433C13.217 14.7597 13.1734 15.3914 12.757 15.7541L9.65696 18.4541C9.42945 18.6523 9.12464 18.7371 8.82747 18.685C8.5303 18.6329 8.27254 18.4494 8.12602 18.1857L6.12602 14.5857C5.85781 14.1029 6.03176 13.4941 6.51454 13.2259Z"
        fill="inherit"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66449 11.6206C10.7728 9.75909 12.5705 8.35175 14.9626 8.0675C17.7651 7.73449 20.4086 8.78495 22.1286 10.6545C22.5025 11.061 22.4762 11.6936 22.0697 12.0675C21.6633 12.4414 21.0307 12.4151 20.6568 12.0086C19.3701 10.6101 17.3612 9.79655 15.1986 10.0535C13.5194 10.2531 12.2265 11.227 11.383 12.6438C10.5302 14.076 10.1578 15.9386 10.3762 17.7765C9.49976 18.0002 9.54818 18.435 8.99976 18.5002C8.45133 18.5653 8.45535 18.5609 8.39018 18.0125C8.12479 15.7791 8.5654 13.4666 9.66449 11.6206Z"
        fill="inherit"
      />
    </StyledProcessingIcon>
  );
});

const animation = keyframes`
  to {
    transform: rotateZ(-720deg);
  }
`;

const StyledProcessingIcon = styled.svg`
  ${({ withAnimation }) =>
    withAnimation &&
    css`
      animation: ${animation};
      animation-timing-function: ease-in-out;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`;
