export function getClickedPosition(
  event: MouseEvent,
  relatedElement: HTMLElement | null = null
) {
  const { clientX, clientY } = event;
  const target = event.target as HTMLElement;

  if (target) {
    const rect = target.getBoundingClientRect();
    const relatedRect = (relatedElement || target).getBoundingClientRect();

    const x = clientX - rect.x + (rect.x - relatedRect.x);
    const y = clientY - rect.y + (rect.y - relatedRect.y);

    return {
      x,
      y,
      xPercentage: (x / relatedRect.width) * 100,
      yPercentage: (y / relatedRect.height) * 100,
    };
  }
  return null;
}

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export function createCounter(
  time: number,
  updateCallback: (time: number) => void = () => {},
  updatePeriod = 1000
) {
  const startTime = Date.now();
  let counter = time;

  function stopTimer() {
    clearInterval(interval);
  }

  updateCallback(time);
  const interval = setInterval(() => {
    counter = time - (Date.now() - startTime);
    updateCallback(Math.max(counter, 0));
    if (counter <= 0) {
      stopTimer();
    }
  }, updatePeriod);

  return stopTimer;
}

export const buildMediaThumbnailUrl = (mediaId: number, accessToken: string) =>
  `${process.env.REACT_APP_BELIVE_EDITOR_SERVER}/media/${mediaId}/thumbnail?accessToken=${accessToken}`;

export const buildVideoUrl = (sourceId: string | number) =>
  `${process.env.REACT_APP_BELIVE_EDITOR_SERVER}/hls/${sourceId}/broadcast_.m3u8`;

export const createScope = (scopeName: string) => (name: string) =>
  `[${scopeName}] ${name}`;
