import React from 'react';
import { connect } from 'react-redux';
import 'common/Utils.js';
import 'components/blocks/Timeline/TimelineUndoRedo/TimelineUndoRedo.css';

import { TimeLineTrackTrimmRedo, TimeLineTrackTrimmUndo } from 'common/Redux';
import {
  StyledButton,
  StyledRedoIcon,
  StyledUndoIcon,
} from 'components/blocks/Timeline/TimelineUndoRedo/styles';
import { BUTTON_SIZES_ENUM } from 'components/ui/Button/types';

let index = 0;
let ActionsDictionary = [];

class TimeLineUndoRedo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bDisableRedo: true,
      bDisableUndo: true,
    };
  }

  componentDidMount = async () => {
    ActionsDictionary.push({ start: 0, end: 2 });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.TimeLine.startTrimmPos !== this.props.TimeLine.startTrimmPos ||
      prevProps.TimeLine.endTrimmPos !== this.props.TimeLine.endTrimmPos
    ) {
      //Check that arrived value is different than the value we updated from here.

      //Add/Update changes to Dictionary
      // console.log("ActionsDictionary.length:",ActionsDictionary.length);
      if (index >= ActionsDictionary.length) {
        ActionsDictionary.push({
          start: this.props.TimeLine.startTrimmPos,
          end: this.props.TimeLine.endTrimmPos,
        });
        index++;
        this.setState({ bDisableUndo: false });
      } else {
        // console.log("Index: ",index, " , " , ActionsDictionary[index]);
        ActionsDictionary[index] = {
          start: this.props.TimeLine.startTrimmPos,
          end: this.props.TimeLine.endTrimmPos,
        };
        index++;
        this.setState({ bDisableUndo: false });
      }
    }
  };

  render = () => {
    const isUndoDisabled = this.props.TimeLine.actionHistoryIndex < 0;
    const isRedoDisabled =
      this.props.TimeLine.actionHistoryIndex >=
      this.props.TimeLine.actionHistory.length - 1;

    return (
      <div className="TimeLineUndoRedo">
        <StyledButton
          size={BUTTON_SIZES_ENUM.XS}
          onClick={this.handleUnDo}
          disabled={isUndoDisabled}
        >
          <StyledUndoIcon />
          <span>Undo</span>
        </StyledButton>
        <StyledButton
          size={BUTTON_SIZES_ENUM.XS}
          onClick={this.handleReDo}
          disabled={isRedoDisabled}
        >
          <span>Redo</span>
          <StyledRedoIcon />
        </StyledButton>
      </div>
    );
  };

  handleUnDo = () => {
    index--;
    if (index <= 0) {
      index = 0;
      this.setState({ bDisableUndo: true });
    }

    if (index < ActionsDictionary.length && ActionsDictionary.length > 1) {
      this.setState({ bDisableRedo: false });
    }

    let start = ActionsDictionary[index].start;
    let end = ActionsDictionary[index].end;
    this.props.TimeLineTrackTrimmUndo(start, end);
  };

  handleReDo = () => {
    index++;
    if (index >= ActionsDictionary.length) {
      index = ActionsDictionary.length - 1;
      this.setState({ bDisableRedo: true });
    }
    let start = ActionsDictionary[index].start;
    let end = ActionsDictionary[index].end;
    this.props.TimeLineTrackTrimmRedo(start, end);
  };
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    TimeLineTrackTrimmUndo: (start, end) =>
      dispatch(TimeLineTrackTrimmUndo(start, end)),
    TimeLineTrackTrimmRedo: (start, end) =>
      dispatch(TimeLineTrackTrimmRedo(start, end)),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(TimeLineUndoRedo);
