import styled from 'styled-components';
import { colors } from 'common/colors';
import { scrollMixin } from 'components/ui/CustomScroll/scrollMixin';
import { MAX_RIGHT_PANEL_WIDTH } from 'components/pages/HomePage/constants';

export const StyledLayout = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  max-height: 100vh;
  padding: 32px 72px;
  ${scrollMixin(colors.lightgray)};
`;

export const StyledSidebar = styled.div`
  width: ${MAX_RIGHT_PANEL_WIDTH}px;
`;

export const StyledSection = styled.div`
  width: 100%;
  max-width: 720px;

  &:not(:last-child) {
    margin-bottom: 56px;
  }
`;

export const StyledSectionTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 140px;
  box-sizing: border-box;
  background: ${colors.white};
  border-radius: 8px;
  padding: 20px;
  user-select: none;
`;
