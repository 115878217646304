import styled from 'styled-components';
import VideoPlayer from 'react-player';
import { PreviewOverlayContainer } from 'components/blocks/PreviewOverlay';
import { opacity } from 'utils/styles';
import { colors } from 'common/colors';
import { InteractiveProgressBar } from 'components/ui/InteractiveProgressBar';
import { StyledInteractiveProgressBar } from 'components/ui/InteractiveProgressBar/styles';

export const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100% !important;
  height: 100% !important;
`;

export const StyledPlayPauseOverlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  &:hover {
    opacity: 1;
  }
`;

export const StyledPlayPauseButton = styled.div`
  width: 56px;
  height: 56px;
  margin: auto;
  background-color: ${opacity(colors.black, 0.7)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: ${colors.white};
    margin: auto;
  }
`;

export const StyledProgressBar = styled(InteractiveProgressBar)`
  position: absolute;
  bottom: 0;
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: ${opacity(colors.black, 0.1)};
  box-shadow: 0 0 8px ${opacity(colors.black, 0.1)};

  ${StyledInteractiveProgressBar} {
    border-radius: 4px;
  }
`;
