import styled, { css } from 'styled-components';
import { conditionalStyle } from 'utils/styles';

export const StyledInteractiveProgressBarContainer = styled.div<{
  onMouseMove: any;
  ref: any;
}>``;

export const StyledInteractiveProgressBar = styled.div.attrs(
  ({ progress }: { progress: number }) => ({
    style: { width: `${progress * 100}%` },
  })
)<{ color: string; animated: boolean; progress: number }>`
  background-color: ${({ color }) => color};
  height: 100%;

  ${conditionalStyle({
    animated: css`
      transition: width 0.1s;
    `,
  })}
`;
