import React from 'react';
import { connect } from 'react-redux';
import Track from 'components/blocks/Timeline/TracksArea/Track/TimelineTrack';
import 'components/blocks/Timeline/TracksArea/TimelineTracksArea.css';

const TimelineTracksArea = (props) => {
  return (
    <div className="TracksAreaContainer">
      <div className="TracksArea">
        <Track.TimelineTrack
          timeline={props.timeline}
          changeTimeline={props.changeTimeline}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TimelineTracksArea);
