type openUrl = (
  url: string,
  options?: { newTab: true } | { newTab: false; delay?: number }
) => void;

export const openUrl: openUrl = (
  url,
  options = {
    newTab: false,
  }
) => {
  if (options.newTab) {
    window.open(url, '_blank');
  } else {
    setTimeout(() => (window.location.href = url), options.delay ?? 0);
  }
};

export function openPricingPage() {
  openUrl(
    `${process.env.REACT_APP_BELIVE_BE_URL}/packages`, { newTab: true }
  );
}
