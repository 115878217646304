import React, { FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { filter, map, size } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  attachMediaToProject,
  updateProject,
} from 'common/Redux/Project/actions';
import { selectActiveProject } from 'common/Redux/Project/selectors';
import { GlobalState } from 'common/Redux/types';
import { Media, MediaCollection, MediaType, Project } from 'common/types';
import {
  StyledMedia,
  StyledMediaButtonGroup,
  StyledMediaContent,
  StyledMediaContentHeader,
  StyledMediaTabs,
  StyledMediaWrapper,
  StyledProjectName,
  StyledTab,
  StyledTabContent,
  StyledInputContainer,
} from './styles';
import { Tabs } from 'components/ui/Tabs';
import { selectUserMediaCollection } from 'common/Redux/User/selectors';
import { MediaPlaceholder } from 'components/pages/HomePage/components/MediaPlaceholder';
import { deleteUserMedia } from 'common/Redux/User/actions';
import { buildMediaThumbnailUrl } from 'utils/helpers';
import { getAccessTokenSelector } from 'common/Redux/Auth/selectors';
import { RenderIfShoppable } from '../../../../ui/RenderIfShoppable';

enum TabTypes {
  All = 'All',
  Broadcasts = 'Broadcasts',
  Uploads = 'Uploads',
}

export const MediaContent: FC<{
  activeProject: Project | null;
  updateProject: (payload: {
    projectId: number;
    name?: string;
    title?: string;
  }) => void;
  mediaCollection: MediaCollection;
  deleteUserMedia: (payload: { id: number }) => void;
  attachMediaToProject: (payload: {
    projectId: number;
    mediaId: number;
  }) => void;
  accessToken: string;
}> = ({
  activeProject,
  updateProject,
  mediaCollection,
  deleteUserMedia,
  attachMediaToProject,
  accessToken,
}) => {
  const [activeTab, setActiveTab] = useState(TabTypes.All);
  const hasAnyMedia = size(mediaCollection) > 0;
  const collectionMap = useMemo(
    () => ({
      [TabTypes.All]: mediaCollection,
      [TabTypes.Broadcasts]: filter(
        mediaCollection,
        (media) => media.type === MediaType.Broadcast
      ),
      [TabTypes.Uploads]: filter(
        mediaCollection,
        (media) => media.type !== MediaType.Broadcast
      ),
    }),
    [mediaCollection]
  );

  return (
    <StyledMediaContent>
      <StyledMediaContentHeader>
        <StyledInputContainer>
          <label>
            Project name:
          </label>
          {activeProject?.name && (
              <StyledProjectName
                  name={activeProject.name}
                  onChange={(name: string) =>
                      updateProject({ projectId: activeProject.id, name })
                  }
              />
          )}
        </StyledInputContainer>
        <RenderIfShoppable>
          <StyledInputContainer>
            <label>
              Title:
            </label>
            {activeProject && (
                <StyledProjectName
                    name={activeProject.title}
                    onChange={(title: string) =>
                        updateProject({ projectId: activeProject.id, title })
                    }
                />
            )}
          </StyledInputContainer>
        </RenderIfShoppable>
        {hasAnyMedia && <StyledMediaButtonGroup />}
      </StyledMediaContentHeader>
      <Tabs activeTabId={activeTab}>
        <StyledMediaTabs>
          {Object.values(TabTypes).map((type, i) => (
            <Tabs.Tab tabId={type} key={i}>
              {(props: { isActive: boolean }) => (
                <StyledTab
                  active={props.isActive}
                  onClick={() => setActiveTab(type)}
                >
                  {type}
                </StyledTab>
              )}
            </Tabs.Tab>
          ))}
        </StyledMediaTabs>
        <StyledTabContent>
          {hasAnyMedia ? (
            Object.keys(TabTypes).map((type, i) => (
              <Tabs.TabContent tabId={type} key={i}>
                <StyledMediaWrapper>
                  {map(collectionMap[activeTab], (media: Media) => {
                    const img = media.processing
                      ? undefined
                      : buildMediaThumbnailUrl(media.id, accessToken);

                    return (
                      <StyledMedia
                        name={media.name}
                        img={img}
                        key={media.id}
                        processing={media.processing}
                        isStreamReady={media.isStreamReady}
                        onDelete={() => deleteUserMedia({ id: media.id })}
                        onUse={() =>
                          attachMediaToProject({
                            projectId: activeProject!.id,
                            mediaId: media.id,
                          })
                        }
                      />
                    );
                  })}
                </StyledMediaWrapper>
              </Tabs.TabContent>
            ))
          ) : (
            <MediaPlaceholder />
          )}
        </StyledTabContent>
      </Tabs>
    </StyledMediaContent>
  );
};

const mapState = createStructuredSelector<
  GlobalState,
  {
    activeProject: ReturnType<typeof selectActiveProject>;
    mediaCollection: MediaCollection;
    accessToken: string;
  }
>({
  activeProject: selectActiveProject,
  mediaCollection: selectUserMediaCollection,
  accessToken: getAccessTokenSelector,
});

const mapDispatch = {
  updateProject,
  deleteUserMedia,
  attachMediaToProject,
};

export const MediaContentContainer = connect(
  mapState,
  mapDispatch
)(MediaContent);
