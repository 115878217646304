import { PreviewColorTheme, PreviewColorThemeType } from 'components/blocks/PreviewThemes/types';
import { colors } from 'common/colors';
import whiteBackground from 'components/blocks/PreviewThemes/content/white.png';
import blackBackground from 'components/blocks/PreviewThemes/content/black.png';
import orangeBackground from 'components/blocks/PreviewThemes/content/orange.png';
import ny2 from 'components/blocks/PreviewThemes/content/ny2.png';
import ny5 from 'components/blocks/PreviewThemes/content/ny5.png';
import ny6 from 'components/blocks/PreviewThemes/content/ny6.png';
import simple1 from 'components/blocks/PreviewThemes/content/simple1.jpg';
import simple3 from 'components/blocks/PreviewThemes/content/simple3.jpg';
import simple4 from 'components/blocks/PreviewThemes/content/simple4.jpg';
import summer1 from 'components/blocks/PreviewThemes/content/summer1.jpg';
import summer2 from 'components/blocks/PreviewThemes/content/summer2.jpg';
import more1 from 'components/blocks/PreviewThemes/content/more1.jpg';
import { opacity } from 'utils/styles';

const FOREGROUND_OPACITY = 0.5;

export const previewColorThemes: Record<PreviewColorThemeType, PreviewColorTheme> = {
  [PreviewColorThemeType.Black]: {
    textColor: colors.white,
    backgroundColor: colors.black,
    backgroundImage: blackBackground,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.White]: {
    textColor: colors.black,
    backgroundColor: colors.white,
    backgroundImage: whiteBackground,
    shadowColor: opacity(colors.grey, 0.6),
  },
  [PreviewColorThemeType.Orange]: {
    textColor: colors.white,
    backgroundColor: colors.yellow,
    backgroundImage: orangeBackground,
    shadowColor: opacity(colors.vine, 0.4),
  },
  [PreviewColorThemeType.NewYear2]: {
    isNew: true,
    textColor: colors.yellow,
    foregroundColor: opacity(colors.darkVine, FOREGROUND_OPACITY),
    backgroundColor: colors.darkVine,
    backgroundImage: ny2,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.NewYear5]: {
    isNew: true,
    textColor: colors.vine,
    foregroundColor: opacity(colors.white, FOREGROUND_OPACITY),
    backgroundColor: '#eff3f4',
    backgroundImage: ny5,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.NewYear6]: {
    isNew: true,
    textColor: colors.white,
    foregroundColor: opacity(colors.darkBlue, FOREGROUND_OPACITY),
    backgroundColor: colors.darkBlue,
    backgroundImage: ny6,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.Simple1]: {
    isNew: true,
    textColor: colors.darkPurple,
    backgroundColor: colors.lightblue,
    backgroundImage: simple1,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.Simple3]: {
    isNew: true,
    textColor: colors.white,
    backgroundColor: colors.purple,
    backgroundImage: simple3,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.Simple4]: {
    isNew: true,
    textColor: colors.white,
    backgroundColor: colors.darkMagenta,
    backgroundImage: simple4,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.Summer1]: {
    isNew: true,
    textColor: colors.darkPurple,
    backgroundColor: colors.lightGreen,
    backgroundImage: summer1,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.Summer2]: {
    isNew: true,
    textColor: colors.darkPurple,
    backgroundColor: colors.lightPink,
    backgroundImage: summer2,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.More1]: {
    isNew: true,
    textColor: colors.darkPurple,
    backgroundColor: colors.lightYellow,
    backgroundImage: more1,
    shadowColor: opacity(colors.almostBack, 0.3),
  },
  [PreviewColorThemeType.Custom]: {
    textColor: colors.white,
    foregroundColor: opacity(colors.darkBlue, FOREGROUND_OPACITY),
    backgroundColor: colors.darkBlue,
    backgroundImage: '',
    shadowColor: opacity(colors.almostBack, 0.3),
  },
};

export const previewColorThemesOrder: PreviewColorThemeType[] = [
  PreviewColorThemeType.Black,
  PreviewColorThemeType.White,
  PreviewColorThemeType.Orange,
  PreviewColorThemeType.NewYear2,
  PreviewColorThemeType.NewYear5,
  PreviewColorThemeType.NewYear6,
  PreviewColorThemeType.Simple1,
  PreviewColorThemeType.Simple3,
  PreviewColorThemeType.Simple4,
  PreviewColorThemeType.Summer1,
  PreviewColorThemeType.Summer2,
  PreviewColorThemeType.More1,
]
