import styled from 'styled-components';
import { EmojiIcon } from 'components/ui/Icons/EmojiIcon';
import { colors } from 'common/colors';

export const StyledEmojiIcon = styled(EmojiIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: ${colors.steel};
`;
