import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { noop } from 'lodash';
import { AngleIcon } from 'components/ui/Icons/AngleIcon';
import { ReactionsIcon } from 'components/ui/Icons/ReactionsIcon';
import { CrossArrowIcon } from 'components/ui/Icons/CrossArrowIcon';

export const TryBroadcastAnalytics: FC<{
  onClick: () => any;
  onClose: () => any;
}> = ({ onClick = noop, onClose = noop }) => {
  return (
    <StyledWrapper>
      <StyledTryBroadcastAnalytics onClick={onClick}>
        <StyledIcons />
        <StyledContent>
          <StyledTitle>
            Edit your broadcasts efficiently: learn about Activity Timeline
            <StyledAngleIcon />
          </StyledTitle>
          <StyledDescription>
            See your analytics, audience’s feedback, Studio events and more
            right on the timeline in Editor!
          </StyledDescription>
        </StyledContent>
        <StyledCloseIcon onClick={onClose} />
      </StyledTryBroadcastAnalytics>
    </StyledWrapper>
  );
};

const StyledIcons = styled(ReactionsIcon)`
  width: 300px;
  transition: transform 0.2s;
  margin: -20px 0;
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const StyledDescription = styled.div`
  font-size: 12px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.summerRabbit};
`;

const StyledTryBroadcastAnalytics = styled.div`
  position: relative;
  height: 64px;
  border-radius: 8px;
  display: flex;
  background-color: ${colors.white};
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 16px 0 ${colors.lightgray};

    ${StyledIcons} {
      transform: translate(-1px, -2px);
    }
  }
`;

const StyledWrapper = styled.div`
  padding: 8px;
  background-color: ${colors.winterRabbit};
`;

export const StyledAngleIcon = styled(AngleIcon)`
  width: 5px;
  height: 12px;
  margin-left: 18px;
  stroke: ${colors.grey};
`;

export const StyledCloseIcon = styled(CrossArrowIcon)`
  width: 11px;
  position: absolute;
  right: 10px;
  top: 10px;
  stroke: ${colors.grey};

  &:hover {
    stroke: ${colors.lightgray};
  }
`;
