import styled from 'styled-components';

export const StyledPreviewProgressWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 100%;
  background: #363636;
`;

export const StyledPreviewProgressBar = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: #00c853;
`;
