import {
  PREVIEW_CONTROL_NOT_ENDED,
  PREVIEW_CONTROL_PAUSE,
  PREVIEW_CONTROL_PLAY,
  PREVIEW_SEEK_TO,
  SET_PROJECT_OVERLAY,
  SET_COLOR_THEME,
  SET_TITLES_ACTION,
  SET_ASPECT_RATIO,
  SET_VIDEO_RECT
} from './PreviewTypes';
import { action } from '../actions';
import { selectIsPlaying } from 'common/Redux/Preview/selectors';

export const PreviewSeekTo = (seekToPosition) => {
  console.log('PreviewSeekTo');
  return (dispatch) => {
    try {
      dispatch(_PreviewSeekTo(PREVIEW_SEEK_TO, seekToPosition));
    } catch (e) {
      console.log(e);
    }
  };
};

export const togglePreviewPlayingState = (forcePause = false) => {
  return (dispatch, getState) => {
    const isPlaying = selectIsPlaying(getState());

    if (forcePause || isPlaying) {
      dispatch(pausePreviewPlayer());
    } else {
      dispatch(resumePreviewPlayer());
    }
  };
};

export const resumePreviewPlayer = action(PREVIEW_CONTROL_PLAY);
export const pausePreviewPlayer = action(PREVIEW_CONTROL_PAUSE);

export const PreviewControlsNotEnded = () => {
  return (dispatch) => {
    try {
      dispatch(_PreviewControlsNotEnded(PREVIEW_CONTROL_NOT_ENDED));
    } catch (e) {
      console.log(e);
    }
  };
};

export const _PreviewSeekTo = (_type, _seekToPosition) => {
  return {
    type: _type,
    payload: _type,
    seekToPosition: _seekToPosition,
  };
};

export const _PreviewControlsNotEnded = (_type) => {
  return {
    type: _type,
    payload: _type,
  };
};

export function setOverlayType(payload) {
  return {
    type: SET_PROJECT_OVERLAY,
    payload,
  };
}

export function setAspectRatio(payload) {
  return {
    type: SET_ASPECT_RATIO,
    payload,
  };
}

export function setColorThemeType(payload) {
  return {
    type: SET_COLOR_THEME,
    payload,
  };
}

export function setVideoRect(payload) {
  return {
    type: SET_VIDEO_RECT,
    payload,
  };
}

export const setPreviewTitles = action(SET_TITLES_ACTION);
