import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const LiveIcon = React.memo(({ ...props }) => {
  return (
    <StyledLiveIcon viewBox="0 0 32 32" {...props}>
      <circle
        cx="16.1411"
        cy="16.1421"
        r="3"
        transform="rotate(-45 16.1411 16.1421)"
        fill="inherit"
      />
      <path
        d="M20.3842 11.8994C22.7273 14.2426 22.7273 18.0416 20.3842 20.3847M11.8989 20.3847C9.55574 18.0416 9.55574 14.2426 11.8989 11.8994"
        fill="none"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.2132 9.07102C27.1184 12.9763 27.1184 19.3079 23.2132 23.2132M9.07107 23.2132C5.16582 19.3079 5.16582 12.9763 9.07107 9.07102"
        fill="none"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </StyledLiveIcon>
  );
});

const animation = keyframes`
  to {
    opacity: 0.3;
  }
`;

const liveStyle = css`
  animation: ${animation};
  animation-direction: alternate;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const StyledLiveIcon = styled.svg`
  ${({ withAnimation }) =>
    withAnimation &&
    css`
      circle {
        ${liveStyle};
      }
      path:nth-of-type(1) {
        ${liveStyle};
        animation-delay: 0.3s;
      }
      path:nth-of-type(2) {
        ${liveStyle};
        animation-delay: 0.6s;
      }
    `};
`;
