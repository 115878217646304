import React from 'react';

export const VideoIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.72 10.32L17.6 8.16001C17.6494 8.11056 17.6989 8.09168 17.7672 8.06557C17.8095 8.04944 17.8589 8.03055 17.92 8L17.92 8.00002C18 8.08001 18.08 8.16001 18.08 8.32V15.68C18.08 15.84 18 15.92 17.92 16L17.92 16L17.92 16C17.84 16.08 17.84 16.08 17.76 16.08C17.72 16.08 17.7 16.06 17.68 16.04C17.66 16.02 17.64 16 17.6 16L14.72 13.44V14.48C14.72 15.36 14 16.08 13.12 16.08H7.6C6.72 16.08 6 15.36 6 14.48V9.6C6 8.72 6.72 8 7.6 8H13.12C14 8 14.72 8.72 14.72 9.6V10.32ZM10.3333 13.3333C11.0697 13.3333 11.6667 12.7364 11.6667 12C11.6667 11.2636 11.0697 10.6667 10.3333 10.6667C9.59695 10.6667 9 11.2636 9 12C9 12.7364 9.59695 13.3333 10.3333 13.3333Z"
        fill="inherit"
      />
    </svg>
  );
});
