import React from 'react';
import styled from 'styled-components';

export const DevModeIndicator = () => {
  return (
    <StyledDevModeWrapper>
      <StyledDevModeIndicator>
        Running in unsecure development mode
      </StyledDevModeIndicator>
    </StyledDevModeWrapper>
  );
};

const StyledDevModeWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid darkred;
  box-sizing: border-box;
  opacity: 0.8;
  pointer-events: none;
  z-index: 999999;
`;

const StyledDevModeIndicator = styled.div`
  position: absolute;
  padding: 0 8px 4px 4px;
  color: white;
  font-size: 12px;
  border-radius: 0 0 8px;
  font-weight: bold;
  background-color: darkred;
  white-space: nowrap;
`;
