import React, { FC } from 'react';

export const UploadIcon: FC = ({ ...props }) => {
  return (
    <svg viewBox="0 0 24 24" {...props} stroke="none">
      <path d="M15 20.25H9C6.7275 20.25 4.875 18.3975 4.875 16.125V14.25C4.875 13.6275 5.3775 13.125 6 13.125C6.6225 13.125 7.125 13.6275 7.125 14.25V16.125C7.125 17.16 7.965 18 9 18H15C16.035 18 16.875 17.16 16.875 16.125V14.25C16.875 13.6275 17.3775 13.125 18 13.125C18.6225 13.125 19.125 13.6275 19.125 14.25V16.125C19.125 18.3975 17.2725 20.25 15 20.25Z" />
      <path d="M12 16.5C11.3775 16.5 10.875 15.9975 10.875 15.375V6.75C10.875 6.1275 11.3775 5.625 12 5.625C12.6225 5.625 13.125 6.1275 13.125 6.75V15.375C13.125 15.9975 12.6225 16.5 12 16.5Z" />
      <path d="M15 8.99992C14.715 8.99992 14.4225 8.88742 14.205 8.66992L12 6.46492L9.79504 8.66992C9.35254 9.11242 8.64004 9.11242 8.20504 8.66992C7.77005 8.22742 7.76255 7.51492 8.20504 7.07992L11.205 4.07992C11.6475 3.63742 12.36 3.63742 12.795 4.07992L15.795 7.07992C16.2375 7.52242 16.2375 8.23492 15.795 8.66992C15.5775 8.88742 15.285 8.99992 15 8.99992Z" />
    </svg>
  );
};
