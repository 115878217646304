import React from 'react';
import styled, { css } from 'styled-components';

import { BinIcon } from 'components/ui/Icons/BinIcon';
import { colors } from 'common/colors';

const activeStyles = css`
  background: ${colors.watermelon};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
`;

export const DeleteButton = styled(({ active, ...rest }) => (
  <BinIcon {...rest} />
))`
  width: 24px;
  height: 24px;
  fill: ${colors.white};
  border-radius: 4px;
  cursor: pointer;

  ${({ active }) => active && activeStyles}

  :hover {
    ${activeStyles}
  }
`;
