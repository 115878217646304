import React, { FC } from 'react';
import styled from 'styled-components';
import loaderImage from 'common/Images/loader.png';

export const Loader: FC<{ className?: string }> = ({ className }) => (
  <StyledLoader className={className} />
);

export const StyledLoader = styled.div`
  width: 32px;
  height: 32px;
  margin: auto;
  background-image: url("${loaderImage}");
  background-repeat: no-repeat;
  background-size: contain;
`;
