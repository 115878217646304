import React, { useCallback, useRef } from 'react';
import {
  StyledEvent,
  StyledEventsTimelineArea,
  StyledLeftCircle,
  StyledLine,
  StyledPeakEvent,
  StyledRightCircle,
} from 'components/blocks/EventsTimeline/components/EventsTimelineArea/styles';
import { EVENT_ICONS } from 'components/blocks/EventsTimeline/constants';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { getClickedPosition } from 'utils/helpers';

export const EventsTimelineArea = ({
  events,
  peakEvents,
  duration,
  TimelinePlayedPosition,
  PreviewSeekTo,
  ...restProps
}) => {
  const ref = useRef();
  const setProgressTimeline = useCallback(
    (percentage) => {
      //Write event to analytics
      analytics.trackEvent(analyticsEvents.EVENTS_CLICKED);
      const progress = (percentage * duration) / 100;
      TimelinePlayedPosition(progress);
      PreviewSeekTo(progress);
    },
    [TimelinePlayedPosition, PreviewSeekTo, duration]
  );

  const onMouseDown = useCallback(
    (event) => {
      if (ref.current === event.target) {
        const { xPercentage } = getClickedPosition(event);
        setProgressTimeline(xPercentage);
      }
    },
    [setProgressTimeline]
  );
  return (
    <StyledEventsTimelineArea
      ref={ref}
      {...restProps}
      onMouseDown={onMouseDown}
    >
      <StyledLine />
      <StyledLeftCircle />
      <StyledRightCircle />
      {peakEvents.map((eventProps) => {
        const { eventType, position, size } = eventProps;
        const key = eventType + position + size;
        return (
          <StyledPeakEvent
            key={key}
            onClick={() => setProgressTimeline(position)}
            {...eventProps}
          />
        );
      })}
      {events
        .filter(({ eventType }) => Boolean(EVENT_ICONS[eventType]))
        .map((eventProps) => {
          const { eventType, position } = eventProps;
          const key = eventType + position;
          return (
            <StyledEvent
              key={key}
              onClick={() => setProgressTimeline(position)}
              {...eventProps}
            />
          );
        })}
    </StyledEventsTimelineArea>
  );
};
