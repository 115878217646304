import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/ui/Button';
import { colors } from 'common/colors';
import { openStudio } from 'components/pages/HomePage/services';
import { ButtonType } from 'components/ui/Button/types';

export const EventsGotoStudioMessage = ({
  filters,
  onFilterChange,
  ...restProps
}) => {
  return (
    <StyledEventsGotoStudioMessage {...restProps}>
      <StyledWrapper>
        <StyledCaption>
          It’s seems like there’re no comments or reactions to show here.
        </StyledCaption>
        <StyledLine>
          <StyledGotoStudioButton
            type={ButtonType.Secondary}
            onClick={() => openStudio('Broadcast')}
          >
            Create New Stream
          </StyledGotoStudioButton>
          <StyledCaption>
            and we’ll show how your audience performed during your show.
          </StyledCaption>
        </StyledLine>
      </StyledWrapper>
    </StyledEventsGotoStudioMessage>
  );
};

const StyledEventsGotoStudioMessage = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  color: ${colors.darkCyan};
  align-items: center;
  pointer-events: none;
`;

const StyledWrapper = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  font-weight: normal;
  flex-direction: column;
`;

const StyledLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledCaption = styled.div`
  color: ${colors.summerRabbit};
  margin: 0 8px;
  white-space: nowrap;
`;

export const StyledGotoStudioButton = styled(Button)`
  display: flex;
  white-space: nowrap;
  pointer-events: initial;
`;
