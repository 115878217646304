import { hotjar } from 'react-hotjar';

let HotJarId = process.env.REACT_APP_BELIVE_HOTJAR_ID;
let HotJarSv = process.env.REACT_APP_BELIVE_HOTJAR_SV;

const HotJarInit = () => {
  //Initialize hotjar
  hotjar.initialize(HotJarId, HotJarSv);
};

export { HotJarInit };
