import { GlobalState } from 'common/Redux/types';
import { createSelector, Selector } from 'reselect';

export const selectActiveToolbox: Selector<
  GlobalState,
  GlobalState['toolbox']
> = (state) => state.toolbox;

export const selectActiveToolboxItem = (state: GlobalState) =>
  selectActiveToolbox(state).activeItem;
