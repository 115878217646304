import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserFeatures } from '../../types';
import { GlobalState } from '../types';

type FeaturesState = Partial<Record<UserFeatures, boolean>>;

const initialState: FeaturesState = {};

export const FEATURES_REDUCER_NAME = 'features';

export const featuresSlice = createSlice({
  name: FEATURES_REDUCER_NAME,
  initialState,
  reducers: {
    saveFeaturesAction: (state, action: PayloadAction<FeaturesState>) => {
      return action.payload;
    },
  },
});

export const { saveFeaturesAction } = featuresSlice.actions;

export const getFeaturesSelector = (state: GlobalState) => state[FEATURES_REDUCER_NAME];

export const featuresReducer = featuresSlice.reducer;
