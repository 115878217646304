import React from 'react';

export const Back15Icon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M3 13C3 14.78 3.52784 16.5201 4.51677 18.0001C5.5057 19.4802 6.91131 20.6337 8.55585 21.3149C10.2004 21.9961 12.01 22.1743 13.7558 21.8271C15.5016 21.4798 17.1053 20.6226 18.364 19.364C19.6226 18.1053 20.4798 16.5016 20.8271 14.7558C21.1743 13.01 20.9961 11.2004 20.3149 9.55585C19.6337 7.91131 18.4802 6.50571 17.0001 5.51677C15.5201 4.52784 13.78 4 12 4H9M9 4L12 1M9 4L12 7"
        fill="none"
        stroke="inherit"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0529 17V9.66846H8.61342L6.87855 10.9146V12.2466L8.54359 11.0649H8.61342V17H10.0529ZM11.9115 15.1201C11.9598 16.2695 12.975 17.1396 14.5057 17.1396C16.1493 17.1396 17.2665 16.1191 17.2665 14.54C17.2665 13.0146 16.1761 12.1606 14.9139 12.1606C14.0868 12.1606 13.5873 12.5474 13.4422 12.7837H13.3671L13.5282 10.8394H16.8583V9.66846H12.368L12.0619 13.9438H13.3295C13.4852 13.6538 13.8827 13.2671 14.5326 13.2671C15.263 13.2671 15.8646 13.7827 15.8646 14.5884C15.8646 15.48 15.2254 15.9956 14.5326 15.9956C13.9256 15.9956 13.3509 15.7002 13.2435 15.1201H11.9115Z"
        stroke="none"
        fill="inherit"
      />
    </svg>
  );
};
