import React from 'react';

export const MybroadcastsIcon = React.memo(({ isHover, ...otherProps }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...otherProps}>
      <path
        d="M12 23H7.48C5.56 23 4 21.44 4 19.53V8.47C4 6.55 5.56 5 7.48 5H24.53C26.45 5 28 6.56 28 8.47V19.03C28 19.58 27.55 20.03 27 20.03C26.45 20.03 26 19.58 26 19.03V8.47C26 7.66 25.34 7 24.53 7H7.48C6.66 7 6 7.66 6 8.47V19.52C6 20.33 6.66 20.99 7.48 20.99H12C12.55 20.99 13 21.44 13 21.99C13 22.54 12.55 23 12 23Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M27 27H14C13.45 27 13 26.55 13 26C13 22.26 14.71 20.5 16.57 19.69C16.2 19.03 16 18.28 16 17.5C16 15.02 18.02 13 20.5 13C22.98 13 25 15.02 25 17.5C25 18.31 24.79 19.09 24.39 19.77C26.73 20.84 28 23 28 26C28 26.55 27.55 27 27 27ZM15.05 25H25.94C25.67 22.93 24.52 21.69 22.45 21.22C22.08 21.13 21.78 20.84 21.7 20.47C21.62 20.1 21.75 19.71 22.05 19.47C22.65 18.99 23 18.28 23 17.51C23 16.13 21.88 15.01 20.5 15.01C19.12 15.01 18 16.13 18 17.51C18 18.26 18.33 18.96 18.91 19.43C19.21 19.68 19.34 20.07 19.24 20.44C19.14 20.81 18.85 21.1 18.47 21.18C16.4 21.6 15.3 22.83 15.05 25Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M24 11H8C7.45 11 7 10.55 7 10C7 9.45 7.45 9 8 9H24C24.55 9 25 9.45 25 10C25 10.55 24.55 11 24 11Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M14 15H8C7.45 15 7 14.55 7 14C7 13.45 7.45 13 8 13H14C14.55 13 15 13.45 15 14C15 14.55 14.55 15 14 15Z"
        fill="inherent"
        stroke="none"
      />
      <path
        d="M14 19H8C7.45 19 7 18.55 7 18C7 17.45 7.45 17 8 17H14C14.55 17 15 17.45 15 18C15 18.55 14.55 19 14 19Z"
        fill="inherent"
        stroke="none"
      />
    </svg>
  );
});
