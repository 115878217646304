import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { VideoRecord } from 'components/blocks/VideoRecord';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { requests } from 'common/requests';
import { MediaType, Project } from 'common/types';
import { processManager } from 'services/processes';
import { FullscreenPopup } from 'components/ui/FullscreenPopup';
import { createStructuredSelector } from 'reselect';
import { GlobalState } from 'common/Redux/types';
import { selectActiveProject } from 'common/Redux/Project/selectors';
import { selectUserId } from 'common/Redux/Auth/selectors';
import { ProcessStatus } from 'services/processes/types';

export const VideoRecordingPopup: FC<{
  activeProject: Project | null;
  userId: number;
  isVisible: boolean;
  onClose: () => void;
  onVideoRecorded: (success: boolean) => void;
}> = ({ isVisible, onClose, onVideoRecorded, activeProject, userId }) => {
  const [isVideoSending, setIsVideoSending] = useState(false);

  return (
    <FullscreenPopup isVisible={isVisible} customPopup>
      <VideoRecord
        isVisible={isVisible}
        isLoading={isVideoSending}
        onClose={onClose}
        onVideoRecorded={async (stream) => {
          analytics.trackEvent(analyticsEvents.INSERT_RECORDING);
          setIsVideoSending(true);
          const { processKey } = await requests.createMedia({
            type: MediaType.Recording,
            projectId: activeProject?.id,
            file: stream,
          });

          const process = await processManager.createProcess(
            userId,
            processKey as string
          );

          await process.end;
          setIsVideoSending(false);
          onVideoRecorded(process.status === ProcessStatus.DONE);
        }}
      />
    </FullscreenPopup>
  );
};

const mapState = createStructuredSelector<
  GlobalState,
  {
    activeProject: ReturnType<typeof selectActiveProject>;
    userId: ReturnType<typeof selectUserId>;
  }
>({
  activeProject: selectActiveProject,
  userId: selectUserId,
});

export const VideoRecordingPopupContainer = connect(mapState)(
  VideoRecordingPopup
);
