import styled, { css } from 'styled-components';

import { colors } from 'common/colors';
import * as typography from 'common/typography.js';
import { SimpleButton } from 'components/ui/SimpleButton';
import { scrollMixin } from 'components/ui/CustomScroll/scrollMixin';
import { BaseTextAreaComponent } from 'components/ui/TextArea/BaseTextAreaComponent';
import { conditionalStyle } from 'utils/styles';

export const StyledTextArea = styled(BaseTextAreaComponent)`
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.active ? colors.yellow : colors.lightgray3};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  ${scrollMixin()};

  &:focus {
    border-color: ${(props) =>
      props.active ? colors.yellow : colors.steel};
  }
`;
export const StyledTextAreaWrapper = styled.div`
  /*display: inline-block;*/
  display: flex;
  position: relative;
  min-width: 100px;
  min-height: 64px;
`;
export const StyledButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 8px;
  right: 10px;
  height: 16px;
  width: auto;
  margin-left: 6px;

  ${conditionalStyle({
    disabled: css`
      pointer-events: none;
    `,
  })};
`;

export const StyledSendButton = styled(SimpleButton)`
  color: ${colors.textAreaSendButtonText};
  font-family: 'Inter-Bold', sans-serif;
  font-size: ${typography.small};
  padding-left: 0;
`;

// import styled from 'styled-components';

// import { colors } from '../../Common/colors';

// export const StyledBaseTextArea = styled.textarea`
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
//   border: 1px solid ${colors.lightgray};
//   color: ${colors.textAreaTextColor};
//   outline: none;
//   resize: none;
//   font-family: inherit;

//   ::placeholder {
//     color: ${colors.textAreaPlaceholderTextColor};
//   }
// `;
