import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from 'common/colors';

export const SelectDividerComponent = React.memo((props) => {
  const { children, ...other } = props;

  return <StyledWrapper {...other}>{children}</StyledWrapper>;
});

SelectDividerComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

SelectDividerComponent.defaultProps = {};

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 10px;
  font-weight: bold;
  color: ${colors.yellow};
`;
