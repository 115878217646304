import {
  ON_TIMELINE_CHANGE_TIMESTAMP,
  ON_TIMELINE_EXTRACT_FRAMES_ENDED,
  ON_TIMELINE_TRACK_LOADED_AND_READY,
  ON_TIMELINE_TRACK_PLAYED_POSITION,
  ON_TIMELINE_UPDATE_DURATION,
  ON_TIMELINE_ZOOM_CHANGE,
  UPDATED_VIDEOURL,
} from '../Constants';
import { fitEndTrimPosition, fitStartTrimPosition } from './utils';
import {
  TIMELINE_CHANGE_TIMESTAMP,
  TIMELINE_EXTRACT_FRAMES_ENDED,
  TIMELINE_RESET,
  TIMELINE_RESET_KEYFRAMES,
  TIMELINE_TRACK_LOADED_AND_READY,
  TIMELINE_TRACK_PLAYED_POSITION,
  TIMELINE_TRIM_END_UPDATED,
  TIMELINE_TRIM_START_UPDATED,
  TIMELINE_TRIMM_POS_REDO,
  TIMELINE_TRIMM_POS_UNDO,
  TIMELINE_UPDATE_DURATION,
  TIMELINE_UPDATE_EVENTS_DATA,
  TIMELINE_UPDATE_PEEKEVENTS_DATA,
  TIMELINE_ZOOM_CHANGE,
} from 'common/Redux/Timeline/types';
import {
  defaultEndTrimPos,
  defaultStartTrimPos,
} from 'common/Redux/Timeline/constants';

const initialState = {
  currentZoom: 1,
  duration: null,
  durationReady: false,
  trackEnded: false,
  ready: false,
  currentPlayPosition: 0,
  startTrimmPos: defaultStartTrimPos,
  endTrimmPos: defaultEndTrimPos,
  framesExtracted: false,
  actionHistory: [],
  actionHistoryIndex: -1,
  trackFrames: [],
  videoUrl: null,
};

const TimelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMELINE_ZOOM_CHANGE:
      return {
        ...state,
        controlEvent: ON_TIMELINE_ZOOM_CHANGE,
        currentZoom: action.payload,
      };
    case TIMELINE_CHANGE_TIMESTAMP:
      return {
        ...state,
        controlEvent: ON_TIMELINE_CHANGE_TIMESTAMP,
        currentTimeStamp: action.payload,
      };
    case TIMELINE_TRACK_PLAYED_POSITION:
      return {
        ...state,
        controlEvent: ON_TIMELINE_TRACK_PLAYED_POSITION,
        currentPlayPosition: action.payload,
      };
    case TIMELINE_EXTRACT_FRAMES_ENDED:
      return {
        ...state,
        controlEvent: ON_TIMELINE_EXTRACT_FRAMES_ENDED,
        trackFrames: action.trackFrames,
        trackFramesUpdated: Date.now(),
        framesExtracted: true,
      };
    case TIMELINE_UPDATE_DURATION:
      return {
        ...state,
        controlEvent: ON_TIMELINE_UPDATE_DURATION,
        durationReady: true,
        duration: action.duration,
      };
    case TIMELINE_RESET:
      return {
        ...initialState,
      };
    case TIMELINE_RESET_KEYFRAMES:
      return {
        ...state,
        trackFrames: [],
      };
    case TIMELINE_TRIM_START_UPDATED:
      return {
        ...state,
        ...processHistory(
          state,
          fitStartTrimPosition(action.ts, state),
          !action.saveHistory
        ),
      };
    case TIMELINE_TRIM_END_UPDATED:
      return {
        ...state,
        ...processHistory(
          state,
          fitEndTrimPosition(action.ts, state),
          !action.saveHistory
        ),
      };
    case TIMELINE_TRIMM_POS_UNDO:
      return {
        ...state,
        ...backHistory(state),
      };
    case TIMELINE_TRIMM_POS_REDO:
      return {
        ...state,
        ...forwardHistory(state),
      };
    case TIMELINE_TRACK_LOADED_AND_READY:
      return {
        ...state,
        controlEvent: ON_TIMELINE_TRACK_LOADED_AND_READY,
        ready: action.ready,
      };
    case UPDATED_VIDEOURL:
      return {
        ...state,
        videoUrl: action.payload,
      };
    case TIMELINE_UPDATE_EVENTS_DATA:
      return {
        ...state,
        events: action.payload,
      };
    case TIMELINE_UPDATE_PEEKEVENTS_DATA:
      return {
        ...state,
        events: action.payload,
      };
    default:
      return state;
  }
};

function processHistory(state, stateChanges, dry = false) {
  const { actionHistory, actionHistoryIndex } = state;
  const previousStateChanges = {};
  const isHistoryHasNoChanges = Object.keys(stateChanges).every(
    (key) => stateChanges[key] === state[key]
  );

  if (isHistoryHasNoChanges) return {};

  Object.keys(stateChanges).forEach(
    (key) => (previousStateChanges[key] = state[key])
  );

  const record = {
    previous: previousStateChanges,
    next: stateChanges,
  };
  const newHistory = [
    ...actionHistory.slice(0, actionHistoryIndex + 1),
    record,
  ];

  return dry
    ? stateChanges
    : {
        actionHistory: newHistory,
        actionHistoryIndex: newHistory.length - 1,
        ...stateChanges,
      };
}

function backHistory(state) {
  const { actionHistory, actionHistoryIndex } = state;
  if (actionHistoryIndex < 0) return {};
  const record = actionHistory[actionHistoryIndex].previous;

  return {
    ...state,
    ...record,
    actionHistoryIndex: actionHistoryIndex - 1,
  };
}

function forwardHistory(state) {
  const { actionHistory, actionHistoryIndex } = state;
  if (actionHistoryIndex >= actionHistory.length - 1) return {};
  const record = actionHistory[actionHistoryIndex + 1].next;

  return {
    ...state,
    ...record,
    actionHistoryIndex: actionHistoryIndex + 1,
  };
}

export default TimelineReducer;
