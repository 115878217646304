import { Defer } from 'utils/models/Defer';
import firebase from 'firebase';
import { FirebaseProcess } from './FirebaseProcess';
import { videoEditorFirebaseApp } from '../firebase';

class ProcessManager {
  private _rootRef: firebase.database.Reference = firebase.database().ref();
  private readonly _init = new Defer();
  private readonly _processes: Record<string, FirebaseProcess> = {};

  constructor(rootRef: firebase.database.Reference) {
    this._rootRef = rootRef;
    this._init.resolve();
  }

  async createProcess(userId: number, processKey: string) {
    await this._init;

    if (!this._processes[processKey]) {
      this._processes[processKey] = new FirebaseProcess(
        this._rootRef.child(userId.toString()).child('processes').child(processKey)
      );
      this._processes[processKey].end.then(() => this.removeProcess(processKey));
    }

    return this._processes[processKey];
  }

  private removeProcess(processKey: string) {
    delete this._processes[processKey];
  }
}

export const processManager = new ProcessManager(videoEditorFirebaseApp.database().ref('belive/users'));

