import React from 'react';

export const GuestIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.99984C10 8.89527 10.8954 7.99984 12 7.99984C13.1046 7.99984 14 8.89527 14 9.99984C14 11.1044 13.1046 11.9998 12 11.9998C10.8954 11.9998 10 11.1044 10 9.99984ZM12 6.6665C10.1591 6.6665 8.66667 8.15889 8.66667 9.99984C8.66667 10.6002 8.82538 11.1635 9.10314 11.65C7.32798 12.4656 6 13.9084 6 15.9998C6 16.368 6.29848 16.6665 6.66667 16.6665H17.3333C17.7015 16.6665 18 16.368 18 15.9998C18 13.9084 16.672 12.4656 14.8969 11.65C15.1746 11.1635 15.3333 10.6002 15.3333 9.99984C15.3333 8.15889 13.8409 6.6665 12 6.6665ZM13.9555 12.6996C13.4062 13.0982 12.7306 13.3332 12 13.3332C11.2694 13.3332 10.5938 13.0982 10.0445 12.6996C8.60411 13.2506 7.65795 14.1493 7.4028 15.3332H16.5972C16.3421 14.1493 15.3959 13.2506 13.9555 12.6996Z"
        fill="inherit"
      />
    </svg>
  );
});
