/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { StyledBaseTextArea } from 'components/ui/TextArea/BaseTextAreaStyles';

export class BaseTextAreaComponent extends PureComponent {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value: value || '', focus: false, props: { value } };
  }

  render() {
    const {
      forwardRef,
      placeholder,
      className,
      disabled,
      ...props
    } = this.props;
    return (
      <StyledBaseTextArea
        {...props}
        ref={forwardRef}
        className={className}
        placeholder={!this.state.focus ? placeholder : ''}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        disabled={disabled}
        value={this.state.value}
      />
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (value !== state.props.value) {
      return { value, props };
    }
    return null;
  }

  componentDidUpdate() {
    const { autoHeight, forwardRef, onChange } = this.props;
    onChange(this.state.value);
    if (autoHeight) {
      fitByHeight(forwardRef);
    }
  }

  componentDidMount() {
    const { forwardRef, setChange, autoHeight } = this.props;
    setChange(this.onChange);

    if (autoHeight) {
      fitByHeight(forwardRef);
    }
  }

  onChange = ({ target: { value } }) => {
    const { singleLine, isEditable } = this.props;

    if (!isEditable) return;

    const filteredText = singleLine ? value.replace('\n', '') : value;
    this.setState({ value: filteredText });
  };

  onBlur = (e) => {
    this.setState({ focus: false });
    if (this.props.onBlur) this.props.onBlur(e);
  };

  onFocus = () => {
    this.setState({ focus: true });
  };
}

function fitByHeight(ref) {
  const el = ref?.current;
  if (el) {
    // trick to make text area auto size by height
    el.style.cssText = 'height:auto';
    el.style.cssText = `height:${el.scrollHeight}px`;
  }
}

BaseTextAreaComponent.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setChange: PropTypes.func,
  forwardRef: PropTypes.object,
  singleLine: PropTypes.bool,
  isEditable: PropTypes.bool,
  maxLength: PropTypes.number,
};
BaseTextAreaComponent.defaultProps = {
  value: '',
  placeholder: '',
  singleLine: false,
  isEditable: true,
  onChange: noop,
  setChange: noop,
};
