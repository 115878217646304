import styled from 'styled-components';
import { AddIcon } from 'components/ui/Icons/AddIcon';
import { MediaCollectionIcon } from 'components/ui/Icons/MediaCollectionIcon';
import { TemplateIcon } from 'components/ui/Icons/TemplateIcon';
import { SupportIcon } from 'components/ui/Icons/SupportIcon';
import { Toolbox } from 'components/ui/Toolbox';
import { colors } from 'common/colors';

export const StyledToolbox = styled(Toolbox)`
  height: auto;
`;

export const StyledPlusIcon = styled(AddIcon)<{ active?: boolean }>`
  height: 14px;
  width: 14px;
  margin-bottom: 4px;
`;

export const StyledMediaCollectionIcon = styled(MediaCollectionIcon)<{
  active?: boolean;
}>`
  height: 20px;
  width: 22px;
  margin-bottom: 4px;
`;

export const StyledTemplateIcon = styled(TemplateIcon)<{
  active?: boolean;
}>`
  height: 18px;
  width: 18px;
  margin-bottom: 4px;
`;

export const StyledSupportItemContent = styled.div`
  position: relative;
  text-align: center;
`;

export const StyledSupportIcon = styled(SupportIcon)<{
  active?: boolean;
  onClick?: () => void;
}>`
  height: 24px;
  width: 24px;
  background: ${colors.almostBack};
  border-radius: 50%;
  overflow: hidden;
`;
