import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { size } from 'lodash';

import { Layout } from './components/Layout';
import {
  StyledBroadcastPlaceholderText,
  StyledCreateProjectButton,
  StyledLoader,
  StyledMediaIcon,
  StyledNewBroadcastButton,
  StyledPlusIcon,
  StyledSidebar,
  StyledSupport,
  StyledVideoProjectTitle,
} from './styles';
import { StartupPageProps } from './types';
import { Button } from 'components/ui/Button';
import { BroadcastList } from 'components/blocks/BroadcastList';
import { ProjectList } from './components/ProjectList';
import {
  getBroadcastListSelector,
  selectorAreBroadcastsFetched,
} from 'common/Redux/BroadcastsList/selectors';
import {
  selectAreProjectsFetched,
  selectUserProjectCollection,
} from 'common/Redux/User/selectors';
import { createProject, deleteProject } from 'common/Redux/User/actions';
import { createStructuredSelector } from 'reselect';
import { GlobalState } from 'common/Redux/types';
import { Broadcast, ProjectCollection, ProjectType } from 'common/types';
import { openStudio } from 'components/pages/HomePage/services';
import { Header } from 'components/blocks/Header';
import { useHistory } from 'react-router-dom';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { ButtonType } from 'components/ui/Button/types';
import { fetchBroadcastList } from 'common/Redux/BroadcastsList/BroadcastsListActions';
import { useRedirectBroadcastId } from 'components/pages/StartupPage/hooks';
import { Spacer } from 'components/ui/Spacer';

export const StartupPage: FC<StartupPageProps> = ({
  projects,
  broadcasts,
  onProjectDelete,
  areBroadcastsFetched,
  areProjectsFetched,
  fetchBroadcastList,
  createProject,
}) => {
  const history = useHistory();

  useEffect(() => {
    fetchBroadcastList();
  }, []);

  const goToProject = (payload: { id: number; replace?: boolean }) => {
    const { id, replace } = payload;
    const redirectTo = replace ? history.replace : history.push;

    redirectTo(`/project/${id}`);
    analytics.trackEvent(analyticsEvents.OPEN_PROJECT);
  };

  const createNewProject = (payload: {
    broadcastId?: number;
    name?: string;
    source: string;
    type?: ProjectType;
    replace?: boolean;
  }) => {
    const {
      broadcastId,
      name,
      replace,
      source,
      type = ProjectType.STANDARD,
    } = payload;

    createProject({
      broadcastId,
      name,
      type,
      resolve: (id) => goToProject({ id, replace: replace }),
      reject: () => history.push(`/`),
    });
    if (source === 'Sidebar') {
      analytics.trackEvent(
        type === ProjectType.SHOPPABLE
          ? analyticsEvents.CREATE_SHOPPABLE_VIDEO
          : analyticsEvents.EDIT_VIDEO
      );
    } else {
      analytics.trackEvent(analyticsEvents.CREATE_PROJECT, {
        Source: source,
      });
    }
  };

  // Handle scenario when a user comes from My Streams page
  const redirectBroadcastId = useRedirectBroadcastId((id) => {
    createNewProject({ broadcastId: id, source: 'My Streams', replace: true });
  });
  if (redirectBroadcastId) return null;

  return (
    <Layout>
      <Layout.Content>
        <Layout.Content.Section
          title={
            <StyledVideoProjectTitle>
              <div>My Video Projects</div>
              <StyledCreateProjectButton
                type={ButtonType.Secondary}
                onClick={() => createNewProject({ source: 'Dashboard' })}
              >
                <StyledPlusIcon />
                Create
              </StyledCreateProjectButton>
            </StyledVideoProjectTitle>
          }
          placeholder={
            <>
              <StyledMediaIcon />
              <div>All the broadcasts you’ll make will be here</div>
            </>
          }
        >
          {areProjectsFetched ? (
            size(projects) && (
              <ProjectList
                projects={projects}
                onProjectSelect={goToProject}
                onProjectDelete={onProjectDelete}
              />
            )
          ) : (
            <StyledLoader />
          )}
        </Layout.Content.Section>

        <Layout.Content.Section
          title={'My Broadcasts'}
          placeholder={
            <>
              <StyledBroadcastPlaceholderText>
                Broadcasts you create appear here
              </StyledBroadcastPlaceholderText>
            </>
          }
        >
          {areBroadcastsFetched ? (
            broadcasts.length && (
              <BroadcastList
                broadcasts={broadcasts}
                onBroadcastSelect={({ id, title }) =>
                  createNewProject({
                    name: title,
                    broadcastId: id,
                    source: 'Broadcast List',
                  })
                }
              />
            )
          ) : (
            <StyledLoader />
          )}
        </Layout.Content.Section>
      </Layout.Content>
      <Layout.Sidebar>
        <StyledSidebar>
          <Header />
          <Button
            type={ButtonType.Primary}
            onClick={() => createNewProject({ source: 'Sidebar' })}
          >
            Edit Video
          </Button>
          <Spacer height={8} />
          <Button
            type={ButtonType.Primary}
            onClick={() =>
              createNewProject({
                source: 'Sidebar',
                type: ProjectType.SHOPPABLE,
              })
            }
          >
            Create Shoppable Video
          </Button>
          <StyledNewBroadcastButton
            type={ButtonType.Secondary}
            onClick={() => openStudio('Home Right Panel')}
          >
            Create New Stream
          </StyledNewBroadcastButton>
        </StyledSidebar>
      </Layout.Sidebar>

      <StyledSupport />
    </Layout>
  );
};

const mapState = createStructuredSelector<
  GlobalState,
  {
    projects: ProjectCollection;
    broadcasts: Broadcast[];
    areBroadcastsFetched: boolean;
    areProjectsFetched: boolean;
  }
>({
  projects: selectUserProjectCollection,
  broadcasts: getBroadcastListSelector,
  areBroadcastsFetched: selectorAreBroadcastsFetched,
  areProjectsFetched: selectAreProjectsFetched,
});

const mapDispatch = {
  onProjectDelete: deleteProject,
  fetchBroadcastList,
  createProject,
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = typeof mapDispatch;
type OwnProps = Omit<StartupPageProps, keyof StateProps | keyof DispatchProps>;

export const StartupPageContainer = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  GlobalState
>(
  mapState,
  mapDispatch
)(StartupPage);
