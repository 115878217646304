import {
  openEditorToolboxItem,
  selectActiveToolboxItem,
  TOOLBOX_ITEM_TYPES,
  TOOLBOX_UPDATE_ACTIVE_ITEM,
} from 'common/Redux/Toolbox';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { sagaMiddleware } from 'common/sagas/sagaMiddleware';
import { pausePreviewPlayer } from 'common/Redux/Preview/PreviewActions';
import { selectIsPlaying } from 'common/Redux/Preview/selectors';
import { PREVIEW_CONTROL_PLAY } from 'common/Redux/Preview/PreviewTypes';

function* pausePreviewOnLeaveEditorTab() {
  let activeToolboxItemBeforeChange = yield select(selectActiveToolboxItem);

  while (true) {
    const action = yield take(TOOLBOX_UPDATE_ACTIVE_ITEM);

    if (
      activeToolboxItemBeforeChange === TOOLBOX_ITEM_TYPES.EDITOR &&
      action.payload !== TOOLBOX_ITEM_TYPES.EDITOR
    ) {
      if (yield select(selectIsPlaying)) yield put(pausePreviewPlayer());
    }

    activeToolboxItemBeforeChange = action.payload;
  }
}

function* openEditorTab() {
  const activeToolboxItem = yield select(selectActiveToolboxItem);

  if (activeToolboxItem !== TOOLBOX_ITEM_TYPES.EDITOR) {
    yield put(openEditorToolboxItem());
  }
}

function* previewRootSaga() {
  yield all([
    call(pausePreviewOnLeaveEditorTab),
    takeEvery(PREVIEW_CONTROL_PLAY, openEditorTab),
  ]);
}

sagaMiddleware.run(previewRootSaga);
