import styled, { css } from 'styled-components';
import { Button } from 'components/ui/Button';
import { DownloadContainer } from 'components/blocks/Download';
import { ShareContainer } from 'components/ui/ShareButton';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 37px;
`;

export const StyledAvatarWrapper = styled.div``;

const buttonStyle = css`
  margin: 4px;
`;

export const StyledDownloadButton = styled(DownloadContainer)`
  flex: 1;
  ${buttonStyle};
`;

export const StyledShareButton = styled(ShareContainer)`
  flex: 1;
  ${buttonStyle};
  margin-bottom: 8px;
`;

export const StyledOpenBroadcastButton = styled(Button)`
  display: flex;
  margin-top: auto;
  width: 100%;
  height: 32px;
  justify-content: center;
  padding: 0;
  align-items: center;
`;
