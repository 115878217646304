import { action } from 'common/Redux/actions';
import { MediaCollection, ProjectCollection, ProjectType } from 'common/types';
import { requests } from 'common/requests';
import { analytics } from '../../3rdUtils/analytics/analytics';
import { analyticsEvents } from '../../3rdUtils/analytics/analyticsEventsEnums';
import { LIST_IDS } from '../../../components/ui/Checklist/types';

export const PROJECTS_UPDATE = 'PROJECTS_UPDATE';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const USER_MEDIA_UPDATE = 'USER_MEDIA_UPDATED';

export const updateUserProjects = (payload: ProjectCollection) => ({
  type: PROJECTS_UPDATE,
  payload,
});

export const updateUserMedia = action<MediaCollection>(USER_MEDIA_UPDATE);

export const createProject = (payload?: {
  name?: string;
  broadcastId?: number;
  type?: ProjectType;
  resolve?: (projectId: number) => void;
  reject?: () => void;
}) => ({ type: CREATE_PROJECT, payload });

export const deleteProject = (payload: { id: number }) => () =>
  requests.deleteProject(payload);

export const deleteUserMedia = (payload: { id: number }) => () => {
  analytics.trackEvent(analyticsEvents.MEDIA_DELETED);
  return requests.deleteMedia(payload);
};

export const saveChecklist = (payload: {
  checklistId: LIST_IDS;
  itemId: string;
  checked: boolean;
}) => {
  return requests.saveChecklist(payload);
};
