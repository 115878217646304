import styled, { css } from 'styled-components';

import { Button } from 'components/ui/Button';
import { colors } from 'common/colors';
import { ShareIcon } from 'components/ui/Icons/ShareIcon';
import { conditionalStyle } from 'utils/styles';

export const StyledDownloadButtonWrapper = styled.div`
  position: relative;
`;

export const StyledShareButton = styled(Button)`
  display: flex;
  height: 32px;
  width: 100%;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

export const StyledShareIcon = styled(ShareIcon)`
  height: 22px;
  margin-right: 4px;
`;
