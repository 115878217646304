import React from 'react';
import { colors } from 'common/colors';

export const VideoPlayerIcon = React.memo(({ ...props }) => {
  return (
    <svg viewBox="0 0 56 32" {...props} stroke="none">
      <rect width="56" height="32" rx="2" fill={colors.fog} fillOpacity="0.3" />
      <path
        d="M23 14.6667V17.3333C23 20.3105 23 21.799 23.971 22.3817C24.942 22.9643 26.2555 22.2638 28.8824 20.8627L31.3824 19.5294C34.2941 17.9765 35.75 17.2 35.75 16C35.75 14.8 34.2941 14.0235 31.3824 12.4706L28.8824 11.1373C26.2555 9.73625 24.942 9.03574 23.971 9.61835C23 10.201 23 11.6895 23 14.6667Z"
        fill={colors.grey}
      />
    </svg>
  );
});
