import styled from 'styled-components';
import {
  getIconSize,
  getLineSize,
} from 'components/blocks/EventsTimeline/components/PeakEvent/utils';
import { colors } from 'common/colors';

export const StyledPeakEvent = styled.div`
  transform: translateX(-50%);
  width: ${getIconSize}px;
  height: ${getIconSize}px;
  margin-bottom: ${getLineSize}px;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledIconWrapper = styled.div`
  transition: transform 0.1s;
  border-radius: 50%;
  height: 100%;
  overflow: hidden;

  &:hover {
    transform: scale(1.2);
  }
`;

export const StyledLine = styled.div`
  width: 2px;
  margin: auto;
  top: 100%;
  height: ${getLineSize}px;
  background: linear-gradient(
    to bottom,
    ${colors.lightgray2},
    ${colors.white} 90%
  );
  pointer-events: none;
`;
