import React, { FC, useState } from 'react';
import { StyledDownloadButtonWrapper, StyledShareButton, StyledShareIcon } from 'components/ui/ShareButton/styles';
import { getBase64Overlay } from 'components/blocks/PreviewOverlay/context';
import { OverlayLayouts } from 'components/blocks/Overlays/constants';
import { SharePopup, SharePopupState } from 'components/blocks/SharePopup';
import { PreviewColorThemeType } from 'components/blocks/PreviewThemes/types';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import {
  analyticsDestinations,
  analyticsEvents, analyticsOverlayColorNames,
  analyticsOverlayNames,
} from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { firebaseService } from 'services/firebase';
import { ShareStatus } from 'components/ui/ShareButton/constants';
import { FacebookDestinationType } from 'components/blocks/SharePopup/types';
import { requests } from 'common/requests';
import { OverlayType, RectPosition } from '../../blocks/Overlays/types';
import { BUTTON_SIZES_ENUM, ButtonType } from '../Button/types';

export const ShareButton: FC<{
  isDownloadButtonDisabled: boolean;
  broadcastId: string;
  projectId: number;
  className: string;
  trimStart: number;
  trimEnd: number;
  overlayType: OverlayType;
  themeType: PreviewColorThemeType;
  pausePreview?: () => void;
  videoRect: RectPosition;
}> = ({
  isDownloadButtonDisabled,
  broadcastId,
  projectId,
  overlayType,
  themeType,
  className,
  pausePreview = () => {},
  videoRect,
}) => {
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);
  const [sharePopupData, setSharePopupData] = useState<{
    state: SharePopupState;
    link?: string;
  }>({ state: SharePopupState.IDLE });

  const onShare = async (data: {
    title: string;
    description: string;
    facebookDestinationId?: string;
    facebookTokens?: Record<string, string>;
    facebookDestinationType?: FacebookDestinationType;
  }) => {
    const {
      title,
      description,
      facebookDestinationId,
      facebookTokens,
      facebookDestinationType,
    } = data;

    let unsubscribe = () => {};

    try {
      if (facebookDestinationId) {
        const shareStatusPath = `${firebaseService.refs.broadcasts}/${broadcastId}/share`;
        let shareProcessStarted = false;

        unsubscribe = firebaseService.on('value', shareStatusPath, (value) => {
          switch (value?.status) {
            case ShareStatus.INIT:
              shareProcessStarted = true;
              setSharePopupData({ state: SharePopupState.PROCESSING });
              break;

            case ShareStatus.SUCCESS:
              if (!shareProcessStarted) break;
              const isOverlayEnabled = overlayType !== OverlayType.Default;
              analytics.trackEvent(analyticsEvents.EXPORT_SUCCESS, {
                'Type': 'Share',
                'Destination': facebookDestinationType ? analyticsDestinations[facebookDestinationType] : 'None',
                'Text Overlays': isOverlayEnabled,
                'Text Overlays Type': analyticsOverlayNames[overlayType],
                'Text Overlays Style':
                  isOverlayEnabled
                    ? analyticsOverlayColorNames[themeType]
                    : 'None',
              });
              setSharePopupData({
                state: SharePopupState.DONE,
                link: value?.link,
              });
              unsubscribe();
              break;

            case ShareStatus.FAILED:
              if (!shareProcessStarted) break;
              setSharePopupData({ state: SharePopupState.FAIL });
              analytics.trackEvent(analyticsEvents.SHARE_FAILED, {
                Destination: `Facebook ${facebookDestinationType}`,
              });
              unsubscribe();
              break;
          }
        });

        await requests.shareFacebookVideo({
          projectId,
          destinationId: facebookDestinationId,
          facebookTokens,
          title,
          description,
          ...(overlayType && {
            overlay: getBase64Overlay(),
            overlayConfig: {
              videoRect,
            }
          }),
        });
      }
    } catch (err) {
      unsubscribe();
      analytics.trackEvent(analyticsEvents.SHARE_FAILED);
    }
  };

  const handleClick = () => {
    analytics.trackEvent(analyticsEvents.EXPORT_CLICKED, { 'Export Type': 'Share' });
    pausePreview();
    if (
      [SharePopupState.DONE, SharePopupState.FAIL].includes(
        sharePopupData.state
      )
    ) {
      setSharePopupData({ state: SharePopupState.IDLE });
    }
    setIsSharePopupVisible(true);
  };

  const onClose = () => {
    if (sharePopupData.state === SharePopupState.PROCESSING) return;
    setIsSharePopupVisible(false);
  };

  return (
    <StyledDownloadButtonWrapper className={className}>
      <StyledShareButton
        size={BUTTON_SIZES_ENUM.S}
        type={ButtonType.Secondary}
        disabled={isDownloadButtonDisabled}
        onClick={handleClick}
      >
        <StyledShareIcon />
        Share
      </StyledShareButton>
      <SharePopup
        state={sharePopupData.state}
        shareLink={sharePopupData.link}
        isVisible={isSharePopupVisible}
        onShare={onShare}
        onClose={onClose}
      />
    </StyledDownloadButtonWrapper>
  );
};
