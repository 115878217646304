import React from 'react';

export const LayoutIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5286 5.86225C14.7889 5.6019 15.2111 5.6019 15.4714 5.86225L17.8047 8.19559C18.0651 8.45594 18.0651 8.87805 17.8047 9.1384L15.4714 11.4717C15.2111 11.7321 14.7889 11.7321 14.5286 11.4717C14.2682 11.2114 14.2682 10.7893 14.5286 10.5289L15.7239 9.33366H8C7.63181 9.33366 7.33333 9.63214 7.33333 10.0003V10.667C7.33333 11.0352 7.03486 11.3337 6.66667 11.3337C6.29848 11.3337 6 11.0352 6 10.667V10.0003C6 8.89576 6.89543 8.00033 8 8.00033H15.7239L14.5286 6.80506C14.2682 6.54471 14.2682 6.1226 14.5286 5.86225Z"
        fill="inherit"
      />
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4714 18.1377C9.21105 18.3981 8.78894 18.3981 8.52859 18.1377L6.19526 15.8044C5.93491 15.5441 5.93491 15.122 6.19526 14.8616L8.52859 12.5283C8.78894 12.2679 9.21105 12.2679 9.4714 12.5283C9.73175 12.7886 9.73175 13.2107 9.4714 13.4711L8.27614 14.6663L16 14.6663C16.3682 14.6663 16.6667 14.3679 16.6667 13.9997L16.6667 13.333C16.6667 12.9648 16.9651 12.6663 17.3333 12.6663C17.7015 12.6663 18 12.9648 18 13.333L18 13.9997C18 15.1042 17.1046 15.9997 16 15.9997L8.27614 15.9997L9.4714 17.1949C9.73175 17.4553 9.73175 17.8774 9.4714 18.1377Z"
        fill="inherit"
      />
    </svg>
  );
});
