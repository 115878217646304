import React from 'react';

export const ForwardArrowIcon = React.memo(({ ...props }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15.5 14.5L20 10M20 10L15.5 5.5M20 10H8C5.79086 10 4 11.7909 4 14V14C4 16.2091 5.79086 18 8 18H10"
        stroke="inherit"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
