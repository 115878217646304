import React from 'react';
import 'common/Utils.js';
import { StyledProcessNotifierWrapper } from 'components/ui/ProcessNotifier/styles';
import {
  ProcessNotifierMessages,
  ProcessNotifierTitles,
} from 'components/ui/ProcessNotifier/ProcessNotifierEnums';

export const ProcessNotifier = ({ type, percentage = null }) => {
  const roundedPercentage =
    percentage !== null ? `${Math.floor(percentage)}%` : '';

  return (
    <StyledProcessNotifierWrapper
      title={ProcessNotifierTitles[type]}
      rightTitle={roundedPercentage}
      content={ProcessNotifierMessages[type]}
      percentage={percentage}
    />
  );
};
