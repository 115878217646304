export enum colors {
  tangerine = '#f28a21',
  yellow = '#f9bb40',
  lightYellow = '#ffc',
  grey = '#b1b1b1',
  steel = '#b1b1b1',
  fog = '#bbbbbb',
  darkGrey = '#363636',
  summerRabbit = '#515151',
  darkPurple = '#4A475C',
  winterRabbit = '#ececec',
  darkBlue = '#09191E',
  lightgray = '#cccccc',
  lightgray2 = '#eee',
  lightgray3 = '#dedede',
  snow = '#fafafa',
  white = '#ffffff',
  lightblue = '#eef',
  lightPink = '#fee',
  carrot = '#E27161',
  watermelon = '#f05b5c',
  darkCyan = '#5298AD',
  nightSky = '#3E4F60',
  almostBack = '#212529',
  black = 'black',
  lightVine = '#B22620',
  vine = '#a80000',
  darkVine = '#5E211E',
  green = 'green',
  lightGreen = '#00C853',
  purple = '#62f',
  darkMagenta = '#a0a',

  disabledBackgroundColor = 'rgba(187, 187, 187, 0.3)',
  disabledColor = '#212529',
}
