import styled, { css } from 'styled-components';
import closeIcon from 'components/ui/Support/Icons/close.svg';
import questionIcon from 'components/ui/Support/Icons/question.svg';
import { colors } from 'common/colors';
import { lighten } from 'polished';

const buttonSize = 40;
const optionSize = 32;
const optionOffset = optionSize + 8;
const titleOffset = optionSize + 16;

const getCenteredBackground = (url: string, color = '') => css`
  background: ${color} url('${url}') no-repeat center;
`;

const positionContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledSupport = styled.div<{ isVisible: boolean }>`
  position: relative;
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  z-index: 999;
  transition: transform 0.3s;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      transform: translateY(${buttonSize + 16}px);
    `};
`;

export const StyledSupportButton = styled(positionContainer)<{
  isMenuVisible: boolean;
  onClick: () => void;
}>`
  border-radius: 50%;
  color: white;
  line-height: ${buttonSize}px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  ${({ isMenuVisible }) =>
    getCenteredBackground(
      isMenuVisible ? closeIcon : questionIcon,
      lighten(0.07, colors.disabledColor)
    )};

  :hover {
    background-color: ${lighten(0.1, colors.disabledColor)};
  }
`;

export const StyledOptionsContainer = positionContainer;

export const StyledOptionName = styled.div`
  position: absolute;
  left: 0;
  height: ${optionSize}px;
  box-shadow: 0 0 8px ${colors.lightgray};
  padding: 0 16px 0 ${titleOffset}px;

  display: flex;
  align-items: center;

  background-color: ${colors.white};
  transition: background-color 0.3s;
  font-weight: bold;
  color: black;
  width: 64px;
`;

export const StyledOptionIcon = styled.div<{ icon: string }>`
  position: absolute;
  left: 0;
  width: ${optionSize}px;
  height: ${optionSize}px;
  ${({ icon }) => getCenteredBackground(icon, '#f05b5c')};
`;

export const StyledOption = styled.div<{
  isMenuVisible: boolean;
  index: number;
}>`
  position: absolute;
  width: ${optionSize}px;
  height: ${optionSize}px;
  margin-bottom: 16px;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -${({ index }) => (index + 1) * optionOffset}px);
  transition: transform 0.3s, background-color 0.3s, opacity 0.3s;

  &:hover ${StyledOptionIcon} {
    background-color: #ff6061;
  }

  ${({ isMenuVisible }) =>
    !isMenuVisible &&
    css`
      &,
      :hover {
        transform: translate(-50%, 0) rotateZ(-90deg) scale(0.5);
        pointer-events: none;
        opacity: 0;
      }
    `};
`;
