import React from 'react';

export const ScreenIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.6665C6.89543 6.6665 6 7.56193 6 8.6665V13.3332C6 14.4377 6.89543 15.3332 8 15.3332H11.3333V16.6665H9.33333C8.96514 16.6665 8.66667 16.965 8.66667 17.3332C8.66667 17.7014 8.96514 17.9998 9.33333 17.9998H14.6667C15.0349 17.9998 15.3333 17.7014 15.3333 17.3332C15.3333 16.965 15.0349 16.6665 14.6667 16.6665H12.6667V15.3332H16C17.1046 15.3332 18 14.4377 18 13.3332V8.6665C18 7.56193 17.1046 6.6665 16 6.6665H8ZM7.33333 8.6665C7.33333 8.29831 7.63181 7.99984 8 7.99984H16C16.3682 7.99984 16.6667 8.29831 16.6667 8.6665V13.3332C16.6667 13.7014 16.3682 13.9998 16 13.9998H8C7.63181 13.9998 7.33333 13.7014 7.33333 13.3332V8.6665Z"
        fill="inherit "
      />
    </svg>
  );
});
