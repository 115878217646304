import React from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';

const CrownIconComponent = React.memo((props) => (
  <svg viewBox="0 0 20 20" {...props} data-aid="crownIcon">
    <circle cx="10" cy="10" r="10" fill="inherit" stroke="none" />
    <path
      stroke="#FFF"
      fill="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 14H5.004L4 7l3 4 3-6 3 6 3-4z"
    />
  </svg>
));

export const CrownIcon = styled(CrownIconComponent)`
  fill: ${colors.watermelon};
  width: 15px;
  height: 15px;
`;
