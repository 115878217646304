import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import { MAX_RIGHT_PANEL_WIDTH } from 'components/pages/HomePage/constants';

export const StyledPage = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 100vh;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
`;

export const StyledBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  min-height: 300px;
`;

export const StyledPanel = styled.div`
  position: relative;
  background-color: ${colors.white};
  width: ${MAX_RIGHT_PANEL_WIDTH}px;
  flex-shrink: 0;
  flex-grow: 0;
  
  ${({ isVisible = true }) =>
    !isVisible &&
    css`
      width: 0;
    `}};
  
  ${({ isDisabled = false }) =>
    isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
      filter: grayscale(1);
    `}};
`;

export const StyledContent = styled.div`
  position: relative;
  min-width: 300px;
  flex-grow: 1;
`;

export const StyledFooter = styled.div`
  flex-shrink: 0;
  flex-grow: 0;

  transition: max-height 0.5s ease-out;
  overflow: hidden;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      max-height: 0;
    `};
`;
