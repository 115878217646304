import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { openUrl } from 'utils/naviagation';

export function gotToLoginPage() {
  openUrl(
    `${process.env.REACT_APP_BELIVE_EDITOR_AUTH_PROXY_SERVER}/login?origin=${window.location.href}`
  );
}

export const openStudio = (source = '') => {
  openUrl(process.env.REACT_APP_BELIVE_STUDIO_URL!, { newTab: true });
  analytics.trackEvent(analyticsEvents.GO_TO_STUDIO, { source });
};
