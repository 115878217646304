import {
  TIMELINE_CHANGE_TIMESTAMP,
  TIMELINE_EXTRACT_FRAMES_ENDED,
  TIMELINE_RESET,
  TIMELINE_RESET_KEYFRAMES,
  TIMELINE_TRACK_LOADED_AND_READY,
  TIMELINE_TRACK_PLAYED_POSITION,
  TIMELINE_TRIM_END_UPDATED,
  TIMELINE_TRIM_START_UPDATED,
  TIMELINE_TRIMM_POS_REDO,
  TIMELINE_TRIMM_POS_UNDO,
  TIMELINE_UPDATE_DURATION,
  TIMELINE_UPDATE_END_TRIMM_POS,
  TIMELINE_UPDATE_EVENTS_DATA,
  TIMELINE_UPDATE_PEEKEVENTS_DATA,
  TIMELINE_UPDATE_START_TRIMM_POS,
  TIMELINE_ZOOM_CHANGE,
} from 'common/Redux/Timeline/types';

export const TimelineZoomChange = (zoom) => {
  return (dispatch) => {
    try {
      dispatch(_TimelineZoomChange(zoom));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelineTSChange = (ts) => {
  return (dispatch) => {
    try {
      dispatch(_TimelineTSChange(ts));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelinePlayedPosition = (ts) => {
  return (dispatch) => {
    try {
      dispatch(_TimelinePlayedPosition(ts));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelineExtractFramesEnded = (frames) => {
  return (dispatch) => {
    try {
      dispatch(_TimelineExtractFramesEnded(frames));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelineUpdateDuration = (duration) => {
  return (dispatch) => {
    try {
      dispatch(_TimelineUpdateDuration(duration));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelineTrackLoadedAndReady = () => {
  return (dispatch) => {
    try {
      dispatch(_TimelineTrackLoadedAndReady());
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimeLineTrackTrimmUndo = (start, end) => {
  return (dispatch) => {
    try {
      dispatch(_TimeLineTrackTrimmUndo(start, end));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimeLineTrackTrimmRedo = (start, end) => {
  return (dispatch) => {
    try {
      dispatch(_TimeLineTrackTrimmRedo(start, end));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimeLineTrackUpdateTrimmStartPos = (ts, saveHistory = false) => {
  return (dispatch) => {
    try {
      dispatch(_TimeLineTrackUpdateTrimmStartPos(ts, saveHistory));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimeLineTrackUpdateTrimmEndPos = (ts, saveHistory = false) => {
  return (dispatch) => {
    try {
      dispatch(_TimeLineTrackUpdateTrimmEndPos(ts, saveHistory));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelineUpdateEvents = (data) => {
  return (dispatch) => {
    try {
      dispatch(_TimelineUpdateEvents(data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const TimelineUpdatePeekEvents = (data) => {
  return (dispatch) => {
    try {
      dispatch(_TimelineUpdatePeekEvents(data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const _TimelineUpdateEvents = (data) => {
  return {
    type: TIMELINE_UPDATE_EVENTS_DATA,
    payload: data,
  };
};

export const _TimelineUpdatePeekEvents = (data) => {
  return {
    type: TIMELINE_UPDATE_PEEKEVENTS_DATA,
    payload: data,
  };
};

export const _TimelineZoomChange = (zoom) => {
  return {
    type: TIMELINE_ZOOM_CHANGE,
    payload: zoom,
  };
};

export const _TimelineTSChange = (ts) => {
  return {
    type: TIMELINE_CHANGE_TIMESTAMP,
    payload: ts,
  };
};

export const _TimelinePlayedPosition = (ts) => {
  return {
    type: TIMELINE_TRACK_PLAYED_POSITION,
    payload: ts,
  };
};

export const _TimelineExtractFramesEnded = (trackFrames) => {
  return {
    type: TIMELINE_EXTRACT_FRAMES_ENDED,
    trackFrames,
    trackEnded: true,
  };
};

export const _TimelineUpdateDuration = (duration) => {
  return {
    type: TIMELINE_UPDATE_DURATION,
    duration: duration,
  };
};

export const _TimelineTrackLoadedAndReady = () => {
  return {
    type: TIMELINE_TRACK_LOADED_AND_READY,
    ready: true,
  };
};

export const _TimeLineTrackUpdateTrimmStartPos = (ts, saveHistory = false) => {
  return {
    type: TIMELINE_UPDATE_START_TRIMM_POS,
    payload: {
      saveHistory,
      ts,
    },
  };
};

export const _TimeLineTrackUpdateTrimmEndPos = (ts, saveHistory = false) => {
  return {
    type: TIMELINE_UPDATE_END_TRIMM_POS,
    payload: {
      saveHistory,
      ts,
    },
  };
};

export const _TimeLineTrackTrimmUndo = (start, end) => {
  return {
    type: TIMELINE_TRIMM_POS_UNDO,
    start,
    end,
  };
};

export const _TimeLineTrackTrimmRedo = (start, end) => {
  return {
    type: TIMELINE_TRIMM_POS_REDO,
    start,
    end,
  };
};

export const updateTrimStart = (ts, saveHistory = false) => {
  return {
    type: TIMELINE_TRIM_START_UPDATED,
    saveHistory,
    ts,
  };
};

export const updateTrimEnd = (ts, saveHistory = false) => {
  return {
    type: TIMELINE_TRIM_END_UPDATED,
    saveHistory,
    ts,
  };
};

export const resetTimeline = () => {
  return {
    type: TIMELINE_RESET,
  }
};

export const resetKeyframes = () => {
  return {
    type: TIMELINE_RESET_KEYFRAMES,
  }
};

