import { UserPilotInit, UserPilotIdentify } from './3rdUtils/userpilot';
import { HotJarInit } from './3rdUtils/hotjar';
//import Intercomm from './3rdUtils/Intercomm'

const ThirdPartiesServiceInit = () => {
  //Init Hotjar
  HotJarInit();

  //Init UserPilot
  UserPilotInit();
};

const ThirdPartiesServiceIdentify = (user) => {
  //Update user details on userPilot (Usually when doing login)
  UserPilotIdentify(user);
};

export { ThirdPartiesServiceInit, ThirdPartiesServiceIdentify };
