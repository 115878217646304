import {
  HIDE_NOTIFIER,
  SHOW_NOTIFIER,
  UPDATE_NOTIFIER,
  CANCELED_DOWNLOAD_NOTIFIER,
  START_DOWNLOAD_NOTIFIER,
} from './NotifierTypes';

const initialState = {
  visible: false,
  canceled: false,
  notifierData: {},
};

const NotifierReducer = (state = initialState, action) => {
  let result;
  switch (action.type) {
    case SHOW_NOTIFIER:
      result = {
        ...state,
        visible: true,
        notifierData: action.payload,
      };
      break;
    case UPDATE_NOTIFIER:
      result = {
        ...state,
        notifierData: Object.assign({}, state.notifierData, action.payload),
      };
      break;
    case HIDE_NOTIFIER:
      result = {
        ...state,
        visible: false,
      };
      break;
    case CANCELED_DOWNLOAD_NOTIFIER:
      result = {
        ...state,
        visible: false,
        canceled: true,
      };
      break;
    case START_DOWNLOAD_NOTIFIER:
      result = {
        ...state,
        visible: false,
        canceled: false,
      };
      break;
    default:
      result = state;
  }
  return result;
};

export default NotifierReducer;
