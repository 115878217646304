import { createSelector } from 'reselect';
import { selectUserBroadcastMediaIds } from 'common/Redux/User/selectors';

const getBroadcastListState = (state) => state.BroadcastList;

export const getBroadcastIdSelector = (state) =>
  getBroadcastListState(state).broadcastId;

export const getBroadcastListSelector = createSelector(
  getBroadcastListState,
  (broadcastList) => broadcastList.broadcastList
);

export const selectExpandedBroadcastsWithAttachedField = createSelector(
  [getBroadcastListSelector, selectUserBroadcastMediaIds],
  (broadcasts, userBroadcastMediaIds) =>
    broadcasts.map((b) => ({
      ...b,
      attached: userBroadcastMediaIds.includes(b.id),
    }))
);

export const selectorAreBroadcastsFetched = (state) =>
  getBroadcastListState(state).isBroadcastsReceived;
