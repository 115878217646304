import { GlobalState } from 'common/Redux/types';
import { createSelector, Selector } from 'reselect';

const selectTimelineEventsState: Selector<
  GlobalState,
  GlobalState['eventsTimeline']
> = (state) => state.eventsTimeline;

export const selectTimelineEvents = createSelector(
  selectTimelineEventsState,
  (state) => state.events
);

export const selectTimelinePeakEvents = createSelector(
  selectTimelineEventsState,
  (state) => state.peakEvents
);

export const selectIsEventsTimelineVisible = createSelector(
  [selectTimelinePeakEvents, selectTimelineEvents],
  (peakEvents, events) => peakEvents.length + events.length > 0
);

export const selectIsEventsTimelineDisabled = createSelector(
  selectTimelineEventsState,
  (state) => {
    const { rawState, duration } = state;
    return !rawState || !duration;
  }
);
