import React, { FC } from 'react';
import { colors } from 'common/colors';

export const CheckIcon: FC = React.memo((props) => (
  <svg viewBox="0 0 41 40" {...props}>
    <g clipPath="url(#checkIconClip0)">
      <path d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z" fill="inherit"/>
      <path d="M18.2329 29.1667C17.6329 29.1667 17.0329 28.95 16.5829 28.55L10.5163 23.2167C9.48294 22.3 9.38294 20.7167 10.2996 19.6834C11.2163 18.65 12.7996 18.55 13.8329 19.4667L17.9163 23.0667L26.8829 11.7834C27.7496 10.7 29.3163 10.5167 30.3996 11.3834C31.4829 12.25 31.6663 13.8167 30.7996 14.9L20.1996 28.2334C19.7663 28.7667 19.1496 29.1 18.4663 29.1667C18.3663 29.1667 18.2996 29.1667 18.2329 29.1667Z" fill={colors.white}/>
    </g>
    <defs>
      <clipPath id="checkIconClip0">
        <rect width="40" height="40" fill={colors.white} transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
));
