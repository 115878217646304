import React from 'react';
import styled from 'styled-components';
import { OverlayContainer } from 'components/blocks/Overlays';
import { PreviewThemesContainer } from 'components/blocks/PreviewThemes';
import { VideoFormatSelector } from 'components/blocks/VideoFormatSelector';

export const TemplatesTab = () => {
  return (
    <>
      <StyledTabContainer>
        <VideoFormatSelector />
      </StyledTabContainer>
      <StyledTabContainer>
        <OverlayContainer />
      </StyledTabContainer>
      <StyledTabContainer>
        <PreviewThemesContainer />
      </StyledTabContainer>
    </>
  );
};

const StyledTabContainer = styled.div`
  padding: 8px 0;
`;
