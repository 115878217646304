import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import { noop } from 'lodash';

import {
  StyledText,
  StyledTextarea,
  StyledTextWrapper,
  StyledWrapper,
} from './styles';

const limitTextLength = (text: string, maxLength?: number) =>
  text.substr(0, maxLength);

export const EditableText: FC<{
  text: string;
  onChange?: (text: string) => void;
  maxLength?: number;
  className?: string;
}> = ({ text, onChange = noop, maxLength, className }) => {
  const [isEditing, setEditingStatus] = useState(false);
  const processText = useCallback((text: string) => limitTextLength(text, maxLength), [
    maxLength,
  ]);
  const [inputText, setInputText] = useState(text);

  const updateInputText = useCallback(
    (text: string) => {
      setInputText(processText(text));
    },
    [setInputText, processText]
  );

  const finishEditing = () => {
    setEditingStatus(false);
    if (text !== inputText && inputText.length > 0) onChange(inputText);
  };

  return (
    <StyledWrapper className={className}>
      {isEditing ? (
        <StyledTextarea
          value={inputText}
          onChange={(e: SyntheticEvent) => {
            updateInputText((e.target as HTMLInputElement).value);
          }}
          onBlur={finishEditing}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              finishEditing();
            }
          }}
          maxLength={maxLength}
          autoFocus
        />
      ) : (
        <StyledTextWrapper
          onClick={() => {
            setEditingStatus(true);
            updateInputText(text);
          }}
        >
          <StyledText>{processText(text)}</StyledText>
        </StyledTextWrapper>
      )}
    </StyledWrapper>
  );
};
