import React, { ComponentType, FC, PropsWithChildren, useEffect } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { noop } from 'lodash';

const HookedComponent: FC<PropsWithChildren<{ onMount: () => void; onUnmount: () => void }>> = ({
  onMount,
  onUnmount,
  children,
}) => {
  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  return <>{children}</>;
};

export const HookedRoute: FC<
  {
    component: ComponentType;
    onEnter?: (payload: RouteComponentProps) => void;
    onLeave?: (payload: RouteComponentProps) => void;
  } & RouteProps
> = ({ component: Component, onEnter = noop, onLeave = noop, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(routeProps: RouteComponentProps) => {
        return (
          <HookedComponent
            onMount={() => onEnter(routeProps)}
            onUnmount={() => onLeave(routeProps)}
          >
            <Component {...routeProps} />
          </HookedComponent>
        );
      }}
    />
  );
};
