import styled from 'styled-components';

export const StyledRightPanel = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 12px 16px 6px;
  box-sizing: border-box;
`;

export const StyledButtonsGroup = styled.div`
  margin: 4px -4px;
`;
