import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { StyledFC } from 'types';
import { colors } from 'common/colors';
import { AddIcon } from 'components/ui/Icons/AddIcon'
import { conditionalStyle } from 'utils/styles';
import { RefreshIcon } from 'components/ui/Icons/RefreshIcon';
import { UserFeatures } from 'common/types';
import { FeatureWrapperComponent } from 'components/blocks/FeatureWrapper';

export const AddCustomTheme: StyledFC<{ onClick?: () => void, isLoading: boolean }> = ({
  isLoading,
  onClick,
}) => {
  return <AddCustomTheme.Styled isLoading={isLoading} onClick={onClick}>
    {isLoading ? <StyledRefreshIcon /> : (
      <FeatureWrapperComponent feature={UserFeatures.UploadOwnBackground}>
        <StyledAddIcon />
      </FeatureWrapperComponent>
    )}
  </AddCustomTheme.Styled>
}

const loadingAnimation = keyframes`
  100% {
    transform: rotate(-360deg);
  }
`;

AddCustomTheme.Styled = styled.div<{ isLoading: boolean }>`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 8px;
  stroke: ${colors.white};
  fill: none;

  background-color: ${colors.tangerine};
  border-radius: 50%;
  cursor: pointer;

  ${FeatureWrapperComponent.Styled} {
    width: 100%;
    height: 100%;
  }

  ${conditionalStyle({
    isLoading: css`
      pointer-events: none;
      animation: ${loadingAnimation};
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      background-color: ${colors.grey};
    `,
  })}
`;

const StyledAddIcon = styled(AddIcon)`
  margin: 8px;
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  margin: 4px;
`;