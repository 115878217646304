const MINIMUM_TRIM_INTERVAL = 2;

export function fitStartTrimPosition(ts, { endTrimmPos, duration }) {
  const newState = { startTrimmPos: ts };

  if (duration && newState.startTrimmPos > duration - MINIMUM_TRIM_INTERVAL) {
    newState.startTrimmPos = duration - MINIMUM_TRIM_INTERVAL;
  }
  if (newState.startTrimmPos > endTrimmPos - MINIMUM_TRIM_INTERVAL) {
    newState.endTrimmPos = newState.startTrimmPos + MINIMUM_TRIM_INTERVAL;
  }

  return newState;
}

export function fitEndTrimPosition(ts, { startTrimmPos }) {
  const newState = { endTrimmPos: ts };

  if (newState.endTrimmPos < MINIMUM_TRIM_INTERVAL) {
    newState.endTrimmPos = MINIMUM_TRIM_INTERVAL;
  }
  if (newState.endTrimmPos < startTrimmPos + MINIMUM_TRIM_INTERVAL) {
    newState.startTrimmPos = newState.endTrimmPos - MINIMUM_TRIM_INTERVAL;
  }

  return newState;
}
