import React, { FC, useCallback, useEffect, useState } from 'react';
import { TIME_LINE_INTERVAL } from 'components/blocks/Preview/constants';
import {
  StyledPlayPauseButton,
  StyledPlayPauseOverlay,
  StyledProgressBar,
  StyledVideoPlayer
} from 'components/blocks/SharePopup/components/SharePlayer/styles';
import { PlayPauseIcon } from 'components/ui/Icons/PlayPauseIcon';
import { colors } from 'common/colors';
import ReactPlayer from 'react-player';
import { Project, ProjectAspectRatioValue } from '../../../../../common/types';
import { PreviewOverlayContainer } from '../../../PreviewOverlay';

let serverAddress = process.env.REACT_APP_BELIVE_EDITOR_SERVER;

type SharePlayerProps = {
  className?: string;
  startTrim: number;
  endTrim: number;
  isVisible?: boolean;
  projectSource: string;
  activeProject: Project;
};

export const SharePlayer: FC<SharePlayerProps> = ({
  className,
  startTrim,
  endTrim,
  isVisible = true,
  projectSource,
  activeProject,
}) => {
  const [player, setPlayer] = useState<ReactPlayer>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const trimmedDuration = endTrim - startTrim;
  const isSourceReady = Boolean(projectSource);
  const videoUrl = isSourceReady
    ? `${serverAddress}/hls/${projectSource}/broadcast_.m3u8`
    : undefined;

  const onProgressChange = useCallback(
    (progress: number) => {
      setProgress(progress);
      if (player?.getInternalPlayer()) {
        player.seekTo(progress * trimmedDuration + startTrim);
      }
    },
    [player, startTrim, trimmedDuration]
  );

  useEffect(() => {
    if (!isVisible) {
      setIsPlaying(false);
    }
  }, [isVisible]);

  useEffect(() => {
    onProgressChange(0);
  }, [startTrim, endTrim]);

  const onPlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  const onProgress = (data: { playedSeconds: number }) => {
    const { playedSeconds } = data;
    const progressPosition = playedSeconds - startTrim;

    const finalProgress = Math.min(progressPosition / trimmedDuration, 1);

    if (finalProgress === 1) {
      setIsPlaying(false);
      onProgressChange(0);
    } else {
      setProgress(finalProgress);
    }
  };

  return (
    <PreviewOverlayContainer
      className={className}
      aspectRatio={ProjectAspectRatioValue[activeProject.aspectRatio]}
      contentAspectRatio={16/9}
      content={
        <>
          {projectSource && (
            <StyledVideoPlayer
              // onClick={this.handleVideoClick}
              ref={setPlayer}
              url={videoUrl}
              volume={1}
              muted={false}
              progressInterval={TIME_LINE_INTERVAL}
              // playbackRate={this.state.playbackRate}
              playing={isPlaying}
              // onReady={this.handleTrackPlayerOnReady}
              // onSeek={this.handleTrackPlayerOnSeek}
              // onEnded={this.handleTrackPlayerEnded}
              // onError={(e) => console.log('onError', e)}
              onProgress={onProgress}
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'true',
                  },
                  hlsOptions: {
                    xhrSetup: function (xhr: XMLHttpRequest) {
                      xhr.withCredentials = true;
                    },
                  },
                },
              }}
            />
          )}
          <StyledPlayPauseOverlay isVisible={!isPlaying}>
            <StyledPlayPauseButton onClick={onPlayPauseClick}>
              <PlayPauseIcon isPause={isPlaying} />
            </StyledPlayPauseButton>
          </StyledPlayPauseOverlay>
          <StyledProgressBar
            progress={progress}
            color={colors.yellow}
            onProgressChange={onProgressChange}
          />
        </>
      }
    />
  );
};
