import React from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { MediaButtonGroup } from 'components/blocks/LeftPanel/components/MediaButtonGroup';

export const MediaPlaceholder = () => {
  return (
    <StyledMediaPlaceholder>
      <StyledText>
        All your uploaded files will appear hear. Make a first step
      </StyledText>
      <MediaButtonGroup />
    </StyledMediaPlaceholder>
  );
};

export const StyledMediaPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  border-radius: 16px;
  background: ${colors.disabledBackgroundColor};
  text-align: center;
  user-select: none;
`;

export const StyledText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.summerRabbit};
  margin-bottom: 40px;
`;
