import React from 'react';

export const PriceTagIcon = (props) => {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <g style={{ 'transformOrigin': '50% 50%', 'transform': 'scale(0.65)' }}>
        <defs>
          <mask id="dollar-mask">
            <rect fill="white" x="0" y="0" width="32" height="32" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
              stroke="black"
              strokeWidth="3"
              d="M12.994 15.47c-.466.946-.222 2.229.564 3.015a2.532 2.532 0 0 0 3.58-3.58 2.53 2.53 0 0 1 3.58-3.58c.819.819 1.035 2.12.496 3.085M13.05 18.95l-1.414 1.414M22.243 9.757l-1.415 1.415"
            />
          </mask>
        </defs>
        <path
          className="active"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          mask="url(#dollar-mask)"
          fill="none"
          stroke="#D12A44"
          d="M27.192 4.808l.707 10.606L14.464 28.85 3.151 17.536 16.586 4.1z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          strokeWidth="2"
          d="M27.192 4.808l.707 10.606L14.464 28.85 3.151 17.536 16.586 4.1z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
          strokeWidth="3"
          d="M12.994 15.47c-.466.946-.222 2.229.564 3.015a2.532 2.532 0 0 0 3.58-3.58 2.53 2.53 0 0 1 3.58-3.58c.819.819 1.035 2.12.496 3.085M13.05 18.95l-1.414 1.414M22.243 9.757l-1.415 1.415"
        />
      </g>
    </svg>
  );
};
