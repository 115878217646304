import React, { FC } from 'react';

export const AngleIcon: FC<{ className?: string }> = ({ className }) => (
  <svg className={className} viewBox="0 0 7 12" fill="none">
    <path
      d="M1 1.33301L5.66667 5.99967L1 10.6663"
      stroke="inherit"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
