import React from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { EventCircle } from 'components/blocks/EventsTimeline/components/EventCircle';
import { EVENT_TITLES } from 'components/blocks/EventsTimeline/constants';

export const EventsFilter = ({ filters, onFilterChange, ...restProps }) => {
  return (
    <StyledFilters {...restProps}>
      {Object.keys(filters).map((key) => (
        <StyledFilterButton
          key={key}
          eventType={key}
          text={EVENT_TITLES[key]}
          onClick={() => onFilterChange(key, !filters[key])}
          disabled={!filters[key]}
        />
      ))}
    </StyledFilters>
  );
};

const StyledFilters = styled.div`
  display: flex;
  color: ${colors.darkCyan};
  align-items: center;
`;

const StyledFilterButton = styled(EventCircle)`
  margin-left: 8px;
`;
