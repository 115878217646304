import React, { useCallback, useEffect, useState } from 'react';
import { EventsTimelineArea } from 'components/blocks/EventsTimeline/components/EventsTimelineArea';
import {
  StyledEventsFilter,
  StyledEventsInnerWrapper,
  StyledEventsWrapper,
  StyledProgressLine,
  StyledStaticContainer,
} from 'components/blocks/EventsTimeline/styles';
import { EVENT_TITLES } from 'components/blocks/EventsTimeline/constants';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { EventsGotoStudioMessage } from 'components/blocks/EventsTimeline/components/EventsGoToStudioMessage';

export const EventsTimeline = ({
  scrollLeft = 0,
  containerWidth = 0,
  progressPosition = 0,
  events,
  duration,
  peakEvents,
  TimelinePlayedPosition,
  PreviewSeekTo,
  ...restProps
}) => {
  const [filters, setFilters] = useState(getInitialFilters(events));
  const [filteredEvents, setFilteredEvents] = useState(events);

  const onFilterChange = useCallback(
    (eventType, value) => {
      const newFiltersState = { ...filters, [eventType]: value };
      const newFilteredEventsState = events.filter(
        ({ eventType }) => newFiltersState[eventType],
      );

      //Write event to analytics
      analytics.trackEvent(analyticsEvents.TIMELINE_FILTER_CLICKED);

      setFilters(newFiltersState);
      setFilteredEvents(newFilteredEventsState);
    },
    [filters, setFilters, events, setFilteredEvents],
  );

  useEffect(() => {
    setFilteredEvents(events.filter(({ eventType }) => filters[eventType]));
  }, [setFilteredEvents, events, filters]);

  useEffect(() => {
    setFilters(getInitialFilters(events));
  }, [setFilters, events]);

  return (
    <StyledEventsWrapper>
      <StyledEventsInnerWrapper {...restProps}>
        <StyledProgressLine progressPosition={progressPosition} />
        <EventsTimelineArea
          events={filteredEvents}
          peakEvents={peakEvents}
          duration={duration}
          TimelinePlayedPosition={TimelinePlayedPosition}
          PreviewSeekTo={PreviewSeekTo}
        />
        <StyledEventsFilter
          scrollLeft={scrollLeft}
          filters={filters}
          onFilterChange={onFilterChange}
        />
        <StyledStaticContainer
          scrollLeft={scrollLeft}
          containerWidth={containerWidth}
        >
          {peakEvents.length === 0 && <EventsGotoStudioMessage />}
        </StyledStaticContainer>
      </StyledEventsInnerWrapper>
    </StyledEventsWrapper>
  );
};

function getInitialFilters(events) {
  return events.reduce((acc, { eventType }) => {
    if (!acc[eventType] && EVENT_TITLES[eventType]) {
      acc[eventType] = true;
    }
    return acc;
  }, {});
}
