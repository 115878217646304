import firebase from 'firebase';
import { eventChannel } from 'redux-saga';
import { noop } from 'lodash';
import { Defer } from 'utils/models/Defer';

const { REACT_APP_FIREBASE_STUDIO_DATABASE_URL } = process.env;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_BELIVE_EDITOR_FIREBASE_REPURPOSING_APIKEY,
  authDomain:
    process.env.REACT_APP_BELIVE_EDITOR_FIREBASE_REPURPOSING_AUTHDOMAIN,
  databaseURL:
    process.env.REACT_APP_BELIVE_EDITOR_FIREBASE_REPURPOSING_DATABASE_URL,
};

const firebaseStudioConfig = {
  ...firebaseConfig,
  databaseURL: REACT_APP_FIREBASE_STUDIO_DATABASE_URL,
};

export const FirebaseNull = Symbol('null');

export const videoEditorFirebaseApp = firebase.initializeApp(firebaseConfig);
export const studioFirebaseApp = firebase.initializeApp(
  firebaseStudioConfig,
  'studio'
);

function on(
  event: firebase.database.EventType,
  path: string,
  callback: (val: any) => void,
  app = videoEditorFirebaseApp
): () => void {
  const ref = app.database().ref(path);

  const eventCallback = (snapshot: any) => callback(snapshot.val());
  ref.on(event, eventCallback);

  return () => ref.off(event, eventCallback);
}

const once = (
  node: string | firebase.database.Reference,
  app = videoEditorFirebaseApp
) => {
  return (typeof node === 'string' ? app.database().ref(node) : node)
    .once('value')
    .then((snapshot: any) => snapshot.val());
};

function off<T = {}>(path: string, app = videoEditorFirebaseApp) {
  console.log(path);
  return app.database().ref(path).off('value');
}

function ref(path: string): firebase.database.Reference {
  return firebase.database().ref(path);
}

function signInWithCustomToken(token: string) {
  return firebase.auth(videoEditorFirebaseApp).signInWithCustomToken(token);
}

function subscribeOnUserUpdate(
  userId: number,
  callback: (userData: any) => void = noop
) {
  return on('value', `${refs.users}/${userId}`, callback);
}

function getFeatureRef(featureName: string, userId: number) {
  return getUserRef(userId).child('features').child(featureName);
}

function getUserRef(userId: number) {
  return ref(refs.users).child(userId.toString());
}

export function createEventChannel(
  ref: firebase.database.Reference,
  once = false
) {
  return eventChannel((emit) => {
    const handler: any = once ? ref.once : ref.on;
    handler.call(ref, 'value', (snapshot: firebase.database.DataSnapshot) => {
      if (snapshot.exists()) {
        emit(snapshot.val());
      }
    });
    return () => ref.off();
  });
}

export function waitForUpdateVersion(
  userId: number,
  updateVersion: number
): Promise<number> {
  const defer = new Defer<number>();

  const ref = getUserRef(userId).child('updateVersion');

  const handler = (ss: firebase.database.DataSnapshot) => {
    const val = ss.val() || 1;

    if (val >= updateVersion) {
      ref.off('value', handler);
      defer.resolve(val);
    }
  };

  ref.on('value', handler);

  return defer.promise;
}

const refs = {
  broadcasts: 'belive/broadcasts',
  users: 'belive/users',
};

export const firebaseService = {
  on,
  once,
  off,
  ref,
  refs,
  signInWithCustomToken,

  createEventChannel,
  subscribeOnUserUpdate,
  getUserRef,
  getFeatureRef,
};
