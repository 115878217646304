import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import { VideoIcon } from 'components/ui/Icons/VideoIcon';
import { DeleteButton } from 'components/ui/DeleteButton';
import { conditionalStyle } from 'utils/styles';
import { Button } from '../../../ui/Button';

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledImagePlaceholder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 187px;
  background: ${colors.darkGrey};
  border-radius: 8px;
`;

export const StyledImagePlaceholderIcon = styled(VideoIcon)`
  width: 85px;
  height: 85px;
  fill: ${colors.disabledBackgroundColor};
  transition: transform 0.15s ease-out;
`;

export const StyledEditButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  visibility: hidden;
`;

export const StyledDeleteButton = styled(DeleteButton)`
  visibility: hidden;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
    `}
`;

export const StyledActionButtons = styled.div`
  display: inline-flex;
  position: absolute;
  padding: 4px;
  top: 155px;
`;

export const StyledDisabledCurtain = styled.div`
  transform: translateY(-100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${colors.darkGrey};
  color: ${colors.lightgray};
  font-size: 12px;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
`;

export const StyledWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  margin-right: 8px;
  margin-bottom: 16px;
  width: 187px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  
  :hover {
    ${StyledDeleteButton} {
      visibility: visible;
    }
    
    ${StyledDisabledCurtain} {
      transform: none;
    }
  }
  
  ${({ isDisabled }) => !isDisabled && css`
    :hover {
      ${StyledImagePlaceholderIcon} {
        transform: scale(1.1);
        transition: transform 0.1s ease-in;
      }
      ${StyledEditButton} {
        visibility: visible;
      }
    }
  `};
  
  ${conditionalStyle({
    isDisabled: css`
      cursor: unset;
      ${StyledImagePlaceholder} {
        opacity: 0.8;
      }
    `
  })};
`;
