import React, { FC } from 'react';
import { noop } from 'lodash';

import { BroadcastListItem } from 'components/blocks/BroadcastList/components/BroadcastListItem';
import {
  StyledList,
  StyledListItem,
} from 'components/blocks/BroadcastList/styles';
import { Broadcast } from 'common/types';

export const BroadcastList: FC<{
  broadcasts: Array<Broadcast & { attached?: boolean }>;
  onBroadcastSelect?: (payload: { id: number; title: string }) => void;
  asColumn?: boolean;
}> = ({ broadcasts, onBroadcastSelect = noop, asColumn = false }) => {
  return broadcasts.length ? (
    <StyledList itemsCount={broadcasts.length} asColumn={asColumn}>
      {broadcasts.map(({ id, title, isDisabled, ...broadcastRest }, i) => (
        <StyledListItem key={i}>
          <BroadcastListItem
            {...broadcastRest}
            title={title}
            isDisabled={broadcastRest.attached || isDisabled}
            onClick={() => onBroadcastSelect({ id, title })}
          />
        </StyledListItem>
      ))}
    </StyledList>
  ) : null;
};
