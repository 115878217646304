type SameKeys<T> = { [K in keyof T]: any };

// TODO: outdated! remove all usages
export function createSelector<T>(selectors: T): (state: any) => SameKeys<T> {
  return (state: any) =>
    Object.keys(selectors).reduce<SameKeys<T>>((acc, key) => {
      // @ts-ignore
      acc[key] = selectors[key](state);
      return acc;
    }, {} as SameKeys<T>);
}
