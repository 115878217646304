import React, { FC } from 'react';

export const TrimmerIcon: FC<any> = (props) => {
  return (
    <svg viewBox="0 0 3 11" {...props} stroke="none">
      <rect width="1" height="11" fill="inherit"/>
      <rect width="1" x="2" height="11" fill="inherit"/>
    </svg>
  );
};
