import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { darken } from 'polished';

import { colors } from 'common/colors';

const StyledSimpleButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;

  /* stylelint-disable selector-type-no-unknown  */
  ${(props) => {
    return (
      props.disabled &&
      css`
        color: ${colors.lightgray} !important;
      `
    );
  }}: hover {
    color: ${darken(0.2, colors.lightgray)};
  }
`;

export const SimpleButton = React.memo((props) => {
  return <StyledSimpleButton {...props}>{props.children}</StyledSimpleButton>;
});

SimpleButton.propTypes = {
  children: PropTypes.string,
};
