import {
  PreviewSeekTo,
  TimeLineTrackUpdateTrimmEndPos,
  TimeLineTrackUpdateTrimmStartPos,
} from 'common/Redux';
import { connect } from 'react-redux';
import { TimelineTrimmer } from 'components/blocks/Timeline/TracksArea/TrackTrimmer/TimelineTrimmer';

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    PreviewSeekTo: (position) => dispatch(PreviewSeekTo(position)),
    TimeLineTrackUpdateTrimmStartPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmStartPos(...params)),
    TimeLineTrackUpdateTrimmEndPos: (...params) =>
      dispatch(TimeLineTrackUpdateTrimmEndPos(...params)),
  };
};

export const TrackTrimmer = connect(
  mapStateToProps,
  mapDispachToProps
)(TimelineTrimmer);
