import { connect } from 'react-redux';
import { createSelector } from 'common/Redux/selectors';
import { selectIsPlaying } from 'common/Redux/Preview/selectors';
import { PlayButton } from './PlayButton';
import { togglePreviewPlayingState } from 'common/Redux';
import { selectIsTimelineDisabled } from 'common/Redux/Timeline/selectors';

const mapStateToProps = createSelector({
  isPlaying: selectIsPlaying,
  isDisabled: selectIsTimelineDisabled,
});

const mapDispatchToProps = {
  playPause: togglePreviewPlayingState,
};

export const PlayButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayButton);
