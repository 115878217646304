import React from 'react';

export const BigLikeIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 70 70" fill="none" {...props}>
      <path
        d="M0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35Z"
        fill="url(#like_linear)"
      />
      <rect x="20" y="31" width="8" height="18" rx="2" fill="white" />
      <path
        d="M34.7273 21.3986C34.7273 28.8803 32.2727 31.3741 29 33.0367V45.5061C29.8182 46.3374 31.9455 48 33.9091 48H43.7273C47.3407 48 48.6364 45.5061 47.8182 43.8435C49.5 43.5 50 40.5 48.6364 39.6871C50.5 40 52 36 49.4545 34.6993C52 33 50.8261 28.8803 47 28.8803H40.4545V22.2299C40.4545 18.0735 34.7273 18.0735 34.7273 21.3986Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="like_linear"
          x1="35"
          y1="0"
          x2="35"
          y2="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBC33D" />
          <stop offset="1" stopColor="#E68009" />
        </linearGradient>
      </defs>
    </svg>
  );
});
