import React, { FC } from 'react';
import { map } from 'lodash';
import styled from 'styled-components';

import { ProjectItem } from 'components/pages/StartupPage/components/ProjectItem';
import { ProjectCollection } from 'common/types';

export const ProjectList: FC<{
  projects: ProjectCollection;
  onProjectSelect: (payload: { id: number }) => void;
  onProjectDelete: (payload: { id: number }) => void;
}> = ({ projects, onProjectSelect, onProjectDelete }) => (
  <StyledList>
    {map(projects, ({ name, media }, key) => {
      const id = Number(key);
      const projectMedia = media[+Object.keys(media)[0]];
      const { isStreamReady = true } = projectMedia || {};

      return (
        <ProjectItem
          key={id}
          title={name}
          isDisabled={!isStreamReady}
          disableReason="This recording is no longer available for editing."
          onClick={() => onProjectSelect({ id })}
          onDelete={() => onProjectDelete({ id })}
        />
      );
    }).reverse()}
  </StyledList>
);

const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
