import React, { FC, PropsWithChildren } from 'react';

import { StyledButton } from 'components/ui/Button/styles';
import { BUTTON_SIZES_ENUM, ButtonProps, ButtonType } from 'components/ui/Button/types';

export const Button: FC<PropsWithChildren<ButtonProps>> = React.memo(
  ({
    onClick,
    type: buttonType = ButtonType.Regular,
    disabled: isDisabled = false,
    primary = false,
    size = BUTTON_SIZES_ENUM.S,
    ...restProps
  }) => (
    <StyledButton
      // TODO: Temporary solution to avoid disabled state for button (because disabled state doesnt emit blur events)
      onClick={(isDisabled ? null : onClick) as () => void}
      isDisabled={isDisabled}
      size={size}
      primary={primary}
      buttonType={buttonType}
      {...restProps}
    />
  )
);
