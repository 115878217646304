import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StyledOverlayLayoutWrapper, StyledOverlayList } from 'components/blocks/Overlays/styles';
import { OverlayLayouts, overlayOrder } from 'components/blocks/Overlays/constants';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import {
  analyticsEvents,
  analyticsOverlayNames
} from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { OverlayType } from './types';
import { StyledBlockName } from 'common/styles';

export const Overlays: FC<{
  overlayType?: OverlayType;
  setOverlayType?: (overlayType: OverlayType) => void;
}> = ({ overlayType, setOverlayType = () => {} }) => {
  const [localOverlayType, setLocalOverlayType] = useState(overlayType);

  useEffect(() => {
    if (overlayType !== localOverlayType) {
      setLocalOverlayType(overlayType);
    }
  }, [setLocalOverlayType, overlayType, localOverlayType]);

  const setOverlayLayout = useCallback(
    (newOverlayType: OverlayType) => {
      setOverlayType(newOverlayType);
      setLocalOverlayType(newOverlayType);
      analytics.trackEvent(analyticsEvents.TEXT_OVERLAYS_CLICKED, {
        Type: analyticsOverlayNames[newOverlayType],
      });
    },
    [setOverlayType]
  );

  const layouts = useMemo(
    () =>
      overlayOrder.map((currentOverlayType) => {
        const { OverlayIcon } = OverlayLayouts[currentOverlayType];
        return (
          <StyledOverlayLayoutWrapper
            key={currentOverlayType}
            isActive={localOverlayType === currentOverlayType}
            onClick={() => setOverlayLayout(currentOverlayType)}
          >
            <OverlayIcon />
          </StyledOverlayLayoutWrapper>
        );
      }),
    [localOverlayType, setOverlayLayout]
  );

  return (
    <>
      <StyledBlockName>Templates</StyledBlockName>
      <StyledOverlayList>{layouts}</StyledOverlayList>
    </>
  );
};
