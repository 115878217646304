import styled from 'styled-components';
import loader from 'common/Images/loader.png';
import { CrossIcon } from 'components/ui/Icons/CrossIcon';
import { colors } from 'common/colors';

export const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

export const StyledProcessNotifier = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25), 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 1000;
`;

export const StyledProcessNotifierTitle = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${colors.disabledColor};
  margin-bottom: 8px;
  justify-content: space-between;
  user-select: none;
`;

export const StyledRightTitle = styled.div`
  display: flex;
  align-items: start;
  vertical-align: center;
  text-align: right;
`;

export const StyledCloseButton = styled(CrossIcon)<{ onClick?: () => void }>`
  width: 11px;
  height: 11px;
  margin-left: 8px;
  fill: ${colors.lightgray};
  stroke: ${colors.lightgray};
  cursor: pointer;
`;

export const StyledLine = styled.div<{ percentage: number }>`
  position: relative;
  height: 4px;
  border-radius: 2px;
  background: #dedede;

  &:after {
    position: absolute;
    border-radius: 2px;
    content: '';
    width: ${({ percentage }) => percentage}%;
    height: 100%;
    background: ${colors.yellow};
    transition: 0.3s;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  margin: 16px 0;
`;

export const StyledLoader = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-image: url('${loader}');
  background-repeat: no-repeat;
  background-size: 32px 32px;
`;

export const StyledMessage = styled.div`
  flex-grow: 1;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: 240px;
  color: ${colors.disabledColor};
`;
