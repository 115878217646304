import React, { useEffect, useState } from 'react';
import { noop } from 'lodash';
import {
  StyledButton,
  StyledContent,
  StyledContentLeftColumn,
  StyledContentRightColumn,
  StyledDescriptionTextArea,
  StyledDestinationImage,
  StyledDestinationOption,
  StyledDestinationText,
  StyledLine,
  StyledLoadingWrapper,
  StyledPopupTitle,
  StyledSharePreviewPopup,
  StyledSocialMediaDropDown,
  StyledSocialMediaDropDownArea,
  StyledSocialTitle,
  StyledTitleTextArea,
  StyledVideoPreview,
  StyledVideoStats,
} from 'components/blocks/SharePopup/styles';
import { FullscreenPopup } from 'components/ui/FullscreenPopup';
import {
  facebookLogin,
  getGroups,
  getPages,
  initFacebookSdk,
} from 'services/facebookService';
import { SocialNetworks } from 'components/blocks/SocialPopupDropDown/constants';
import {
  FacebookDestination,
  FacebookDestinationType,
} from 'components/blocks/SharePopup/types';
import { FacebookPermission } from 'services/facebookService/constants';
import {
  DEFAULT_DESCRIPTION,
  PERMISSIONS_FOR_GROUPS,
  PERMISSIONS_FOR_PAGES,
} from 'components/blocks/SharePopup/constants';
import { Loader } from 'components/ui/Loader';
import { ShareComplete } from 'components/blocks/SharePopup/components/ShareComplete';
import { StyledCloseButton } from 'components/ui/Popup/styles';
import { socialNetworkOptions } from 'components/blocks/SocialPopupDropDown';
import { ShareFailed } from 'components/blocks/SharePopup/components/ShareFailed';
import { firebaseService, studioFirebaseApp } from 'services/firebase';
import { InstallFacebookApp } from 'components/blocks/SharePopup/components/InstallFacebookApp';
import { ButtonType } from '../../ui/Button/types';

const { REACT_APP_DISABLE_FACEBOOK_GROUP_VALIDATION } = process.env;

export enum SharePopupState {
  IDLE,
  PROCESSING,
  DONE,
  FAIL,
}

export const SharePopup = ({
  state = SharePopupState.IDLE,
  shareLink = '',
  isVisible = true,
  onClose = noop,
  onShare = noop,
  ...restProps
}) => {
  const [facebookDestinations, setFacebookDestinations] = useState<
    FacebookDestination[]
  >();
  const [socialNetwork, setSocialNetwork] = useState<SocialNetworks>();
  const [
    facebookDestination,
    setFacebookDestination,
  ] = useState<FacebookDestination>();
  const [facebookAccessToken, setFacebookToken] = useState();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
  const [isFBGroupAppInstalled, setIsFBGroupAppInstalled] = useState(true);
  const [groupFirebaseListener, setGroupFirebaseListener] = useState({
    off: () => {},
  });

  const isFBGroupAppCheckDisabled =
    REACT_APP_DISABLE_FACEBOOK_GROUP_VALIDATION?.toLowerCase() === 'true';
  const isFBGroup = facebookDestination?.type === FacebookDestinationType.GROUP;
  const isDestinationReady = Boolean(
    socialNetwork &&
      facebookDestination &&
      (!isFBGroup || isFBGroupAppInstalled || isFBGroupAppCheckDisabled)
  );

  useEffect(() => {
    if (socialNetwork === SocialNetworks.Facebook) {
      initFacebookSdk()
        .then(() =>
          facebookLogin([
            FacebookPermission.PUBLIC_PROFILE,
            ...PERMISSIONS_FOR_PAGES,
            ...PERMISSIONS_FOR_GROUPS,
          ])
        )
        .then(async ({ authResponse: { accessToken: access_token } }: any) => {
          const pages = await getPages();
          const groups = await getGroups(true);

          setFacebookToken(access_token);
          setFacebookDestinations([
            /*{ ...myPage, name: 'My own timeline' },*/
            ...pages.data.map((data) => ({
              ...data,
              type: FacebookDestinationType.PAGE,
            })),
            ...groups.data.map((data) => ({
              ...data,
              type: FacebookDestinationType.GROUP,
              access_token,
            })),
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [socialNetwork]);

  const facebookDestinationOptions = facebookDestinations?.map((value) => ({
    label: (
      <StyledDestinationOption>
        <StyledDestinationImage image={value.picture?.data.url} />
        <StyledDestinationText title={value.name}>
          {value.name}
        </StyledDestinationText>
      </StyledDestinationOption>
    ),
    value,
  }));

  const onDestinationChange = async ({
    value,
  }: {
    value: FacebookDestination;
  }) => {
    setFacebookDestination(value);
    if (
      value.type === FacebookDestinationType.GROUP &&
      !isFBGroupAppCheckDisabled
    ) {
      groupFirebaseListener.off();
      const off = firebaseService.on(
        'value',
        `belive/groups/${value.id}`,
        (data) => {
          const { installed = false } = data || {};
          setIsFBGroupAppInstalled(Boolean(installed));
        },
        studioFirebaseApp
      );
      setGroupFirebaseListener({ off });
    }
  };

  return (
    <FullscreenPopup isVisible={isVisible} onClose={onClose} customPopup>
      <StyledSharePreviewPopup {...restProps}>
        <StyledPopupTitle>
          <span>Share Settings</span>
          <StyledCloseButton onClick={onClose} />
        </StyledPopupTitle>
        <StyledLine />
        <StyledContent>
          {(state === SharePopupState.IDLE ||
            state === SharePopupState.PROCESSING) && (
            <>
              <StyledContentLeftColumn>
                <StyledVideoPreview isVisible={isVisible} />
                <StyledVideoStats />
              </StyledContentLeftColumn>
              <StyledContentRightColumn>
                <StyledSocialTitle>Social Network</StyledSocialTitle>
                <StyledSocialMediaDropDownArea>
                  <StyledSocialMediaDropDown
                    onChange={({ value }: { value: SocialNetworks }) =>
                      setSocialNetwork(value)
                    }
                    value={socialNetworkOptions.find(
                      ({ value }) => value === socialNetwork
                    )}
                  />
                </StyledSocialMediaDropDownArea>
                <StyledSocialTitle>Share To</StyledSocialTitle>
                {socialNetwork === SocialNetworks.Facebook && (
                  <>
                    <StyledSocialMediaDropDownArea>
                      <StyledSocialMediaDropDown
                        placeholder="Select..."
                        options={facebookDestinationOptions}
                        onChange={onDestinationChange}
                        closeMenuOnSelect={true}
                        isMulti={false}
                        isSearchable={false}
                        maxMenuHeight={220}
                        value={facebookDestinationOptions?.find(
                          ({ value }) => value === facebookDestination
                        )}
                      />
                    </StyledSocialMediaDropDownArea>
                    {isFBGroup && !isFBGroupAppInstalled && (
                      <InstallFacebookApp
                        destinationId={facebookDestination?.id}
                      />
                    )}
                  </>
                )}
                <StyledTitleTextArea
                  placeholder="Post Title..."
                  maxLength={100}
                  disabled={!isDestinationReady}
                  onChange={(value: string) => setTitle(value)}
                  value={title}
                  emoji
                />
                <StyledDescriptionTextArea
                  placeholder="Post Description..."
                  maxLength={100}
                  disabled={!isDestinationReady}
                  onChange={(value: string) => setDescription(value)}
                  value={description}
                  emoji
                />
                <StyledButton
                  type={ButtonType.Primary}
                  disabled={!isDestinationReady}
                  onClick={() => {
                    onShare({
                      title,
                      description,
                      facebookDestinationId: facebookDestination?.id,
                      facebookTokens: {
                        user: facebookAccessToken,
                        destination: facebookDestination?.access_token,
                      },
                      facebookDestinationType: facebookDestination?.type,
                    });
                  }}
                >
                  Share
                </StyledButton>
              </StyledContentRightColumn>
            </>
          )}
          {state === SharePopupState.PROCESSING && (
            <StyledLoadingWrapper>
              <Loader />
            </StyledLoadingWrapper>
          )}
          {state === SharePopupState.DONE && <ShareComplete href={shareLink} />}
          {state === SharePopupState.FAIL && <ShareFailed />}
        </StyledContent>
      </StyledSharePreviewPopup>
    </FullscreenPopup>
  );
};
