export type FacebookDestination = {
  id: string;
  name: string;
  access_token: string;
  type: FacebookDestinationType,
  privacy?: string;
  picture?: {
    data: {
      width: number;
      height: number;
      is_silhouette: boolean;
      url: string;
    };
  };
};

export enum FacebookDestinationType {
  TIMELINE = 'timeline',
  GROUP = 'group',
  PAGE = 'page',
}
