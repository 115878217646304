import React, { FC } from 'react';

export const CameraIcon: FC = ({ ...props }) => {
  return (
    <svg viewBox="0 0 19 13" {...props} stroke="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.08 3.47999L17.4 0.240015C17.4742 0.165846 17.5483 0.137513 17.6508 0.0983563C17.7142 0.0741602 17.7883 0.0458311 17.88 0L17.88 3.10242e-05C18 0.120021 18.12 0.240013 18.12 0.479993V11.52C18.12 11.76 18 11.88 17.88 12L17.88 12L17.88 12C17.76 12.12 17.76 12.12 17.64 12.12C17.58 12.12 17.55 12.09 17.52 12.06C17.49 12.03 17.46 12 17.4 12L13.08 8.16002V9.72001C13.08 11.04 12 12.12 10.68 12.12H2.4C1.08 12.12 0 11.04 0 9.72001V2.4C0 1.08 1.08 0 2.4 0H10.68C12 0 13.08 1.08 13.08 2.4V3.47999ZM6.5 8C7.60457 8 8.5 7.10457 8.5 6C8.5 4.89543 7.60457 4 6.5 4C5.39543 4 4.5 4.89543 4.5 6C4.5 7.10457 5.39543 8 6.5 8Z"
      />
    </svg>
  );
};
