import { css } from 'styled-components';

export const small = '12px';
export const medium = '14px';
export const large = '16px';
export const extralarge = '18px';

export const InterFont = () => css`
  font-family: 'Inter', sans-serif;
`;

export const InterBoldFont = () => css`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
`;
