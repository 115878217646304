import { connect } from 'react-redux';

import { getTrimDurationSelector } from 'common/Redux/Timeline/selectors';
import { createSelector } from 'common/Redux/selectors';
import { VideoStats } from 'components/blocks/SharePopup/components/VideoStats/VideoStats';

const mapStateToProps = createSelector({
  duration: getTrimDurationSelector,
});

export const VideoStatsContainer = connect(mapStateToProps)(VideoStats);
