import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { CheckIcon } from 'components/ui/Icons/CheckIcon';
import { Link } from 'components/ui/Link';

export const ShareComplete: FC<{ href: string }> = ({ href }) => {
  return (
    <StyledShareComplete>
      <StyledCheckIcon />
      <StyledText>
        <span>We’ve sent your post to Facebook.</span>
        <br />
        <span>It may take few seconds before it will be visible.</span>
      </StyledText>
      <StyledLink primary blank href={href}>
        Open My Post
      </StyledLink>
    </StyledShareComplete>
  );
};

const StyledShareComplete = styled.div`
  width: 560px;
  height: 200px;
  padding: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledCheckIcon = styled(CheckIcon)`
  fill: ${colors.lightGreen};
  width: 40px;
  height: 40px;
  margin: 0 auto 16px;
`;

const StyledText = styled.div`
  line-height: 24px;
  size: 16px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  margin: 30px auto 0;
`;
