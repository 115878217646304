import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { ThemeIndicator, ThemeIndicatorProps } from './ThemeIndicator';
import { colors } from 'common/colors';
import { CrossIcon } from 'components/ui/Icons/CrossIcon';
import { requests } from 'common/requests';
import { ConfirmationTooltip } from 'components/ui/ConfirmationTooltip';
import { PLACEMENT_ENUM } from 'components/ui/Tooltip';

export const CustomThemeIndicator: FC<ThemeIndicatorProps> = ({ theme, ...props }) => {

  const onDelete = () => requests.removeCustomBackground();

  return (
    <ThemeIndicator
      theme={theme}
      withImage={true}
      {...props}
    >
      <ConfirmationTooltip
        placement={PLACEMENT_ENUM.TOP}
        confirmText={'Are you sure?'}
        onConfirm={onDelete}
        offset={[4, -16]}
      >
        <StyledClose>
          <CrossIcon />
        </StyledClose>
      </ConfirmationTooltip>
    </ThemeIndicator>
  )
}

const StyledClose = styled.div<{ onClick?: MouseEventHandler }>`
  position: absolute;
  width: 8px;
  height: 8px;
  right: 0;
  top: 0;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${colors.white};
  stroke: ${colors.white};
  transform: translate(1px, -1px);
  border-radius: 50%;
  background: ${colors.nightSky};
  box-shadow: 0 0 0 2px ${colors.white};
`;
