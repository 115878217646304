type BorderRadius = { tl: number; tr: number; br: number; bl: number };

type RoundRectConfig = {
  context: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  radius?: BorderRadius | number;
  fill?: boolean;
  stroke?: boolean;
};

export function drawRoundRect(params: RoundRectConfig) {
  const {
    context,
    x,
    y,
    width,
    height,
    radius,
    fill = false,
    stroke = false,
  } = params;
  let radiusConfig = { tl: 0, tr: 0, br: 0, bl: 0 };
  if (typeof radius === 'number') {
    radiusConfig = { tl: radius, tr: radius, br: radius, bl: radius };
  }
  if (typeof radius === 'object') {
    Object.assign(radiusConfig, radius);
  }
  context.beginPath();
  context.moveTo(x + radiusConfig.tl, y);
  context.lineTo(x + width - radiusConfig.tr, y);
  context.quadraticCurveTo(x + width, y, x + width, y + radiusConfig.tr);
  context.lineTo(x + width, y + height - radiusConfig.br);
  context.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radiusConfig.br,
    y + height
  );
  context.lineTo(x + radiusConfig.bl, y + height);
  context.quadraticCurveTo(x, y + height, x, y + height - radiusConfig.bl);
  context.lineTo(x, y + radiusConfig.tl);
  context.quadraticCurveTo(x, y, x + radiusConfig.tl, y);
  context.closePath();
  if (fill) {
    context.fill();
  }
  if (stroke) {
    context.stroke();
  }
}
