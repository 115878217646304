import React from 'react';

export const AmazonProductIcon = (props) => {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <g style={{ 'transform-origin': '50% 55%', 'transform': 'scale(0.5)' }}>
        <path
          d="M17.2176 16.147C9.12164 20 4.09719 16.7763 0.880886 14.8183C0.681862 14.6949 0.343591 14.8472 0.637087 15.1843C1.7086 16.4835 5.22017 19.615 9.80379 19.615C14.3906 19.615 17.1193 17.1123 17.4606 16.6757C17.7997 16.2428 17.5602 16.004 17.2175 16.147H17.2176ZM19.4913 14.8913C19.2739 14.6082 18.1693 14.5554 17.4742 14.6408C16.7779 14.7238 15.7329 15.1493 15.8237 15.4048C15.8704 15.5005 15.9655 15.4575 16.4438 15.4145C16.9234 15.3667 18.267 15.1971 18.5469 15.5631C18.8282 15.9316 18.1184 17.687 17.9888 17.9701C17.8635 18.2533 18.0366 18.3263 18.2719 18.1377C18.5039 17.9492 18.924 17.4611 19.2058 16.7703C19.4858 16.0758 19.6565 15.1069 19.4913 14.8913H19.4913Z"
          fill="inherit"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.774 8.28499C11.774 9.29595 11.7995 10.1391 11.2885 11.0369C10.8761 11.7669 10.2228 12.2158 9.49286 12.2158C8.49644 12.2158 7.91614 11.4566 7.91614 10.3362C7.91614 8.12435 9.89791 7.72292 11.774 7.72292V8.28499ZM14.3908 14.6101C14.2193 14.7633 13.9711 14.7743 13.7777 14.6721C12.9163 13.9567 12.763 13.6246 12.2886 12.9421C10.8652 14.3947 9.85783 14.829 8.01107 14.829C5.8285 14.829 4.12769 13.4822 4.12769 10.7851C4.12769 8.67919 5.27013 7.24479 6.89417 6.54409C8.30303 5.92355 10.2703 5.81408 11.774 5.6426V5.30679C11.774 4.68994 11.8214 3.96001 11.4601 3.42718C11.1425 2.94904 10.5367 2.75194 10.0038 2.75194C9.01473 2.75194 8.13147 3.25924 7.91614 4.31036C7.87229 4.544 7.7008 4.77395 7.46724 4.78488L4.94886 4.51485C4.73722 4.4673 4.50365 4.29582 4.56205 3.97094C5.14235 0.919802 7.89783 0 10.365 0C11.6279 0 13.2775 0.335809 14.274 1.29208C15.5368 2.47091 15.4163 4.04394 15.4163 5.75569V9.79963C15.4163 11.015 15.92 11.5478 16.3945 12.2048C16.5623 12.4384 16.5989 12.7195 16.3871 12.8946C15.8579 13.3362 14.9163 14.1575 14.3981 14.6174L14.3908 14.6101"
          fill="inherit"
          stroke="none"
        />
        <path
          d="M17.2176 16.147C9.12164 20 4.09719 16.7763 0.880886 14.8183C0.681862 14.6949 0.343591 14.8472 0.637087 15.1843C1.7086 16.4835 5.22017 19.615 9.80379 19.615C14.3906 19.615 17.1193 17.1123 17.4606 16.6757C17.7997 16.2428 17.5602 16.004 17.2175 16.147H17.2176ZM19.4913 14.8913C19.2739 14.6082 18.1693 14.5554 17.4742 14.6408C16.7779 14.7238 15.7329 15.1493 15.8237 15.4048C15.8704 15.5005 15.9655 15.4575 16.4438 15.4145C16.9234 15.3667 18.267 15.1971 18.5469 15.5631C18.8282 15.9316 18.1184 17.687 17.9888 17.9701C17.8635 18.2533 18.0366 18.3263 18.2719 18.1377C18.5039 17.9492 18.924 17.4611 19.2058 16.7703C19.4858 16.0758 19.6565 15.1069 19.4913 14.8913H19.4913Z"
          fill="inherit"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.774 8.28499C11.774 9.29595 11.7995 10.1391 11.2885 11.0369C10.8761 11.7669 10.2228 12.2158 9.49286 12.2158C8.49644 12.2158 7.91614 11.4566 7.91614 10.3362C7.91614 8.12435 9.89791 7.72292 11.774 7.72292V8.28499ZM14.3908 14.6101C14.2193 14.7633 13.9711 14.7743 13.7777 14.6721C12.9163 13.9567 12.763 13.6246 12.2886 12.9421C10.8652 14.3947 9.85783 14.829 8.01107 14.829C5.8285 14.829 4.12769 13.4822 4.12769 10.7851C4.12769 8.67919 5.27013 7.24479 6.89417 6.54409C8.30303 5.92355 10.2703 5.81408 11.774 5.6426V5.30679C11.774 4.68994 11.8214 3.96001 11.4601 3.42718C11.1425 2.94904 10.5367 2.75194 10.0038 2.75194C9.01473 2.75194 8.13147 3.25924 7.91614 4.31036C7.87229 4.544 7.7008 4.77395 7.46724 4.78488L4.94886 4.51485C4.73722 4.4673 4.50365 4.29582 4.56205 3.97094C5.14235 0.919802 7.89783 0 10.365 0C11.6279 0 13.2775 0.335809 14.274 1.29208C15.5368 2.47091 15.4163 4.04394 15.4163 5.75569V9.79963C15.4163 11.015 15.92 11.5478 16.3945 12.2048C16.5623 12.4384 16.5989 12.7195 16.3871 12.8946C15.8579 13.3362 14.9163 14.1575 14.3981 14.6174L14.3908 14.6101"
          fill="inherit"
          stroke="none"
        />
      </g>
    </svg>
  );
};
