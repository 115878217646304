import styled, { css } from 'styled-components';
import { colors } from './colors';

export const ActiveStyle = css`
  box-shadow: 0 0 0 4px ${colors.yellow};
`;

export const StyledBlockName = styled.div`
  padding: 16px 8px 16px 0;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.summerRabbit};
`;

export const NewStyle = css`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    top: 0;
    transform: translate(1px, -1px);
    border-radius: 50%;
    background: linear-gradient(to bottom, #ff9f0e, #ef5254);
    box-shadow: 0 0 0 2px ${colors.white};
  }
`;
