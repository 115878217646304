import styled, { css } from 'styled-components';
import { ProjectName } from 'components/blocks/LeftPanel/components/ProjectName';
import { colors } from 'common/colors';
import { conditionalStyle } from 'utils/styles';
import { MediaButtonGroup } from 'components/blocks/LeftPanel/components/MediaButtonGroup';
import { Media } from 'components/ui/Media';

export const StyledMediaContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 16px 23px;
`;

export const StyledMediaContentHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  
  label {
    font-weight: bold;
  }
`;
export const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledProjectNameWrapper = styled.div`
  width: 248px;

  // reserve place for placeholder
  min-height: 62px;
`;

export const StyledProjectName = styled(ProjectName)`
  display: inline;
`;

export const StyledMediaTabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.lightgray3};
`;

export const StyledTab = styled.div<{ active: boolean }>`
  font-size: 12px;
  line-height: 16px;
  padding: 6px 22px;
  cursor: pointer;
  margin-bottom: -1px;
  color: ${colors.summerRabbit};

  ${conditionalStyle({
    active: css`
      cursor: default;
      color: ${colors.tangerine};
      border-bottom: 1px solid ${colors.tangerine};
    `,
  })}
`;

export const StyledTabContent = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-y: scroll;
  padding-top: 16px;
`;

export const StyledMediaButtonGroup = styled(MediaButtonGroup)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledMediaWrapper = styled.div``;

export const StyledMedia = styled(Media)`
  margin: 0 14px 7px 0;
`;
