import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from 'common/colors';
import { conditionalStyle } from 'utils/styles';
import { OverlayVideoConfig } from 'components/blocks/Overlays/types';

function getVideoChildStyle(
  video: OverlayVideoConfig
): FlattenSimpleInterpolation {
  return css`
    width: ${video.width}%;
    height: ${video.height}%;
    left: ${video.left}%;
    top: ${video.top}%;
  `;
}

export const StyledOverlay = styled.div<{ isReversed: boolean }>`
  position: absolute;
  left: 5%;
  top: 5%;
  width: calc(100% - 10%);
  height: calc(100% - 10%);
  
  display: flex;
  flex-direction: ${({ isReversed }) => isReversed ? 'column-reverse' : 'column'};
  align-items: center;
  gap: 3%;
`;

export const StyledOverlayContentBounder = styled.div<{ maxHeight: number, maxWidth: number }>`
  ${({ maxWidth, maxHeight }) => css`
    max-width: ${maxWidth}%;
    max-height: ${maxHeight}%;
    width: 100%;
    position: relative;
    overflow: hidden;
  `};
`;

export const StyledOverlayARKeeper = styled.div<{ aspectRatio: number }>`
  ${({ aspectRatio }) => css`
    position: relative;
    aspect-ratio: ${aspectRatio};
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  `};
`;

export const StyledOverlayContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledContentWrapper = styled.div<{ aspectRatio: number }>`
  position: relative;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  max-width: 100%;
  max-height: 100%;
`;

export const StyledPreviewOverlay = styled.div<{ aspectRatio: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

export const StyledBackground = styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-size: cover;
  background-position: center;
`;

export const StyledCanvas = styled.canvas`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const StyledHoverTextArea = styled.textarea<{ interactive: boolean }>`
  text-align: center;
  border: 1px solid ${colors.lightgray};
  background-color: ${colors.white};
  border-radius: 4px;
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  flex-grow: 1;

  resize: none;
  outline: none;

  opacity: 0;
  transition: opacity 0.1s;

  &:hover,
  &:focus {
    opacity: 1;
  }

  ${conditionalStyle({
    interactive: css`
      pointer-events: auto;
    `,
  })}
`;
