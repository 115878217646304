import { connect } from 'react-redux';
import { Download } from 'components/blocks/Download/Download';
import { getDownloadStatusSelector } from 'components/blocks/Download/selectors';
import { toggleDownloadingAction } from 'components/blocks/Download/actions';
import {
  getVideoRectSelector,
  selectColorThemeType,
  selectCurrentOverlayType
} from 'common/Redux/Preview/selectors';
import { selectProjectId } from 'common/Redux/Project/selectors';
import { selectUserId } from 'common/Redux/Auth/selectors';

const mapStateToProps = (state) => {
  return {
    status: getDownloadStatusSelector(state),
    isDownloadButtonDisabled: !Boolean(state.TimeLine.framesExtracted),
    userId: selectUserId(state),
    accessToken: state.Auth.accessToken,
    overlayType: selectCurrentOverlayType(state),
    videoRect: getVideoRectSelector(state),
    themeType: selectColorThemeType(state),
    projectId: selectProjectId(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleStatus: (status) => dispatch(toggleDownloadingAction(status)),
  };
};

export const DownloadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Download);
