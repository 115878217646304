import React, { FC, PropsWithChildren, RefObject } from 'react';
import RCTooltip from 'rc-tooltip';
import classnames from 'classnames';
import 'rc-tooltip/assets/bootstrap.css';

export const TOOLTIP_CLASS_PREFIX = 'bl-tooltip';
export const TOOLTIP_DELAY = 0.3;
export const TRIGGER_ENUM = {
  CLICK: 'click',
  HOVER: 'hover',
  FOCUS: 'focus',
  NONE: '',
};

export enum PLACEMENT_ENUM {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  RIGHT_TOP = 'rightTop',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_RIGHT = 'bottomRight',
}

export const THEME_ENUM = {
  DARK: 'dark-theme',
  DARK_GRAY: 'dark-gray-theme',
  LIGHT: 'light-theme',
};

const hoverSupported = window.matchMedia('(hover: hover)').matches;

type TooltipProps = {
  overlay: React.ReactNode;
  overlayClass?: string;
  offset?: number[];
  hide?: boolean;
  tooltipRef?: RefObject<any>;
  hideArrow?: boolean;
  theme?: string;
  hover?: boolean;
  trigger?: string | string[];
  placement?: string;
  mouseEnterDelay?: number;
  withPaddings?: boolean;
  visible?: boolean;
  onVisibleChange?: (isVisible: boolean) => void;
  getTooltipContainer?: () => HTMLElement;
};

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = React.memo((props) => {
  const {
    offset,
    hide,
    theme,
    overlayClass,
    trigger,
    mouseEnterDelay,
    hover,
    withPaddings,
    tooltipRef,
    hideArrow,
    children,
  } = props;
  const className = classnames(overlayClass, theme, {
    hover,
    'with-paddings': withPaddings,
    'hide-arrow': hideArrow,
  });
  const disableTooltip = !hoverSupported && trigger === TRIGGER_ENUM.HOVER;
  const internalProps: { visible?: boolean } = {};
  if (hide || disableTooltip) internalProps.visible = false;

  return (
    <RCTooltip
      {...internalProps}
      overlayClassName={className}
      prefixCls={TOOLTIP_CLASS_PREFIX}
      align={{ offset }}
      ref={tooltipRef}
      trigger={trigger}
      transitionName="bl-tooltip-fade"
      arrowContent={<div className="bl-tooltip-arrow-inner" />}
      mouseEnterDelay={mouseEnterDelay}
      {...props}
    >
      {children as any}
    </RCTooltip>
  );
});

Tooltip.defaultProps = {
  placement: PLACEMENT_ENUM.RIGHT,
  trigger: TRIGGER_ENUM.HOVER,
  theme: THEME_ENUM.LIGHT,
  mouseEnterDelay: TOOLTIP_DELAY,
  hover: false,
  withPaddings: true,
};
