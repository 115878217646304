export enum Repurposing_Broadcasts_Statuses {
  HLS_Convert_Started = 'HLS_Convert_Started',
  HLS_Convert_OnProgress = 'HLS_Convert_OnProgress',
  HLS_Convert_Stopped_By_Error = 'HLS_Convert_Stopped_By_Error',
  HLS_Convert_Stopped_By_Duration = 'HLS_Convert_Stopped_By_Duration',
  HLS_Convert_Finished = 'HLS_Convert_Finished',
  TRIMM_Stopped_By_Error = 'TRIMM_Stopped_By_Error',
  TRIMM_Prepare_Started = 'TRIMM_Prepare_Started',
  TRIMM_Prepare_OnProgress = 'TRIMM_Prepare_OnProgress',
  TRIMM_Prepare_Finished = 'TRIMM_Prepare_Finished',
  TRIMM_Download_Started = 'TRIMM_Download_Started',
  TRIMM_Download_Finished = 'TRIMM_Download_Finished',
}
