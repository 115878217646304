import React, { FC, PropsWithChildren, SyntheticEvent, useCallback, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { conditionalStyle, opacity } from 'utils/styles';
import { colors } from 'common/colors';

const ANIMATION_TIME = 300;

export const FullscreenPopup: FC<
  PropsWithChildren<{
    isVisible?: boolean;
    onClose?: (event: SyntheticEvent) => void;
    customPopup?: boolean;
  }>
> = ({
  isVisible = true,
  customPopup = false,
  children,
  onClose = () => {},
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const onClick = (event: SyntheticEvent) => {
    const { target } = event;
    if (target === ref.current) {
      onClose(event);
    }
  };

  return (
    <StyledFullscreenPopup ref={ref} isVisible={isVisible} onClick={onClick}>
      <StyledContent
        customPopup={customPopup}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </StyledContent>
    </StyledFullscreenPopup>
  );
};

const StyledContent = styled.div<{ customPopup: boolean }>`
  ${({ customPopup }) =>
    !customPopup &&
    css`
      width: 560px;
      padding: 24px;
      background-color: ${colors.white};
      border-radius: 8px;
    `}
`;

const appearAnimation = keyframes`
  from {
    transform: translateY(-50%) perspective(800px) scale(0.5) rotateX(90deg);
    opacity: 0;
    display: flex;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const disappearAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(-50%) perspective(800px) scale(0.5) rotateX(90deg);
    opacity: 0;
  }
`;

const StyledFullscreenPopup = styled.div<{ isVisible: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${opacity(colors.black, 0.6)};
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: opacity ${ANIMATION_TIME}ms;

  ${StyledContent} {
    animation: ${disappearAnimation} ${ANIMATION_TIME}ms;
  }

  ${conditionalStyle({
    isVisible: css`
      opacity: 1;
      backdrop-filter: blur(8px);
      pointer-events: initial;

      ${StyledContent} {
        animation: ${appearAnimation} ${ANIMATION_TIME}ms;
      }
    `,
  })}
`;
