import { createGlobalStyle } from 'styled-components';

export const TooltipGlobalStyle = createGlobalStyle`
  .bl-tooltip {
    position: absolute;
    z-index: 1071;
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.5;
    &:not(.hover) {
      pointer-events: none;
    }
  }
  .bl-tooltip-hidden {
    display: none;
  }
  .bl-tooltip-placement-top,
  .bl-tooltip-placement-topLeft,
  .bl-tooltip-placement-topRight {
    padding: 5px 0 9px 0;
  }
  .bl-tooltip-placement-right,
  .bl-tooltip-placement-rightTop,
  .bl-tooltip-placement-rightBottom {
    padding: 0 5px 0 9px;
  }
  .bl-tooltip-placement-bottom,
  .bl-tooltip-placement-bottomLeft,
  .bl-tooltip-placement-bottomRight {
    padding: 9px 0 5px 0;
  }
  .bl-tooltip-placement-left,
  .bl-tooltip-placement-leftTop,
  .bl-tooltip-placement-leftBottom {
    padding: 0 9px 0 5px;
  }
  .bl-tooltip-inner {
    text-align: left;
    text-decoration: none;
  }
  .with-paddings .bl-tooltip-inner {
    padding: 8px;
  }
  .bl-tooltip-arrow,
  .bl-tooltip-arrow-inner {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .bl-tooltip-placement-top .bl-tooltip-arrow,
  .bl-tooltip-placement-topLeft .bl-tooltip-arrow,
  .bl-tooltip-placement-topRight .bl-tooltip-arrow {
    bottom: 4px;
    margin-left: -6px;
    border-width: 6px 6px 0;
  }
  .bl-tooltip-placement-top .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-topLeft .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-topRight .bl-tooltip-arrow-inner {
    margin-left: -5px;
    border-width: 5px 5px 0;
  }
  .bl-tooltip-placement-top .bl-tooltip-arrow {
    left: 50%;
  }
  .bl-tooltip-placement-topLeft .bl-tooltip-arrow {
    left: 15%;
  }
  .bl-tooltip-placement-topRight .bl-tooltip-arrow {
    right: 15%;
  }
  .bl-tooltip-placement-right .bl-tooltip-arrow,
  .bl-tooltip-placement-rightTop .bl-tooltip-arrow,
  .bl-tooltip-placement-rightBottom .bl-tooltip-arrow {
    left: 4px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
  }
  .bl-tooltip-placement-right .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-rightTop .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-rightBottom .bl-tooltip-arrow-inner {
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
  }
  .bl-tooltip-placement-right .bl-tooltip-arrow {
    top: 50%;
  }
  .bl-tooltip-placement-rightTop .bl-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .bl-tooltip-placement-rightBottom .bl-tooltip-arrow {
    bottom: 15%;
  }
  .bl-tooltip-placement-left .bl-tooltip-arrow,
  .bl-tooltip-placement-leftTop .bl-tooltip-arrow,
  .bl-tooltip-placement-leftBottom .bl-tooltip-arrow {
    right: 4px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
  }
  .bl-tooltip-placement-left .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-leftTop .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-leftBottom .bl-tooltip-arrow-inner {
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
  }
  .bl-tooltip-placement-left .bl-tooltip-arrow {
    top: 50%;
  }
  .bl-tooltip-placement-leftTop .bl-tooltip-arrow {
    top: 15%;
    margin-top: 0;
  }
  .bl-tooltip-placement-leftBottom .bl-tooltip-arrow {
    bottom: 15%;
  }
  .bl-tooltip-placement-bottom .bl-tooltip-arrow,
  .bl-tooltip-placement-bottomLeft .bl-tooltip-arrow,
  .bl-tooltip-placement-bottomRight .bl-tooltip-arrow {
    top: 4px;
    margin-left: -6px;
    border-width: 0 6px 6px;
  }
  .bl-tooltip-placement-bottom .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-bottomLeft .bl-tooltip-arrow-inner,
  .bl-tooltip-placement-bottomRight .bl-tooltip-arrow-inner {
    margin-left: -5px;
    border-width: 0 5px 5px;
  }
  .bl-tooltip-placement-bottom .bl-tooltip-arrow {
    left: 50%;
  }
  .bl-tooltip-placement-bottomLeft .bl-tooltip-arrow {
    left: 15%;
  }
  .bl-tooltip-placement-bottomRight .bl-tooltip-arrow {
    right: 15%;
  }
  .dark-theme.bl-tooltip-placement-bottom .bl-tooltip-arrow,
  .dark-theme.bl-tooltip-placement-bottomLeft .bl-tooltip-arrow,
  .dark-theme.bl-tooltip-placement-bottomRight .bl-tooltip-arrow {
    top: 2px;
  }

  .bl-tooltip.bl-tooltip-zoom-enter,
  .bl-tooltip.bl-tooltip-zoom-leave,
  .bl-tooltip.bl-tooltip-fade-enter,
  .bl-tooltip.bl-tooltip-fade-leave {
    display: block;
  }
  .bl-tooltip-zoom-enter,
  .bl-tooltip-zoom-appear,
  .bl-tooltip-fade-enter,
  .bl-tooltip-fade-appear {
    opacity: 0;
    animation-duration: .1s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-play-state: paused;
  }
  .bl-tooltip-zoom-enter,
  .bl-tooltip-zoom-appear {
    transform: scale(0);
    animation-timing-function: ease-out;
  }
  .bl-tooltip-zoom-leave,
  .bl-tooltip-fade-leave {
    animation-duration: .1s;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-play-state: paused;
  }
  .bl-tooltip-zoom-leave {
    animation-timing-function: ease-in;
  }
  .bl-tooltip-fade-enter.bl-tooltip-fade-enter-active,
  .bl-tooltip-fade-appear.bl-tooltip-fade-appear-active {
    animation-name: blToolTipFadeIn;
    animation-play-state: running;
  }
  .bl-tooltip-fade-leave.bl-tooltip-fade-leave-active {
    animation-name: blToolTipFadeOut;
    animation-play-state: running;
  }
  
  .bl-tooltip-zoom-enter.bl-tooltip-zoom-enter-active,
  .bl-tooltip-zoom-appear.bl-tooltip-zoom-appear-active {
    animation-name: blToolTipZoomIn;
    animation-play-state: running;
  }
  .bl-tooltip-zoom-leave.bl-tooltip-zoom-leave-active {
    animation-name: blToolTipZoomOut;
    animation-play-state: running;
  }

  @keyframes blToolTipZoomIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes blToolTipZoomOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes blToolTipFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blToolTipFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
