import React, { FC } from 'react';
import { FullscreenPopup } from 'components/ui/FullscreenPopup';
import { ButtonType } from 'components/ui/Button/types';
import {
  StyledButton,
  StyledButtons,
  StyledCloseIcon,
  StyledText,
  StyledTitle,
  StyledTitleIcon,
  StyledWrapper,
} from 'components/blocks/Timeline/TracksArea/Track/components/ActivityTimelineIntroPopup/styles';
import { openUrl } from 'utils/naviagation';
import URLs from 'common/urls';

export const ActivityTimelineIntroPopup: FC<{
  isVisible: boolean;
  onClose: () => void;
}> = (props) => {
  return (
    <FullscreenPopup {...props}>
      <StyledWrapper>
        <StyledTitleIcon />
        <StyledCloseIcon onClick={props.onClose} />
        <StyledTitle>Activity Timeline</StyledTitle>
        <StyledText>
          Broadcast Activity Timeline allows you to see highlights of your
          recorded Stream, it shows you when audience’s attention and feedback
          was peaking, what events and media were used and more.
          <br />
          <br />
          You can then use it for your advantage to quickly edit your videos
          around such moments for maximum effect!
        </StyledText>
        <StyledButtons>
          <StyledButton type={ButtonType.Secondary} onClick={props.onClose}>
            Back to Editor
          </StyledButton>
          <StyledButton
            type={ButtonType.Primary}
            onClick={() =>
              openUrl(URLs.knowledgeCenter.audienceActivity, { newTab: true })
            }
          >
            Learn More
          </StyledButton>
        </StyledButtons>
      </StyledWrapper>
    </FullscreenPopup>
  );
};
