import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  hideNotifier,
  togglePreviewPlayingState,
  showNotifier,
} from 'common/Redux';
import { Repurposing_Broadcasts_Statuses } from 'components/pages/HomePage/HomepageEnums.ts';
import { PreviewContainer } from 'components/blocks/Preview';
import { Timeline } from 'components/blocks/Timeline/Timeline';
import { ProcessNotifierTypes } from 'components/ui/ProcessNotifier/ProcessNotifierEnums.ts';
import { updateNotifier } from 'common/Redux/Notifier/NotifierActions';
import 'components/pages/HomePage/HomePage.css';
import { analytics } from 'common/3rdUtils/analytics/analytics';
import {
  isDownloadCanceledSelector,
  isDownloadPreparationSelector,
} from 'components/blocks/Download/selectors';
import { DOWNLOAD_STATUSES } from 'components/blocks/Download/constants';
import { toggleDownloadingAction } from 'components/blocks/Download/actions';
import {
  StyledBody,
  StyledContent,
  StyledFooter,
  StyledPage,
  StyledPanel,
  StyledWrapper,
} from 'components/pages/HomePage/styles';
import { RightPanel } from 'components/blocks/RightPanel';
import { LeftPanel } from 'components/blocks/LeftPanel';
import { EDITABLE_NODES } from 'components/pages/HomePage/constants';
import { firebaseService } from 'services/firebase';
import { getCookie } from 'utils/helpers';
import ToolboxSidebar from 'components/blocks/ToolboxSidebar';
import { requestOpenProject } from 'common/Redux/Project/actions';
import {
  selectActiveProject,
  selectActiveProjectActiveMediaSource,
} from 'common/Redux/Project/selectors';
import { updateUserMedia, updateUserProjects } from 'common/Redux/User/actions';
import { selectUserProjectCollection } from 'common/Redux/User/selectors';
import { requests } from 'common/requests';
import {
  setBroadcastId,
  videoUrlUpdated,
} from 'common/Redux/BroadcastsList/BroadcastsListActions';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import {
  selectActiveToolboxItem,
  TOOLBOX_ITEM_TYPES,
} from 'common/Redux/Toolbox';
import { MediaContent } from 'components/pages/HomePage/components/MediaContent';
import { openUrl } from 'utils/naviagation';

let ServerAddress = process.env.REACT_APP_BELIVE_EDITOR_SERVER;
let StudioServerAddress = process.env.REACT_APP_BELIVE_BE_URL;

let firebaseUpdateUnsubscribe = () => {};

class HomePage extends React.Component {
  state = {
    projectId: this.props.match.params.id,
    TimelineHeight: window.innerHeight * 0.2,
    isUnsupportedBrowserPopupVisible: false,
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.projectActiveMediaSource !== this.props.projectActiveMediaSource
    ) {
      firebaseUpdateUnsubscribe();
      firebaseUpdateUnsubscribe = firebaseService.on(
        'value',
        `${firebaseService.refs.broadcasts}/${this.props.projectActiveMediaSource}`,
        this.handleUpdatesFromFirebase
      );
    }
  };

  componentDidMount = async () => {
    const { projectId } = this.state;
    this.openProject({ id: projectId });

    document.addEventListener('keydown', this.handleKeyDown);

    try {
      const { projectActiveMediaSource } = this.props;

      if (projectActiveMediaSource) {
        firebaseUpdateUnsubscribe = firebaseService.on(
          'value',
          `${firebaseService.refs.broadcasts}/${this.props.projectActiveMediaSource}`,
          this.handleUpdatesFromFirebase
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  componentWillUnmount = () => {
    try {
      document.removeEventListener('keydown', this.handleKeyDown);
    } catch (e) {
      console.error(e);
    }
  };

  render = () => {
    const {
      projectActiveMediaSource,
      activeToolboxItem,
      activeProject,
    } = this.props;
    const isEditorTabOpen = activeToolboxItem === TOOLBOX_ITEM_TYPES.EDITOR;
    const isMediaTabOpen = activeToolboxItem === TOOLBOX_ITEM_TYPES.MEDIA;

    return activeProject ? (
      <StyledPage>
        <ToolboxSidebar />
        <StyledWrapper>
          <StyledBody>
            {isEditorTabOpen && <LeftPanel />}
            <StyledContent>
              <PreviewContainer isVisible={isEditorTabOpen} />
              {isMediaTabOpen && <MediaContent />}
            </StyledContent>
            <StyledPanel>
              <RightPanel projectSource={projectActiveMediaSource} />
            </StyledPanel>
          </StyledBody>
          <StyledFooter>
            <Timeline {...this.props} />
          </StyledFooter>
        </StyledWrapper>
      </StyledPage>
    ) : null;
  };

  handleKeyDown = (event) => {
    const { keyCode, target: { nodeName } = {} } = event;
    if (keyCode === 32) {
      if (EDITABLE_NODES.includes(nodeName.toLowerCase())) return;
      this.props.PreviewControlsPlayPause();
      event.preventDefault();
    }
  };

  handleLoadVideo = (broadcastId) => {
    //Check if we have converted video for the requested BroadcastId
    //If Not then start conversion and will load it in the end, if yes it will loaded it now.
    const body = { broadcastId };

    requests
      .isBroadcastExists(body)
      .then((data) => {
        if (data) {
          if (!data.exist) {
            if (!isNaN(broadcastId)) {
              console.error("Can't find converted video start to Convert");
              requests.startBroadcastConversion(body);
            }
          } else {
            this.props.videoUrlUpdated(
              `${ServerAddress}/hls/${broadcastId}/broadcast_.m3u8`
            );
          }
        } else {
          this.props.showNotifier({
            Type: ProcessNotifierTypes.BROADCAST_NOT_FOUND,
          });
          openUrl(
            `${StudioServerAddress}/users/${this.props.Auth.me.id}/broadcasts`,
            {
              newTab: false,
              delay: 3000,
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleUpdatesFromFirebase = (property) => {
    const { isDownloadCancelled, isDownloadPreparation } = this.props;
    const { projectId } = this.state;

    if (property === null) return;

    switch (property.status) {
      case Repurposing_Broadcasts_Statuses.HLS_Convert_Started:
        console.log('Started!!!');
        this.props.showNotifier({
          Type: ProcessNotifierTypes.WAIT_FOR_CONVERT,
          percentage: 0,
        });
        break;
      case Repurposing_Broadcasts_Statuses.HLS_Convert_Finished:
        this.props.videoUrlUpdated(
          `${ServerAddress}/hls/${projectId}/broadcast_.m3u8`
        );
        this.props.hideNotifier();
        break;
      case Repurposing_Broadcasts_Statuses.HLS_Convert_Stopped_By_Error:
        this.props.hideNotifier();
        break;
      case Repurposing_Broadcasts_Statuses.HLS_Convert_Stopped_By_Duration:
        this.props.showNotifier({
          Type: ProcessNotifierTypes.BROADCAST_IS_TOO_LONG,
        });
        break;
      case Repurposing_Broadcasts_Statuses.TRIMM_Prepare_Finished:
        if (isDownloadCancelled) {
          analytics.trackEvent(analyticsEvents.DOWNLOAD_PREP_CANCELED);
        } else {
          if (isDownloadPreparation) {
            console.log('Finished to prepare the Trimmed file for download.');
            this.props.toggleDownloadStatus(DOWNLOAD_STATUSES.DOWNLOADING);
            this.handleStartDownload();
          }
        }
        break;
      case Repurposing_Broadcasts_Statuses.TRIMM_Download_Finished:
        this.props.hideNotifier();
        break;
      case Repurposing_Broadcasts_Statuses.HLS_Convert_OnProgress:
        this.props.updateNotifier({ percentage: property.percentage });
        break;
      default:
        break;
    }
  };

  handleStartDownload = async () => {
    try {
      const getDownloadFileAddress = `${ServerAddress}/downloadTrimmedFile/${
        this.props.projectActiveMediaSource
      }`;
      console.log('Start To Download: ', getDownloadFileAddress);
      window.location.assign(getDownloadFileAddress);
    } catch (e) {
      console.log(e);
    }
  };

  closeProject = () => {};

  openProject = ({ id }) => {
    const { openProject } = this.props;
    openProject(+id);
  };
}

const mapState = (state) => {
  return {
    activeToolboxItem: selectActiveToolboxItem(state),
    isDownloadPreparation: isDownloadPreparationSelector(state),
    isDownloadCancelled: isDownloadCanceledSelector(state),
    activeProject: selectActiveProject(state),
    projectActiveMediaSource: selectActiveProjectActiveMediaSource(state),
    projects: selectUserProjectCollection(state),
    ...state,
  };
};

const mapDispatch = {
  videoUrlUpdated,
  setBroadcastId,
  PreviewControlsPlayPause: togglePreviewPlayingState,
  showNotifier,
  updateNotifier,
  hideNotifier,
  toggleDownloadStatus: toggleDownloadingAction,
  updateUserProjects,
  updateUserMedia,
  openProject: requestOpenProject,
};

const AppWithRouter = withRouter(HomePage);

export const HomePageContainer = connect(mapState, mapDispatch)(AppWithRouter);
