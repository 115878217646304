import {
  BIG_ICON_SIZE,
  MAX_LINE_SIZE,
  MIN_LINE_SIZE,
  SMALL_ICON_SIZE,
} from 'components/blocks/EventsTimeline/components/PeakEvent/constants';

export function getIconSize({ size }) {
  return (size / 100) * (BIG_ICON_SIZE - SMALL_ICON_SIZE) + SMALL_ICON_SIZE;
}

export function getLineSize({ size }) {
  return (size / 100) * (MAX_LINE_SIZE - MIN_LINE_SIZE) + MIN_LINE_SIZE;
}
