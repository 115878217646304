import { PROJECTS_UPDATE, USER_MEDIA_UPDATE } from 'common/Redux/User/actions';
import { UserReducerType, UserState } from 'common/Redux/User/types';
import { DataFetchingStatuses } from 'common/types';
import produce, { Draft } from 'immer';
import { PayloadAction } from '@reduxjs/toolkit';

const initialState: UserState = {
  projects: {
    fetchingStatus: DataFetchingStatuses.Idle,
    collection: {},
  },
  media: {},
};

export const UserReducer = produce((draft: Draft<UserState>, action: PayloadAction<any>) => {
  switch (action.type) {
    case PROJECTS_UPDATE:
      draft.projects.fetchingStatus = DataFetchingStatuses.Resolved;
      draft.projects.collection = action.payload;
      break;

    case USER_MEDIA_UPDATE:
      draft.media = action.payload;
      break;
  }
}, initialState) as UserReducerType;
