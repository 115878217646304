import React, { FC } from 'react';
import { connect } from 'react-redux';

import { Toolbox } from 'components/ui/Toolbox';
import {
  StyledMediaCollectionIcon,
  StyledTemplateIcon,
  StyledToolbox,
} from 'components/blocks/ToolboxSidebar/styles';
import {
  selectActiveToolboxItem,
  TOOLBOX_ITEM_TYPES,
  updateActiveToolboxItem,
} from 'common/Redux/Toolbox';
import { createSelector } from 'common/Redux/selectors';
import { Support } from 'components/ui/Support/Support';
import { pausePreviewPlayer } from 'common/Redux';
import { analyticsEvents } from '../../../common/3rdUtils/analytics/analyticsEventsEnums';
import { analytics } from '../../../common/3rdUtils/analytics/analytics';
import { isShoppableProjectSelector } from '../../../common/Redux/Project/selectors';

const ToolboxSidebar: FC<{
  activeItem: string | null;
  setActiveItem: (item: TOOLBOX_ITEM_TYPES) => void;
  isShoppableProject: boolean;
}> = ({ activeItem, setActiveItem, isShoppableProject }) => {

  const setActiveIconWithAnalytics: typeof setActiveItem = (type) => {
    setActiveItem(type);

    switch (type) {
      case TOOLBOX_ITEM_TYPES.EDITOR:
        return analytics.trackEvent(analyticsEvents.EDITOR_TAB_CLICKED);

      case TOOLBOX_ITEM_TYPES.MEDIA:
        return analytics.trackEvent(analyticsEvents.MEDIA_TAB_CLICKED);
    }
  };

  return (
    <StyledToolbox activeItemId={activeItem}>
      <Toolbox.Item
        key={TOOLBOX_ITEM_TYPES.MEDIA}
        itemId={TOOLBOX_ITEM_TYPES.MEDIA}
        name="Media"
        icon={<StyledMediaCollectionIcon />}
        onClick={() => setActiveIconWithAnalytics(TOOLBOX_ITEM_TYPES.MEDIA)}
      />
      <Toolbox.Item
        key={TOOLBOX_ITEM_TYPES.EDITOR}
        itemId={TOOLBOX_ITEM_TYPES.EDITOR}
        name={isShoppableProject ? 'Shoppable Video' : 'Editor'}
        icon={<StyledTemplateIcon />}
        onClick={() => setActiveIconWithAnalytics(TOOLBOX_ITEM_TYPES.EDITOR)}
      />
      <Toolbox.Footer>
        <Toolbox.Item>
          <Support />
        </Toolbox.Item>
      </Toolbox.Footer>
    </StyledToolbox>
  );
};

const mapState = createSelector({
  activeItem: selectActiveToolboxItem,
  isShoppableProject: isShoppableProjectSelector,
});

const mapDispatch = {
  setActiveItem: updateActiveToolboxItem,
  pausePreviewPlayer,
};

export default connect(mapState, mapDispatch)(ToolboxSidebar);
