import styled, {css} from 'styled-components';
import { conditionalStyle } from 'utils/styles';
import { ActiveStyle } from 'common/styles';
import { colors } from 'common/colors';

export const StyledOverlayList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
`;

export const StyledOverlayLayoutWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 72px;
  height: 72px;
  margin: 8px;

  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.1s;
  cursor: pointer;

  ${conditionalStyle({ isActive: ActiveStyle })};
`;
