import styled, { css } from 'styled-components';
import { BackArrowIcon } from 'components/ui/Icons/BackArrowIcon';
import { Button } from 'components/ui/Button/Button';
import { ForwardArrowIcon } from 'components/ui/Icons/ForwardArrowIcon';
import { colors } from 'common/colors';

export const StyledUndoIcon = styled(BackArrowIcon)`
  height: 100%;
  stroke: ${colors.disabledColor};
`;

export const StyledRedoIcon = styled(ForwardArrowIcon)`
  height: 100%;
  stroke: ${colors.disabledColor};
`;

export const StyledButton = styled(Button)`
  padding: 3px 4px;
  height: 24px;
  display: flex;
  align-items: center;
  & > * {
    margin: 2px;
  }

  svg {
    ${({ disabled }) =>
      disabled &&
      css`
        stroke: ${colors.disabledBackgroundColor};
      `};
  }
`;
