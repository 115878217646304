import styled, { css } from 'styled-components';

export const StyledCommonTrack = styled.div`
  background: #b1b1b1 !important;
  border-radius: 2px !important;
`;

export const StyledHorizontalTrack = styled(StyledCommonTrack)`
  height: 4px !important;
  width: calc(100% - 12px) !important;
  left: 6px !important;
  bottom: 6px !important;
  background: transparent !important;
  ${({ noScrollX }) =>
    noScrollX &&
    css`
      display: none;
    `}
`;

export const StyledVerticalTrack = styled(StyledCommonTrack)`
  width: 4px !important;
  height: calc(100% - 12px) !important;
  right: 6px !important;
  top: 6px !important;
  background: transparent !important;
`;
