import { Userpilot } from 'userpilot';

let token = process.env.REACT_APP_BELIVE_USERPILOT_TOKEN;

const UserPilotInit = () => {
  //Initialize UserPilot
  Userpilot.initialize(token);
};

const UserPilotIdentify = (user) => {
  //Identify userDetails by Auth (Me) details
  Userpilot.identify(user.id, {
    name: user.name,
    email: '',
    created_at: user.subscription.created_at,
  });
};

export { UserPilotInit, UserPilotIdentify };
