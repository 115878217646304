import { colors } from 'common/colors';

export const textDefault = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.14,
  letterSpacing: 'normal',
  fontFamily: '-apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
};
const overrides = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    width: 'calc(100% - 2px)', // calc is added because menu is wider than container
    marginTop: 0,
    marginLeft: 1,
    marginBottom: 0,
    borderRadius: '0 0 4px 4px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    scrollMargin: '0 10px 0 0',
    borderRadius: '0 0 4px 4px',
    overflowY: 'unset',
  }),
  control: (provided, { menuIsOpen, selectProps }) => ({
    ...provided,
    height: selectProps.controlHeight || 40,
    borderColor: colors.lightgray,
    borderRadius: menuIsOpen ? '4px 4px 0 0' : 4,
    boxShadow: 'none',
    ':hover': {
      borderColor: colors.lightgray,
    },
  }),
  input: (provided) => ({
    ...provided,
    ...textDefault,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...textDefault,
    color: colors.greyishBrown,
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    ...textDefault,
    fontWeight: 400,
    color: colors.brownGrey,
  }),
  option: (provided, state) => ({
    ...provided,
    ...textDefault,
    height: state.data.optionHeight || 40,
    borderTop: state.data.separatorAbove ? '1px solid #DEDEDE' : 'none',
    display: 'flex',
    alignItems: 'center',
    background: state.isSelected ? colors.lightgray2 : colors.white,
    color: colors.greyishBrown,
    padding: '8px',

    ':active': {
      background: colors.brownGrey,
    },

    ':hover': {
      background:
        state.data.addNewOption || state.data.disabled
          ? 'inherit'
          : colors.lightgray2,
      filter: state.data.addNewOption ? 'brightness(1.1)' : '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px 16px 8px 8px',
    pointerEvents: 'none', // added because for some reason clicking on dropdown indicator closes
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  noOptionsMessage: (provided) => {
    return {
      ...provided,
      paddingTop: '2px',
      paddingBottom: '4px',
    };
  },
};

export default overrides;
