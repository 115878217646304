import {
  PREVIEW_CONTROL_NOT_ENDED,
  PREVIEW_CONTROL_PAUSE,
  PREVIEW_CONTROL_PLAY,
  PREVIEW_SEEK_TO,
  SELECTED_MEDIA,
  SET_COLOR_THEME,
  SET_PROJECT_OVERLAY,
  SET_TITLES_ACTION,
  SET_ASPECT_RATIO,
  SET_VIDEO_RECT,
} from './PreviewTypes';

import {
  ON_CONTROL_NOT_ENDED,
  ON_CONTROL_PAUSE,
  ON_CONTROL_PLAY,
  ON_PROGRESS_SEEKTO,
  ON_SELECTED_MEDIA,
  UPDATED_VIDEOURL,
} from '../Constants';
import { OverlayType } from 'components/blocks/Overlays/types';
import { defaultTitles, defaultTheme } from 'common/Redux/Preview/constants';
import { ProjectAspectRatio } from '../../types';

const initialState = {
  playing: false,
  currentSpeedSelected: 1,
  ended: false,
  overlayType: OverlayType.Default,
  aspectRatio: ProjectAspectRatio.Square1x1,
  themeType: defaultTheme,
  titles: defaultTitles,
  videoRect: { left: 0, top: 0, width: 0, height: 0 },
};

const PreviewReducer = (state = initialState, action) => {
  let result;
  switch (action.type) {
    case PREVIEW_CONTROL_PLAY:
      result = {
        ...state,
        controlEvent: ON_CONTROL_PLAY,
        playing: true,
      };
      break;
    case PREVIEW_CONTROL_PAUSE:
      result = {
        ...state,
        controlEvent: ON_CONTROL_PAUSE,
        playing: false,
      };
      break;
    case PREVIEW_CONTROL_NOT_ENDED:
      result = {
        ...state,
        controlEvent: ON_CONTROL_NOT_ENDED,
        ended: false,
      };
      break;
    case PREVIEW_SEEK_TO:
      result = {
        ...state,
        controlEvent: ON_PROGRESS_SEEKTO,
        seekToPosition: action.seekToPosition,
      };
      break;
    case SELECTED_MEDIA:
      result = {
        ...state,
        controlEvent: ON_SELECTED_MEDIA,
      };
      break;
    case UPDATED_VIDEOURL:
      result = {
        ...state,
        videoUrl: action.payload,
      };
      break;
    case SET_PROJECT_OVERLAY:
      result = {
        ...state,
        overlayType: action.payload,
      };
      break;
    case SET_ASPECT_RATIO:
      result = {
        ...state,
        aspectRatio: action.payload,
      };
      break;
    case SET_COLOR_THEME:
      result = {
        ...state,
        themeType: action.payload,
      };
      break;
    case SET_TITLES_ACTION:
      result = {
        ...state,
        titles: action.payload,
      };
      break;
    case SET_VIDEO_RECT:
      result = {
        ...state,
        videoRect: action.payload,
      };
      break;
    default:
      result = state;
  }
  return result;
};

export default PreviewReducer;
