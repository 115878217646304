import { RectPosition } from '../../../Overlays/types';

export enum ALIGNMENT {
  LEFT,
  RIGHT,
  CENTER,
}

export enum VERTICAL_ALIGNMENT {
  TOP,
  BOTTOM,
  CENTER,
}

export type DrawLinesParams = {
  lines: string[];
  context: CanvasRenderingContext2D;
  align: ALIGNMENT;
  position: RectPosition;
  dry: boolean;
  offsetY: number;
  size: number;
};
