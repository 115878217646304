import React, { FC } from 'react';
import date from 'date-and-time';
import { StyledVideoStats } from 'components/blocks/SharePopup/components/VideoStats/styles';

export const VideoStats: FC<{ duration: number; className?: string }> = ({
  duration,
  className,
}) => {
  const durationDate = new Date(duration * 1000);
  const isDurationLongerThenHour = duration > 60 * 60;
  const timeFormat = isDurationLongerThenHour ? 'h:mm:ss' : 'm:ss';

  return (
    <StyledVideoStats className={className}>
      <div>{date.format(durationDate, timeFormat, true)}</div>
    </StyledVideoStats>
  );
};
