import { FacebookPermission } from 'services/facebookService/constants';

export const DEFAULT_DESCRIPTION =
  'I’ve made this clip with the Be.Live Video Editor http://be.live/editor';

export const PERMISSIONS_FOR_GROUPS = [
  FacebookPermission.PUBLISH_TO_GROUPS,
  FacebookPermission.GROUP_ACCESS_MEMBER_INFO,
];
export const PERMISSIONS_FOR_PAGES = [
  FacebookPermission.PAGES_READ_USER_CONTENT,
  FacebookPermission.PAGES_MANAGE_POSTS,
  FacebookPermission.PAGES_MANAGE_ENGAGEMENT,
];
