import React from 'react';
import styled from 'styled-components';
import { colors } from 'common/colors';
import { PreviewPlaceholderIcon } from 'components/ui/Icons/PreviewPlaceholderIcon';

export const PlayerPlaceholder = () => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledPreviewPlaceholderIcon />
        <StyledText>Click “Media” to get started</StyledText>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: ${colors.lightgray3};
  border: 3px dashed ${colors.disabledBackgroundColor};
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInnerWrapper = styled.div`
  text-align: center;
`;

export const StyledPreviewPlaceholderIcon = styled(PreviewPlaceholderIcon)`
  width: 100px;
  height: 40px;
  margin-bottom: 20px;
  fill: ${colors.grey};
`;

export const StyledText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.summerRabbit};
`;
