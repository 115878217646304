import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TrimmedData } from 'components/pages/SupervisorPage/types';
import { TrimmedDataRow } from 'components/pages/SupervisorPage/components/TrimmedDataRow';
import { requests } from 'common/requests';
import { openUrl } from 'utils/naviagation';

export const SupervisorPage: FC = () => {
  const [trimmedHistory, setTrimmedHistory] = useState<TrimmedData[]>();

  useEffect(() => {
    requests.geTrimmedVideoList().then(setTrimmedHistory);
  }, []);

  return trimmedHistory ? (
    <StyledList>
      {trimmedHistory.map((data, index) => {
        return (
          <TrimmedDataRow
            data={data}
            key={index}
            onClick={() => openUrl(data.signedPath)}
          />
        );
      })}
    </StyledList>
  ) : (
    <div>loading</div>
  );
};

const StyledList = styled.div`
  display: flex;
  margin-top: 36px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
