import React from 'react';

export const VideoIcon = React.memo(({ className }) => {
  return (
    <svg viewBox="0 0 32 32" className={className}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C7.89543 7 7 7.89543 7 9V23C7 24.1046 7.89543 25 9 25H23C24.1046 25 25 24.1046 25 23V9C25 7.89543 24.1046 7 23 7H9ZM9 9H23V23H9V9ZM13 20.3301L20.5 16L13 11.6699L13 20.3301Z"
        fill="inherit"
      />
    </svg>
  );
});
