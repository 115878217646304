import React, { useState } from 'react';
import styled from 'styled-components';
import { Checklist } from '../../ui/Checklist';
import {
  amazonGuidelinesChecklist,
  shoppableVideoCreationChecklist,
} from './checklists';
import { RenderIfShoppable } from '../../ui/RenderIfShoppable';
import { useChecklists } from './useChecklist';

export const ChecklistContainer = ({
  onShoppableVideoGuidelinesComplete,
  onAmazonGuidelinesComplete,
}) => {
  const [openChecklistId, setOpenChecklist] = useState(null);

  const { checklists, onChecklistItemChecked } = useChecklists({
    checklistId: openChecklistId,
  });

  const onChange = (id, checked) => {
    onChecklistItemChecked(id, checked);
  };

  const onOpen = (id) => {
    setOpenChecklist(id);
  };

  return (
    <ChecklistContainer.Styled>
      <RenderIfShoppable>
        <Checklist
          key="shoppableVideoCreation"
          id="shoppableVideoCreation"
          onComplete={onShoppableVideoGuidelinesComplete}
          onChange={onChange}
          isOpen={openChecklistId === 'shoppableVideoCreation'}
          onOpenCallback={onOpen}
          items={checklists.shoppable}
        >
          <Checklist.ArrowIcon />
          <div>
            <Checklist.Header>
              {shoppableVideoCreationChecklist.title}
            </Checklist.Header>
            <Checklist.Progress
              title={shoppableVideoCreationChecklist.description}
            />
          </div>
          <Checklist.Content />
        </Checklist>
        <Checklist
          key="amazonGuidelines"
          id="amazonGuidelines"
          onComplete={onAmazonGuidelinesComplete}
          onChange={onChange}
          isOpen={openChecklistId === 'amazonGuidelines'}
          onOpenCallback={onOpen}
          items={checklists.amazon}
        >
          <Checklist.ArrowIcon />
          <div>
            <Checklist.Header>
              {amazonGuidelinesChecklist.title}
            </Checklist.Header>
            <Checklist.Progress title={amazonGuidelinesChecklist.description} />
          </div>
          <Checklist.Content />
        </Checklist>
      </RenderIfShoppable>
    </ChecklistContainer.Styled>
  );
};

ChecklistContainer.Styled = styled.div`
  flex-grow: 1;
  z-index: 15;
`;
