import React from 'react';

export const BackArrowIcon = React.memo(({ ...props }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.5 14.5L4 10M4 10L8.5 5.5M4 10H16C18.2091 10 20 11.7909 20 14V14C20 16.2091 18.2091 18 16 18H14"
        stroke="inherit"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
