import { call, put, select, take } from 'redux-saga/effects';
import { selectUserId } from 'common/Redux/Auth/selectors';
import { firebaseService } from 'services/firebase';
import {
  hideNotifier,
  showNotifier,
  updateNotifier,
} from 'common/Redux/Notifier/NotifierActions';
import { ProcessNotifierTypes } from 'components/ui/ProcessNotifier/ProcessNotifierEnums';
import { ProcessStatus } from 'services/processes/types';

export function* displayProcess(processKey: string) {
  const userId = yield select(selectUserId);

  const ref = firebaseService
    .ref(firebaseService.refs.users)
    .child(userId.toString())
    .child('processes')
    .child(processKey);

  yield put(
    showNotifier({
      Type: ProcessNotifierTypes.WAIT_FOR_CONVERT,
      percentage: 0,
    })
  );

  const channel = yield call(firebaseService.createEventChannel, ref);

  while (true) {
    const process = yield take(channel);

    if (process) {
      const { status, progress } = process;

      switch (status) {
        case ProcessStatus.INIT:
        case ProcessStatus.PROCESSING:
          yield put(
            updateNotifier({
              Type: ProcessNotifierTypes.WAIT_FOR_CONVERT,
              percentage: progress,
            })
          );
          break;

        default:
          yield put(hideNotifier());
          break;
      }
    } else {
      yield put(hideNotifier());
      channel.close();
    }
  }
}
