import { DOWNLOAD_STATUSES, TOGGLE_DOWNLOAD_STATUS_ACTION } from 'components/blocks/Download/constants';

const initialState = {
  status: DOWNLOAD_STATUSES.IDLE,
};

export const DownloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DOWNLOAD_STATUS_ACTION:
      return { ...state, status: action.payload };

    default:
      return state;
  }
};
