import React from 'react';

export const TitleIcon = React.memo((props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3327 6.6665H8.66602C7.53268 6.6665 6.66602 7.53317 6.66602 8.6665V15.3332C6.66602 16.4665 7.53268 17.3332 8.66602 17.3332H15.3327C16.466 17.3332 17.3327 16.4665 17.3327 15.3332V8.6665C17.3327 7.53317 16.466 6.6665 15.3327 6.6665ZM15.9993 15.3332C15.9993 15.7332 15.7327 15.9998 15.3327 15.9998H8.66602C8.26602 15.9998 7.99935 15.7332 7.99935 15.3332V8.6665C7.99935 8.2665 8.26602 7.99984 8.66602 7.99984H15.3327C15.7327 7.99984 15.9993 8.2665 15.9993 8.6665V15.3332Z"
        fill="inherit"
      />
      <path
        stroke="none"
        d="M13.3327 13.3335H9.33268C8.93268 13.3335 8.66602 13.6002 8.66602 14.0002C8.66602 14.4002 8.93268 14.6668 9.33268 14.6668H13.3327C13.7327 14.6668 13.9993 14.4002 13.9993 14.0002C13.9993 13.6002 13.7327 13.3335 13.3327 13.3335Z"
        fill="inherit"
      />
    </svg>
  );
});
