import TextareaAutosize from 'react-autosize-textarea';
import styled, { css } from 'styled-components';
import { colors } from 'common/colors';
import { InterBoldFont } from 'common/typography';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: text;
  transition: background 0.2s 0.1s;
  height: 30px;

  :hover {
    background: ${colors.disabledBackgroundColor};
    border-radius: 4px;
  }
`;

const typography = css`
  ${InterBoldFont()}
  font-size: 14px;
  line-height: 30px;
`;

export const StyledTextWrapper = styled.div`
  display: inline-flex;
  padding: 0 10px;
  word-break: break-word;
  min-width: 100px;
  min-height: 30px;
  ${typography}
`;

export const StyledText = styled.div`
  flex: 1;
  color: ${colors.summerRabbit};
`;

export const StyledTextarea = styled(TextareaAutosize)`
  min-height: 30px;
  min-width: 100px;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid ${colors.disabledBackgroundColor};
  font-size: 14px;
  padding: 0px 9px;
  outline: none;
  overflow: hidden;
  overflow-wrap: break-word;
  resize: none;
  color: ${colors.summerRabbit};
  ${typography}
`;
