export const messages = {
  downloadPopupTitle: 'Preparing Video',
  downloadPopupContent:
    'Please wait while the video is trimmed and download. [It can take few minutes]',
  cancel: 'Cancel',
  done: 'Downloading would start in a seconds. Sit back & relax 🙂',
  processMessages: [
    'Please wait while the minions do their work',
    'Grabbing extra minions',
    'Doing the heavy lifting',
    "We're working very Hard .... Really",
    'Waking up the minions',
    'Generating witty dialog...',
    'Swapping time and space...',
    'Bending the spoon...',
    "Don't think of purple hippos...",
    'We need a new fuse...',
    'Upgrading Windows, your PC will restart several times. Sit back and relax.',
    '640K ought to be enough for anybody',
    "We're building the buildings as fast as we can",
    'Would you prefer chicken, steak, or tofu?',
    '(Pay no attention to the man behind the curtain)',
    "Don't worry - a few bits tried to escape, but we caught them",
    'Checking the gravitational constant in your locale...',
    'Go ahead -- hold your breath!',
    "...at least you're not on hold...",
    'The server is powered by a lemon and two electrodes.',
    "We're testing your patience",
    'Follow the white rabbit',
    'While the satellite moves into position',
    'The bits are flowing slowly today',
    "It's still faster than you could draw it",
    "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
    'My other loading screen is much faster.',
    "Testing on Timmy... We're going to need another Timmy.",
    'Are we there yet?',
    'Just count to 10',
    'Do not run! We are your friends!',
    "We're making you a cookie.",
    'Spinning the wheel of fortune...',
    'Loading the enchanted bunny...',
    'Computing chance of success',
    'I feel like im supposed to be loading something...',
    "I swear it's almost done.",
    'Unicorns are at the end of this road, I promise.',
    'Listening for the sound of one hand clapping...',
    "Keeping all the 1's and removing all the 0's...",
    "Making sure all the i's have dots...",
    'We are not liable for any broken screens as a result of waiting.',
    'Where did all the internets go',
    'Granting wishes...',
    'Spinning the hamster…',
    'Load it and they will come',
    'Convincing AI not to turn evil..',
    'There is no spoon. Because we are not done loading it',
    'How did you get here?',
    'Wait, do you smell something burning?',
    'Computing the secret to life, the universe, and everything.',
    'When nothing is going right, go left!!...',
    "I love my job only when I'm on vacation...",
    'Whenever I find the key to success, someone changes the lock.',
    'I’ve got problem for your solution…..',
    'Adults are just kids with money.',
    'We are not liable for any broken screens as a result of waiting.',
    'Hello IT, have you tried turning it off and on again?',
    'Dividing by zero...',
    'If I’m not back in five minutes, just wait longer.',
    'Simulating traveling salesman...',
    'Looking for sense of humour, please hold on.',
    'Please wait while the intern refills his coffee.',
    'Finding someone to hold my beer',
    "Whatever you do, don't look behind you...",
    'Loading funny message...',
    'Feel free to spin in your chair',
    'Mining some bitcoins...',
    'Downloading more RAM..',
    'Alt-F4 speeds things up.',
    'Initializing the initializer...',
    'Optimizing the optimizer...',
    'Pushing pixels...',
    'Building a wall...',
    'Everything in this universe is either a potato or not a potato',
    'Reading Terms and Conditions for you.',
    'You seem like a nice person...',
    'Work, work...',
    'Discovering new ways of making you wait...',
    'Still faster than Windows update',
  ],
};
