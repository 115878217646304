import { Google } from './plugins/google';
import { Amplitude } from './plugins/amplitude';
import { FacebookPixel } from './plugins/facebookPixel';
import { HelpCrunch } from './plugins/helpCrunch';

const allPlugins = [HelpCrunch, Google, Amplitude, FacebookPixel];

export const ANALYTICS_TYPES = {
  GOOGLE: Google,
  AMPLITUDE: Amplitude,
  FACEBOOKPIXEL: FacebookPixel,
  HELP_CRUNCH: HelpCrunch,
};

const isAvailable = ({ ID }) => ID;

const extend = (params) => ({
  ...params,
  App: 'Repurposing',
});

export class Analytics {
  constructor() {
    allPlugins.filter(isAvailable).forEach(({ init = () => {} }) => init());
  }

  pageView(path, plugins = allPlugins) {
    plugins
      .filter(isAvailable)
      .forEach(({ pageView = () => {} }) => pageView(path));
  }

  setUserId(userId) {
    allPlugins
      .filter(isAvailable)
      .forEach(({ setUserId = () => {} }) => setUserId(userId));
  }

  trackEvent(name, params = {}, plugins = allPlugins) {
    const data = extend(params);
    plugins
      .filter(isAvailable)
      .forEach(({ track = () => {} }) => track(name, data));
  }

  static getInstance({ getInstance = async () => null } = {}) {
    return getInstance();
  }
}

export const analytics = new Analytics();
