import React, {
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  StyledBackground,
  StyledCanvas,
  StyledContentWrapper,
  StyledHoverTextArea,
  StyledOverlay,
  StyledOverlayARKeeper,
  StyledOverlayContentBounder,
  StyledOverlayContentWrapper,
  StyledPreviewOverlay,
} from 'components/blocks/PreviewOverlay/styles';
import {
  MAX_TITLE_LENGTH,
  OVERLAY_RESOLUTION,
  SET_TITLE_DELAY
} from 'components/blocks/PreviewOverlay/constants';
import { setCanvas } from 'components/blocks/PreviewOverlay/context';
import { drawText } from 'components/blocks/PreviewOverlay/components/canvasText';
import { PreviewColorTheme, PreviewColorThemeType } from 'components/blocks/PreviewThemes/types';
import {
  ALIGNMENT,
  VERTICAL_ALIGNMENT
} from 'components/blocks/PreviewOverlay/components/canvasText/types';
import { debounce } from 'lodash';
import { OverlayType, RectPosition } from '../Overlays/types';
import { previewColorThemes } from '../PreviewThemes/constants';
import { PLACE_YOUR_TEXT_PLACEHOLDER } from '../../../common/Redux/Preview/constants';

export const PreviewOverlay: FC<{
  overlayType?: OverlayType;
  className?: string;
  interactive?: boolean;
  content: ReactNode;
  themeType: PreviewColorThemeType;
  titles: string[];
  setTitles?: (titles: string[]) => void;
  setVideoRect?: (rect: RectPosition) => void,
  aspectRatio: number;
  contentAspectRatio: number;
  children?: any;
}> = (props) => {
  const {
    overlayType = OverlayType.Default,
    themeType,
    className,
    content,
    interactive = false,
    children,
    titles,
    aspectRatio,
    contentAspectRatio,
    setTitles = () => {},
    setVideoRect = () => {},
  } = props;
  const isOverlaysEnabled = overlayType !== OverlayType.Default;
  const [localTitles, setLocalTitles] = useState(titles);
  const isSecondTitleVisible = overlayType === OverlayType.TwoTitles;
  const isOverlayReversed = overlayType === OverlayType.BottomTitle;

  const overlayRef = useRef() as RefObject<HTMLCanvasElement>;
  const contentRef = useRef() as RefObject<HTMLDivElement>;
  const firstTitleRef = useRef() as RefObject<HTMLTextAreaElement>;
  const secondTitleRef = useRef() as RefObject<HTMLTextAreaElement>;

  useEffect(() => {
    if (overlayRef.current) {
      setCanvas(overlayRef.current);
    }
  });

  useEffect(() => {
    setLocalTitles(titles);
  }, [titles]);

  const resolution = useMemo(() => {
    return {
      width: OVERLAY_RESOLUTION * aspectRatio,
      height: OVERLAY_RESOLUTION
    }
  }, [aspectRatio]);

  useEffect(() => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext('2d');
      if (context) {
        drawOverlay({
          context,
          resolution,
          titles: localTitles,
          theme: previewColorThemes[themeType],
          refs: {
            overlayRef,
            firstTitleRef,
            secondTitleRef,
          }
        });
        setVideoRect(getRefRectPosition(contentRef, overlayRef));
      }
    }
  }, [resolution, localTitles, themeType, aspectRatio, overlayType, overlayRef, overlayRef, firstTitleRef, secondTitleRef]);

  const debouncedSetPreview = useCallback(
    debounce(setTitles, SET_TITLE_DELAY),
    []
  );

  const onTextChange = (text: string, index: number): void => {
    const newTitles = localTitles.slice(0);
    newTitles[index] = text;
    debouncedSetPreview(newTitles);
    setLocalTitles(newTitles);
  };

  return (
    <StyledPreviewOverlay aspectRatio={aspectRatio} className={className}>
      <StyledBackground
        backgroundImage={previewColorThemes[themeType].backgroundImage}
      />
      {isOverlaysEnabled ? (
        <>
          <StyledCanvas
            ref={overlayRef}
            width={resolution.width}
            height={resolution.height}
          />
          <StyledOverlay isReversed={isOverlayReversed}>
            <StyledHoverTextArea
              ref={firstTitleRef}
              placeholder={PLACE_YOUR_TEXT_PLACEHOLDER}
              interactive={interactive}
              maxLength={MAX_TITLE_LENGTH}
              value={localTitles[0]}
              onChange={({ target: { value } }: { target: { value: string } }) =>
                onTextChange(value, 0)
              }
            />

            <StyledOverlayContentBounder maxHeight={66} maxWidth={100}>
              <StyledOverlayARKeeper  aspectRatio={contentAspectRatio}>
                <StyledOverlayContentWrapper ref={contentRef}>
                  {content}
                </StyledOverlayContentWrapper>
              </StyledOverlayARKeeper>
            </StyledOverlayContentBounder>

            {isSecondTitleVisible && (
              <StyledHoverTextArea
                ref={secondTitleRef}
                placeholder={PLACE_YOUR_TEXT_PLACEHOLDER}
                interactive={interactive}
                maxLength={MAX_TITLE_LENGTH}
                value={localTitles[1]}
                onChange={({ target: { value } }: { target: { value: string } }) =>
                  onTextChange(value, 1)
                }
              />
            )}
          </StyledOverlay>
        </>
      ) : (
        <StyledContentWrapper aspectRatio={contentAspectRatio} ref={contentRef}>
          {content}
        </StyledContentWrapper>
      )}
      {children}
    </StyledPreviewOverlay>
  );
};

function getRelativeRectPosition(
  position: RectPosition,
  frameWidth: number,
  frameHeight: number
): RectPosition {
  return {
    left: (position.left * frameWidth) / 100,
    top: (position.top * frameHeight) / 100,
    width: (position.width * frameWidth) / 100,
    height: (position.height * frameHeight) / 100,
  };
}

function getRefRectPosition(childRef: RefObject<HTMLElement>, relativeRef: RefObject<HTMLElement>): RectPosition {
  if (!childRef.current || !relativeRef.current) {
    return {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    };
  }

  const childOffset = childRef.current.getBoundingClientRect();
  const childRect = window.getComputedStyle(childRef.current);
  const relativeOffset = relativeRef.current.getBoundingClientRect();
  const relativeRect = window.getComputedStyle(relativeRef.current);

  return {
    left: (childOffset.x - relativeOffset.x) / parseFloat(relativeRect.width) * 100,
    top: (childOffset.y - relativeOffset.y) / parseFloat(relativeRect.height) * 100,
    width: (parseFloat(childRect.width) / parseFloat(relativeRect.width)) * 100,
    height: (parseFloat(childRect.height) / parseFloat(relativeRect.height)) * 100,
  };
}

function getPositionArray(
  position: RectPosition
): [number, number, number, number] {
  return [position.left, position.top, position.width, position.height];
}

function drawOverlay({
  context,
  titles,
  resolution,
  theme,
  refs: {
    overlayRef,
    firstTitleRef,
    secondTitleRef,
  }
}: {
  context: CanvasRenderingContext2D;
  titles: string[];
  resolution: { width: number; height: number };
  theme: PreviewColorTheme;
  refs: {
    overlayRef: RefObject<HTMLElement>;
    firstTitleRef: RefObject<HTMLElement>;
    secondTitleRef: RefObject<HTMLElement>;
  };
}) {
  context.clearRect(0, 0, resolution.width, resolution.height);
  context.fillStyle = theme.textColor;

  [firstTitleRef, secondTitleRef]
    .filter(ref => Boolean(ref.current))
    .forEach((ref, index) => {
    const positionRect = getRelativeRectPosition(
      getRefRectPosition(ref, overlayRef),
      resolution.width,
      resolution.height
    );

    const title = titles[index] || PLACE_YOUR_TEXT_PLACEHOLDER;
    const { 1: text = '' } = /((\S[\S\s]*)?\S)/g.exec(title) || [];
    if (text.length > 0) {
      drawText(text, context, positionRect, {
        maxFontSize: 120,
        align: ALIGNMENT.CENTER,
        verticalAlign: VERTICAL_ALIGNMENT.CENTER,
        foregroundColor: theme.foregroundColor,
      });
    }
  });
}
