import {
  fitEndTrimPosition,
  fitStartTrimPosition,
} from 'common/Redux/Timeline/utils';

const TrackFrameWidth = 100;
const TrimmerHandlerWidth = 24;

export function calculatePositions(TimeLine, width) {
  const convertTimestampToPosition = (timestamp) =>
    (timestamp * width) / TimeLine.duration;

  return {
    trimmerRangePositionLeft:
      convertTimestampToPosition(TimeLine.startTrimmPos) - TrimmerHandlerWidth,

    trimmerRangePositionWidth:
      convertTimestampToPosition(TimeLine.endTrimmPos) -
      convertTimestampToPosition(TimeLine.startTrimmPos),

    trimmerRangeLeft: convertTimestampToPosition(TimeLine.startTrimmPos),

    trimmerRangeWidth:
      convertTimestampToPosition(TimeLine.endTrimmPos) -
      convertTimestampToPosition(TimeLine.startTrimmPos),

    trimmerRangePositionRight: convertTimestampToPosition(TimeLine.endTrimmPos),

    leftTrimmerPosition: {
      x: convertTimestampToPosition(TimeLine.startTrimmPos),
      y: 0,
    },

    rightTrimmerPosition: {
      x: TimeLine.duration ? convertTimestampToPosition(TimeLine.endTrimmPos) : width,
      y: 0,
    },
  };
}

export function getWidth(ref) {
  if (ref.current) {
    const { width } = window.getComputedStyle(ref.current.parentNode);
    return parseFloat(width);
  }
  return 0;
}

export function handleTrackLineMouseDown({
  mousePositionX,
  TimeLine,
  ref,
  PreviewSeekTo,
}) {
  if (TimeLine.trackFrames === undefined) return;
  let leftOffsetCalc =
    TimeLine.trackFrames.length *
    TrackFrameWidth *
    0.01 *
    (window.innerWidth / window.screen.width);
  let node = ref.current.parentNode;
  const nodeOffsets = node.getBoundingClientRect();
  let mouseClickX = mousePositionX - nodeOffsets.x - leftOffsetCalc;
  if (mouseClickX < 0) mouseClickX = 0;
  let seekToPosition = TimeLine.duration * (mouseClickX / nodeOffsets.width);
  if (seekToPosition < 1) seekToPosition = 1;
  PreviewSeekTo(seekToPosition, true);
}

export function handleLeftTrimmerOnDrag({
  event,
  data,
  props,
  ref,
  saveHistory,
}) {
  let node = ref.current.parentNode;
  const nodeOffsets = node.getBoundingClientRect();
  let ts = (props.TimeLine.duration * data.x) / nodeOffsets.width;
  if (saveHistory) {
    props.TimeLineTrackUpdateTrimmStartPos(ts, saveHistory);
    props.changeTimeline(fitStartTrimPosition(ts, props.TimeLine));
  } else {
    props.changeTimeline({ startTrimmPos: ts });
  }
  event.stopPropagation();
}

export function handleRightTrimmerOnDrag({
  event,
  data,
  props,
  ref,
  saveHistory,
}) {
  let node = ref.current.parentNode;
  const nodeOffsets = node.getBoundingClientRect();
  let ts = (props.TimeLine.duration * data.x) / nodeOffsets.width;
  if (saveHistory) {
    props.TimeLineTrackUpdateTrimmEndPos(ts, saveHistory);
    props.changeTimeline(fitEndTrimPosition(ts, props.TimeLine));
  } else {
    props.changeTimeline({ endTrimmPos: ts });
  }
  event.stopPropagation();
}
