import { connect } from 'react-redux';
import { Header } from 'components/blocks/Header/Header';

const mapStateToProps = (state) => {
  return {
    accessToken: state.Auth.accessToken,
    userId: state.Auth.me.id,
    image: state.Auth.me.image,
    ...state,
  };
};

export const HeaderContainer = connect(mapStateToProps)(Header);
