import { sagaMiddleware } from 'common/sagas/sagaMiddleware';
import { all, call, fork, select, takeEvery } from 'redux-saga/effects';
import { CREATE_PROJECT } from './actions';
import { requests } from 'common/requests';
import { waitForUpdateVersion } from 'services/firebase';
import { selectUserId } from 'common/Redux/Auth/selectors';
import { ActionWithPayload } from 'common/Redux/types';
import { displayProcess } from 'common/Redux/Notifier/sagas';
import { ProjectType } from 'common/types';

function* createProjectSaga(
  action: ActionWithPayload<{
    name: string;
    broadcastId: number;
    type?: ProjectType;
    resolve?: (projectId: number) => void;
    reject?: () => void;
  }>
) {
  const userId = yield select(selectUserId);
  const { broadcastId, name, type, resolve, reject } = action.payload;

  try {
    const res: {
      projectId: number;
      updateVersion: number;
      processKey?: string;
    } = yield call(requests.createProject, {
      broadcastId: broadcastId,
      name: (name || broadcastId) ? `New Video: ${name || broadcastId}` : 'New Video',
      title: '',
      type,
    });
    const { projectId, updateVersion, processKey } = res;

    if (resolve) {
      yield call(waitForUpdateVersion, userId, updateVersion);
      yield call(() => resolve(projectId));
    }
    if (processKey) yield fork(displayProcess, processKey);
  } catch (err) {
    console.error(err);
    if (reject) yield call(reject);
  }
}

function* userRootSaga() {
  yield all([takeEvery(CREATE_PROJECT, createProjectSaga)]);
}

sagaMiddleware.run(userRootSaga);
