import React from 'react';
import { TryBroadcastAnalytics } from 'components/blocks/EventsTimeline/components/TryBroadcastAnalytics';
import { ActivityTimelineIntroPopup } from 'components/blocks/Timeline/TracksArea/Track/components/ActivityTimelineIntroPopup';
import { useToggle } from 'components/hooks/useToggle';
import { useLocalStorage } from 'components/hooks/useLocalStorage';

export const ActivityTimelineBanner = () => {
  const [isPopupVisible, togglePopupVisibility] = useToggle();
  const [
    isActivityTimelineBannerDismissed,
    dismissActivityTimelineBanner,
  ] = useLocalStorage('activityTimelineBanner', true);

  return isActivityTimelineBannerDismissed ? (
    <>
      <TryBroadcastAnalytics
        onClick={togglePopupVisibility}
        onClose={() => dismissActivityTimelineBanner(false)}
      />
      <ActivityTimelineIntroPopup
        isVisible={isPopupVisible}
        onClose={togglePopupVisibility}
      />
    </>
  ) : null;
};
