import { sagaMiddleware } from '../../sagas/sagaMiddleware';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  selectActiveProjectActiveMediaId,
  selectProjectId,
} from '../Project/selectors';
import { requests } from 'common/requests';
import { firebaseService } from 'services/firebase';
import firebase from 'firebase';
import { resetTimeline, updateTrimEnd, updateTrimStart } from './actions';
import { getEndTrimSelector, getStartTrimSelector } from './selectors';
import { MapFactory } from 'utils/models/MapFactory';
import { throttle } from 'lodash';
import {
  TIMELINE_TRIMM_POS_REDO,
  TIMELINE_TRIMM_POS_UNDO,
  TIMELINE_UPDATE_END_TRIMM_POS,
  TIMELINE_UPDATE_START_TRIMM_POS,
} from './types';
import { ActionWithPayload } from '../types';
import { onEveryProjectOpenSaga } from '../Project/sagas';
import { OpenProjectProps } from '../Project/types';
import { Project } from '../../types';
import { selectorChangeSaga } from '../../sagas/utils/selectorChageSaga';
import {
  defaultEndTrimPos,
  defaultStartTrimPos,
} from 'common/Redux/Timeline/constants';

const trimStartProperty: keyof Project = 'trimStart';
const trimEndProperty: keyof Project = 'trimEnd';
const THROTTLE_DELAY = 1000;

const throttledSendTrimData = new MapFactory<number>((projectId) => {
  const bindUpdateProject = requests.updateProject.bind(requests, projectId);
  return throttle(bindUpdateProject, THROTTLE_DELAY);
});

function* setInitialTrimStart(rootRef: firebase.database.Reference) {
  const trimStart = yield call(
    firebaseService.once,
    rootRef.child(trimStartProperty)
  );

  if (typeof trimStart === 'number' && trimStart >= 0) {
    yield put(yield call(updateTrimStart, trimStart || defaultStartTrimPos));
  }
}

function* setInitialTrimEnd(rootRef: firebase.database.Reference) {
  const trimEnd = yield call(
    firebaseService.once,
    rootRef.child(trimEndProperty)
  );

  if (typeof trimEnd === 'number' && trimEnd >= 0) {
    yield put(yield call(updateTrimEnd, trimEnd || defaultEndTrimPos));
  }
}

function* onProjectOpenSaga(
  previous: OpenProjectProps,
  next: OpenProjectProps
) {
  previous.ref?.child(trimStartProperty).off('value');
  previous.ref?.child(trimEndProperty).off('value');

  if (next.ref) {
    yield fork(setInitialTrimEnd, next.ref);
    yield fork(setInitialTrimStart, next.ref);
  }
}

function* sendTrimData() {
  const trimStart = yield select(getStartTrimSelector);
  const trimEnd = yield select(getEndTrimSelector);
  const projectId = yield select(selectProjectId);

  const throttledUpdateProject = throttledSendTrimData.get(projectId);
  yield call(throttledUpdateProject, { trimStart, trimEnd });
}

function* onTrimStartChangedSaga({
  payload: { ts, saveHistory },
}: ActionWithPayload<{ ts: number; saveHistory?: boolean }>) {
  yield put(yield call(updateTrimStart, ts, saveHistory));
  yield fork(sendTrimData);
}

function* onTrimEndChangedSaga({
  payload: { ts, saveHistory },
}: ActionWithPayload<{ ts: number; saveHistory?: boolean }>) {
  yield put(yield call(updateTrimEnd, ts, saveHistory));
  yield fork(sendTrimData);
}

function* onActiveMediaChangeSaga() {
  yield put(resetTimeline());
}

function* timelineRootSaga() {
  yield all([
    takeEvery(TIMELINE_UPDATE_START_TRIMM_POS, onTrimStartChangedSaga),
    takeEvery(TIMELINE_UPDATE_END_TRIMM_POS, onTrimEndChangedSaga),
    onEveryProjectOpenSaga(onProjectOpenSaga),
    takeEvery(TIMELINE_TRIMM_POS_UNDO, sendTrimData),
    takeEvery(TIMELINE_TRIMM_POS_REDO, sendTrimData),
    selectorChangeSaga(
      selectActiveProjectActiveMediaId,
      onActiveMediaChangeSaga
    ),
  ]);
}

sagaMiddleware.run(timelineRootSaga);
