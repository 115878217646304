import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { colors } from 'common/colors';
import { BUTTON_SIZES_ENUM, ButtonType } from './types';

const getSizeStyles = (size: BUTTON_SIZES_ENUM) => {
  switch (size) {
    case BUTTON_SIZES_ENUM.XS:
      return css`
        padding: 4px 8px;
        font-size: 12px;
        line-height: 16px;
      `;

    case BUTTON_SIZES_ENUM.S:
      return css`
        padding: 6px 8px;
        font-size: 14px;
        line-height: 20px;
      `;

    case BUTTON_SIZES_ENUM.M:
      return css`
        padding: 8px 16px;
        font-size: 20px;
        line-height: 20px;
      `;

    default:
      return ``;
  }
};

export const StyledButton = styled.button<{
  isDisabled: boolean;
  size: BUTTON_SIZES_ENUM;
  buttonType: ButtonType;
  primary: boolean;
}>`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  transition: all 0.1s ease;
  opacity: 0.8;
  border: none;
  user-select: none;

  color: ${colors.summerRabbit};
  fill: ${colors.summerRabbit};
  stroke: ${colors.summerRabbit};

  ${getTypeStyles};

  :hover {
    opacity: 1;
  }

  ${({ size }) => css`
    ${getSizeStyles(size)};
  `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      &, &:hover {
        opacity: 0.3;
        filter: grayscale(1);
        pointer-events: none;
        cursor: default;
      }
    `};
`;

function getTypeStyles({ buttonType, isDisabled }: { buttonType: ButtonType, isDisabled: boolean }) {
  switch (buttonType) {
    case ButtonType.Primary:
      return css`
        background-color: ${colors.tangerine};
        color: ${colors.darkGrey};
        fill: ${colors.darkGrey};
        stroke: ${colors.darkGrey};
        
        opacity: 1;
        
        &:hover {
          background-color: ${lighten(isDisabled ? 0 : 0.05, colors.tangerine)};
        }`;
    case ButtonType.Secondary:
      return css`
        border: 2px solid ${colors.tangerine};
        background: transparent;
      `;

    default:
      return css``;
  }
}
