import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CheckListProvider, useChecklistContext } from './context';
import { CustomScrollbar } from '../Select/SelectComponentStyles';
import { Header } from './components/Header';
import { Progress } from './components/Progress';
import { Item } from './components/Item';
import { ChecklistItems, LIST_IDS } from './types';
import { colors } from 'common/colors';
import { ArrowIcon } from './components/ArrowIcon';

const CHECKLIST_CONTENT_WIDTH = 260;
const CHECKLIST_MAX_HEIGHT = 440;

type ChecklistProps = {
  onComplete: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  items: ChecklistItems;
  onChange: (id: string, checked: boolean) => void;
  onOpenCallback?: (id: LIST_IDS) => void;
  id: LIST_IDS;
};

export const Checklist = ({ children, ...rest }: ChecklistProps) => {
  return (
    <CheckListProvider {...rest}>
      <Checklist.Styled>{children}</Checklist.Styled>
    </CheckListProvider>
  );
};

Checklist.Styled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 7px;
`;

export const Content = () => {
  const { isOpen, items, id } = useChecklistContext();
  return (
    <Content.Styled isOpen={isOpen}>
      <CustomScrollbar
        height={CHECKLIST_MAX_HEIGHT}
        autoHeight
        autoHeightMax={CHECKLIST_MAX_HEIGHT}
      >
        <StyledItemsContainer>
          {items.map((item) => (
            <Checklist.Item {...item} key={`${id}-${item.id}`} />
          ))}
        </StyledItemsContainer>
      </CustomScrollbar>
    </Content.Styled>
  );
};

const StyledItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 0 15px 0 5px;
`;

const slideOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateX(-${CHECKLIST_CONTENT_WIDTH + 20}px);
  }
`;
const slideIn = keyframes`
  from {
    opacity: 1;
    transform: translateX(-${CHECKLIST_CONTENT_WIDTH - 30}px);
  }
  to {
    opacity: 0;
    transform: translateX(0%);
  }
`;

Content.Styled = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: -25px;
  height: ${CHECKLIST_MAX_HEIGHT}px;
  width: ${CHECKLIST_CONTENT_WIDTH}px;
  opacity: 0;
  padding: 5px 0 5px 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  box-shadow: 2px 2px 10px lightgrey;

  ${(props) =>
    props.isOpen
      ? css`
          animation: ${slideOut} 0.3s ease-in-out forwards;
        `
      : css`
          animation: ${slideIn} 0.3s ease-in-out forwards;
          pointer-events: none;
        `};
`;

Checklist.Header = Header;
Checklist.Progress = Progress;
Checklist.Content = Content;
Checklist.Item = Item;
Checklist.ArrowIcon = ArrowIcon;
