export class MapFactory<K = any, V = any> {

  private readonly _map: Map<K, V> = new Map();

  constructor(
    private readonly _itemConstructor: (key: K) => V
  ) {

  }

  get(key: K): V {
    if (!this._map.has(key)) {
      this._map.set(key, this._itemConstructor(key));
    }

    return this._map.get(key) as V;
  }
}
