import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  StyledOption,
  StyledOptionIcon,
  StyledOptionName,
  StyledOptionsContainer,
  StyledSupport,
  StyledSupportButton,
} from 'components/ui/Support/Styles';
import questionIcon from 'components/ui/Support/Icons/question.svg';
import chatIcon from 'components/ui/Support/Icons/chat.svg';
import { analyticsEvents } from 'common/3rdUtils/analytics/analyticsEventsEnums';
import { Analytics, analytics, ANALYTICS_TYPES } from 'common/3rdUtils/analytics/analytics';
import { useBlur } from 'components/hooks/useBlur';
import { RefObject } from 'react';
// import articlesIcon from './Icons/articles.svg';
// import youtubeIcon from './Icons/youtube.svg';

const items = [
  {
    name: 'Articles',
    url: 'https://help.be.live/video-editor',
    icon: questionIcon,
  },
  // {
  //   name: 'Articles',
  //   url: 'https://help.belive.tv/en/collections/1790254-streaming-with-be-live',
  //   icon: articlesIcon,
  // },
  // {
  //   name: 'Tutorials',
  //   url: 'https://www.youtube.com/watch?v=LIEMSlkf6D8&list=PLpTOpxn224h02lqhq4wy-VUlUKgIEdTMi',
  //   icon: youtubeIcon,
  // },
  {
    name: 'Chat',
    icon: chatIcon,
    onClick: async () => {
      const instance = await Analytics.getInstance(ANALYTICS_TYPES.HELP_CRUNCH) as any;
      if (instance) {
        instance('openChat');
      }
    },
  },
].reverse();

export const Support: FC<{
  isVisible?: boolean;
  renderButton?: FC<{
    isMenuVisible: boolean;
    onMenuClick: () => void;
    openMenu: () => void;
    closeMenu: () => void;
  }>;
  className?: string;
}> = ({ isVisible = true, renderButton, className = '' }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isMenuVisible = isMenuOpened && isVisible;

  const openMenu = () => {
    setIsMenuOpened(true);
  };

  const closeMenu = () => {
    analytics.trackEvent(analyticsEvents.HELP, { source: 'None' });
    setIsMenuOpened(false);
  };

  const onMenuClick = isMenuOpened ? closeMenu : openMenu;

  const onBlur = useCallback(() => {
    if (isMenuOpened) {
      setIsMenuOpened(false);
      analytics.trackEvent(analyticsEvents.HELP, { source: 'None' });
    }
  }, [setIsMenuOpened, isMenuOpened]);

  useEffect(() => {
    if (isMenuOpened && !isVisible) {
      setIsMenuOpened(false);
    }
  }, [isMenuOpened, isVisible]);

  const ref = useBlur(onBlur);

  return (
    <StyledSupport
      ref={ref as RefObject<HTMLDivElement>}
      isVisible={isVisible}
      className={className}
    >
      <StyledOptionsContainer>
        {items.map((item, index) => {
          const { name, url, icon, onClick, ...restProps } = item;

          const onItemClick = () => {
            if (onClick) {
              onClick();
            } else if (url) {
              window.open(url, '_blank');
            }

            setIsMenuOpened(false);
            analytics.trackEvent(analyticsEvents.HELP, { source: name });
          };

          return (
            <StyledOption
              key={name}
              {...{ ...restProps, index, isMenuVisible, onClick: onItemClick }}
            >
              <StyledOptionName>{name}</StyledOptionName>
              <StyledOptionIcon icon={icon} />
            </StyledOption>
          );
        })}
      </StyledOptionsContainer>
      {renderButton ? (
        renderButton({
          isMenuVisible,
          onMenuClick,
          openMenu,
          closeMenu,
        })
      ) : (
        <StyledSupportButton
          isMenuVisible={isMenuVisible}
          onClick={onMenuClick}
        />
      )}
    </StyledSupport>
  );
};
